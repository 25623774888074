import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Constants from "./../../core/Constants";
import GtNavigationButtons from "src/components/Common/GtNavigationButtons";
import { AppContext } from "src/contexts/AppContext";
import { CoreInterfaces, DTOs } from "src/core/Models";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import GtExpandCollapseAll from "src/components/Common/GtExpandCollapseAll";
import AccountsReceivableSummaryPanel from "src/components/Panels/AccountsReceivableSummaryPanel";
import AccountsPayableSummaryPanel from "src/components/Panels/AccountsPayableSummaryPanel";
import GtLegend from "src/components/Common/GtLegend";
import CorporateCardManagementSummaryPanel from "src/components/Panels/CorporateCardManagementSummaryPanel";
import OtherAccountAndReconciliationSummaryPanel from "src/components/Panels/OtherAccountAndReconciliationSummaryPanel";
import ServiceCostSummaryPanel from "src/components/Panels/ServiceCostSummaryPanel";
import PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel from "src/components/Panels/PayrollAndExpenseAndTravelInvoiceManagement";
import EmptySummaryPanel from "src/components/Panels/EmptySummaryPanel";
import ServiceStaffingAllocationSummaryPanel from "src/components/Panels/ServiceStaffingAllocationSummaryPanel";
import {
  areApplicationConfVersionsChanged,
  isFinalorOutdatedVersion,
  mapServiceCodesToServicesDTOs,
} from "src/utils";
import AccountingRecurringServicesPanel from "src/components/Panels/AccountingRecurringServicesPanel";
import PayrollMonthlyRecurringServicesPanel from "src/components/Panels/PayrollMonthlyRecurringServicesPanel";
import OtherServiceTotalsPanel from "src/components/Panels/OtherServiceTotalsPanel";
import ContactPersonSummaryPanel from "src/components/Panels/ContactPersonSummaryPanel";
import ServiceStaffingAllocationExtendedSummaryPanel from "src/components/Panels/ServiceStaffingAllocationExtendedSummaryPanel";
import AdditionalCostsFixedPricePanel from "src/components/Panels/AdditionalCostsFixedPricePanel";
import AnnualReportingSummaryPanel from "src/components/Panels/AnnualReportingSummaryPanel";
import CalculationServiceTaskPanelWrapper from "src/components/Panels/CalculationServiceTaskPanelWrapper";
import GtExpandMoreIcon from "src/components/Common/GtExpandMoreIcon";

function PriceAndTimeCalculationPage(): JSX.Element {
  const { t } = useTranslation();
  const initialPageState: CoreInterfaces.PageState = {
    title: "Pages.PriceAndTimeCalculation.Title",
  };
  const [pageState] = useState<CoreInterfaces.PageState>(initialPageState);
  const [isDetailsAccordionExpanded, setIsDetailsAccordionExpanded] =
    useState(true);
  const { globalState, dispatch } = useContext(AppContext);

  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.AccountsReceivable,
    Constants.ServiceCode.AccountsPayable,
    Constants.ServiceCode.CorporateCardManagement,
    Constants.ServiceCode.OtherAccountAndReconciliation,
    Constants.ServiceCode.PeriodReporting,
    Constants.ServiceCode.InterimListedCompanies,
    Constants.ServiceCode.AnnualReporting,
    Constants.ServiceCode.AnnualPayrollRoutines,
    Constants.ServiceCode.AnnualReportingListedCompanies,
    Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
    Constants.ServiceCode.StartUpAccounting,
    Constants.ServiceCode.StartUpPayroll,
    Constants.ServiceCode.YearlyInternalDocumentationAndFormalities,
  ]);

  const hasSelectedServices = globalState.services.some(
    (service) =>
      service.data.code !== Constants.ServiceCode.EngagementCounseling &&
      service.state.canBeSelectedInServicePage &&
      service.state.isSelected
  );

  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);

  const shouldDisplayRatesChangeMessage =
    globalState.state.isRatesChangeMessagesShow &&
    areApplicationConfVersionsChanged(globalState) &&
    !isFinalorOutdatedVersionStatus;

  function handleExpandedAccordionChange(code: Constants.ServiceCode) {
    const serviceDTO = globalState.services.find(
      (item) => item.data.code === code
    );
    const nextService: DTOs.ServiceDTO = {
      ...serviceDTO,
      state: {
        ...serviceDTO.state,
        isExpandedPriceAndTimeCalculationAccordion:
          !serviceDTO.state.isExpandedPriceAndTimeCalculationAccordion,
      },
    };
    dispatch({
      type: Constants.AppStateActions.ServiceExpandChange,
      payload: nextService,
    });
  }

  function toggleExpandedAll(flag: boolean): void {
    const nextServices = globalState?.services?.map(
      (serviceDTO: DTOs.ServiceDTO) => {
        const nextService: DTOs.ServiceDTO = {
          ...serviceDTO,
          state: {
            ...serviceDTO.state,
            isExpandedPriceAndTimeCalculationAccordion: serviceDTO.state
              .isSelected
              ? flag
              : serviceDTO.state.isExpandedPriceAndTimeCalculationAccordion,
          },
        };

        return nextService;
      }
    );

    dispatch({
      type: Constants.AppStateActions.ServicesOverride,
      payload: nextServices,
    });
  }

  return (
    <article
      className={`gt-priceAndTimeCalculationPage ${
        hasSelectedServices
          ? "gt-priceAndTimeCalculationPage--withSelectedServicesState"
          : ""
      } ${
        shouldDisplayRatesChangeMessage && hasSelectedServices
          ? "gt-priceAndTimeCalculationPage--withPositiveTopMarginVariant"
          : ""
      }`}
    >
      <section className="gt-priceAndTimeCalculationPage__topHeader gt-pageTopPanel">
        <Typography
          className="gt-pageTopPanel__title"
          variant="h5"
          gutterBottom={true}
        >
          {t(pageState.title)}
        </Typography>
        <div className="gt-pageTopPanel__actions">
          <GtLegend />
          <GtExpandCollapseAll
            toggleExpandedAll={(flag: boolean) => {
              toggleExpandedAll(flag);
            }}
            expandButtonText={t("General.ExpandDetails")}
            collapseButtonText={t("General.CollapseDetails")}
          ></GtExpandCollapseAll>
        </div>
      </section>

      <section className="gt-priceAndTimeCalculationPage__summarySection">
        <AccountingRecurringServicesPanel
          serviceRecurringType={Constants.ServiceRecurringType.Monthly}
        />
        <AccountingRecurringServicesPanel
          serviceRecurringType={Constants.ServiceRecurringType.Quarterly}
        />
        <PayrollMonthlyRecurringServicesPanel />
        <OtherServiceTotalsPanel
          serviceRecurringType={Constants.ServiceRecurringType.Yearly}
          includeStaffingBreakdown={true}
        />
        <OtherServiceTotalsPanel
          serviceRecurringType={Constants.ServiceRecurringType.OneTime}
          includeStaffingBreakdown={false}
        />
      </section>

      {hasSelectedServices && (
        <section className="gt-priceAndTimeCalculationPage__regularServices">
          <Accordion
            className="gt-priceAndTimeAccordion"
            onChange={() => {
              setIsDetailsAccordionExpanded(
                (currentExpansionState) => !currentExpansionState
              );
            }}
            expanded={isDetailsAccordionExpanded}
          >
            <AccordionSummary
              className="gt-priceAndTimeAccordion__summary"
              expandIcon={<GtExpandMoreIcon />}
            >
              <span className="gt-priceAndTimeAccordion__summary-title">
                {t("General.ServiceDetails")}
              </span>
              <span className="gt-priceAndTimeAccordion__summary-expandedText">
                {isDetailsAccordionExpanded
                  ? t("General.CollapseDetails")
                  : t("General.ExpandDetails")}
              </span>
            </AccordionSummary>
            <AccordionDetails className="gt-priceAndTimeAccordion__content">
              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[Constants.ServiceCode.AccountsReceivable]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.AccountsReceivable
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AccountsReceivable]
                    }
                    taskGroups={[Constants.TaskGroup.DefaultGroup]}
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AccountsReceivable]
                    }
                  >
                    <AccountsReceivableSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={servicesMap[Constants.ServiceCode.AccountsPayable]}
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.AccountsPayable
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AccountsPayable]
                    }
                    taskGroups={[Constants.TaskGroup.DefaultGroup]}
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AccountsPayable]
                    }
                  >
                    <AccountsPayableSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[Constants.ServiceCode.CorporateCardManagement]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.CorporateCardManagement
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.CorporateCardManagement]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.CorporateCardManagement]
                    }
                  >
                    <CorporateCardManagementSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[
                    Constants.ServiceCode.OtherAccountAndReconciliation
                  ]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.OtherAccountAndReconciliation
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode.OtherAccountAndReconciliation
                      ]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode.OtherAccountAndReconciliation
                      ]
                    }
                  >
                    <OtherAccountAndReconciliationSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={servicesMap[Constants.ServiceCode.PeriodReporting]}
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.PeriodReporting
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.PeriodReporting]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.PeriodReporting]
                    }
                  >
                    <ContactPersonSummaryPanel
                      serviceDTO={
                        servicesMap[Constants.ServiceCode.PeriodReporting]
                      }
                    />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[Constants.ServiceCode.InterimListedCompanies]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.InterimListedCompanies
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.InterimListedCompanies]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.InterimListedCompanies]
                    }
                  >
                    <ContactPersonSummaryPanel
                      serviceDTO={
                        servicesMap[Constants.ServiceCode.PeriodReporting]
                      }
                    />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={servicesMap[Constants.ServiceCode.AnnualReporting]}
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.AnnualReporting
                  );
                }}
              >
                <>
                  <AdditionalCostsFixedPricePanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AnnualReporting]
                    }
                  />
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AnnualReporting]
                    }
                    taskGroups={[
                      Constants.TaskGroup.AnnualReport,
                      Constants.TaskGroup.StatutoryAnnualReport,
                      Constants.TaskGroup.CorporateIncomeTax,
                      Constants.TaskGroup.AnnualReportV2,
                      Constants.TaskGroup.AnnualReportV3,
                    ]}
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AnnualReporting]
                    }
                  >
                    <AnnualReportingSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[
                    Constants.ServiceCode.AnnualReportingListedCompanies
                  ]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.AnnualReportingListedCompanies
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode.AnnualReportingListedCompanies
                      ]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode.AnnualReportingListedCompanies
                      ]
                    }
                  >
                    <ContactPersonSummaryPanel
                      serviceDTO={
                        servicesMap[Constants.ServiceCode.AnnualReporting]
                      }
                    />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[
                    Constants.ServiceCode
                      .PayrollAndExpenseAndTravelInvoiceManagement
                  ]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode
                      .PayrollAndExpenseAndTravelInvoiceManagement
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationExtendedSummaryPanel
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode
                          .PayrollAndExpenseAndTravelInvoiceManagement
                      ]
                    }
                    taskGroups={[
                      Constants.TaskGroup.MonthlyPayrollRun,
                      Constants.TaskGroup.MonthlyPayrollReconciliation,
                      Constants.TaskGroup.MonthlyReportingPayroll,
                    ]}
                  />
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode
                          .PayrollAndExpenseAndTravelInvoiceManagement
                      ]
                    }
                    taskGroups={[
                      Constants.TaskGroup.AdditionalPayrollServices,
                      Constants.TaskGroup.PayrollServicesInAddition,
                    ]}
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode
                          .PayrollAndExpenseAndTravelInvoiceManagement
                      ]
                    }
                  >
                    <PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[Constants.ServiceCode.AnnualPayrollRoutines]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.AnnualPayrollRoutines
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AnnualPayrollRoutines]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.AnnualPayrollRoutines]
                    }
                  >
                    <PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[Constants.ServiceCode.StartUpAccounting]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.StartUpAccounting
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.StartUpAccounting]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.StartUpAccounting]
                    }
                  >
                    <EmptySummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={servicesMap[Constants.ServiceCode.StartUpPayroll]}
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode.StartUpPayroll
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.StartUpPayroll]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[Constants.ServiceCode.StartUpPayroll]
                    }
                  >
                    <EmptySummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>

              <ServiceCostSummaryPanel
                serviceDTO={
                  servicesMap[
                    Constants.ServiceCode
                      .YearlyInternalDocumentationAndFormalities
                  ]
                }
                onExpandChange={() => {
                  handleExpandedAccordionChange(
                    Constants.ServiceCode
                      .YearlyInternalDocumentationAndFormalities
                  );
                }}
              >
                <>
                  <ServiceStaffingAllocationSummaryPanel
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode
                          .YearlyInternalDocumentationAndFormalities
                      ]
                    }
                  />
                  <CalculationServiceTaskPanelWrapper
                    serviceDTO={
                      servicesMap[
                        Constants.ServiceCode
                          .YearlyInternalDocumentationAndFormalities
                      ]
                    }
                  >
                    <EmptySummaryPanel />
                  </CalculationServiceTaskPanelWrapper>
                </>
              </ServiceCostSummaryPanel>
            </AccordionDetails>
          </Accordion>
        </section>
      )}

      <GtNavigationButtons current={2} />
    </article>
  );
}

export default PriceAndTimeCalculationPage;
