import { CoreInterfaces } from "../Models";
import * as Constants from "./../Constants";

export const priceListConfig: CoreInterfaces.PayrollPriceListConfig = {
  [Constants.PriceList.PriceList1]: {
    Thresholds: [
      {
        AmountMin: Number.MIN_SAFE_INTEGER,
        AmountMax: Number.MAX_SAFE_INTEGER,
        BasicEmployeePrice: 2500,
        FlexHRMPrice: null,
        WorkerPrice: null,
      },
    ],
    PriceListDataType: Constants.PriceListDataType.FixedPrice,
  },
  [Constants.PriceList.PriceList2]: {
    Thresholds: [
      {
        AmountMin: 1,
        AmountMax: 3,
        BasicEmployeePrice: 3000,
        FlexHRMPrice: null,
        WorkerPrice: null,
      },
      {
        AmountMin: 4,
        AmountMax: 10,
        BasicEmployeePrice: 6000,
        FlexHRMPrice: null,
        WorkerPrice: null,
      },
    ],
    PriceListDataType: Constants.PriceListDataType.FixedPrice,
  },
  [Constants.PriceList.PriceList3]: {
    Thresholds: [
      {
        AmountMin: 1,
        AmountMax: 3,
        BasicEmployeePrice: 2500,
        FlexHRMPrice: 500,
        WorkerPrice: 1000,
      },
      {
        AmountMin: 4,
        AmountMax: 10,
        BasicEmployeePrice: 5000,
        FlexHRMPrice: 1000,
        WorkerPrice: 2000,
      },
    ],
    PriceListDataType: Constants.PriceListDataType.FixedPrice,
  },
  [Constants.PriceList.PriceList4]: {
    Thresholds: [
      {
        AmountMin: 1,
        AmountMax: 25,
        BasicEmployeePrice: 250,
        FlexHRMPrice: 50,
        WorkerPrice: 20,
      },
      {
        AmountMin: 26,
        AmountMax: 50,
        BasicEmployeePrice: 230,
        FlexHRMPrice: 50,
        WorkerPrice: 20,
      },
      {
        AmountMin: 51,
        AmountMax: 100,
        BasicEmployeePrice: 210,
        FlexHRMPrice: 50,
        WorkerPrice: 20,
      },
      {
        AmountMin: 101,
        AmountMax: Number.MAX_SAFE_INTEGER,
        BasicEmployeePrice: 190,
        FlexHRMPrice: 50,
        WorkerPrice: 20,
      },
    ],
    PriceListDataType: Constants.PriceListDataType.DynamicPrice,
  },
};
