import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import {
  mapArrayToBoolean,
  mapQuestionCodesToQuestionDTOs,
  populateCheckboxes,
} from "src/utils/question-utils";

export const AnnualReportingProcessor: CoreInterfaces.QuestionProcessors = (
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) => {
  const { Q0601, Q0604, Q0605, Q0607, Q0610, Q0611, Q0613 } =
    Constants.AnnualReportingQuestions;
  const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
    Q0601,
    Q0604,
    Q0605,
    Q0607,
    Q0610,
    Q0611,
    Q0613,
  ]);

  return {
    [Constants.AnnualReportingQuestions.Q0601]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const requiredService: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.AccountsReceivable,
        Constants.ServiceCode.AccountsPayable,
        Constants.ServiceCode.CorporateCardManagement,
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
        Constants.ServiceCode.InterimListedCompanies,
        Constants.ServiceCode.AnnualPayrollRoutines,
        Constants.ServiceCode.AnnualReportingListedCompanies,
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.StartUpAccounting,
        Constants.ServiceCode.StartUpPayroll,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          requiredService.includes(eachService.data.code)
      );
      const matchService = appState.services.some(
        (service) =>
          service.data.code === Constants.ServiceCode.AnnualReporting &&
          service.state.isSelected
      );
      questionDTO.state.isShown = !hasMatchingServices && matchService;
    },
    [Constants.AnnualReportingQuestions.Q0602]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.AnnualReportingQuestions.Q0603]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
        appState.generalVariables.isCompanyRegisteredVAT;
    },
    [Constants.AnnualReportingQuestions.Q0605]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3;
    },
    [Constants.AnnualReportingQuestions.Q0606]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const requiredService: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.OtherAccountAndReconciliation,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          requiredService.includes(eachService.data.code)
      );
      questionDTO.state.isShown = !hasMatchingServices;
    },
    [Constants.AnnualReportingQuestions.Q0607]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = (
        questionDTOMap.Q0610.data.userValue as string
      ).includes(Constants.PartOfAnnualAccounts.CompanyDeclaration);
    },
    [Constants.AnnualReportingQuestions.Q0608]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        {
          key: Constants.HelpfulConstants.PleaseSelect,
          value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
        },
        ...(appState.remoteData?.customerContactPersons || []),
      ];
    },
    [Constants.AnnualReportingQuestions.Q0610]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      if (
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes
      ) {
        questionDTO.data.userValue = populateCheckboxes([
          Constants.PartOfAnnualAccounts.AnnualAccounts,
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]);
      }
    },
    [Constants.AnnualReportingQuestions.Q0611]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const requiredServices: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          requiredServices.includes(eachService.data.code)
      );
      questionDTO.state.isShown = hasMatchingServices;
    },
    [Constants.AnnualReportingQuestions.Q0612]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.isAuditorGrantThornton;
    },
    [Constants.AnnualReportingQuestions.Q0613]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const requiredServices = [
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
        Constants.ServiceCode.AnnualReportingListedCompanies,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          requiredServices.includes(eachService.data.code)
      );

      questionDTO.state.isShown = !hasMatchingServices;
    },
    [Constants.AnnualReportingQuestions.Q0615]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0616]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        !questionDTOMap.Q0613.state.isShown &&
        questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0617]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.Yes &&
        !questionDTOMap.Q0613.state.isShown &&
        questionDTOMap.Q0611.state.isShown &&
        !appState.generalVariables.isAuditorGrantThornton;
    },
    [Constants.AnnualReportingQuestions.Q0618]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        questionDTOMap.Q0613.state.isShown &&
        !questionDTOMap.Q0611.state.isShown &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        appState.generalVariables.isAuditorGrantThornton;
    },
    [Constants.AnnualReportingQuestions.Q0619]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.isAuditorGrantThornton &&
        questionDTOMap.Q0613.state.isShown &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0620]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0621]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0622]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0623]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.Yes &&
        !appState.generalVariables.isAuditorGrantThornton &&
        questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0624]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
        ]) &&
        !(questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.CompanyDeclaration
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        appState.generalVariables.isAuditorGrantThornton &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        questionDTOMap.Q0613.state.isShown &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0625]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.isAuditorGrantThornton &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        questionDTOMap.Q0613.state.isShown &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0626]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        !(questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        appState.generalVariables.isAuditorGrantThornton &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0627]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        !(questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualAccounts
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0628]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0629]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0630]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0631]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.AnnualReport
        ) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.Yes &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0632]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.CompanyDeclaration
        ) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.No &&
        questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0633]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.CompanyDeclaration
        ) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.Yes &&
        questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0634]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.CompanyDeclaration
        ) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.No &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0635]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        (questionDTOMap.Q0610.data.userValue as string).includes(
          Constants.PartOfAnnualAccounts.CompanyDeclaration
        ) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.Yes &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0636]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
        !questionDTOMap.Q0611.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0637]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.No &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0638]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0607.data.userValue === Constants.YesNo.Yes &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        !questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0639]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualAccounts,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K2 &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0640]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualAccounts,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.No &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0641]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualAccounts,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        ((questionDTOMap.Q0601.state.isShown &&
          questionDTOMap.Q0601.data.userValue === Constants.YesNo.No) ||
          !questionDTOMap.Q0601.state.isShown) &&
        !appState.generalVariables.isAuditorGrantThornton &&
        !questionDTOMap.Q0611.state.isShown &&
        questionDTOMap.Q0605.data.userValue === Constants.YesNo.Yes &&
        questionDTOMap.Q0613.state.isShown;
    },
    [Constants.AnnualReportingQuestions.Q0642]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        mapArrayToBoolean(questionDTOMap.Q0610, [
          Constants.PartOfAnnualAccounts.AnnualReport,
          Constants.PartOfAnnualAccounts.AnnualAccounts,
          Constants.PartOfAnnualAccounts.CompanyDeclaration,
        ]) &&
        questionDTOMap.Q0604.data.userValue === Constants.CompanyReport.K3 &&
        questionDTOMap.Q0601.state.isShown &&
        questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
        !questionDTOMap.Q0611.state.isShown;
    },
  };
};
