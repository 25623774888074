import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";

function GtAboutDialog({
  onAboutDialogClose,
}: CorePropsInterfaces.GtAboutDialogProps): JSX.Element {
  const aboutDialogDTO: DTOs.GtDialogDTO = {
    data: {
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [],
    },
    api: {
      onClose: onAboutDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  return (
    <GtDialog gtDialogDTO={aboutDialogDTO}>
      <article className="gt-aboutDialog">
        <section
          className="gt-aboutDialog__logo"
          style={{ backgroundImage: "url(/resources/gt-logo-dark.svg)" }}
        ></section>
        <section className="gt-aboutDialog__info">
          <div className="gt-aboutDialog__info-label">{t("General.About")}</div>
          <div className="gt-aboutDialog__info-value">
            {process.env.REACT_APP_VERSION}
          </div>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtAboutDialog;
