import { Button, TextField } from "@mui/material";
import { t } from "i18next";
import GtDialog from "../Common/GtDialog";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import { useContext, useState } from "react";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "../../core/Constants";
import { fetchData } from "src/utils";
import { useQuery } from "src/core/Hooks";
import { LoadingContext } from "src/contexts/LoadingContext";

export default function ChangeHourlyRatesDialog({
  onClose,
}: CorePropsInterfaces.ChangeHourlyRatesDialogProps) {
  const { globalState, dispatch } = useContext(AppContext);
  const { isLoading, updateApplicationLoadingState } =
    useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );

  const rateCartsConfigurationMap: CoreInterfaces.RateCartWithAdjustedPriceMap =
    {};
  globalState.currentConfiguration.applicationConfiguration.rateCartConfiguration.RateCarts.forEach(
    (rateCart) => {
      rateCartsConfigurationMap[rateCart.StaffLevelCode] = {
        rateOnHour: rateCart.RateOnHour,
        adjustedRateOnHour: null,
      };
    }
  );

  const rateCartsRows =
    globalState.currentConfiguration.applicationConfiguration
      .rateCartConfiguration.RateCarts;

  const [rateCartsState, setRateCartsState] =
    useState<CoreInterfaces.RateCartWithAdjustedPriceMap>(
      rateCartsConfigurationMap
    );

  const [isFormDirty, setIsFormDirty] = useState(false);

  function closeDialog() {
    setRateCartsState(rateCartsConfigurationMap);
    onClose && onClose();
  }

  function save() {
    updateApplicationLoadingState(true);

    const rateCartsArr: Array<CoreInterfaces.RateCart> = [];
    for (const staffLevelCodeKey in rateCartsState) {
      const staffLevelCode = staffLevelCodeKey as Constants.StaffLevel;
      rateCartsArr.push({
        StaffLevelCode: staffLevelCode,
        RateOnHour:
          rateCartsState[staffLevelCode].adjustedRateOnHour ??
          rateCartsState[staffLevelCode].rateOnHour,
      });
    }
    const rateCartConfiguration: CoreInterfaces.RateCartConfiguration = {
      Version:
        globalState.currentConfiguration.applicationConfiguration
          .rateCartConfiguration.Version,
      RateCarts: rateCartsArr,
      Description:
        globalState.currentConfiguration.applicationConfiguration
          .rateCartConfiguration.Description,
    };

    const queryParams = new URLSearchParams({
      businessOpportunityId,
      id: globalState.currentConfiguration.id,
    });

    fetchData<CoreInterfaces.EngagementConfigurationReadPack>(
      `${Constants.APIPath.RateCart}?${queryParams}`,
      Constants.APIMethod.PUT,
      rateCartConfiguration
    )
      .then((engagementConfigurationData) => {
        const action = {
          type: Constants.AppStateActions.UpdateRateCartsConfiguration,
          payload: engagementConfigurationData.RateCartConfiguration,
        };
        dispatch(action);
        const alertConfiguration: CoreInterfaces.AlertConfiguration = {
          severity: Constants.AlertSeverity.Success,
          message: t("General.RateCartsConfigurationSaveSuccess"),
          isDisplayed: true,
        };
        onClose(alertConfiguration);
      })
      .catch(() => {
        const alertConfiguration: CoreInterfaces.AlertConfiguration = {
          severity: Constants.AlertSeverity.Error,
          message: t("General.RateCartsConfigurationSaveFail"),
          isDisplayed: true,
        };
        onClose(alertConfiguration);
      })
      .finally(() => {
        updateApplicationLoadingState(false);
      });
  }

  const onRateCartPriceChange = (
    val: any,
    rateCart: CoreInterfaces.RateCart
  ): void => {
    if (!isNaN(val) || val === Constants.HelpfulConstants.EmptyString) {
      const nextState = {
        ...rateCartsState,
        [rateCart.StaffLevelCode]: {
          ...rateCartsState[rateCart.StaffLevelCode],
          adjustedRateOnHour: !!val
            ? Number(val)
            : Constants.HelpfulConstants.EmptyString,
        },
      };
      const isFormDirty = Object.keys(nextState).some((staffLevel) => {
        const rateCartPayload = nextState[staffLevel as Constants.StaffLevel];
        return (
          !!rateCartPayload.adjustedRateOnHour &&
          rateCartPayload.adjustedRateOnHour !== rateCartPayload.rateOnHour
        );
      });
      setRateCartsState(nextState);
      setIsFormDirty(isFormDirty);
    }
  };

  const auditDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.ChangeHourlyRate"),
      maxWidth: "sm",
      rightButtons: [
        <Button
          disabled={isLoading}
          onClick={() => closeDialog()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          disabled={!isFormDirty || isLoading}
          onClick={() => save()}
          color="tertiary"
          variant="contained"
          key="confirmBtn"
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: closeDialog,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };
  return (
    <GtDialog gtDialogDTO={auditDialogDTO}>
      <article className="gt-hourlyRatesForm">
        <section className="gt-hourlyRatesForm__row gt-hourlyRatesForm__row--title">
          <div className="gt-hourlyRatesForm__row-staffLevel">
            {t("General.StaffLevel")}
          </div>
          <div className="gt-hourlyRatesForm__row-pricePerHour">
            {t("General.PricePerHour")}
          </div>
          <div className="gt-hourlyRatesForm__row-newPrice">
            {t("General.NewPrice")}
          </div>
        </section>
        {rateCartsRows.map((rateCartsRow) => (
          <section
            key={`rateCartRow${rateCartsRow.StaffLevelCode}`}
            className="gt-hourlyRatesForm__row"
          >
            <div className="gt-hourlyRatesForm__row-staffLevel">
              {t(`Options.${rateCartsRow.StaffLevelCode}`)}
            </div>
            <div className="gt-hourlyRatesForm__row-pricePerHour">
              <TextField
                className="gt-TextField"
                variant="outlined"
                inputProps={{ maxLength: 5 }}
                value={rateCartsRow.RateOnHour}
              />
              <span>{Constants.Currency.kr}</span>
            </div>
            <div className="gt-hourlyRatesForm__row-newPrice">
              <TextField
                className="gt-TextField"
                variant="outlined"
                inputProps={{ maxLength: 5 }}
                value={
                  rateCartsState[rateCartsRow.StaffLevelCode]
                    .adjustedRateOnHour ?? ""
                }
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                  onRateCartPriceChange(evt.target.value, rateCartsRow)
                }
              />
              <span>{Constants.Currency.kr}</span>
            </div>
          </section>
        ))}
      </article>
    </GtDialog>
  );
}
