import { useReducer, createContext } from "react";
import { appReducer } from "../core/Context";
import { CoreInterfaces, DTOs } from "../core/Models";
import { createInitialState, getInitialState } from "../utils";
import { servicesConfiguration } from "../core/Configurations/Services.config";
import { formServiceDTOs } from "../utils/dto";
import { applyUpdatesOnQuestionChange } from "../utils/processors/Questions";
import * as Constants from "../core/Constants";
import * as StateProcessors from "../core/Context/state-processors";

const initialAppState = createInitialState();

const serviceHandlingFunction = (service: DTOs.ServiceDTO) => {
  return applyUpdatesOnQuestionChange(initialAppState, service);
};

const services = formServiceDTOs(
  servicesConfiguration,
  serviceHandlingFunction
);

const generalInformation = services.find(
  (service) => service.data.code === Constants.ServiceCode.GeneralInformation
);

const generalVariables =
  StateProcessors.createGeneralVariablesObject(generalInformation);
generalInformation.data.questions.forEach((eachGeneralQuestion) => {
  StateProcessors.updateServicesOnGeneralQuestionChange(
    services,
    eachGeneralQuestion,
    generalVariables
  );
});

initialAppState.services = services;
initialAppState.generalVariables = generalVariables;

export const AppContext = createContext<{
  globalState: CoreInterfaces.AppState;
  dispatch: Function;
  businessUnit: Constants.BusinessUnit;
}>({
  globalState: initialAppState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  businessUnit: null,
});

export function AppContextProvider({
  children,
  businessUnit,
}: {
  children: JSX.Element;
  businessUnit: Constants.BusinessUnit;
}): JSX.Element {
  const appState = getInitialState(businessUnit);
  const [globalState, dispatch] = useReducer(appReducer, appState);

  const providerValue = {
    globalState,
    dispatch,
    businessUnit,
  };

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}
