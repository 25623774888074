import "./ErrorPage.scss";
import { useTranslation } from "react-i18next";
import GtSystemAdministrationMessages from "src/components/Common/GTSystemAdministrationMessages";
import GtSpinner from "src/components/Common/GtSpinner";

function ErrorPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <article className="gt-errorPage">
      <section className="gt-errorPage__logo">
        <GtSpinner />
        <span className="gt-errorPage__logo-text">GrantThornton</span>
      </section>
      <section className="gt-errorPage__administrationMessages">
        <GtSystemAdministrationMessages />
      </section>
      <section className="gt-errorPage__title">
        <h3>{t("General.AnErrorHasOccured")}</h3>
      </section>
    </article>
  );
}

export default ErrorPage;
