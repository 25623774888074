import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import { CoreInterfaces, DTOs } from "src/core/Models";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as Constants from "./../../core/Constants";
import {
  fetchData,
  generateEngagementDocument,
  formatDate,
  generatePdfDocument,
  getEngagementTotals,
  isDigitallySigningChecked,
  sendFile,
  updateStateOnEngagementConfigurationRead,
  generateEngagementDescriptionWordDocument,
  fetchPdfFile,
  isEngagementInFinalVersion,
} from "src/utils";
import { useQuery } from "src/core/Hooks";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import {
  handleConfigurationPersistence,
  saveCurrentConfiguration,
} from "src/utils/handle-configuration-utils";
import GtSetFinalDocumentDialog from "src/components/Common/GtSetFinalDocumentDialog";
import {
  loginRequest,
  sharepointAccessRequestConfig,
} from "./../../msalConfig";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { LoadingContext } from "src/contexts/LoadingContext";
import GtInfoTooltip from "src/components/Common/GtInfoTooltip";
import GtAlertBanner from "src/components/Common/GtAlertBanner";
import {
  deleteSPEngagementFolder,
  executeFinalizeEngagementDocumentFlow,
  resolveSPFolder,
  openSharepointURLInMsWordClient,
  resolveGenerateDocumentsPageConfiguration,
  sendFileToSharepoint,
  isEmailMissingForDigitalSigningUsers,
  resolveDigitalSigningContacts,
  removeOldMetadataDocuments,
} from "src/utils/generate-documents";
import EngagementLetterDocumentsManagerPanel from "src/components/Panels/EngagementLetterDocumentsManagerPanel";
import { useSnackBar } from "src/contexts/SnackbarContext";
import GtEngagementLetterRegenerateConfirmModal from "src/components/Common/GtEngagementLetterRegenerateConfirmModal";
import {
  fetchEngagementDocuments,
  handleFileUpload,
} from "src/utils/documents-manager";
import GtEngagementLetterDocumentsManagerPanelLinks from "src/components/Common/GtEngagementLetterDocumentsManagerPanelLinks";
import GtSetToManuallySignedDialog from "src/components/Common/GtSetToManuallySignedDialog";
import i18n from "src/i18n";

function GenerateDocumentsPage(): JSX.Element {
  const { globalState, dispatch, businessUnit } = useContext(AppContext);
  const { updateApplicationLoadingState, isLoading: isPageLoaderVisible } =
    useContext(LoadingContext);
  const { t } = useTranslation();
  const queryParamsMap = useQuery();
  const { instance } = useMsal();
  const { showSnackBar } = useSnackBar();

  const [accessToken, setAccessToken] = useState(undefined);
  const [
    isEngagementLetterRegenerateConfirmModalVisible,
    setIsEngagementLetterRegenerateConfirmModalVisible,
  ] = useState(false);
  const [engagementDocuments, setEngagementDocuments] = useState<
    Array<CoreInterfaces.EngagementDocument>
  >([]);

  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );

  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);
  const initialPageState: CoreInterfaces.PageState = {
    title: "Pages.GenerateDocuments.Title",
  };
  const [pageState] = useState<CoreInterfaces.PageState>(initialPageState);
  const [isVisibleSetFinalDocumentDialog, setIsVisibleSetFinalDocumentDialog] =
    useState(false);
  const [
    isVisibleSetManuallySignedDialog,
    setIsVisibleSetManuallySignedDialog,
  ] = useState(false);

  const [language, setLanguage] = useState(
    (localStorage.getItem(
      Constants.i18nDocumentStorageKey
    ) as Constants.Languages) || Constants.Languages.SE
  );

  useEffect(() => {
    if (
      isEngagementInFinalVersion(globalState) &&
      globalState.currentConfiguration.engagementDocumentsLanguage
    ) {
      setLanguage(globalState.currentConfiguration.engagementDocumentsLanguage);
      localStorage.setItem(
        Constants.i18nDocumentStorageKey,
        globalState.currentConfiguration.engagementDocumentsLanguage
      );
    }
  }, [isEngagementInFinalVersion(globalState)]);

  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        (event.payload as AuthenticationResult).account
      ) {
        setAccessToken((event.payload as AuthenticationResult).accessToken);
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  useEffect(() => {
    if (globalState.currentConfiguration.id) {
      if (accessToken) {
        const documentsFlow = localStorage.getItem(
          Constants.LOCAL_STORAGE_DOCUMENT_FLOW_KEY
        );
        if (!!documentsFlow) {
          if (
            documentsFlow ===
            Constants.GenerateDocumentsDocumentFlow.SendFilesToSharepoint
          ) {
            const documentGenerateHandler = async () => {
              return await handleGenerateDocuments(accessToken);
            };
            documentGenerateHandler();
          } else if (
            documentsFlow ===
            Constants.GenerateDocumentsDocumentFlow.SetFinalVersion
          ) {
            const createFinalDocumentHandler = () => {
              return createFinalDocument(accessToken);
            };
            createFinalDocumentHandler();
          }
          localStorage.removeItem(Constants.LOCAL_STORAGE_FORCE_REDIRECT_KEY);
        } else {
          if (documentPageConfig.isFinalVersion && !isDigitallySigned()) {
            if (!isPageLoaderVisible) {
              updateApplicationLoadingState(true);
            }
            const sendForDigitallySignedDocumentWrrapperFn = () => {
              return sendForDigitalSigningDocument();
            };
            sendForDigitallySignedDocumentWrrapperFn();
          }
        }
      }
      fetchAndPopulateEngagementDocuments();
    }
  }, [globalState.currentConfiguration.id]);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const configurationNo = globalState.currentConfiguration.version
    ? globalState.currentConfiguration.version
    : "1";
  const configurationName = t("General.ConfigurationVersionNumber", {
    configurationNo,
  });
  const description = globalState.currentConfiguration.description
    ? globalState.currentConfiguration.description
    : "";

  const genericService = globalState.services.find(
    (serviceDTO) =>
      serviceDTO.data.code === Constants.ServiceCode.GeneralInformation
  );

  const createdBy = t("General.CreatedBy", {
    name: globalState.currentConfiguration.createdBy,
    datetime: new Date(
      globalState.currentConfiguration.createdDate
    ).toLocaleDateString([], options),
  });

  const documentPageConfig = resolveGenerateDocumentsPageConfiguration(
    globalState,
    businessUnit
  );

  const engagementDescriptionDocumentArray = [];
  if (documentPageConfig.isFinalVersion) {
    const engagementDocument = engagementDocuments.find(
      (engagementDocument) =>
        engagementDocument.Type ===
        Constants.EngagementDocumentType.EngagementDescription
    );
    if (engagementDocument) {
      engagementDescriptionDocumentArray.push({
        ...engagementDocument,
        OriginalFileName: t("General.EngagementDescription"),
      });
    }
  } else {
    engagementDescriptionDocumentArray.push({
      Id: "EngagementDescriptionDocument",
      OriginalFileName: t("General.GenerateEngagementDescription"),
      DocumentType: Constants.EngagementDocumentType.EngagementDescription,
    });
  }

  const isEmailMissingDigitalSigningUsers =
    isEmailMissingForDigitalSigningUsers(globalState);

  function fetchAndPopulateEngagementDocuments(handleLoading = false): void {
    if (handleLoading) {
      updateApplicationLoadingState(true);
    }
    fetchEngagementDocuments(globalState.currentConfiguration.id)
      .then((engagementDocuments: Array<CoreInterfaces.EngagementDocument>) => {
        setEngagementDocuments(engagementDocuments);
        if (documentPageConfig.isFinalVersion && !isDigitallySigned()) {
          const engagementDescriptionDocument = engagementDocuments.find(
            (engagementDocument) =>
              engagementDocument.Type ===
              Constants.EngagementDocumentType.EngagementDescription
          );
          if (!engagementDescriptionDocument) {
            return saveEngagementDescriptionOnSetToFinal(
              globalState,
              t,
              language
            );
          }
        }
        return Promise.resolve();
      })
      .catch(() => {
        console.error("Exception on fetch final documents");
      })
      .finally(() => {
        if (handleLoading) {
          updateApplicationLoadingState(false);
        }
      });
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLElement>,
    language: Constants.Languages
  ) => {
    if (language !== null) {
      setLanguage(language);
      localStorage.setItem(Constants.i18nDocumentStorageKey, language);
    }
  };

  function isDigitallySigned(): boolean {
    return [
      Constants.SigningStatus.SentForDigitallySigned,
      Constants.SigningStatus.DigitallySigned,
      Constants.SigningStatus.DigitallySignedDeclined,
    ].includes(
      globalState.currentConfiguration.signingStatus as Constants.SigningStatus
    );
  }

  function handleLoginRedirect() {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }

  function processDocument(
    url: string,
    payload: CoreInterfaces.EngagementManuallySigningPack,
    successMsg: string,
    errorMsg: string
  ): void {
    updateApplicationLoadingState(true);
    fetchData<CoreInterfaces.EngagementConfigurationReadPack>(
      url,
      Constants.APIMethod.POST,
      payload
    )
      .then((serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
        updateStateOnEngagementConfigurationRead(
          serverReturn,
          dispatch,
          (service: DTOs.ServiceDTO) =>
            applyUpdatesOnQuestionChange(globalState, service)
        );
        showSnackBar(t(successMsg), "success");
      })
      .catch(() => {
        showSnackBar(t(errorMsg), "error");
      })
      .finally(() => {
        updateApplicationLoadingState(false);
      });
  }

  function startCreateFinalDocumentFlow(): void {
    updateApplicationLoadingState(true);

    setTimeout(() =>
      saveCurrentConfiguration(
        globalState,
        dispatch,
        businessOpportunityId,
        businessUnit,
        clientId
      )
        .catch((Ex: any) => {
          showSnackBar(t("General.ConfigurationSaveFail"), "error");
          console.log("Error while saving the configuration", Ex);
        })
        .then(() => {
          setLocalValuesAndRedirectToSharepoint();
        })
    );
  }

  function setLocalValuesAndRedirectToSharepoint() {
    localStorage.setItem(
      Constants.LOCAL_STORAGE_FORCE_REDIRECT_KEY,
      Constants.Routes.GenerateDocuments
    );
    localStorage.setItem(
      Constants.LOCAL_STORAGE_DOCUMENT_FLOW_KEY,
      Constants.GenerateDocumentsDocumentFlow.SetFinalVersion
    );
    instance
      .loginRedirect(sharepointAccessRequestConfig)
      .catch((error) => console.log(error));
  }

  function createFinalDocument(accessToken: string): void {
    updateApplicationLoadingState(true);
    handleConversionOnFinalVersionDocuments(accessToken)
      .then(() => {
        if (
          documentPageConfig.isEngagementCounselingSelected ||
          documentPageConfig.isAuditBusinessUnit
        ) {
          // skip engagement description processing when Service 10 is selected
          return Promise.resolve();
        } else {
          return saveEngagementDescriptionOnSetToFinal(
            globalState,
            t,
            language
          );
        }
      })
      .then(() => {
        const apiUrl =
          globalState.remoteData.businessOpportunityInfo?.Status ===
          Constants.BusinessOpportunityStatuses.OPEN
            ? Constants.APIPath.FinalVersion
            : Constants.APIPath.FinalVersionForWonOportunities;

        let totalEngagementCost = getEngagementTotals(globalState);
        if (totalEngagementCost === 0) {
          totalEngagementCost = Number(
            process.env.REACT_APP_MINIMAL_TOTAL_ENGAGEMENT_COST
          );
        }

        return handleConfigurationPersistence(
          apiUrl,
          Constants.APIMethod.POST,
          globalState,
          dispatch,
          businessOpportunityId,
          businessUnit,
          clientId,
          description,
          globalState.currentConfiguration.version,
          globalState.currentConfiguration.engagementLetterMetadata,
          globalState.currentConfiguration.digitalSigningDetails,
          totalEngagementCost,
          null,
          language
        );
      })
      .then(() =>
        deleteSPEngagementFolder(
          globalState.currentConfiguration.id,
          accessToken
        )
      )
      .then(() => {
        setIsVisibleSetFinalDocumentDialog(false);
        updateApplicationLoadingState(false);
        showSnackBar(t("General.CreateFinalDocumentSuccess"), "success");
        fetchAndPopulateEngagementDocuments();
      })
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar(t("General.CreateFinalDocumentFail"), "error");
      });
  }

  function manuallySignDocument(
    payload: CoreInterfaces.EngagementManuallySigningPack
  ): void {
    processDocument(
      `${Constants.APIPath.ManuallySign}`,
      payload,
      "General.ManuallySignSuccess",
      "General.ManuallySignFail"
    );
    setIsVisibleSetManuallySignedDialog(false);
  }

  function sendForDigitalSigningDocument(): Promise<void> {
    const formdata = new FormData();
    const filename = t("General.Filename", {
      companyName: globalState.generalVariables.companyName,
      orgNr: globalState.generalVariables.organisationNo,
    });
    const digitalSigningUsers = resolveDigitalSigningContacts(globalState);
    const engagementSigningDto: CoreInterfaces.EngagementSigningDto = {
      BusinessOpportunityId: businessOpportunityId,
      EngagementConfigurationId: globalState.currentConfiguration.id,
      CaseId: globalState.currentConfiguration.digitalSigningDetails?.CaseId,
      FileName: filename,
      ContactPersons: digitalSigningUsers,
    };
    formdata.append(
      "EngagementSigningDto",
      JSON.stringify(engagementSigningDto)
    );
    formdata.append("token", accessToken);
    return sendFile<CoreInterfaces.EngagementConfigurationReadPack>(
      `${Constants.APIPath.SendForDigitalSigning}`,
      Constants.APIMethod.POST,
      formdata
    )
      .then((serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
        updateStateOnEngagementConfigurationRead(
          serverReturn,
          dispatch,
          (service: DTOs.ServiceDTO) =>
            applyUpdatesOnQuestionChange(globalState, service)
        );
        fetchAndPopulateEngagementDocuments();
        showSnackBar(t("General.SendForDigitalSigningSuccess"), "success");
      })
      .catch(() => {
        fetchAndPopulateEngagementDocuments();
        showSnackBar(t("General.SendForDigitalSigningFail"), "error");
      })
      .finally(() => {
        localStorage.removeItem(Constants.i18nDocumentStorageKey);
        setLanguage(Constants.Languages.SE);
        setAccessToken(undefined);
        updateApplicationLoadingState(false);
      });
  }

  function changeConsent(consent: Partial<CoreInterfaces.Consent>): void {
    dispatch({
      type: Constants.AppStateActions.UpdateConsent,
      payload: {
        ...globalState.currentConfiguration.consent,
        ...consent,
      },
    });
  }

  const generateEngagementDescription = async (): Promise<void> => {
    if (
      globalState.currentConfiguration.id &&
      documentPageConfig.areAllServicesCompleted
    ) {
      return generatePdfDocument(
        globalState,
        dispatch,
        t,
        language,
        updateApplicationLoadingState
      );
    }
  };

  const getCurrentEmployee = (): CoreInterfaces.ContactPersonItem => {
    const authenticatedUserEmail =
      globalState.authProviderUserDetails.userDetails;
    const authenticatedEmployee = globalState.remoteData.gtContactPersons.find(
      (cp) => cp.email === authenticatedUserEmail
    );
    if (!authenticatedEmployee) {
      console.error("Could not find the authenticated employee");
      return globalState.remoteData.gtContactPersons[0];
    } else {
      return authenticatedEmployee;
    }
  };

  function saveConfiguration() {
    saveCurrentConfiguration(
      globalState,
      dispatch,
      businessOpportunityId,
      businessUnit,
      clientId
    )
      .catch((Ex: any) => {
        console.log("Error while saving the configuration", Ex);
      })
      .finally(() => updateApplicationLoadingState(false));
  }

  function handleEngagementLetterGenerationFlow() {
    if (
      globalState.currentConfiguration.engagementLetterMetadata?.createdDate
    ) {
      if (!isEngagementLetterRegenerateConfirmModalVisible) {
        setIsEngagementLetterRegenerateConfirmModalVisible(true);
        return;
      } else {
        setIsEngagementLetterRegenerateConfirmModalVisible(false);
      }
    }

    localStorage.setItem(
      Constants.LOCAL_STORAGE_FORCE_REDIRECT_KEY,
      Constants.Routes.GenerateDocuments
    );
    localStorage.setItem(
      Constants.LOCAL_STORAGE_DOCUMENT_FLOW_KEY,
      Constants.GenerateDocumentsDocumentFlow.SendFilesToSharepoint
    );
    instance
      .loginRedirect(sharepointAccessRequestConfig)
      .catch((error) => console.log(error));
  }

  async function handleGenerateDocuments(accessToken: string) {
    const initialLanguage = i18n.language;
    try {
      updateApplicationLoadingState(true);
      localStorage.removeItem(Constants.LOCAL_STORAGE_DOCUMENT_FLOW_KEY);
      await resolveSPFolder(globalState.currentConfiguration.id, accessToken);

      const engagementLetterMetadata =
        globalState.currentConfiguration.engagementLetterMetadata;
      if (
        engagementLetterMetadata?.version > 0 ||
        (engagementLetterMetadata?.version === null &&
          !!engagementLetterMetadata.createdDate)
      ) {
        await removeOldMetadataDocuments(
          globalState.currentConfiguration,
          documentPageConfig,
          accessToken
        );
      }

      const templateData: Record<Constants.EngagementDocumentType, any> =
        {} as Record<Constants.EngagementDocumentType, any>;

      await i18n.changeLanguage(language.toLocaleLowerCase());

      for (const documentType of documentPageConfig.engagementDocumentTypes) {
        templateData[documentType] = documentPageConfig.templateDataFnsMap[
          documentType
        ](globalState, genericService, language);
      }

      const documentsGenerationDate = new Date();
      const spPromises = documentPageConfig.engagementDocumentTypes.map(
        (documentTypeInput) =>
          generateEngagementDocument(
            documentTypeInput,
            templateData[documentTypeInput],
            language
          ).then((documentResponse) => {
            const documentContent = documentResponse.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const documentType =
              documentTypeInput === Constants.EngagementDocumentType.AuditLetter
                ? Constants.EngagementDocumentType.EngagementLetter
                : documentTypeInput;

            const spFileName = `${documentType}_${formatDate(
              documentsGenerationDate,
              Constants.HelpfulConstants.DateFormatTrimYearMonthDayHourMinutes
            )}.${Constants.FileExtension.Docx}`;

            return sendFileToSharepoint(
              globalState.currentConfiguration.id,
              spFileName,
              documentContent,
              accessToken
            );
          })
      );
      await Promise.all(spPromises);

      const currentEmployee = getCurrentEmployee();
      const actionPack: {
        currentUser: CoreInterfaces.Employee;
        generationDate: Date;
        version: number;
      } = {
        currentUser: {
          FirstName: currentEmployee.firstName,
          LastName: currentEmployee.lastName,
          Email: currentEmployee.email,
          Office: currentEmployee.office,
          Id: null,
        },
        generationDate: documentsGenerationDate,
        version:
          globalState.currentConfiguration.engagementLetterMetadata?.version ??
          1,
      };
      dispatch({
        type: Constants.AppStateActions.UpdateEngagementDocumentsMetadata,
        payload: actionPack,
      });
      setTimeout(() => saveConfiguration());
    } catch (Ex: any) {
      let errorMessage = t("General.EngagementLetterGenerateError");
      if (Ex.status === Constants.HttpStatusCodes.Locked) {
        errorMessage = t("General.DocumentOpenedByOtherClient");
      }
      showSnackBar(
        t(errorMessage),
        "error",
        Constants.ERROR_MESSAGE_VISIBILITY_MILLIS
      );
      updateApplicationLoadingState(false);
    } finally {
      i18n.changeLanguage(initialLanguage);
    }
  }

  function handleConversionOnFinalVersionDocuments(
    accessToken: string
  ): Promise<Array<void>> {
    localStorage.removeItem(Constants.LOCAL_STORAGE_DOCUMENT_FLOW_KEY);
    const fileHandlePromises = documentPageConfig.engagementDocumentTypes.map(
      (documentType) =>
        executeFinalizeEngagementDocumentFlow(
          businessOpportunityId,
          globalState,
          documentType,
          accessToken
        )
    );

    return Promise.all(fileHandlePromises);
  }

  function handleDocumentAction(linkData: any, readMode: boolean): void {
    if (!documentPageConfig.isFinalVersion) {
      openSharepointURLInMsWordClient(
        globalState.currentConfiguration.id,
        linkData.DownloadableFileName,
        readMode,
        globalState.currentConfiguration.engagementLetterMetadata
      );
    }
  }

  function handleOpenFinalDocumentDialogClick(): void {
    setIsVisibleSetFinalDocumentDialog(true);
    globalState.currentConfiguration.totalCostManualOverride = null;
  }

  let engagementLetterAndAppendixDocuments: Array<{
    Id: string;
    OriginalFileName: string;
    DownloadableFileName: string;
  }> = [];

  if (!documentPageConfig.isFinalVersion) {
    if (!!globalState.currentConfiguration.engagementLetterMetadata) {
      engagementLetterAndAppendixDocuments =
        documentPageConfig.engagementDocumentTypes.map((documentType) => {
          return {
            Id: `engagementDocument${documentType}`,
            OriginalFileName: t(`General.${documentType}`),
            DownloadableFileName: documentType,
          };
        });
    }
  } else {
    const engagementDocumentTypes: Constants.EngagementDocumentType[] =
      documentPageConfig.engagementDocumentTypes[0] ===
      Constants.EngagementDocumentType.AuditLetter
        ? [Constants.EngagementDocumentType.EngagementLetter]
        : documentPageConfig.engagementDocumentTypes;

    engagementLetterAndAppendixDocuments = engagementDocuments
      .filter((engagementDocument) => {
        return engagementDocumentTypes
          .map((documentType) => documentType)
          .includes(engagementDocument.Type);
      })
      .map((document) => ({
        ...document,
        OriginalFileName: t(`General.${document.Type}`),
        DownloadableFileName: document.Type,
      }));
  }

  function convertDocToPDF(doc: any): Promise<Blob> {
    const formdata = new FormData();
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    formdata.append("file", out);
    return fetchPdfFile(
      Constants.APIPath.ConvertToPdf,
      Constants.APIMethod.POST,
      formdata
    );
  }

  function saveEngagementDescriptionOnSetToFinal(
    globalState: CoreInterfaces.AppState,
    t: Function,
    language: string
  ): Promise<void> {
    if (businessUnit !== Constants.BusinessUnit.Audit) {
      return generateEngagementDescriptionWordDocument(globalState, t, language)
        .then((doc) => convertDocToPDF(doc))
        .then((pdfFileContent: Blob) => {
          const documentType =
            Constants.EngagementDocumentType.EngagementDescription;
          return handleFileUpload(
            businessOpportunityId,
            globalState.currentConfiguration.id,
            pdfFileContent,
            documentType,
            null,
            [],
            `${documentType}.${Constants.FileExtension.Pdf}`
          );
        });
    }
  }

  return (
    <article className="gt-generateDocumentsPage">
      <section className="gt-generateDocumentsPage__topPanel">
        <Typography
          className="gt-generateDocumentsPage__topPanel-title"
          variant="h5"
          gutterBottom={true}
        >
          {t(pageState.title)}
        </Typography>
      </section>
      <section className="gt-generateDocumentsPage__version">
        <div className="gt-generateDocumentsPage__version-info">
          <div className="gt-generateDocumentsPage__version-info-wrapper">
            <span className="gt-generateDocumentsPage__version-info-wrapper-title">
              {configurationName}
            </span>
            <span className="gt-generateDocumentsPage__version-info-wrapper-createdBy">
              {createdBy}
            </span>
          </div>
          <div className="gt-generateDocumentsPage__version-info-comment">
            {description}
          </div>
        </div>
        {documentPageConfig.isFinalOrOutdatedVersionStatus &&
          globalState.currentConfiguration.signingStatus ===
            Constants.SigningStatus.NotSigned && (
            <div className="gt-generateDocumentsPage__version-ctas">
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsVisibleSetManuallySignedDialog(true)}
              >
                {t("General.ManuallySetToSigned")}
              </Button>
              {isDigitallySigningChecked(globalState) && (
                <Button
                  color="tertiary"
                  variant="contained"
                  disabled={isEmailMissingDigitalSigningUsers}
                  onClick={() => handleLoginRedirect()}
                >
                  {t("General.SendForDigitalSigning")}
                </Button>
              )}
            </div>
          )}
      </section>
      <section className="gt-generateDocumentsPage__language">
        <div> {t("General.DocumentsLanguage")}</div>
        <ToggleButtonGroup
          color="primary"
          value={language}
          exclusive={true}
          onChange={handleLanguageChange}
          disabled={documentPageConfig.isOutdatedVersionStatus}
        >
          <ToggleButton value={Constants.Languages.SE}>
            {Constants.Languages.SE}
          </ToggleButton>
          <ToggleButton value={Constants.Languages.EN}>
            {Constants.Languages.EN}
          </ToggleButton>
        </ToggleButtonGroup>
      </section>
      {documentPageConfig.isEngagementLetterRegenerable && (
        <>
          <section className="gt-section">
            <article className="gt-generateDocumentsPanel">
              <section className="gt-generateDocumentsPanel__title">
                <h4>{t("General.EngagementLetter")}</h4>
              </section>
              {documentPageConfig.shouldShowObsoleteEngagementLetterNotification && (
                <section className="gt-generateDocumentsPanel__notification">
                  <GtAlertBanner
                    message={t("General.EngagementLetterObsoleteMessage")}
                    variant={Constants.GtAlertBannerVariant.GenerateDocuments}
                  />
                </section>
              )}
              <section className="gt-generateDocumentsPanel__actions">
                <div className="gt-generateDocumentsPanel__actions-cta">
                  <h5>{t("General.Actions")}</h5>
                  <Button
                    disabled={
                      !documentPageConfig.areAllServicesCompleted ||
                      !globalState.currentConfiguration.id ||
                      documentPageConfig.isFinalOrOutdatedVersionStatus
                    }
                    onClick={handleEngagementLetterGenerationFlow}
                    color="tertiary"
                    variant="outlined"
                    key="reGenerateLetterAndAppendixBtn"
                  >
                    {t(documentPageConfig.reGenerateButtonLabel)}
                  </Button>
                </div>
                <div className="gt-generateDocumentsPanel__actions-overview">
                  <h5>{t("General.Overview")}</h5>
                  <div className="gt-generateDocumentsPanel__actions-overview-infoLines">
                    <div className="gt-generateDocumentsPanel__actions-overview-infoLines-line">
                      <span className="gt-generateDocumentsPanel__actions-overview-infoLines-line--verticalAlignMiddle">
                        <i>{t("General.GeneratedBy")}:</i>
                      </span>
                      <span className="gt-generateDocumentsPanel__actions-overview-infoLines-line--personalInfo">
                        {!!globalState.currentConfiguration
                          .engagementLetterMetadata?.createdBy ? (
                          <>
                            <strong>
                              {
                                globalState.currentConfiguration
                                  .engagementLetterMetadata?.createdBy.FirstName
                              }{" "}
                              {
                                globalState.currentConfiguration
                                  .engagementLetterMetadata?.createdBy?.LastName
                              }
                            </strong>
                            <div className="gt-generateDocumentsPanel__actions-overview-infoLines-line-email">
                              {
                                globalState.currentConfiguration
                                  .engagementLetterMetadata?.createdBy?.Email
                              }
                            </div>
                          </>
                        ) : (
                          <>-</>
                        )}
                      </span>
                    </div>
                    <div className="gt-generateDocumentsPanel__actions-overview-infoLines-line">
                      <span>
                        <i>{t("General.DateAndTime")}</i>
                      </span>
                      <span>
                        <strong>
                          {globalState.currentConfiguration
                            .engagementLetterMetadata?.createdDate
                            ? formatDate(
                                globalState.currentConfiguration
                                  .engagementLetterMetadata?.createdDate,
                                Constants.HelpfulConstants
                                  .DateFormatYearMonthDayHourMinutes
                              )
                            : "-"}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="gt-generateDocumentsPanel__actions-generatedDocuments">
                  <h5>
                    {t("General.GenerateDocuments")}{" "}
                    <GtInfoTooltip
                      tooltipTitle={
                        businessUnit === Constants.BusinessUnit.Audit
                          ? t(
                              "General.TooltipAuditEngagementLetterGenerateDocuments"
                            )
                          : t(
                              "General.TooltipEngagementLetterGenerateDocuments"
                            )
                      }
                      testIdPart="generateDocumentsTooltipTest"
                    />
                  </h5>
                  <GtEngagementLetterDocumentsManagerPanelLinks
                    engagementConfigurationId={
                      globalState.currentConfiguration.id
                    }
                    links={engagementLetterAndAppendixDocuments}
                    hasDownloadLink={documentPageConfig.isFinalVersion}
                    isReadonlyMode={
                      documentPageConfig.isFinalOrOutdatedVersionStatus
                    }
                    clickHandler={handleDocumentAction}
                  />
                </div>
              </section>
              {globalState.currentConfiguration.id &&
                globalState.currentConfiguration.engagementLetterMetadata
                  ?.createdDate && (
                  <section className="gt-generateDocumentsPanel__additionalDocuments">
                    <EngagementLetterDocumentsManagerPanel
                      documents={engagementDocuments.filter(
                        (engagementDocument) =>
                          engagementDocument.Type ===
                          Constants.EngagementDocumentType.AdditionalDocument
                      )}
                      refetchDocuments={() =>
                        fetchAndPopulateEngagementDocuments(true)
                      }
                    />
                  </section>
                )}
            </article>
          </section>
          {isEngagementLetterRegenerateConfirmModalVisible && (
            <GtEngagementLetterRegenerateConfirmModal
              onConfirm={handleEngagementLetterGenerationFlow}
              onDialogClose={() =>
                setIsEngagementLetterRegenerateConfirmModalVisible(false)
              }
            />
          )}
        </>
      )}
      {documentPageConfig.isEngagementDescriptionAreaVisible && (
        <section className="gt-section">
          <article className="gt-generateDocumentsPanel">
            <h4>{t("General.EngagementDescription")}</h4>
            <section className="gt-generateDocumentsPanel__columns">
              <div className="gt-generateDocumentsPanel__columns-left"></div>
              <div className="gt-generateDocumentsPanel__columns-right">
                <h5>
                  {t("General.Actions")}{" "}
                  <GtInfoTooltip
                    tooltipTitle={t(
                      "General.TooltipEngagementDescriptionActions"
                    )}
                    testIdPart="generateDocumentsTooltipTest"
                  />
                </h5>
                <GtEngagementLetterDocumentsManagerPanelLinks
                  engagementConfigurationId={
                    globalState.currentConfiguration.id
                  }
                  links={engagementDescriptionDocumentArray}
                  hasDownloadLink={documentPageConfig.isFinalVersion}
                  isReadonlyMode={
                    documentPageConfig.isFinalVersion ||
                    !globalState.currentConfiguration.id ||
                    documentPageConfig.isOutdatedVersionStatus ||
                    !documentPageConfig.areAllServicesCompleted
                  }
                  clickHandler={
                    !documentPageConfig.isOutdatedVersionStatus &&
                    globalState.currentConfiguration.id &&
                    documentPageConfig.areAllServicesCompleted
                      ? generateEngagementDescription
                      : undefined
                  }
                />
              </div>
            </section>
          </article>
        </section>
      )}

      <section className="gt-section">
        <article className="gt-generateDocumentsPanel">
          <h4>{t("General.EngagementConfirmation")}</h4>
          <section className="gt-generateDocumentsPanel__columns">
            <div className="gt-generateDocumentsPanel__columns-left">
              <FormControlLabel
                label={t("General.IsCompetenceInResourcesAvailable")}
                control={
                  <Checkbox
                    data-testid={"generate-document-checkbox"}
                    size="small"
                    checked={
                      !!globalState.currentConfiguration.consent
                        ?.IsCompetenceInResourcesAvailable
                    }
                    onChange={(e) => {
                      changeConsent({
                        IsCompetenceInResourcesAvailable: e.target.checked,
                      });
                    }}
                    disabled={documentPageConfig.isFinalOrOutdatedVersionStatus}
                  />
                }
              />
            </div>
          </section>
        </article>
      </section>

      <section className="gt-navigationButtons">
        {!documentPageConfig.isFinalOrOutdatedVersionStatus &&
          documentPageConfig.isEngagementLetterGenerated && (
            <Button
              data-testid={"set-to-final"}
              color="tertiary"
              variant="contained"
              disabled={
                !globalState.currentConfiguration.consent
                  ?.IsCompetenceInResourcesAvailable ||
                !globalState.currentConfiguration.id ||
                !documentPageConfig.areAllServicesCompleted ||
                (isEmailMissingDigitalSigningUsers &&
                  isDigitallySigningChecked(globalState))
              }
              onClick={() => handleOpenFinalDocumentDialogClick()}
            >
              {t("General.CreateFinalDocuments")}
            </Button>
          )}
      </section>
      {isVisibleSetFinalDocumentDialog && (
        <GtSetFinalDocumentDialog
          onConfirm={() => startCreateFinalDocumentFlow()}
          onDialogClose={() => setIsVisibleSetFinalDocumentDialog(false)}
        />
      )}
      {isVisibleSetManuallySignedDialog && (
        <GtSetToManuallySignedDialog
          onConfirm={(payload: CoreInterfaces.EngagementManuallySigningPack) =>
            manuallySignDocument(payload)
          }
          onDialogClose={() => setIsVisibleSetManuallySignedDialog(false)}
        />
      )}
    </article>
  );
}
export default GenerateDocumentsPage;
