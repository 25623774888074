import { t } from "i18next";
import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "../../../core/Constants";
import {
  findQuestionInService,
  resolveTaskDisplayedAllocationOfResponsibility,
  resolveTaskDisplayedComment,
  resolveTaskDisplayedFrequency,
} from "src/utils/shared";
import { formatMinutesToHoursAndMinutes } from "src/utils/helpers";
import { calculateEstimatedTimeInMinutes } from "src/utils/task-utils";
import { fetchTitleFromSelectedOption } from "src/utils/question-utils";

function accountsReceivableServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const { generalVariables } = extraData;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.AccountReceivableQuestion.Q0104]: "q0104",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });

  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.AccountReceivableTasks.T0101]: (taskDTO: DTOs.TaskDTO) => {
        const title = t(taskDTO.data.title, {
          accountPreSystem: questionDTOMap.q0104.data.userValue,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.AccountReceivableTasks.T0102]: (taskDTO: DTOs.TaskDTO) => {
        const title = t(taskDTO.data.title, {
          accountPreSystem: questionDTOMap.q0104.data.userValue,
          accountingSystem: generalVariables.accountingSystem,
        });
        const allocationOfResponsibility =
          resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
        const params = {
          accountCreator: t(`Options.${allocationOfResponsibility}`),
          accountingSystem: generalVariables.accountingSystem,
        };
        const calculationComment = resolveTaskDisplayedComment(taskDTO, params);
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);

        return {
          ...tableRow,
          title,
          calculationComment,
        };
      },
      [Constants.AccountReceivableTasks.T0103]: (taskDTO: DTOs.TaskDTO) => {
        const title = t(taskDTO.data.title, {
          accountingSystem: generalVariables.accountingSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.AccountReceivableTasks.T0104]: (taskDTO: DTOs.TaskDTO) => {
        const title = t(taskDTO.data.title, {
          accountingSystem: generalVariables.accountingSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.AccountReceivableTasks.T0115]: (taskDTO: DTOs.TaskDTO) => {
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        const allocationOfResponsibility =
          resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
        const params = {
          allocationOfResponsibility: t(
            `Options.${allocationOfResponsibility}`
          ),
        };
        const calculationComment = resolveTaskDisplayedComment(taskDTO, params);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.AccountReceivableTasks.T0116]: (taskDTO: DTOs.TaskDTO) => {
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        const allocationOfResponsibility =
          resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
        const params = {
          allocationOfResponsibility: t(
            `Options.${allocationOfResponsibility}`
          ),
        };
        const calculationComment = resolveTaskDisplayedComment(taskDTO, params);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.AccountReceivableTasks.T0118]: (taskDTO: DTOs.TaskDTO) => {
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        let allocationOfResponsibility =
          generalVariables.accruesPreparedRevenue;
        allocationOfResponsibility =
          resolveTaskDisplayedAllocationOfResponsibility(
            taskDTO,
            allocationOfResponsibility
          );
        return {
          ...tableRow,
          allocationOfResponsibility,
        };
      },
    };

  return taskProcessors;
}

function accountsPayableServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.AccountPayableQuestion.Q0205]: "q0205",
    [Constants.AccountPayableQuestion.Q0206]: "q0206",
    [Constants.AccountPayableQuestion.Q0205_1]: "q0205_1",
    [Constants.AccountPayableQuestion.Q0206_1]: "q0206_1",
    [Constants.AccountPayableQuestion.Q0225]: "q0225",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });

  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.AccountPayableTasks.T0201]: (taskDTO: DTOs.TaskDTO) => {
        const q0205ActiveQuestion = questionDTOMap.q0205.state.isShown
          ? questionDTOMap.q0205
          : questionDTOMap.q0205_1;
        const q0206ActiveQuestion = questionDTOMap.q0206.state.isShown
          ? questionDTOMap.q0206
          : questionDTOMap.q0206_1;

        const accountPayablePreSystem = q0205ActiveQuestion.state.isShown
          ? q0205ActiveQuestion.data.userValue
          : q0206ActiveQuestion.data.userValue;

        const title = t(taskDTO.data.title, {
          accountPayablePreSystem: t(`Options.${accountPayablePreSystem}`),
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);

        return {
          ...tableRow,
          title,
        };
      },
      [Constants.AccountPayableTasks.T0202]: (taskDTO: DTOs.TaskDTO) => {
        const param = questionDTOMap.q0225.data.userValue;
        const title = t(taskDTO.data.title, {
          accountPayablePreSystem: param,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };

  return taskProcessors;
}

function corporateCardManagementServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const { generalVariables } = extraData;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.CorporateCardManagementQuestion.Q0303]: "q0303",
    [Constants.CorporateCardManagementQuestion.Q0303_1]: "q0303_1",
    [Constants.CorporateCardManagementQuestion.Q0313]: "q0313",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });

  const taskProcessors: {
    [taskCode in Constants.AutomaticTaskCode]?: (
      taskDTO: DTOs.TaskDTO
    ) => CoreInterfaces.CalculationServiceTableRow;
  } = {
    [Constants.CorporateCardManagementTasks.T0301]: (taskDTO: DTOs.TaskDTO) => {
      const activeQuestion = questionDTOMap.q0303.state.isShown
        ? questionDTOMap.q0303
        : questionDTOMap.q0303_1;
      const corporateCard =
        activeQuestion.data.userValue ===
        Constants.CorporateCardPreSystem.AnnatForsystem
          ? questionDTOMap.q0313.data.userValue
          : t(`Options.${activeQuestion.data.userValue}`);
      const title = t(taskDTO.data.title, {
        corporateCardPreSystem: corporateCard,
      });
      const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
      return {
        ...tableRow,
        title,
      };
    },
    [Constants.CorporateCardManagementTasks.T0306]: (taskDTO: DTOs.TaskDTO) => {
      const title = t(taskDTO.data.title, {
        accountingSystem: generalVariables.accountingSystem,
      });
      const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
      return {
        ...tableRow,
        title,
      };
    },
  };

  return taskProcessors;
}

function otherAccountAndReconciliationServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const { generalVariables } = extraData;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.OtherAccountAndReconciliationQuestions.Q0416]: "q0416",
    [Constants.OtherAccountAndReconciliationQuestions.Q0417]: "q0417",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.OtherAccountAndReconciliationTasks.T0438]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const questionValue =
          questionDTOMap.q0416.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0416.data.options.find(
                (option) => option.key === questionDTOMap.q0416.data.userValue
              )?.value
            : "";
        const preSystem = questionDTOMap.q0417.state.isShown
          ? questionDTOMap.q0417.data.userValue
          : t(questionValue);

        const title = t(taskDTO.data.title, {
          preSystem: preSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },

      [Constants.OtherAccountAndReconciliationTasks.T0442]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const title = t(taskDTO.data.title, {
          accountingSystem: generalVariables.accountingSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };
  return taskProcessors;
}

function periodReportingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const { generalVariables } = extraData;

  const convertFromFrequencyVATToFrequency =
    generalVariables.declarationFrequencyVAT ===
      Constants.VATDeclarationFrequency.MonthlyOver ||
    generalVariables.declarationFrequencyVAT ===
      Constants.VATDeclarationFrequency.MonthlyUnder
      ? Constants.Frequency.Monthly
      : generalVariables.declarationFrequencyVAT;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.PeriodReportingQuestion.Q0502]: "q0502",
    [Constants.PeriodReportingQuestion.Q0509]: "q0509",
    [Constants.PeriodReportingQuestion.Q0509_1]: "q0509_1",
    [Constants.PeriodReportingQuestion.Q0510]: "q0510",
    [Constants.PeriodReportingQuestion.Q0511]: "q0511",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.PeriodReportingTasks.T0504]: (taskDTO: DTOs.TaskDTO) => {
        const activeQuestion = questionDTOMap.q0509.state.isShown
          ? questionDTOMap.q0509
          : questionDTOMap.q0509_1;
        const title = t(taskDTO.data.title, {
          Q0509: t(`Options.${activeQuestion.data.userValue}`),
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PeriodReportingTasks.T0505]: (taskDTO: DTOs.TaskDTO) => {
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          Q0510: questionDTOMap.q0510.data.userValue,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.PeriodReportingTasks.T0508]: (taskDTO: DTOs.TaskDTO) => {
        const frequency = resolveTaskDisplayedFrequency(
          taskDTO,
          convertFromFrequencyVATToFrequency
        );
        const tooltip = t(taskDTO.data.calculationTooltip);
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          frequency,
          tooltip,
        };
      },
      [Constants.PeriodReportingTasks.T0509]: (taskDTO: DTOs.TaskDTO) => {
        const frequency = resolveTaskDisplayedFrequency(
          taskDTO,
          convertFromFrequencyVATToFrequency
        );
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          frequency,
        };
      },
      [Constants.PeriodReportingTasks.T0510]: (taskDTO: DTOs.TaskDTO) => {
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          Q0502: questionDTOMap.q0502.data.userValue,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.PeriodReportingTasks.T0523]: (taskDTO: DTOs.TaskDTO) => {
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          Q0511: questionDTOMap.q0511.data.comment,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
    };

  return taskProcessors;
}

function interimListedCompaniesServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {};
  return taskProcessors;
}

function annualReportingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {};
  return taskProcessors;
}

function annualReportingListedCompaniesServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {};
  return taskProcessors;
}

function payrollAndExpenseAndTravelInvoiceManagementServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0708]:
      "q0708",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0713]:
      "q0713",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0724]:
      "q0724",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0725]:
      "q0725",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0727]:
      "q0727",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0730]:
      "q0730",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0748]:
      "q0748",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0759]:
      "q0759",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0763]:
      "q0763",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });

  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0701]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPortal =
          questionDTOMap.q0725.data.userValue === Constants.YesNo.Yes
            ? Constants.HelpfulConstants.Flow
            : questionDTOMap.q0727.data.userValue;
        const title = t(taskDTO.data.title, {
          payrollPortal: payrollPortal,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0702]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0703]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0704]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0706]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0711]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0724]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const contactPersonPayroll =
          questionDTOMap.q0763.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0763.data.options.find(
                (option) => option.key === questionDTOMap.q0763.data.userValue
              )?.value
            : "";
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          contactPersonPayroll: contactPersonPayroll,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0725]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const contactPersonPayroll =
          questionDTOMap.q0763.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0763.data.options.find(
                (option) => option.key === questionDTOMap.q0763.data.userValue
              )?.value
            : "";
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          contactPersonPayroll: contactPersonPayroll,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0726]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payslipDistribution = t(
          `Options.${questionDTOMap.q0730?.data.userValue}`
        );
        const title = t(taskDTO.data.title, {
          payslipDistribution: payslipDistribution,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0727]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const question = questionDTOMap.q0724.state.isShown
          ? questionDTOMap.q0724.data.userValue
          : "";
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          Q0724: question,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0730]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const q0748 = questionDTOMap.q0748.state.isShown
          ? questionDTOMap.q0748.data.userValue
          : "";
        const calculationComment = resolveTaskDisplayedComment(taskDTO, {
          Q0748: q0748,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          calculationComment,
        };
      },
    };

  return taskProcessors;
}

function annuallyPayrollRoutineServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {
      [Constants.AnnualPayrollRoutinesTasks.T0749]: (taskDTO: DTOs.TaskDTO) => {
        const payrollService = extraData.extraServices.find(
          (serviceDTO: DTOs.ServiceDTO) =>
            serviceDTO.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
        );
        const q0759 = findQuestionInService(
          payrollService,
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0759
        );
        const titleText = fetchTitleFromSelectedOption(q0759);
        const title = t(taskDTO.data.title, {
          Q0759: titleText,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };
  return taskProcessors;
}

function startUpAccountingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {};
  return taskProcessors;
}

function yearlyInternalDocumentationAndFormalitiesServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.CalculationServiceTableRow> =
    {};
  return taskProcessors;
}

export function getDefaultTableRow(
  serviceDTO: DTOs.ServiceDTO,
  taskDTO: DTOs.TaskDTO
): CoreInterfaces.CalculationServiceTableRow {
  const tableRow: CoreInterfaces.CalculationServiceTableRow = {
    id: `calculationServiceTask${taskDTO.data.code}Row`,
    title: "",
    frequency: "",
    allocationOfResponsibility: "",
    calculationComment: "",
    tooltip: "",
    staffLevel: "",
    serviceDTO,
    taskDTO,
    estimatedTimePerUnit: 0,
    sumTimeInHrsAndMinutes: "",
    calculationMethod: null,
  };

  tableRow.title =
    taskDTO.data.type === Constants.TaskType.Automatic
      ? t(taskDTO.data.title)
      : taskDTO.data.title;
  tableRow.frequency =
    taskDTO.data.adjustments.frequency ?? taskDTO.data.frequency;
  tableRow.allocationOfResponsibility =
    taskDTO.data.adjustments.allocationOfResponsibility ??
    taskDTO.data.allocationOfResponsibility;
  tableRow.calculationComment = resolveTaskDisplayedComment(taskDTO);
  tableRow.tooltip =
    taskDTO.data.type === Constants.TaskType.Automatic
      ? t(taskDTO.data.calculationTooltip)
      : taskDTO.data.calculationTooltip;
  tableRow.estimatedTimePerUnit =
    taskDTO.data.adjustments.estimatedTimePerUnit ??
    taskDTO.data.estimatedTimePerUnit ??
    0;
  tableRow.staffLevel =
    taskDTO.data.adjustments.staffLevel ?? taskDTO.data.staffLevel ?? "";
  const sumTimeInMinutes = calculateEstimatedTimeInMinutes(serviceDTO, taskDTO);
  if (!isNaN(sumTimeInMinutes)) {
    tableRow.sumTimeInHrsAndMinutes =
      formatMinutesToHoursAndMinutes(sumTimeInMinutes);
  }
  return tableRow;
}

const CalculationServiceProcessors: CoreInterfaces.ServiceTaskProcessorsMap<CoreInterfaces.CalculationServiceTableRow> =
  {
    AccountsReceivable: accountsReceivableServiceProcessor,
    AccountsPayable: accountsPayableServiceProcessor,
    CorporateCardManagement: corporateCardManagementServiceProcessor,
    OtherAccountAndReconciliation:
      otherAccountAndReconciliationServiceProcessor,
    PeriodReporting: periodReportingServiceProcessor,
    InterimListedCompanies: interimListedCompaniesServiceProcessor,
    AnnualReporting: annualReportingListedCompaniesServiceProcessor,
    AnnualReportingListedCompanies: annualReportingServiceProcessor,
    PayrollAndExpenseAndTravelInvoiceManagement:
      payrollAndExpenseAndTravelInvoiceManagementServiceProcessor,
    AnnualPayrollRoutines: annuallyPayrollRoutineServiceProcessor,
    StartUpAccounting: startUpAccountingServiceProcessor,
    YearlyInternalDocumentationAndFormalities:
      yearlyInternalDocumentationAndFormalitiesServiceProcessor,
  };

export default CalculationServiceProcessors;
