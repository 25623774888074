import * as Constants from "./../../../core/Constants";

import {
  debounce,
  handleEventTargetValue,
  onQuestionInputValueChange,
} from "src/utils";
import { useCallback, useEffect, useState } from "react";

import { CorePropsInterfaces } from "src/core/Models";
import { TextField } from "@mui/material";

function QuestionTextInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const [fieldValue, setFieldValue] = useState(question.data.userValue);

  const debouncedChange = useCallback(
    debounce((evt: any) => {
      onQuestionInputValueChange(question, evt, onChange);
    }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS),
    [onChange]
  );

  function onInputValueChangeDelayed(evt: any): void {
    handleEventTargetValue(question, evt);
    setFieldValue(evt.target.value);
    debouncedChange(evt);
  }

  useEffect(() => {
    setFieldValue(question.data.userValue);
  }, [question.data.userValue]);

  return (
    <TextField
      data-testid={question.data.code}
      className="gt-TextField"
      onChange={onInputValueChangeDelayed}
      value={fieldValue}
      key={`questionInputText${question.data.code}`}
      variant="outlined"
      inputProps={{ maxLength: question.data.inputConstraints.maxLength }}
      disabled={isDisabled}
    />
  );
}

export default QuestionTextInput;
