import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "../../core/Constants/Constants";
import { findQuestionInService, resolveContactPerson } from "src/utils";

export default function OtherAccountAndReconciliationSummaryPanel(): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);

  const service = globalState.services.find(
    (item) =>
      item.data.code === Constants.ServiceCode.OtherAccountAndReconciliation
  );

  const contactPersonQuestion = findQuestionInService(
    service,
    Constants.OtherAccountAndReconciliationQuestions.Q0407
  );
  const nrVouchers = findQuestionInService(
    service,
    Constants.OtherAccountAndReconciliationQuestions.Q0404
  )?.data?.userValue;
  const nrOfBalanceAccount = findQuestionInService(
    service,
    Constants.OtherAccountAndReconciliationQuestions.Q0405
  )?.data?.userValue;

  const contactPerson = resolveContactPerson(
    contactPersonQuestion?.data?.options?.find(
      (item) => item.key === contactPersonQuestion.data.userValue
    )?.value
  );

  return (
    <article className="gt-summaryPanel">
      <div className="gt-summaryPanel__contactPerson">
        {t("PanelHeader.ContactPersonClientText", { contactPerson })}
      </div>
      <div className="gt-summaryPanel__data">
        {t("PanelHeader.NrOfVouchers", { nrVouchers })}
      </div>
      <div className="gt-summaryPanel__data">
        {t("PanelHeader.NrOfBalanceAccount", { nrOfBalanceAccount })}
      </div>
    </article>
  );
}
