import { CorePropsInterfaces } from "src/core/Models";

export default function GtScrollElement({
  service,
}: CorePropsInterfaces.GtScrollElementProps): JSX.Element {
  return (
    <span
      className={`gt-js-panel-virtual-element-for-scroll gt-js-panel-virtual-element-for-scroll-${service.data.code}`}
    ></span>
  );
}
