import { CoreInterfaces, DTOs } from "./../../../core/Models";
import * as Constants from "./../../../core/Constants";
import { t } from "i18next";
import {
  findQuestionInService,
  resolveTaskDisplayedAllocationOfResponsibility,
  resolveTaskDisplayedComment,
} from "src/utils/shared";
import {
  fetchTitleFromSelectedOption,
  mapQuestionCodesToQuestionDTOs,
} from "src/utils/question-utils";

function accountsReceivableServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const { generalVariables } = extraData;
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.AccountReceivableTasks.T0101]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const preSystemNameQuestion = findQuestionInService(
            serviceDTO,
            Constants.AccountReceivableQuestion.Q0104
          );
          const title = t(taskDTO.data.title, {
            accountPreSystem: preSystemNameQuestion.data.userValue,
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            title,
          };
        }
      },
      [Constants.AccountReceivableTasks.T0102]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const preSystemNameQuestion = findQuestionInService(
            serviceDTO,
            Constants.AccountReceivableQuestion.Q0104
          );
          const title = t(taskDTO.data.title, {
            accountPreSystem: preSystemNameQuestion.data.userValue,
            accountingSystem: generalVariables.accountingSystem,
          });

          const allocationOfResponsibility =
            resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
          const comment = resolveTaskDisplayedComment(taskDTO, {
            accountCreator: t(`Options.${allocationOfResponsibility}`),
            accountingSystem: generalVariables.accountingSystem,
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            title,
            comment,
          };
        }
      },
      [Constants.AccountReceivableTasks.T0103]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const title = t(taskDTO.data.title, {
            accountingSystem: generalVariables?.accountingSystem,
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            title,
          };
        }
      },
      [Constants.AccountReceivableTasks.T0104]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const title = t(taskDTO.data.title, {
            accountingSystem: t(generalVariables.accountingSystem),
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            title,
          };
        }
      },
      [Constants.AccountReceivableTasks.T0115]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const allocationOfResponsibility =
            resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
          const comment = resolveTaskDisplayedComment(taskDTO, {
            allocationOfResponsibility: t(
              `Options.${allocationOfResponsibility}`
            ),
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            comment,
          };
        }
      },
      [Constants.AccountReceivableTasks.T0116]: (taskDTO: DTOs.TaskDTO) => {
        if (taskDTO.state.isVisible) {
          const allocationOfResponsibility =
            resolveTaskDisplayedAllocationOfResponsibility(taskDTO);
          const comment = resolveTaskDisplayedComment(taskDTO, {
            allocationOfResponsibility: t(
              `Options.${allocationOfResponsibility}`
            ),
          });
          const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
          return {
            ...tableRow,
            comment,
          };
        }
        return null;
      },
    };

  return taskProcessors;
}

function accountsPayableServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const { Q0205, Q0205_1, Q0206, Q0206_1, Q0225 } =
    Constants.AccountPayableQuestion;
  const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
    Q0205,
    Q0205_1,
    Q0206,
    Q0206_1,
    Q0225,
  ]);

  const taskProcessors: {
    [taskCode in Constants.AutomaticTaskCode]?: (
      taskDTO: DTOs.TaskDTO
    ) => CoreInterfaces.EngagementServiceTableRow;
  } = {
    [Constants.AccountPayableTasks.T0201]: (taskDTO: DTOs.TaskDTO) => {
      const q0205ActiveQuestion = questionDTOMap.Q0205.state.isShown
        ? questionDTOMap.Q0205
        : questionDTOMap.Q0205_1;
      const q0206ActiveQuestion = questionDTOMap.Q0206.state.isShown
        ? questionDTOMap.Q0206
        : questionDTOMap.Q0206_1;

      const accountPayablePreSystem = q0205ActiveQuestion.state.isShown
        ? q0205ActiveQuestion.data.userValue
        : q0206ActiveQuestion.data.userValue;

      const title = t(taskDTO.data.title, {
        accountPayablePreSystem: t(`Options.${accountPayablePreSystem}`),
      });
      const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
      return {
        ...tableRow,
        title,
      };
    },
    [Constants.AccountPayableTasks.T0202]: (taskDTO: DTOs.TaskDTO) => {
      const param = questionDTOMap.Q0225.data.userValue;
      const title = t(taskDTO.data.title, {
        accountPayablePreSystem: param,
      });
      const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
      return {
        ...tableRow,
        title,
      };
    },
  };

  return taskProcessors;
}

function corporateCardManagementServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const { generalVariables } = extraData;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.CorporateCardManagementQuestion.Q0303]: "q0303",
    [Constants.CorporateCardManagementQuestion.Q0303_1]: "q0303_1",
    [Constants.CorporateCardManagementQuestion.Q0313]: "q0313",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.CorporateCardManagementTasks.T0301]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const activeQuestion = questionDTOMap.q0303.state.isShown
          ? questionDTOMap.q0303
          : questionDTOMap.q0303_1;
        const corporateCard =
          activeQuestion.data.userValue ===
          Constants.CorporateCardPreSystem.AnnatForsystem
            ? questionDTOMap.q0313.data.userValue
            : t(`Options.${questionDTOMap.q0303.data.userValue}`);
        const title = t(taskDTO.data.title, {
          corporateCardPreSystem: corporateCard,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.CorporateCardManagementTasks.T0306]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const title = t(taskDTO.data.title, {
          accountingSystem: generalVariables.accountingSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };

  return taskProcessors;
}

function otherAccountAndReconciliationServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const { generalVariables } = extraData;

  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.OtherAccountAndReconciliationQuestions.Q0416]: "q0416",
    [Constants.OtherAccountAndReconciliationQuestions.Q0417]: "q0417",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.OtherAccountAndReconciliationTasks.T0438]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const value =
          questionDTOMap.q0416.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0416.data.options.find(
                (option) => option.key === questionDTOMap.q0416.data.userValue
              )?.value
            : "";
        const preSystem = questionDTOMap.q0417.state.isShown
          ? questionDTOMap.q0417.data.userValue
          : t(value);

        const title = t(taskDTO.data.title, {
          preSystem: preSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },

      [Constants.OtherAccountAndReconciliationTasks.T0442]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const title = t(taskDTO.data.title, {
          accountingSystem: generalVariables.accountingSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };
  return taskProcessors;
}

function periodReportingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.PeriodReportingQuestion.Q0502]: "q0502",
    [Constants.PeriodReportingQuestion.Q0509]: "q0509",
    [Constants.PeriodReportingQuestion.Q0509_1]: "q0509_1",
    [Constants.PeriodReportingQuestion.Q0510]: "q0510",
    [Constants.PeriodReportingQuestion.Q0511]: "q0511",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.PeriodReportingTasks.T0504]: (taskDTO: DTOs.TaskDTO) => {
        const activeQuestion = questionDTOMap.q0509.state.isShown
          ? questionDTOMap.q0509
          : questionDTOMap.q0509_1;
        const title = t(taskDTO.data.title, {
          Q0509: t(`Options.${activeQuestion.data.userValue}`),
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PeriodReportingTasks.T0505]: (taskDTO: DTOs.TaskDTO) => {
        const comment = resolveTaskDisplayedComment(taskDTO, {
          Q0510: questionDTOMap?.q0510.data.userValue,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
      [Constants.PeriodReportingTasks.T0510]: (taskDTO: DTOs.TaskDTO) => {
        const comment = resolveTaskDisplayedComment(taskDTO, {
          Q0502: questionDTOMap.q0502.data.userValue,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
      [Constants.PeriodReportingTasks.T0523]: (taskDTO: DTOs.TaskDTO) => {
        const comment = resolveTaskDisplayedComment(taskDTO, {
          Q0511: questionDTOMap.q0511.data.comment,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
    };

  return taskProcessors;
}

function interimListedCompaniesServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {};
  return taskProcessors;
}

function annualReportingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {};
  return taskProcessors;
}

function annualReportingListedCompaniesServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {};
  return taskProcessors;
}

function payrollAndExpenseAndTravelInvoiceManagementServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const usedQuestions: {
    [questionCode in Constants.QuestionCode]?: string;
  } = {
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0708]:
      "q0708",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0713]:
      "q0713",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0724]:
      "q0724",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0725]:
      "q0725",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0727]:
      "q0727",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0730]:
      "q0730",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0748]:
      "q0748",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0759]:
      "q0759",
    [Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0763]:
      "q0763",
  };

  const questionDTOMap: {
    [questionAlias: string]: DTOs.QuestionDTO;
  } = {};

  serviceDTO.data.questions.forEach((eachQuestion) => {
    if (!!usedQuestions[eachQuestion.data.code]) {
      questionDTOMap[usedQuestions[eachQuestion.data.code]] = eachQuestion;
    }
  });

  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0701]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPortal =
          questionDTOMap.q0725.data.userValue === Constants.YesNo.Yes
            ? Constants.HelpfulConstants.Flow
            : questionDTOMap.q0727.data.userValue;
        const title = t(taskDTO.data.title, {
          payrollPortal: payrollPortal,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0702]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0703]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0704]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0706]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0711]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payrollPreSystem = questionDTOMap.q0708.state.isShown
          ? questionDTOMap.q0708.data.userValue
          : questionDTOMap.q0713.state.isShown
          ? t(questionDTOMap.q0713.data.text)
          : "";
        const title = t(taskDTO.data.title, {
          payrollPreSystem: payrollPreSystem,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0724]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const contactPersonPayroll =
          questionDTOMap.q0763.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0763.data.options.find(
                (option) => option.key === questionDTOMap.q0763.data.userValue
              )?.value
            : "";
        const comment = resolveTaskDisplayedComment(taskDTO, {
          contactPersonPayroll: contactPersonPayroll,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0725]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const contactPersonPayroll =
          questionDTOMap.q0763.data.userValue !==
          Constants.HelpfulConstants.PleaseSelect
            ? questionDTOMap.q0763.data.options.find(
                (option) => option.key === questionDTOMap.q0763.data.userValue
              )?.value
            : "";
        const comment = resolveTaskDisplayedComment(taskDTO, {
          contactPersonPayroll: contactPersonPayroll,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0726]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const payslipDistribution = t(
          `Options.${questionDTOMap.q0730?.data.userValue}`
        );
        const title = t(taskDTO.data.title, {
          payslipDistribution: payslipDistribution,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0727]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const question = questionDTOMap.q0724.state.isShown
          ? questionDTOMap.q0724.data.userValue
          : "";
        const comment = resolveTaskDisplayedComment(taskDTO, {
          Q0724: question,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
      [Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0730]: (
        taskDTO: DTOs.TaskDTO
      ) => {
        const q0748 = questionDTOMap.q0748.state.isShown
          ? questionDTOMap.q0748.data.userValue
          : "";
        const comment = resolveTaskDisplayedComment(taskDTO, { Q0748: q0748 });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          comment,
        };
      },
    };
  return taskProcessors;
}
function annuallyPayrollRoutineServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {
      [Constants.AnnualPayrollRoutinesTasks.T0749]: (taskDTO: DTOs.TaskDTO) => {
        const payrollService = extraData.extraServices.find(
          (serviceDTO: DTOs.ServiceDTO) =>
            serviceDTO.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
        );
        const q0759 = findQuestionInService(
          payrollService,
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0759
        );
        const titleText = fetchTitleFromSelectedOption(q0759);
        const title = t(taskDTO.data.title, {
          Q0759: titleText,
        });
        const tableRow = getDefaultTableRow(serviceDTO, taskDTO);
        return {
          ...tableRow,
          title,
        };
      },
    };
  return taskProcessors;
}

function startUpAccountingServiceProcessor(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> {
  const taskProcessors: CoreInterfaces.TaskProcessorMap<CoreInterfaces.EngagementServiceTableRow> =
    {};
  return taskProcessors;
}
export function getDefaultTableRow(
  serviceDTO: DTOs.ServiceDTO,
  taskDTO: DTOs.TaskDTO
): CoreInterfaces.EngagementServiceTableRow {
  const tableRow: CoreInterfaces.EngagementServiceTableRow = {
    id: `engagementServiceTask${taskDTO.data.code}Row`,
    title: "",
    frequency: "",
    allocationOfResponsibility: "",
    comment: "",
    tooltip: "",
    serviceDTO,
    taskDTO,
    estimatedTimePerUnit: 0,
  };
  tableRow.title =
    taskDTO.data.type === Constants.TaskType.Automatic
      ? t(taskDTO.data.title)
      : taskDTO.data.title;
  tableRow.frequency =
    taskDTO.data.adjustments.frequency ?? taskDTO.data.frequency;
  tableRow.allocationOfResponsibility =
    taskDTO.data.adjustments.allocationOfResponsibility ??
    taskDTO.data.allocationOfResponsibility;
  tableRow.estimatedTimePerUnit =
    taskDTO.data.adjustments.estimatedTimePerUnit ??
    taskDTO.data.estimatedTimePerUnit;
  tableRow.comment = resolveTaskDisplayedComment(taskDTO);
  tableRow.tooltip =
    taskDTO.data.type === Constants.TaskType.Automatic
      ? t(taskDTO.data.tooltip)
      : taskDTO.data.tooltip;
  return tableRow;
}

const EngagementServiceProcessors: CoreInterfaces.ServiceTaskProcessorsMap<CoreInterfaces.EngagementServiceTableRow> =
  {
    AccountsReceivable: accountsReceivableServiceProcessor,
    AccountsPayable: accountsPayableServiceProcessor,
    CorporateCardManagement: corporateCardManagementServiceProcessor,
    OtherAccountAndReconciliation:
      otherAccountAndReconciliationServiceProcessor,
    PeriodReporting: periodReportingServiceProcessor,
    InterimListedCompanies: interimListedCompaniesServiceProcessor,
    AnnualReporting: annualReportingServiceProcessor,
    AnnualReportingListedCompanies:
      annualReportingListedCompaniesServiceProcessor,
    PayrollAndExpenseAndTravelInvoiceManagement:
      payrollAndExpenseAndTravelInvoiceManagementServiceProcessor,
    AnnualPayrollRoutines: annuallyPayrollRoutineServiceProcessor,
    StartUpAccounting: startUpAccountingServiceProcessor,
  };

export default EngagementServiceProcessors;
