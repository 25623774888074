import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";
import { AdditionalCostDTO } from "src/core/Models/DTO.interface";
import { createAdditionalCostDTOFromLicense } from "src/utils";

export const OtherAccountAndReconciliationTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0401: findQuestionInService(
        serviceDTO,
        Constants.OtherAccountAndReconciliationQuestions.Q0401
      ),
      q0403: findQuestionInService(
        serviceDTO,
        Constants.OtherAccountAndReconciliationQuestions.Q0403
      ),
      q0406: findQuestionInService(
        serviceDTO,
        Constants.OtherAccountAndReconciliationQuestions.Q0406
      ),
      q0411: findQuestionInService(
        serviceDTO,
        Constants.OtherAccountAndReconciliationQuestions.Q0411
      ),
      q0413: findQuestionInService(
        serviceDTO,
        Constants.OtherAccountAndReconciliationQuestions.Q0413
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0401
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0403.state.isShown &&
          questionsMap.q0403.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0402
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0403.state.isShown &&
          questionsMap.q0403.data.userValue === Constants.YesNo.No;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0403
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0401.state.isShown &&
          questionsMap.q0401.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0405
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isAuditorGrantThornton &&
          appState.generalVariables.isAuditorGrantThornton === true;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0406
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isAuditorGrantThornton &&
          appState.generalVariables.isAuditorGrantThornton === true;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0407
      ) {
        taskDTO.state.isVisible =
          !appState.generalVariables.isAuditorGrantThornton;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0410
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isAuditorGrantThornton &&
          !!questionsMap.q0406 &&
          appState.generalVariables.isAuditorGrantThornton === true &&
          questionsMap.q0406.data.userValue === Constants.Frequency.Monthly;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0411
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isAuditorGrantThornton &&
          !!questionsMap.q0406 &&
          appState.generalVariables.isAuditorGrantThornton === true &&
          questionsMap.q0406.data.userValue === Constants.Frequency.Quarterly;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0413
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isAuditorGrantThornton &&
          appState.generalVariables.isAuditorGrantThornton === true;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0414
      ) {
        const { Handelsbanken, SEB, Nordea } = Constants.CustomerBank;

        taskDTO.state.isVisible =
          appState.generalVariables.customerBank &&
          [Handelsbanken, SEB, Nordea].includes(
            appState.generalVariables.customerBank as Constants.CustomerBank
          );
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0415
      ) {
        const { Handelsbanken, SEB, Nordea } = Constants.CustomerBank;

        taskDTO.state.isVisible =
          !!appState.generalVariables.customerBank &&
          ![Handelsbanken, SEB, Nordea].includes(
            appState.generalVariables.customerBank as Constants.CustomerBank
          );
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0416
      ) {
        taskDTO.state.isVisible =
          !!appState.generalVariables.isCompanyRegisteredVAT;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0438
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0413.state.isShown &&
          questionsMap.q0413.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0439
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0413.state.isShown &&
          questionsMap.q0413.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0440
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0413.state.isShown &&
          questionsMap.q0413.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0441
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0413.state.isShown &&
          questionsMap.q0413.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code === Constants.OtherAccountAndReconciliationTasks.T0442
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0413.state.isShown &&
          questionsMap.q0413.data.userValue === Constants.YesNo.Yes;
      }
      if (
        questionsMap.q0411.state.isShown &&
        Array.isArray(questionsMap.q0411.data.userValue) &&
        questionsMap.q0411.data.userValue.length > 0
      ) {
        const newLicenses: CoreInterfaces.License[] = questionsMap.q0411.data
          .userValue as CoreInterfaces.License[];

        const customLicensesAdditionalCosts: AdditionalCostDTO[] =
          newLicenses.map((license) =>
            createAdditionalCostDTOFromLicense(license)
          );

        serviceDTO.data.additionalCosts = [
          ...serviceDTO.data.additionalCosts.filter(
            (item) => !item.data.isFrontendObject
          ),
          ...customLicensesAdditionalCosts,
        ];
      }
      if (!questionsMap.q0411.state.isShown) {
        serviceDTO.data.additionalCosts = [
          ...serviceDTO.data.additionalCosts.filter(
            (item) => !item.data.isFrontendObject
          ),
        ];
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.OtherAccountAndReconciliationAdditionalCosts.T0429
        ) {
          eachAdditionalCost.state.isVisible =
            appState.generalVariables.isDefaultAccountingSystem;
        }
      });
    }
  };
