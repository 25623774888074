import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as Constants from "../../core/Constants";
import { t } from "i18next";
import { useContext, useMemo } from "react";
import { AppContext } from "src/contexts/AppContext";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { CorePropsInterfaces } from "src/core/Models";
import {
  ceil,
  formatMinutesToHoursAndMinutes,
  getServiceStaffLevelUsageMap,
  getServiceStats,
} from "src/utils";
import i18n from "src/i18n";

export default function OtherServiceTotalsPanel({
  serviceRecurringType,
  includeStaffingBreakdown,
}: CorePropsInterfaces.RecurringServicesPanelProps) {
  const { globalState } = useContext(AppContext);

  const usedServices = globalState.services.filter((eachService) => {
    return (
      eachService.data.recurringType === serviceRecurringType &&
      eachService.state.isSelected
    );
  });

  let totalPrice = 0;
  let totalMinutes = 0;
  const staffLevelUsageMap: {
    [key in Constants.StaffLevel]?: number;
  } = {};
  let rows: Array<any> = [];
  const staffLevelRows: Array<any> = [];

  if (includeStaffingBreakdown) {
    const taskGroups = [
      Constants.TaskGroup.DefaultGroup,
      Constants.TaskGroup.AnnualReport,
      Constants.TaskGroup.StatutoryAnnualReport,
      Constants.TaskGroup.AnnualReportV2,
      Constants.TaskGroup.CorporateIncomeTax,
      Constants.TaskGroup.AnnualReportV3,
    ];
    for (const usedService of usedServices) {
      const serviceStaffLevelUsageMap = getServiceStaffLevelUsageMap(
        usedService,
        taskGroups
      );
      for (const staffLevel in serviceStaffLevelUsageMap) {
        staffLevelUsageMap[staffLevel as Constants.StaffLevel] =
          staffLevelUsageMap[staffLevel as Constants.StaffLevel] || 0;
        let totalTimeForStaffLevel = 0;
        for (const taskGroup of taskGroups) {
          totalTimeForStaffLevel +=
            serviceStaffLevelUsageMap[staffLevel as Constants.StaffLevel][
              taskGroup
            ] || 0;
        }

        staffLevelUsageMap[staffLevel as Constants.StaffLevel] +=
          totalTimeForStaffLevel;
      }
    }
    const staffingHeaderRow = {
      title: t("General.StaffingLevel"),
      totalPrice: t("TableHeader.HoursYearly"),
      hoursPerPeriod: t("TableHeader.NumberOfHours"),
      totalHours: "",
      id: `recurringSummaryServicesPanelServiceStaffLevelSectionHeader`,
    };

    staffLevelRows.push(staffingHeaderRow);
  }

  for (const eachService of usedServices) {
    const serviceStats = getServiceStats(globalState, eachService);
    let totalPricePerService = serviceStats.totalPricePerYear;

    if (
      eachService.data.otherServicesTotalsPanel.isTotalCostOverridenByAdjustment
    ) {
      totalPricePerService = serviceStats.additionalCostsAdjustment;
    }
    totalPrice += totalPricePerService;
    totalMinutes += serviceStats.workTimeInMinutesPerYear;

    const row = {
      title: t(eachService.data.title),
      totalPrice: `${ceil(totalPricePerService)} ${Constants.USED_CURRENCY}`,
      totalHours: formatMinutesToHoursAndMinutes(
        serviceStats.workTimeInMinutesPerYear
      ),
      id: `OtherServiceLine${eachService.data.code}`,
    };
    rows.push(row);
  }

  if (rows.length) {
    const summaryRow: any = {
      title: t(
        `General.Total${serviceRecurringType}${
          serviceRecurringType === Constants.ServiceRecurringType.OneTime
            ? ""
            : "Recurring"
        }Services`
      ),
      totalPrice: `${ceil(totalPrice)} ${Constants.USED_CURRENCY}`,
      totalHours: formatMinutesToHoursAndMinutes(totalMinutes),
      id: Constants.CustomDataGridColumnId.RecurringServicesTotal,
    };
    rows.push(summaryRow);
  }

  let totalTimeInMinutes = 0;
  for (const staffLevel in staffLevelUsageMap) {
    const staffingRow = {
      title: t(`Options.${staffLevel}`),
      totalPrice: formatMinutesToHoursAndMinutes(
        staffLevelUsageMap[staffLevel as Constants.StaffLevel]
      ),
      totalHours: "",
      id: `staffLevelUsageMapRow_${staffLevel}_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffLevelRows.push(staffingRow);
    totalTimeInMinutes +=
      staffLevelUsageMap[staffLevel as Constants.StaffLevel];
  }

  if (totalTimeInMinutes > 0) {
    const staffingTotalRow = {
      title: t("General.TotalNumberOfHours"),
      totalPrice: formatMinutesToHoursAndMinutes(totalTimeInMinutes),
      totalHours: "",
      id: `staffLevelUsageMapRow_Total_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffLevelRows.push(staffingTotalRow);
    rows = rows.concat(staffLevelRows);
  }

  const columns = useMemo(
    () => getColumnsDefinition(serviceRecurringType),
    [serviceRecurringType, i18n.language]
  );

  return (
    !!rows.length && (
      <article
        className="gt-servicePanel"
        data-testid={`OtherServiceTotalsPanel${serviceRecurringType}`}
      >
        <Accordion className="gt-accordion" expanded={true}>
          <AccordionSummary className="gt-accordion__header">
            <div className="gt-accordion__header-container">
              <span className="gt-accordion__header-container-title">
                {t(
                  `PanelHeader.${serviceRecurringType}${
                    serviceRecurringType ===
                    Constants.ServiceRecurringType.OneTime
                      ? ""
                      : "Recurring"
                  }Services`
                )}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="gt-accordion__content">
            <div className="gt-tasksPanel gt-tasksPanel--recurringServices">
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                rowHeight={44}
                columnHeaderHeight={48}
                hideFooterPagination={true}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </article>
    )
  );
}

function getColumnsDefinition(
  serviceRecurringType: Constants.ServiceRecurringType
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total")
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "Title"}
          >
            {params.row.title}
          </article>
        );
      },
    },
    {
      field: "totalPrice",
      flex: 0.7,
      headerName: t(`TableHeader.TotalPrice${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total")
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "TotalPrice"}>
            {params.row.totalPrice}
          </article>
        );
      },
    },
    {
      field: "totalHours",
      headerName: t(`TableHeader.TotalHours${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        if (
          row.id ===
            "recurringSummaryServicesPanelServiceStaffLevelSectionHeader" ||
          row.id.startsWith("staffLevelUsageMapRow_")
        ) {
          return "gt-noBorderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "TotalHours"}>
            {params.row.totalHours}
          </article>
        );
      },
    },
  ];
  return columnDefinition;
}
