import { TextField } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import { debounce } from "src/utils";
import * as Constants from "../../core/Constants";
import { CorePropsInterfaces } from "src/core/Models";

export default function GtDebouncedInput(
  props: CorePropsInterfaces.GtDebouncedInputProps
): JSX.Element {
  const {
    value,
    onChange,
    updateTimestamp,
    debounceTime = Constants.INPUT_CHANGE_DEBOUNCE_MILLIS,
    maxLength,
    sanitizationFn,
    placeholder,
    ...otherProps
  } = props;
  const inputRef = useRef(null);
  const isTypingActive = useRef(false);
  const debouncedChange = debounce((value: string) => {
    onChange(value);
    isTypingActive.current = false;
  }, debounceTime);

  function onInputValueChangeDelayed(evt: ChangeEvent<HTMLInputElement>): void {
    let sanitizedValue = evt.target.value;
    if (!!sanitizationFn) {
      sanitizedValue = sanitizationFn(sanitizedValue);
    } else {
      sanitizedValue = sanitizedValue.replace(/[^\d-]/g, "");
    }
    if (!!maxLength) {
      if (sanitizedValue.length > 1 && sanitizedValue.endsWith("-")) {
        sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
      }
      const usedMaxLength = sanitizedValue.startsWith("-")
        ? maxLength + 1
        : maxLength;
      sanitizedValue = sanitizedValue.substring(0, usedMaxLength);
    }
    inputRef.current.value = sanitizedValue;
    isTypingActive.current = true;
    debouncedChange(sanitizedValue);
  }

  useEffect(() => {
    inputRef.current.value = value;
  }, [updateTimestamp]);

  const memoizedTextField = useMemo(() => {
    return (
      <TextField
        inputRef={inputRef}
        defaultValue={value}
        onChange={onInputValueChangeDelayed}
        placeholder={placeholder}
        {...otherProps}
        autoComplete="false"
      />
    );
  }, [placeholder]);

  return memoizedTextField;
}
