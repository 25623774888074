import * as Constants from "./../../../core/Constants";
import { CorePropsInterfaces, DTOs } from "./../../../core/Models";
import {
  debounce,
  ensureEventValueIsAValidInteger,
  handleEventTargetValue,
  onQuestionInputValueChange,
} from "src/utils";
import { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";

function QuestionCurrencyInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const [fieldValue, setFieldValue] = useState(question.data.userValue);

  const debouncedChange = useCallback(
    debounce((evt: any) => {
      onQuestionInputValueChange(question, evt, onChange);
    }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS),
    [onChange]
  );

  function onInputValueChangeDelayed(evt: any): void {
    ensureEventValueIsAValidInteger(evt);
    handleEventTargetValue(question, evt);
    if (fieldValue !== evt.target.value) {
      setFieldValue(evt.target.value);
      debouncedChange(evt);
    }
  }

  useEffect(() => {
    setFieldValue(question.data.userValue);
  }, [question.data.userValue]);

  return (
    <article className="gt-currencyFieldWrapper">
      <TextField
        data-testid={question.data.code}
        className="gt-TextField"
        type="text"
        onChange={onInputValueChangeDelayed}
        value={fieldValue}
        variant="outlined"
        disabled={isDisabled}
      />
      <span className="gt-currencyFieldWrapper__currencySymbol">
        {Constants.Currency.kr}
      </span>
    </article>
  );
}

export default QuestionCurrencyInput;
