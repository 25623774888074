import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "src/utils";
import * as Constants from "../../core/Constants";
import { CorePropsInterfaces } from "src/core/Models";
import { v4 as uuid } from "uuid";

export default function GtLicenseDebouncedInput(
  props: CorePropsInterfaces.GtDebouncedInputProps
): JSX.Element {
  const {
    value,
    onChange,
    debounceTime = Constants.INPUT_CHANGE_DEBOUNCE_MILLIS,
    updateTimestamp,
    formControlLabel,
    sanitizationFn,
    isDisabled,
    maxLength,
  } = props;

  const inputRef = useRef(null);
  const isTypingActive = useRef(false);
  const [inputId] = useState(uuid());

  const debouncedChange = debounce((sanitizedValue: string) => {
    onChange(sanitizedValue);
    isTypingActive.current = false;
  }, debounceTime);

  function onInputValueChangeDelayed(evt: ChangeEvent<HTMLInputElement>): void {
    let sanitizedValue = evt.target.value;
    if (!!sanitizationFn) sanitizedValue = sanitizationFn(evt);

    inputRef.current.value = sanitizedValue;
    isTypingActive.current = true;
    debouncedChange(sanitizedValue);
  }

  useEffect(() => {
    inputRef.current.value = value;
  }, [updateTimestamp]);

  const memoizedFormControl = useMemo(() => {
    return (
      <FormControl>
        <InputLabel htmlFor={inputId} className="gt-inputLabel" shrink={true}>
          {formControlLabel}
        </InputLabel>
        <OutlinedInput
          id={inputId}
          label={formControlLabel}
          defaultValue={value || ""}
          onChange={onInputValueChangeDelayed}
          disabled={isDisabled}
          inputRef={inputRef}
          inputProps={{ maxLength: maxLength }}
        />
      </FormControl>
    );
  }, [formControlLabel]);

  return memoizedFormControl;
}
