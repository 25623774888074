import { findQuestionInService } from "src/utils/shared";
import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "./../../../core/Constants";

export const OtherAccountAndReconciliationProcessor: CoreInterfaces.QuestionProcessors =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO) => {
    return {
      [Constants.OtherAccountAndReconciliationQuestions.Q0402]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0401 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0401
        );
        questionDTO.state.isShown =
          q0401?.state.isShown && q0401?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0403]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0401 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0401
        );
        questionDTO.state.isShown =
          q0401?.state.isShown && q0401?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0406]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const taskToBeUpdated: Array<Constants.TaskCode> = [
          Constants.OtherAccountAndReconciliationTasks.T0403,
          Constants.OtherAccountAndReconciliationTasks.T0404,
          Constants.OtherAccountAndReconciliationTasks.T0405,
          Constants.OtherAccountAndReconciliationTasks.T0406,
          Constants.OtherAccountAndReconciliationTasks.T0407,
          Constants.OtherAccountAndReconciliationTasks.T0408,
          Constants.OtherAccountAndReconciliationTasks.T0409,
          Constants.OtherAccountAndReconciliationTasks.T0410,
          Constants.OtherAccountAndReconciliationTasks.T0411,
          Constants.OtherAccountAndReconciliationTasks.T0412,
          Constants.OtherAccountAndReconciliationTasks.T0413,
          Constants.OtherAccountAndReconciliationTasks.T0414,
          Constants.OtherAccountAndReconciliationTasks.T0415,
          Constants.OtherAccountAndReconciliationTasks.T0417,
          Constants.OtherAccountAndReconciliationTasks.T0418,
          Constants.OtherAccountAndReconciliationTasks.T0419,
          Constants.OtherAccountAndReconciliationTasks.T0420,
          Constants.OtherAccountAndReconciliationTasks.T0421,
          Constants.OtherAccountAndReconciliationTasks.T0422,
          Constants.OtherAccountAndReconciliationTasks.T0423,
          Constants.OtherAccountAndReconciliationTasks.T0424,
          Constants.OtherAccountAndReconciliationTasks.T0425,
          Constants.OtherAccountAndReconciliationTasks.T0426,
          Constants.OtherAccountAndReconciliationTasks.T0427,
          Constants.OtherAccountAndReconciliationTasks.T0428,
        ];

        const frequencyToUse = (
          questionDTO.data.userValue &&
          questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
            ? questionDTO.data.userValue
            : questionDTO.data.defaultValue
        ) as Constants.Frequency;

        serviceDTO.data.tasks.forEach((eachTask) => {
          if (taskToBeUpdated.includes(eachTask.data.code)) {
            eachTask.data.frequency = frequencyToUse;
          }
        });
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0407]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.data.options = [
          {
            key: Constants.HelpfulConstants.PleaseSelect,
            value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
          },
          ...(appState.remoteData?.customerContactPersons || []),
        ];
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0409]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          appState.generalVariables.isDefaultAccountingSystem;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0410]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          appState.generalVariables.isDefaultAccountingSystem;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0411]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0410 = serviceDTO.data.questions.find(
          (q) =>
            q.data.code ===
            Constants.OtherAccountAndReconciliationQuestions.Q0410
        );
        questionDTO.state.isShown =
          q0410?.state.isShown && q0410?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0412]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown = appState.services.some(
          (eachService) =>
            eachService.state.isSelected &&
            eachService.data.code.includes(
              Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
            )
        );
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0413]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown = appState.services.some(
          (eachService) =>
            !eachService.state.isSelected &&
            eachService.data.code.includes(
              Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
            )
        );
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0414]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0413 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0413
        );
        questionDTO.state.isShown =
          q0413?.state.isShown && q0413?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0415]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0413 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0413
        );
        questionDTO.state.isShown =
          q0413?.state.isShown && q0413?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0416]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0413 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0413
        );
        questionDTO.state.isShown =
          q0413?.state.isShown && q0413?.data.userValue === Constants.YesNo.Yes;
      },
      [Constants.OtherAccountAndReconciliationQuestions.Q0417]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0416 = findQuestionInService(
          serviceDTO,
          Constants.OtherAccountAndReconciliationQuestions.Q0416
        );
        questionDTO.state.isShown =
          q0416?.state.isShown &&
          q0416?.data.userValue ===
            Constants.CorporateCardPreSystem.AnnatForsystem;
      },
    };
  };
