import { t } from "i18next";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Button } from "@mui/material";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import * as Constants from "../../core/Constants";
import { useContext, useState } from "react";
import { AppContext } from "src/contexts/AppContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValidDate } from "src/utils";
import { useQuery } from "src/core/Hooks";

function GtSetToManuallySignedDialog({
  onConfirm,
  onDialogClose,
}: CorePropsInterfaces.GtSetManuallySignedDialogProps): JSX.Element {
  const { globalState } = useContext(AppContext);
  const queryParamsMap = useQuery();
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );

  const [newSigningDate, setNewSigningDate] = useState(null);
  const [isNotAValidData, setIsNotAValidData] = useState(true);

  const onSelectedValueChange = (value: string) => {
    const parsedDate = new Date(value);
    const isAValidDate = value !== null && isValidDate(parsedDate);
    if (
      !isAValidDate ||
      (!!isAValidDate && parsedDate.getTime() > Date.now())
    ) {
      setIsNotAValidData(true);
    } else {
      setIsNotAValidData(false);
    }
    setNewSigningDate(isAValidDate ? value : newSigningDate);
  };

  const setValidityToFinal = () => {
    const payload: CoreInterfaces.EngagementManuallySigningPack = {
      BusinessOpportunityId: businessOpportunityId,
      EngagementConfigurationId: globalState.currentConfiguration.id,
      SigningDate: new Date(
        Date.UTC(
          newSigningDate.getFullYear(),
          newSigningDate.getMonth(),
          newSigningDate.getDate()
        )
      ),
    };
    onConfirm(payload);
  };

  const manuallySetToSignedDialogDTO: DTOs.GtDialogDTO = {
    data: {
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={isNotAValidData}
          onClick={() => {
            setValidityToFinal();
          }}
        >
          {t("General.ManuallySetToSigned")}
        </Button>,
      ],
      title: (
        <span className="gt-finalSectionDialogIcon">
          <NoteAddOutlinedIcon />
        </span>
      ),
    },
    api: {
      onClose: onDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  return (
    <GtDialog gtDialogDTO={manuallySetToSignedDialogDTO}>
      <article className="gt-finalSectionDialog">
        <section className="gt-finalSectionDialog__content">
          <div className="gt-finalSectionDialog__content-question">
            {t("General.ManuallySetToSigned")}
          </div>
          <div className="gt-finalSectionDialog__content-explanation">
            {t("General.SetManuallySignedDetails")}
            <div className="gt-finalSectionDialog__content-dateValue">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateField
                  format={Constants.HelpfulConstants.DateFormatYearMonthAndDay}
                  className={`gt-finalSectionDialog__content-datePicker`}
                  value={newSigningDate}
                  onChange={onSelectedValueChange}
                />
              </LocalizationProvider>
            </div>
          </div>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtSetToManuallySignedDialog;
