import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";
import { mapQuestionCodesToQuestionDTOs } from "src/utils/question-utils";

export const AccountsPayableProcessors: CoreInterfaces.QuestionProcessors = (
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) => {
  return {
    [Constants.AccountPayableQuestion.Q0203]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0202 = findQuestionInService(
        serviceDTO,
        Constants.AccountPayableQuestion.Q0202
      );
      questionDTO.state.isShown = +q0202?.data.userValue > 0;
    },
    [Constants.AccountPayableQuestion.Q0204]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0202 = findQuestionInService(
        serviceDTO,
        Constants.AccountPayableQuestion.Q0202
      );
      questionDTO.state.isShown = +q0202?.data.userValue > 0;
    },
    [Constants.AccountPayableQuestion.Q0205]: (
      // Accounts Payable Pre-System
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0204 = findQuestionInService(
        serviceDTO,
        Constants.AccountPayableQuestion.Q0204
      );

      questionDTO.state.isShown =
        appState.generalVariables.isDefaultAccountingSystem &&
        q0204?.state.isShown &&
        q0204?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.AccountPayableQuestion.Q0205_1]: (
      // Accounts Payable Pre-System
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0204 = findQuestionInService(
        serviceDTO,
        Constants.AccountPayableQuestion.Q0204
      );

      questionDTO.state.isShown =
        !appState.generalVariables.isDefaultAccountingSystem &&
        q0204?.state.isShown &&
        q0204?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.AccountPayableQuestion.Q0206]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0204 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.AccountPayableQuestion.Q0204
      );

      questionDTO.state.isShown =
        appState.generalVariables.isDefaultAccountingSystem &&
        q0204?.state.isShown &&
        q0204?.data.userValue === Constants.YesNo.No;
    },
    [Constants.AccountPayableQuestion.Q0206_1]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0204 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.AccountPayableQuestion.Q0204
      );
      questionDTO.state.isShown =
        !appState.generalVariables.isDefaultAccountingSystem &&
        q0204?.state.isShown &&
        q0204?.data.userValue === Constants.YesNo.No;
    },
    [Constants.AccountPayableQuestion.Q0209]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.AccountPayableTasks.T0203,
        Constants.AccountPayableTasks.T0210,
        Constants.AccountPayableTasks.T0211,
      ];

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.allocationOfResponsibility =
            allocationOfResponsabilityToUse;
        }
      });
    },
    [Constants.AccountPayableQuestion.Q0210]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0204 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountPayableTasks.T0204
      );

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;
      t0204.data.allocationOfResponsibility = allocationOfResponsabilityToUse;

      questionDTO.state.isShown =
        appState.generalVariables.costCenter &&
        appState.generalVariables.costCenter === Constants.YesNo.Yes;
    },
    [Constants.AccountPayableQuestion.Q0214]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.costCenter &&
        appState.generalVariables.costCenter === Constants.YesNo.No;
    },
    [Constants.AccountPayableQuestion.Q0215]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.costCenter &&
        appState.generalVariables.costCenter === Constants.YesNo.Yes;
    },
    [Constants.AccountPayableQuestion.Q0216]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0207 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountPayableTasks.T0207
      );
      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;

      t0207.data.allocationOfResponsibility = allocationOfResponsabilityToUse;
    },
    [Constants.AccountPayableQuestion.Q0218]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const { Handelsbanken, SEB, Nordea } = Constants.CustomerBank;

      questionDTO.state.isShown =
        appState.generalVariables.customerBank &&
        [Handelsbanken, SEB, Nordea].includes(
          appState.generalVariables.customerBank as Constants.CustomerBank
        );
    },
    [Constants.AccountPayableQuestion.Q0219]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.customerBank &&
        (appState.generalVariables.customerBank ===
          Constants.CustomerBank.Swedbank ||
          appState.generalVariables.customerBank ===
            Constants.CustomerBank.DanskeBank);
    },
    [Constants.AccountPayableQuestion.Q0220]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.customerBank &&
        appState.generalVariables.customerBank ===
          Constants.CustomerBank.Otherbanks;
    },
    [Constants.AccountPayableQuestion.Q0221]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        {
          key: Constants.HelpfulConstants.PleaseSelect,
          value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
        },
        ...(appState.remoteData?.customerContactPersons || []),
      ];
    },
    [Constants.AccountPayableQuestion.Q0223]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.AccountPayableTasks.T0203,
        Constants.AccountPayableTasks.T0204,
        Constants.AccountPayableTasks.T0205,
        Constants.AccountPayableTasks.T0206,
        Constants.AccountPayableTasks.T0207,
        Constants.AccountPayableTasks.T0208,
        Constants.AccountPayableTasks.T0210,
        Constants.AccountPayableTasks.T0211,
        Constants.AccountPayableTasks.T0225,
        Constants.AccountPayableTasks.T0226,
        Constants.AccountPayableTasks.T0227,
      ];

      const frequencyToUse = (
        questionDTO.data.userValue &&
        questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
          ? questionDTO.data.userValue
          : questionDTO.data.defaultValue
      ) as Constants.Frequency;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.frequency = frequencyToUse;
        }
      });
    },
    [Constants.AccountPayableQuestion.Q0224]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const { Handelsbanken, SEB, Nordea } = Constants.CustomerBank;

      questionDTO.state.isShown =
        appState.generalVariables.customerBank &&
        ![Handelsbanken, SEB, Nordea].includes(
          appState.generalVariables.customerBank as Constants.CustomerBank
        );
    },
    [Constants.AccountPayableQuestion.Q0225]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const { Q0205, Q0205_1, Q0206, Q0206_1 } =
        Constants.AccountPayableQuestion;
      const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
        Q0205,
        Q0205_1,
        Q0206,
        Q0206_1,
      ]);

      const q0205ActiveQuestion = questionDTOMap.Q0205.state.isShown
        ? questionDTOMap.Q0205
        : questionDTOMap.Q0205_1;
      const q0206ActiveQuestion = questionDTOMap.Q0206.state.isShown
        ? questionDTOMap.Q0206
        : questionDTOMap.Q0206_1;

      questionDTO.state.isShown =
        (q0205ActiveQuestion?.state.isShown &&
          q0205ActiveQuestion?.data.userValue ===
            Constants.AccountPayablePreSystem.AnnatForsystem) ||
        (q0206ActiveQuestion?.state.isShown &&
          q0206ActiveQuestion?.data.userValue ===
            Constants.AccountPayablePreSystem.AnnatForsystem);
    },
    [Constants.AccountPayableQuestion.Q0227]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0226 = findQuestionInService(
        serviceDTO,
        Constants.AccountPayableQuestion.Q0226
      );
      questionDTO.state.isShown =
        q0226?.state.isShown && q0226?.data.userValue === Constants.YesNo.Yes;
    },
  };
};
