import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { AppContext } from "src/contexts/AppContext";
import { day, getPayrollSystem } from "src/utils";
import { DateField } from "@mui/x-date-pickers";
import * as Constants from "./../../core/Constants";

function EngagementDescriptionGeneralPanel(): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);
  const payrollSystem = getPayrollSystem(globalState);
  const [validFromDate] = useState<Dayjs | null>(
    globalState.currentConfiguration.validFromDate
      ? day(globalState.currentConfiguration.validFromDate)
      : null
  );
  const validUntilDate = globalState.currentConfiguration.validUntilDate
    ? day(globalState.currentConfiguration.validUntilDate)
    : null;

  return (
    <article className="gt-engagementDescriptionGeneralPanel">
      <section className="gt-engagementDescriptionGeneralPanel__section">
        <div className="gt-engagementDescriptionGeneralPanel__section-label">
          {t("General.ClientName")}
        </div>
        <div className="gt-engagementDescriptionGeneralPanel__section-value">
          {globalState.generalVariables.companyName}
        </div>
      </section>
      <section className="gt-engagementDescriptionGeneralPanel__section">
        <div className="gt-engagementDescriptionGeneralPanel__section-label">
          {t("General.OrgNo")}
        </div>
        <div className="gt-engagementDescriptionGeneralPanel__section-value">
          {globalState.generalVariables.organisationNo}
        </div>
      </section>
      {globalState.generalVariables.accountingSystem && (
        <section className="gt-engagementDescriptionGeneralPanel__section">
          <div className="gt-engagementDescriptionGeneralPanel__section-label">
            {t("General.AccountingSystem")}
          </div>
          <div className="gt-engagementDescriptionGeneralPanel__section-value">
            {globalState.generalVariables.accountingSystem}
          </div>
        </section>
      )}
      {payrollSystem && (
        <section className="gt-engagementDescriptionGeneralPanel__section">
          <div className="gt-engagementDescriptionGeneralPanel__section-label">
            {t("General.PayrollSystem")}
          </div>
          <div className="gt-engagementDescriptionGeneralPanel__section-value">
            {payrollSystem}
          </div>
        </section>
      )}
      <section className="gt-engagementDescriptionGeneralPanel__section">
        <div className="gt-engagementDescriptionGeneralPanel__section-label">
          {t("General.ValidFrom")}
        </div>
        <div className="gt-engagementDescriptionGeneralPanel__section-dateValue">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              format={
                Constants.HelpfulConstants.DateFormatYearMonthAndDayUpperCase
              }
              className="gt-engagementDescriptionGeneralPanel__section-datePicker"
              value={validFromDate}
              readOnly={true}
            />
            {validUntilDate !== null && (
              <>
                <span className="gt-engagementDescriptionGeneralPanel__section-divider">
                  -
                </span>
                <DateField
                  format={
                    Constants.HelpfulConstants
                      .DateFormatYearMonthAndDayUpperCase
                  }
                  className="gt-engagementDescriptionGeneralPanel__section-datePicker"
                  value={validUntilDate}
                  readOnly={true}
                />
              </>
            )}
          </LocalizationProvider>
        </div>
      </section>
    </article>
  );
}

export default EngagementDescriptionGeneralPanel;
