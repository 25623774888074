import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";
import { getQuestionValue } from "src/utils/question-utils";

export const PayrollAndExpenseAndTravelInvoiceManagementTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0707: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0707
      ),
      q0708: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0708
      ),
      q0709: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0709
      ),
      q0710: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0710
      ),
      q0711: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0711
      ),
      q0712: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0712
      ),
      q0721: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0721
      ),
      q0728: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0728
      ),
      q0729: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0729
      ),
      q0732: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0732
      ),
      q0733: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0733
      ),
      q0741: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0741
      ),
      q0744: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0744
      ),
      q0746: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0746
      ),
      q0750: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0750
      ),
      q0755: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0755
      ),
      q0756: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0756
      ),
      q0758: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0758
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0703
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0750.state.isShown &&
          questionsMap.q0750.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0704
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0707.state.isShown || questionsMap.q0708.state.isShown;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0706
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0750.state.isShown &&
          questionsMap.q0750.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0707
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0728.state.isShown &&
          questionsMap.q0728.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0710
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0729.state.isShown &&
          questionsMap.q0729.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0711
      ) {
        taskDTO.data.allocationOfResponsibility =
          questionsMap.q0707.state.isShown &&
          questionsMap.q0707.data.userValue === Constants.YesNo.Yes
            ? Constants.TaskAllocationResponsibility.Client
            : Constants.TaskAllocationResponsibility.GT;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0719
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0741.state.isShown &&
          questionsMap.q0741.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0720
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0741.state.isShown &&
          questionsMap.q0741.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0721
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0746.state.isShown &&
          questionsMap.q0746.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0722
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0744.state.isShown &&
          questionsMap.q0744.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0723
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0744.state.isShown &&
          questionsMap.q0744.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0727
      ) {
        taskDTO.data.allocationOfResponsibility =
          questionsMap.q0721.state.isShown &&
          questionsMap.q0721.data.userValue === Constants.YesNo.Yes
            ? Constants.TaskAllocationResponsibility.GT
            : Constants.TaskAllocationResponsibility.Client;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0729
      ) {
        const hasMatchingServices = appState.services.some(
          (eachService) =>
            eachService.state.isSelected &&
            Constants.ServiceCode.OtherAccountAndReconciliation.includes(
              eachService.data.code
            )
        );
        taskDTO.state.isVisible = !hasMatchingServices;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0730
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0732.state.isShown &&
          questionsMap.q0732.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0731
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0733.state.isShown &&
          questionsMap.q0733.data.userValue === Constants.YesNo.Yes;
      }
      if (
        taskDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0732
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0733.state.isShown &&
          questionsMap.q0733.data.userValue === Constants.YesNo.Yes;
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0751
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0711.state.isShown ||
            questionsMap.q0712.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0752
        ) {
          eachAdditionalCost.state.isVisible = questionsMap.q0710.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0753
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0709.state.isShown ||
            questionsMap.q0710.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0754
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0755.state.isShown &&
            getQuestionValue(questionsMap.q0755) === Constants.YesNo.Yes;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0755
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0711.state.isShown ||
            questionsMap.q0712.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
            .T0757
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0756.state.isShown &&
            getQuestionValue(questionsMap.q0756) === Constants.YesNo.Yes;
        }
      });
    }
  };
