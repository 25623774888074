import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CorePropsInterfaces } from "src/core/Models";
import { convertStringIntoDate } from "src/utils";

export default function GtDocumentSigningStatusIcon({
  status,
  signingDate,
  isDarkIcon = false,
}: CorePropsInterfaces.GtDocumentSigningStatusIconProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <section className="gt-descriptionTooltip">
          {t(`General.${status}Text`, {
            date: convertStringIntoDate(signingDate),
          })}
        </section>
      }
      placement="right"
    >
      <div
        className="gt-documentSigningStatusIcon"
        style={{
          backgroundImage: `url(/resources/icons/${status}${
            isDarkIcon ? "Dark" : ""
          }.svg)`,
        }}
      ></div>
    </Tooltip>
  );
}
