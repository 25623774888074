import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";

export const AnnualPayrollRoutinesTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0758: findQuestionInService(
        serviceDTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0758
      ),
    };
  };
