import * as Constants from "src/core/Constants/Constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CoreInterfaces } from "src/core/Models";
import { convertStringIntoDate } from "src/utils/helpers";
import { fetchData } from "src/utils";
import { t } from "i18next";
import { useQuery } from "src/core/Hooks";
import { LoadingContext } from "src/contexts/LoadingContext";

function GtAuditLogs(): JSX.Element {
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const [rowResult, setRowResult] = useState([]);
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );

  useEffect(() => {
    if (!!businessOpportunityId) {
      fetchAuditLogs().then((resultData) => {
        setRowResult(resultData);
      });
    }
  }, [businessOpportunityId]);

  function fetchAuditLogs(): Promise<Array<CoreInterfaces.AuditLog>> {
    const url = `${Constants.APIPath.AuditLogs}/${businessOpportunityId}`;
    return fetchData<Array<CoreInterfaces.AuditLogReadPack>>(url)
      .then((result) => {
        const transformedData: CoreInterfaces.AuditLog[] = result.map(
          (item, index) => ({
            id: index,
            changedBy: item.User,
            version: item.ConfigurationVersion.toString(),
            changeDate: convertStringIntoDate(item.Timestamp),
            actions: item.Type,
          })
        );
        updateApplicationLoadingState(false);
        return transformedData;
      })
      .catch(() => []);
  }

  const columns: GridColDef[] = [
    {
      field: "changedBy",
      headerName: t("History.ChangedBy"),
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "version",
      headerName: t("History.Version"),
      width: 93,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "changeDate",
      headerName: t("History.ChangeDate"),
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: t("History.Actions"),
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return t(`History.${params.row.actions}`);
      },
    },
  ];
  return (
    <article className="gt-auditLogs">
      <DataGrid
        rows={rowResult}
        getRowId={(row) => row.id}
        columns={columns}
        rowHeight={44}
        autoHeight
        columnHeaderHeight={44}
      />
    </article>
  );
}

export default GtAuditLogs;
