import { useState } from "react";
import { CorePropsInterfaces } from "src/core/Models";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

function GtQuestionComments(
  props: CorePropsInterfaces.GtQuestionCommentsProps
): JSX.Element {
  const { t } = useTranslation();
  const [questionCommentsVisibility, setQuestionCommentsVisibility] =
    useState(false);
  const questionsWithComments = props.service.data.questions.filter(
    (question) => !!question.data.comment
  );

  return (
    <article className="gt-questionComments">
      <Tooltip title={t("General.InternalNotes")}>
        <ChatRoundedIcon
          className="gt-questionComments__icon"
          onClick={() => {
            setQuestionCommentsVisibility(!questionCommentsVisibility);
          }}
        />
      </Tooltip>
      {questionCommentsVisibility && (
        <div className="gt-questionComments__text">
          <span className="gt-questionComments__text-title">
            {t("General.Comment")}
          </span>
          {questionsWithComments.map((question) => (
            <span key={`questionComment${question.data.code}`}>
              {question.data.comment}
            </span>
          ))}
          {questionsWithComments.length === 0 && (
            <span className="gt-questionComments__text-noComment">
              {t("General.NoComments")}
            </span>
          )}
        </div>
      )}
    </article>
  );
}

export default GtQuestionComments;
