import { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { CoreInterfaces, DTOs } from "src/core/Models";
import { useTranslation } from "react-i18next";
import ServiceQuestionsAccordion from "../../components/Questions/ServiceQuestionsAccordion";
import { AppContext } from "../../contexts/AppContext";
import * as Constants from "./../../core/Constants";
import GtNavigationButtons from "src/components/Common/GtNavigationButtons";
import GtExpandCollapseAll from "src/components/Common/GtExpandCollapseAll";
import GtScrollElement from "src/components/Common/GtScrollElement";
import GtLegend from "src/components/Common/GtLegend";
import { isFinalorOutdatedVersion } from "src/utils";

function QuestionsPage(): JSX.Element {
  const { t } = useTranslation();
  const { globalState, dispatch } = useContext(AppContext);
  const initialPageState: CoreInterfaces.PageState = {
    title: "Pages.Questions.Title",
  };
  const [pageState] = useState<CoreInterfaces.PageState>(initialPageState);
  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  const selectedServices = globalState?.services?.filter(
    (serviceDTO: DTOs.ServiceDTO) =>
      serviceDTO.state.isSelected && serviceDTO.state.isVisibleInQuestionPage
  );
  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);

  function onQuestionChange(
    serviceDTO: DTOs.ServiceDTO,
    questionDTO: DTOs.QuestionDTO
  ) {
    dispatch({
      type: Constants.AppStateActions.QuestionUpdated,
      payload: {
        serviceDTO,
        questionDTO,
      },
    });
  }

  function handleExpendedQuestionAccordionChange(serviceDTO: DTOs.ServiceDTO) {
    const nextService: DTOs.ServiceDTO = {
      ...serviceDTO,
      state: {
        ...serviceDTO.state,
        isExpandedQuestionAccordion:
          !serviceDTO.state.isExpandedQuestionAccordion,
      },
    };
    dispatch({
      type: Constants.AppStateActions.ServiceExpandChange,
      payload: nextService,
    });
  }

  function toggleExpandedAll(flag: boolean): void {
    const nextServices = globalState?.services?.map(
      (serviceDTO: DTOs.ServiceDTO) => {
        const nextService: DTOs.ServiceDTO = {
          ...serviceDTO,
          state: {
            ...serviceDTO.state,
            isExpandedQuestionAccordion: serviceDTO.state.isSelected
              ? flag
              : serviceDTO.state.isExpandedQuestionAccordion,
          },
        };

        return nextService;
      }
    );

    dispatch({
      type: Constants.AppStateActions.ServicesOverride,
      payload: nextServices,
    });
  }

  return (
    <>
      <div className="gt-pageTopPanel">
        <Typography
          className="gt-pageTopPanel__title"
          variant="h5"
          gutterBottom={true}
        >
          {t(pageState.title)}
        </Typography>
        <div className="gt-pageTopPanel__actions">
          <GtLegend />
          <GtExpandCollapseAll
            toggleExpandedAll={(flag: boolean) => {
              toggleExpandedAll(flag);
            }}
            expandButtonText={t("General.ExpandAll")}
            collapseButtonText={t("General.CollapseAll")}
          ></GtExpandCollapseAll>
        </div>
      </div>
      {selectedServices?.length ? (
        selectedServices?.map((serviceDTO: DTOs.ServiceDTO) => (
          <article
            key={`serviceQuestionsPanel${serviceDTO.data.code}`}
            className="gt-servicePanel"
          >
            <GtScrollElement service={serviceDTO} />
            <ServiceQuestionsAccordion
              service={serviceDTO}
              areInputsDisabled={isFinalorOutdatedVersionStatus}
              onChange={(questionDTO: DTOs.QuestionDTO) =>
                onQuestionChange(serviceDTO, questionDTO)
              }
              onExpandChange={() => {
                handleExpendedQuestionAccordionChange(serviceDTO);
              }}
            />
          </article>
        ))
      ) : (
        <div>No selected services</div>
      )}
      <GtNavigationButtons current={1} />
    </>
  );
}

export default QuestionsPage;
