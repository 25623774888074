import * as Constants from "../../core/Constants";
import { CoreInterfaces } from "../../core/Models";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { EngagementLettersFilterPanelProp } from "src/core/Models/CoreProps.interface";
import { AppContext } from "src/contexts/AppContext";
import {
  getRelevantGtOwners,
  isAdministrator,
  isOfficeManager,
} from "src/utils";

function EngagementLettersFilterPanel({
  onFilter,
}: EngagementLettersFilterPanelProp): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);

  const [companyName, setCompanyName] = useState("");
  const [oportunityId, setOportunityId] = useState("");
  const [signingDateStart, setSigningDateStart] = useState(null);
  const [signingDateEnd, setSigningDateEnd] = useState(null);
  const [office, setOffice] = useState(null);
  const [gtOwner, setGtOwner] = useState(null);
  const [organisationNumber, setOrganisationNumber] = useState("");
  const [valabilityStatus, setValabilityStatus] = useState(null);
  const [lastModifiedDate, setLastModifiedDate] = useState(null);
  const [status, setStatus] = useState(
    Constants.EngagementLetterSummaryStatus.ShowAllExceptSigned
  );
  const [businessUnit, setBusinessUnit] = useState(null);
  const [showDeactivatedEngagements, setShowDeactivatedEngagements] =
    useState(false);
  const [isFilterInputFocused, setIsFilterInputFocused] = useState(false);
  const [filterPayload, setFilterPayload] = useState({});

  useEffect(() => {
    if (!isFilterInputFocused) {
      filterRows();
    }
  }, [
    companyName,
    oportunityId,
    organisationNumber,
    signingDateStart,
    signingDateEnd,
    office,
    gtOwner,
    valabilityStatus,
    lastModifiedDate,
    businessUnit,
    status,
  ]);

  function filterRows(): void {
    const filterData = {
      SigningDateStart:
        signingDateStart &&
        !isNaN(
          Date.UTC(
            signingDateStart.$y,
            signingDateStart.$M,
            signingDateStart.$D
          )
        )
          ? new Date(
              Date.UTC(
                signingDateStart.$y,
                signingDateStart.$M,
                signingDateStart.$D
              )
            ).toISOString()
          : null,
      SigningDateEnd:
        signingDateEnd &&
        !isNaN(
          Date.UTC(signingDateEnd.$y, signingDateEnd.$M, signingDateEnd.$D)
        )
          ? new Date(
              Date.UTC(
                signingDateEnd.$y,
                signingDateEnd.$M,
                signingDateEnd.$D,
                23,
                59,
                59
              )
            ).toISOString()
          : null,
      CompanyName: companyName ? companyName : null,
      BusinessOpportunityId: oportunityId ? oportunityId : null,
      OrganisationNumber: organisationNumber ? organisationNumber : null,
      Office:
        office !== Constants.HelpfulConstants.PleaseSelect ? office : null,
      OwnerName:
        gtOwner !== Constants.HelpfulConstants.PleaseSelect ? gtOwner : null,
      ValidityStatus:
        valabilityStatus !== Constants.HelpfulConstants.PleaseSelect
          ? valabilityStatus
          : null,
      BusinessUnit:
        businessUnit !== Constants.HelpfulConstants.PleaseSelect
          ? businessUnit
          : null,
      IsActive: !showDeactivatedEngagements,
      LastModifiedDate:
        lastModifiedDate &&
        !isNaN(
          Date.UTC(
            lastModifiedDate.$y,
            lastModifiedDate.$M,
            lastModifiedDate.$D
          )
        )
          ? new Date(
              Date.UTC(
                lastModifiedDate.$y,
                lastModifiedDate.$M,
                lastModifiedDate.$D
              )
            ).toISOString()
          : null,
      Status: status,
    };

    if (JSON.stringify(filterData) !== JSON.stringify(filterPayload)) {
      setFilterPayload(filterData);
      onFilter(filterData);
    }
  }

  function clearFilters(): void {
    setCompanyName("");
    setOportunityId("");
    setSigningDateStart(null);
    setSigningDateEnd(null);
    setOffice(null);
    setGtOwner(null);
    setOrganisationNumber("");
    setValabilityStatus(null);
    setLastModifiedDate(null);
    setStatus(Constants.EngagementLetterSummaryStatus.ShowAllExceptSigned);
    setBusinessUnit(null);
    setShowDeactivatedEngagements(false);
  }

  return (
    <article className="gt-EngagementLettersFilterPanel">
      <section className="gt-EngagementLettersFilterPanel__row">
        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.ClientName")}</span>
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            value={companyName}
            autoComplete="off"
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder={t("General.ClientNamePlaceholder")}
            onKeyUp={(evt) => {
              if (evt.code === "Enter") {
                filterRows();
              }
            }}
            onBlur={() => {
              setIsFilterInputFocused(false);
            }}
            onFocus={() => {
              setIsFilterInputFocused(true);
            }}
          />
        </section>

        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.ClientOrgNumber")}</span>
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            value={organisationNumber}
            onChange={(e) => setOrganisationNumber(e.target.value)}
            placeholder={t("General.ClientOrgNumberPlaceholder")}
            onKeyUp={(evt) => {
              if (evt.code === "Enter") {
                console.log(evt);
                filterRows();
              }
            }}
            onBlur={() => {
              setIsFilterInputFocused(false);
            }}
            onFocus={() => {
              setIsFilterInputFocused(true);
            }}
          />
        </section>

        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.OportunityId")}</span>
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            value={oportunityId}
            autoComplete="off"
            onChange={(e) => setOportunityId(e.target.value)}
            placeholder={t("General.OportunityId")}
            onKeyUp={(evt) => {
              if (evt.code === "Enter") {
                filterRows();
              }
            }}
            onBlur={() => {
              setIsFilterInputFocused(false);
            }}
            onFocus={() => {
              setIsFilterInputFocused(true);
            }}
          />
        </section>

        {(isAdministrator(globalState) || isOfficeManager(globalState)) && (
          <>
            <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
              <span>{t("General.GtOwner")}</span>
              <Select
                value={gtOwner ?? Constants.HelpfulConstants.PleaseSelect}
                onChange={(e) => setGtOwner(e.target.value)}
              >
                <MenuItem
                  key={Constants.HelpfulConstants.PleaseSelect}
                  value={Constants.HelpfulConstants.PleaseSelect}
                >
                  {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
                </MenuItem>
                {getRelevantGtOwners(globalState).map(
                  (gtContactPerson: CoreInterfaces.ContactPersonItem) => (
                    <MenuItem
                      key={`${gtContactPerson.key}`}
                      value={`${gtContactPerson.firstName} ${gtContactPerson.lastName}`}
                    >
                      {gtContactPerson.firstName} {gtContactPerson.lastName}
                    </MenuItem>
                  )
                )}
              </Select>
            </section>
            <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
              <span>{t("General.Office")}</span>
              <Select
                value={office ?? Constants.HelpfulConstants.PleaseSelect}
                onChange={(e) => setOffice(e.target.value)}
              >
                <MenuItem
                  key={Constants.HelpfulConstants.PleaseSelect}
                  value={Constants.HelpfulConstants.PleaseSelect}
                >
                  {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
                </MenuItem>
                {globalState.remoteData.employeesOffices.map((officeItem) => (
                  <MenuItem
                    key={`office${officeItem.Name}`}
                    value={officeItem.Name}
                  >
                    {officeItem.Name}
                  </MenuItem>
                ))}
              </Select>
            </section>
          </>
        )}
        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.LetterExpirationDate")}</span>
          <Select
            value={valabilityStatus ?? Constants.HelpfulConstants.PleaseSelect}
            onChange={(e) => setValabilityStatus(e.target.value)}
          >
            <MenuItem
              key={Constants.HelpfulConstants.PleaseSelect}
              value={Constants.HelpfulConstants.PleaseSelect}
            >
              {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
            </MenuItem>
            {Object.keys(Constants.ValidityStatus).map(
              (valabilityStatusItem) => (
                <MenuItem
                  key={`valabilityStatus${valabilityStatusItem}`}
                  value={valabilityStatusItem}
                  className={`gt-EngagementLettersFilterPanel__row-inputFilterWrapper-validityStatus--${valabilityStatusItem.toLowerCase()}`}
                >
                  {t(`Options.${valabilityStatusItem}`)}
                </MenuItem>
              )
            )}
          </Select>
        </section>

        <section className="gt-EngagementLettersFilterPanel__row-buttonFilterWrapper">
          <Button color="tertiary" variant="outlined" onClick={clearFilters}>
            {t("General.ClearAll")}
          </Button>
          <Button color="primary" variant="contained" onClick={filterRows}>
            {t("General.Search")}
          </Button>
        </section>
      </section>
      <section className="gt-EngagementLettersFilterPanel__row">
        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.ModifiedDate")}</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={
                Constants.HelpfulConstants.DateFormatYearMonthAndDayUpperCase
              }
              value={lastModifiedDate}
              onChange={(e) => {
                setLastModifiedDate(e);
              }}
              onClose={() => {
                setIsFilterInputFocused(false);
                filterRows();
              }}
              onOpen={() => {
                setIsFilterInputFocused(true);
              }}
              slotProps={{
                textField: {
                  placeholder: t("General.ModifiedDatePlaceholder"),
                  onBlur: () => {
                    setIsFilterInputFocused(false);
                    filterRows();
                  },
                  onFocus: () => {
                    setIsFilterInputFocused(true);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </section>

        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.Status")}</span>
          <Select
            value={status}
            onChange={(e) =>
              setStatus(
                e.target.value as Constants.EngagementLetterSummaryStatus
              )
            }
          >
            {Object.keys(Constants.EngagementLetterSummaryStatus).map(
              (statusItem) => (
                <MenuItem key={`status${statusItem}`} value={statusItem}>
                  {t(`Options.EngagementLetterSummaryStatus.${statusItem}`)}
                </MenuItem>
              )
            )}
          </Select>
        </section>
        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span>{t("General.SigningDate")}</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={
                Constants.HelpfulConstants.DateFormatYearMonthAndDayUpperCase
              }
              value={signingDateStart}
              onChange={(e) => {
                setSigningDateStart(e);
              }}
              onClose={() => {
                setIsFilterInputFocused(false);
                filterRows();
              }}
              onOpen={() => {
                setIsFilterInputFocused(true);
              }}
              maxDate={signingDateEnd}
              slotProps={{
                textField: {
                  placeholder: t("General.StartDatePlaceholder"),
                  onBlur: () => {
                    setIsFilterInputFocused(false);
                    filterRows();
                  },
                  onFocus: () => {
                    setIsFilterInputFocused(true);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </section>
        <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
          <span></span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={
                Constants.HelpfulConstants.DateFormatYearMonthAndDayUpperCase
              }
              value={signingDateEnd}
              onChange={(e) => {
                setSigningDateEnd(e);
              }}
              onClose={() => {
                setIsFilterInputFocused(false);
              }}
              onOpen={() => {
                setIsFilterInputFocused(true);
              }}
              minDate={signingDateStart}
              slotProps={{
                textField: {
                  placeholder: t("General.EndDatePlaceholder"),
                  onBlur: () => {
                    setIsFilterInputFocused(false);
                    filterRows();
                  },
                  onFocus: () => {
                    setIsFilterInputFocused(true);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </section>
        {(isAdministrator(globalState) || isOfficeManager(globalState)) && (
          <section className="gt-EngagementLettersFilterPanel__row-inputFilterWrapper">
            <span>{t("General.BusinessArea")}</span>
            <Select
              value={businessUnit ?? Constants.HelpfulConstants.PleaseSelect}
              onChange={(e) => setBusinessUnit(e.target.value)}
            >
              <MenuItem
                key={Constants.HelpfulConstants.PleaseSelect}
                value={Constants.HelpfulConstants.PleaseSelect}
              >
                {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
              </MenuItem>
              {Object.values(Constants.BusinessUnit).map((businessUnit) => (
                <MenuItem
                  key={`businessUnit${businessUnit}`}
                  value={businessUnit}
                >
                  {t(`Options.BusinessArea.${businessUnit}`)}
                </MenuItem>
              ))}
            </Select>
          </section>
        )}
        <section
          className={
            "gt-EngagementLettersFilterPanel__row-checkBoxFilterWrapper " +
            (isAdministrator(globalState) || isOfficeManager(globalState)
              ? "gt-EngagementLettersFilterPanel__row-checkBoxFilterWrapper--isAdminOrManager"
              : "")
          }
        >
          <FormControlLabel
            label={t("General.ShowDeactivatedEngagements")}
            control={
              <Checkbox
                size="small"
                checked={showDeactivatedEngagements}
                onChange={(e) => {
                  setShowDeactivatedEngagements(e.target.checked);
                }}
              />
            }
          />
        </section>
      </section>
    </article>
  );
}

export default EngagementLettersFilterPanel;
