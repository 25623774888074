import { CoreInterfaces, DTOs } from "./../../../core/Models";
import * as Constants from "./../../../core/Constants";
import { mapQuestionCodesToQuestionDTOs } from "src/utils/question-utils";
import { findQuestionInService } from "src/utils";

export const AccountsReceivableProcessors: CoreInterfaces.QuestionProcessors = (
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) => {
  const { Q0103, Q0105, Q0107 } = Constants.AccountReceivableQuestion;
  const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
    Q0103,
    Q0105,
    Q0107,
  ]);

  return {
    [Constants.AccountReceivableQuestion.Q0104]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        questionDTOMap.Q0103.state.isShown &&
        questionDTOMap.Q0103.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.AccountReceivableQuestion.Q0105]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.isDefaultAccountingSystem;
    },
    [Constants.AccountReceivableQuestion.Q0106]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.AccountReceivableTasks.T0101,
        Constants.AccountReceivableTasks.T0102,
        Constants.AccountReceivableTasks.T0103,
        Constants.AccountReceivableTasks.T0104,
        Constants.AccountReceivableTasks.T0105,
        Constants.AccountReceivableTasks.T0106,
        Constants.AccountReceivableTasks.T0107,
        Constants.AccountReceivableTasks.T0109,
        Constants.AccountReceivableTasks.T0118,
      ];

      const frequencyToUse = (
        questionDTO.data.userValue &&
        questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
          ? questionDTO.data.userValue
          : questionDTO.data.defaultValue
      ) as Constants.Frequency;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.frequency = frequencyToUse;
        }
      });
    },
    [Constants.AccountReceivableQuestion.Q0109]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.AccountReceivableTasks.T0101,
        Constants.AccountReceivableTasks.T0102,
        Constants.AccountReceivableTasks.T0103,
        Constants.AccountReceivableTasks.T0104,
        Constants.AccountReceivableTasks.T0105,
        Constants.AccountReceivableTasks.T0107,
        Constants.AccountReceivableTasks.T0109,
      ];

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.Client
          : Constants.TaskAllocationResponsibility.GT;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.allocationOfResponsibility =
            allocationOfResponsabilityToUse;
        }
      });
    },
    [Constants.AccountReceivableQuestion.Q0111]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0118 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountReceivableTasks.T0118
      );
      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.Client
          : Constants.TaskAllocationResponsibility.GT;

      t0118.data.allocationOfResponsibility = allocationOfResponsabilityToUse;
      questionDTO.state.isShown =
        questionDTOMap.Q0107?.data.userValue ===
        Constants.InvoicingTime.InAdvance;
    },
    [Constants.AccountReceivableQuestion.Q0112]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        questionDTOMap.Q0107?.data.userValue ===
          Constants.InvoicingTime.InArears ||
        questionDTOMap.Q0107?.data.userValue === Constants.InvoicingTime.Other;

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;

      serviceDTO.data.tasks.forEach((taskDTO) => {
        if (taskDTO.data.code === Constants.AccountReceivableTasks.T0119) {
          taskDTO.data.allocationOfResponsibility =
            allocationOfResponsabilityToUse;
        }
      });
    },
    [Constants.AccountReceivableQuestion.Q0113]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0115 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountReceivableTasks.T0115
      );

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.Client
          : Constants.TaskAllocationResponsibility.GT;
      t0115.data.allocationOfResponsibility = allocationOfResponsabilityToUse;

      questionDTO.state.isShown =
        questionDTOMap.Q0105?.data.userValue === Constants.YesNo.No;
    },
    [Constants.AccountReceivableQuestion.Q0114]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0116 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountReceivableTasks.T0116
      );

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.Client
          : Constants.TaskAllocationResponsibility.GT;
      t0116.data.allocationOfResponsibility = allocationOfResponsabilityToUse;

      questionDTO.state.isShown =
        questionDTOMap.Q0105?.data.userValue === Constants.YesNo.No;
    },
    [Constants.AccountReceivableQuestion.Q0116]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.AccountReceivableTasks.T0111,
        Constants.AccountReceivableTasks.T0112,
      ];

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.allocationOfResponsibility =
            allocationOfResponsabilityToUse;
        }
      });

      const q0105 = findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      );

      const { Handelsbanken, SEB, Swedbank, Nordea } = Constants.CustomerBank;

      questionDTO.state.isShown =
        q0105.state.isShown &&
        q0105.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.customerBank &&
        ![Handelsbanken, SEB, Swedbank, Nordea].includes(
          appState.generalVariables.customerBank as Constants.CustomerBank
        );
    },
    [Constants.AccountReceivableQuestion.Q0117]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0105 = findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      );

      const { Handelsbanken, SEB, Swedbank, Nordea } = Constants.CustomerBank;

      questionDTO.state.isShown =
        q0105.state.isShown &&
        q0105.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.customerBank &&
        [Handelsbanken, SEB, Swedbank, Nordea].includes(
          appState.generalVariables.customerBank as Constants.CustomerBank
        );
    },
    [Constants.AccountReceivableQuestion.Q0118]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0111 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountReceivableTasks.T0111
      );
      const frequencyToUse = (
        questionDTO.data.userValue
          ? questionDTO.data.userValue
          : questionDTO.data.defaultValue
      ) as Constants.Frequency;
      t0111.data.frequency = frequencyToUse;

      const q0105 = findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      );

      questionDTO.state.isShown =
        q0105.state.isShown &&
        q0105.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.customerBank &&
        appState.generalVariables.customerBank ===
          Constants.CustomerBank.DanskeBank;
    },
    [Constants.AccountReceivableQuestion.Q0119]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const t0112 = serviceDTO.data.tasks.find(
        (t) => t.data.code === Constants.AccountReceivableTasks.T0112
      );
      const frequencyToUse = (
        questionDTO.data.userValue
          ? questionDTO.data.userValue
          : questionDTO.data.defaultValue
      ) as Constants.Frequency;
      t0112.data.frequency = frequencyToUse;

      const q0105 = findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      );

      questionDTO.state.isShown =
        q0105.state.isShown &&
        q0105.data.userValue === Constants.YesNo.No &&
        appState.generalVariables.customerBank &&
        appState.generalVariables.customerBank ===
          Constants.CustomerBank.Otherbanks;
    },
    [Constants.AccountReceivableQuestion.Q0120]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        {
          key: Constants.HelpfulConstants.PleaseSelect,
          value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
        },
        ...(appState.remoteData?.customerContactPersons || []),
      ];
    },
    [Constants.AccountReceivableQuestion.Q0122]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0105 = findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      );

      questionDTO.state.isShown =
        q0105.state.isShown && q0105.data.userValue === Constants.YesNo.Yes;
    },
  };
};
