import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useRef, useState } from "react";
import { AppContext } from "src/contexts/AppContext";
import { deleteDocument, handleFileUpload } from "src/utils/documents-manager";
import { CoreInterfaces, CorePropsInterfaces } from "src/core/Models";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as Constants from "./../../core/Constants";
import { LoadingContext } from "src/contexts/LoadingContext";
import { useTranslation } from "react-i18next";
import GtInfoTooltip from "../Common/GtInfoTooltip";
import { useSnackBar } from "src/contexts/SnackbarContext";
import GtEngagementLetterDocumentsManagerDeleteConfirm from "../Common/GtEngagementLetterDocumentsManagerDeleteConfirm";
import GtEngagementLetterDocumentsManagerPanelLinks from "../Common/GtEngagementLetterDocumentsManagerPanelLinks";
import { isEngagementInFinalVersion, isOutdatedVersion } from "src/utils";
import { useQuery } from "src/core/Hooks";

function EngagementLetterDocumentsManagerPanel(
  props: CorePropsInterfaces.EngagementLetterDocumentsManagerPanelProps
) {
  const { globalState } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const { t } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const fileUploadRef = useRef(null);
  const { documents, refetchDocuments } = props;

  const queryParamsMap = useQuery();
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );

  const isFinalVersion = isEngagementInFinalVersion(globalState);
  const isOutdatedVersionStatus = isOutdatedVersion(globalState);

  const [documentToDelete, setDocumentToDelete] =
    useState<CoreInterfaces.EngagementDocument>(null);

  function handleDocumentDelete(
    document: CoreInterfaces.EngagementDocument
  ): void {
    if (!isFinalVersion) {
      setDocumentToDelete(document);
    }
  }

  function onDeleteDialogConfirm(): void {
    const document = documentToDelete;
    setDocumentToDelete(null);
    updateApplicationLoadingState(true);
    deleteDocument(
      businessOpportunityId,
      globalState.currentConfiguration.id,
      document
    )
      .then(() => {
        refetchDocuments();
        showSnackBar(
          t("General.EngagementUploaderFileDeleteSuccess"),
          "success"
        );
      })
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar("General.DocumentManagementError", "error");
      });
  }

  function onFileUploadChange(evt: any): void {
    if (
      isFinalVersion ||
      !(documents.length < Constants.ADDITIONAL_DOCUMENTS_LIMIT_NO)
    ) {
      return;
    }
    updateApplicationLoadingState(true);
    handleFileUpload(
      businessOpportunityId,
      globalState.currentConfiguration.id,
      evt.target.files[0],
      Constants.EngagementDocumentType.AdditionalDocument,
      Constants.MEGABYTES_IN_BYTES_25,
      [Constants.FileExtension.Pdf]
    )
      .then(() => {
        refetchDocuments();
        showSnackBar(
          t("General.EngagementUploaderFileUploadSuccess"),
          "success"
        );
      })
      .catch((Ex) => {
        updateApplicationLoadingState(false);
        showSnackBar(Ex, "error");
      })
      .finally(() => {
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      });
  }
  return (
    <article className="gt-engagementLetterDocumentsPanel">
      <Accordion
        defaultExpanded
        className="gt-engagementLetterDocumentsPanel__accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="gt-engagementLetterDocumentsPanel__accordion-summary"
        >
          <a
            className="gt-engagementLetterDocumentsPanel__accordion-summary-titleHref"
            href="#"
          >
            {t("General.AdditionalDocuments")}
          </a>
        </AccordionSummary>
        <AccordionDetails>
          <article className="gt-documentsManager">
            <section className="gt-documentsManager__left">
              <Button
                disabled={
                  isFinalVersion ||
                  isOutdatedVersionStatus ||
                  !(documents.length < Constants.ADDITIONAL_DOCUMENTS_LIMIT_NO)
                }
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon htmlColor="rgba(43, 20, 77, 1)" />}
                className={`gt-documentsManager__left-uploadContainer ${
                  isFinalVersion ||
                  isOutdatedVersionStatus ||
                  !(documents.length < Constants.ADDITIONAL_DOCUMENTS_LIMIT_NO)
                    ? "gt-documentsManager__left-uploadContainer--disabled"
                    : ""
                }`}
              >
                <div className="gt-documentsManager__left-uploadContainer-text">
                  <a className="gt-documentsManager__left-uploadContainer-text-link">
                    {t("General.ClickToUpload")}
                  </a>
                  <span>
                    {t("General.EngagementUploaderMaximulAllowedFileSize", {
                      size:
                        (
                          Constants.MEGABYTES_IN_BYTES_25 /
                          1024 /
                          1024
                        ).toFixed() + " MB",
                    })}
                  </span>
                </div>
                <VisuallyHiddenInput
                  ref={fileUploadRef}
                  type="file"
                  accept=".pdf"
                  onChange={onFileUploadChange}
                />
              </Button>
            </section>
            <section className="gt-documentsManager__right">
              <h5>
                {t("General.UploadedDocuments")}{" "}
                <GtInfoTooltip
                  tooltipTitle={t(
                    "General.TooltipAdditionalDocumentsUploadedDocuments"
                  )}
                  testIdPart="uploadDocumentsTooltipTest"
                />
              </h5>
              <GtEngagementLetterDocumentsManagerPanelLinks
                engagementConfigurationId={globalState.currentConfiguration.id}
                links={documents.map((document) => ({
                  ...document,
                }))}
                hasDownloadLink={!isOutdatedVersionStatus}
                isReadonlyMode={isFinalVersion || isOutdatedVersionStatus}
                deleteHandler={
                  isFinalVersion || isOutdatedVersionStatus
                    ? null
                    : handleDocumentDelete
                }
              />
            </section>
          </article>
        </AccordionDetails>
      </Accordion>
      {!!documentToDelete && (
        <GtEngagementLetterDocumentsManagerDeleteConfirm
          onConfirm={onDeleteDialogConfirm}
          onDialogClose={() => setDocumentToDelete(null)}
        />
      )}
    </article>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default EngagementLetterDocumentsManagerPanel;
