import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Constants from "./../Constants";
import { CorePropsInterfaces } from "src/core/Models";

export function useRootConfig() {
  const location = useLocation();
  const [config, setConfig] = useState<CorePropsInterfaces.RootProps>({
    isEngagementApplicationPage: true,
    isLeftSideBarHidden: false,
    areBreadcrumbsHidden: false,
    areCtasButtonsHidden: false,
    isBusinessUnit: false,
    isOfferStatusHidden: false,
    isVersionNameHidden: false,
    isOwnerNameHidden: false,
    isAlertBannerHidden: false,
    isNotificationBannerHidden: false,
  });

  useEffect(() => {
    if (
      location.pathname === `/${Constants.Routes.EngagementLettersSummaryView}`
    ) {
      setConfig({
        isEngagementApplicationPage: false,
        isLeftSideBarHidden: true,
        areBreadcrumbsHidden: true,
        areCtasButtonsHidden: true,
        isBusinessUnit: true,
        isOfferStatusHidden: true,
        isVersionNameHidden: true,
        isOwnerNameHidden: true,
        isAlertBannerHidden: true,
        isNotificationBannerHidden: true,
      });
    } else {
      setConfig({
        isEngagementApplicationPage: true,
        isLeftSideBarHidden: false,
        areBreadcrumbsHidden: false,
        areCtasButtonsHidden: false,
        isBusinessUnit: false,
        isOfferStatusHidden: false,
        isVersionNameHidden: false,
        isOwnerNameHidden: false,
        isAlertBannerHidden: false,
        isNotificationBannerHidden: false,
      });
    }
  }, [location]);

  return config;
}
