import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";

export const InterimListedCompaniesTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0551: findQuestionInService(
        serviceDTO,
        Constants.InterimListedCompaniesQuestions.Q0551
      ),
      q0552: findQuestionInService(
        serviceDTO,
        Constants.InterimListedCompaniesQuestions.Q0552
      ),
      q0553: findQuestionInService(
        serviceDTO,
        Constants.InterimListedCompaniesQuestions.Q0553
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0559) {
        taskDTO.state.isVisible =
          questionsMap.q0551.data.userValue ===
          Constants.AccountingStandards.IFRS;
      }
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0560) {
        taskDTO.state.isVisible =
          questionsMap.q0551.data.userValue ===
          Constants.AccountingStandards.K3;
      }
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0563) {
        taskDTO.state.isVisible =
          questionsMap.q0552.data.userValue ===
          Constants.ResponsibleForInterimReport.GT;
      }
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0564) {
        taskDTO.state.isVisible =
          questionsMap.q0552.data.userValue ===
          Constants.ResponsibleForInterimReport.GTAndClient;
      }
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0565) {
        taskDTO.state.isVisible =
          questionsMap.q0552.data.userValue ===
          Constants.ResponsibleForInterimReport.Client;
      }
      if (taskDTO.data.code === Constants.InterimListedCompaniesTasks.T0566) {
        taskDTO.state.isVisible =
          questionsMap.q0553.data.userValue === Constants.YesNo.Yes;
      }
    });
  };
