import { useContext, useEffect } from "react";
import { AppContext } from "src/contexts/AppContext";
import GTNotificationBanner from "./GTNotificationBanner";
import { CoreInterfaces } from "./../../core/Models";
import * as Constants from "src/core/Constants";
import { fetchSystemAdministrationMessages } from "src/utils";

export default function GtSystemAdministrationMessages() {
  const { dispatch, globalState } = useContext(AppContext);

  function handleSystemNotificationMessages(): void {
    fetchSystemAdministrationMessages().then((systemAdministrationMessages) => {
      dispatch({
        type: Constants.AppStateActions.UpdateRemoteData,
        payload: {
          remoteData: {
            systemAdministrationMessages,
          },
        },
      });
    });
  }

  function hideSystemAdministrationMessage(
    systemAdministrationMessage: CoreInterfaces.SystemAdministrationMessage
  ): void {
    dispatch({
      type: Constants.AppStateActions.HideSystemAdministrationMessage,
      payload: systemAdministrationMessage.id,
    });
  }

  useEffect(() => {
    handleSystemNotificationMessages();
    const systemNotificationMessagesInterval = setInterval(
      () => handleSystemNotificationMessages(),
      +process.env.REACT_APP_FETCH_SYSTEM_ADMINISTRATION_MESSAGES_INTERVAL
    );

    return () => clearInterval(systemNotificationMessagesInterval);
  }, []);

  return (
    <>
      {globalState.remoteData?.systemAdministrationMessages
        ?.filter((item) => item.state.isShown)
        .map((systemAdministrationMessage) => (
          <GTNotificationBanner
            key={`banner${systemAdministrationMessage.id}`}
            systemAdministrationMessage={systemAdministrationMessage}
            shouldTranslateValue={false}
            onBannerClose={() => {
              hideSystemAdministrationMessage(systemAdministrationMessage);
            }}
          />
        ))}
    </>
  );
}
