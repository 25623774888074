import { CorePropsInterfaces, DTOs } from "src/core/Models";
import * as Constants from "../../../core/Constants";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValidDate } from "src/utils";

function QuestionDateRangeInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  let [startDate, endDate]: Date[] = [null, null];

  const [initialDate, finalDate] = question.data.userValue as Date[];
  if (isValidDate(initialDate)) {
    startDate = initialDate;
  }
  if (isValidDate(finalDate)) {
    endDate = finalDate;
  }

  const onSelectedValueChange = (value: Date, index: number) => {
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (index === 0) {
      newStartDate = isValidDate(value) ? value : null;
    } else {
      newEndDate = isValidDate(value) ? value : null;
    }

    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: [newStartDate, newEndDate],
      },
    };

    onChange && onChange(newQuestionDTO);
  };

  return (
    <article className="gt-questionRow">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateField
          data-testid={`${question.data.code}_0`}
          className="gt-questionRow__datePicker"
          format={Constants.HelpfulConstants.DateFormatYearMonthAndDay}
          value={startDate}
          onChange={(newValue) => onSelectedValueChange(newValue, 0)}
          disabled={isDisabled}
        />
        <span className="gt-questionRow__separator">-</span>
        <DateField
          data-testid={`${question.data.code}_1`}
          className="gt-questionRow__datePicker"
          format={Constants.HelpfulConstants.DateFormatYearMonthAndDay}
          value={endDate}
          onChange={(newValue) => onSelectedValueChange(newValue, 1)}
          disabled={isDisabled}
        />
      </LocalizationProvider>
    </article>
  );
}

export default QuestionDateRangeInput;
