import { t } from "i18next";
import { useContext } from "react";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useQuery } from "src/core/Hooks";
import {
  fetchEngagementConfigurationData,
  updateStateOnEngagementConfigurationRead,
} from "src/utils";
import { AppContext } from "src/contexts/AppContext";
import GtDialog from "./GtDialog";
import { Button } from "@mui/material";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import { LoadingContext } from "src/contexts/LoadingContext";

function GtSaveConflictDialog({
  lastModifiedBy,
}: CorePropsInterfaces.GtSaveConflictDialogProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const dialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.Warning"),
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => closeDialog()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={() => reloadVersion()}
        >
          {t("General.ReloadVersion")}
        </Button>,
      ],
    },
    api: {
      onClose: closeDialog,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function closeDialog(isConflictOnUpdateEngagement = true): void {
    dispatch({
      type: Constants.AppStateActions.UpdateEngagementConflict,
      payload: {
        isConflictOnUpdateEngagement,
        showConflictUpdateDialog: false,
        lastModifiedBy: null,
      },
    });
  }

  function reloadVersion(): void {
    updateApplicationLoadingState(true);
    fetchEngagementConfigurationData(businessOpportunityId).then(
      (engagementConfigurationData) => {
        updateStateOnEngagementConfigurationRead(
          engagementConfigurationData,
          dispatch,
          // eslint-disable-next-line
          () => {},
          null,
          Constants.UpdateStateOnEngagementConfigurationReadScenario.Conflict
        );
        closeDialog(false);
        updateApplicationLoadingState(false);
      }
    );
  }

  return (
    <GtDialog gtDialogDTO={dialogDTO}>
      <article>
        <p>{t("General.ReloadVersionMessage", { lastModifiedBy })}</p>
      </article>
    </GtDialog>
  );
}

export default GtSaveConflictDialog;
