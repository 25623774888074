import { CorePropsInterfaces, DTOs } from "src/core/Models";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useTranslation } from "react-i18next";

function QuestionSelectInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { t } = useTranslation();

  function onSelectedValueChange(evt: SelectChangeEvent): void {
    const selectedOption = question.data.options.find(
      (eachOption) => evt.target.value === eachOption.key
    );
    if (!!selectedOption && question.data.userValue !== selectedOption.key) {
      const newQuestionDTO: DTOs.QuestionDTO = {
        ...question,
        data: {
          ...question.data,
          userValue: selectedOption.key,
        },
      };
      onChange && onChange(newQuestionDTO);
    }
  }

  return (
    <Select
      data-testid={question.data.code}
      className="gt-Select"
      value={(question.data.userValue as string) ?? ""}
      onChange={onSelectedValueChange}
      disabled={question.data.inputConstraints.nonEditableAnswer || isDisabled}
    >
      {question.data.options &&
        question.data.options.map((eachOption) => (
          <MenuItem
            data-testid={`option_question${question.data.code}_option${eachOption.key}`}
            key={`option_question${question.data.code}_option${eachOption.key}`}
            value={eachOption.key}
          >
            {eachOption.untranslatable ? eachOption.value : t(eachOption.value)}
          </MenuItem>
        ))}
    </Select>
  );
}

export default QuestionSelectInput;
