import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CorePropsInterfaces } from "src/core/Models";

export default function GtInfoTooltip({
  tooltipTitle,
  testIdPart,
}: CorePropsInterfaces.GtInfoTooltipProps): JSX.Element {
  return (
    <Tooltip
      title={
        <span data-testid={testIdPart + "TaskTooltipText"}>{tooltipTitle}</span>
      }
    >
      <IconButton data-testid={testIdPart + "TaskTooltip"}>
        <InfoOutlinedIcon className="gt-infoToltip" />
      </IconButton>
    </Tooltip>
  );
}
