import { CoreInterfaces, DTOs } from "../Models";
import * as Constants from "../Constants/Constants";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import { createGeneralVariables } from "src/utils";

export function updateServicesBasedOnQuestionUpdate(
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO,
  modifiedQuestion: DTOs.QuestionDTO
): Array<DTOs.ServiceDTO> {
  const processedServices: Array<DTOs.ServiceDTO> = [];
  for (const eachService of appState.services) {
    if (eachService.data.code === serviceDTO.data.code) {
      const nextService: DTOs.ServiceDTO = {
        ...eachService,
        data: {
          ...eachService.data,
          questions: eachService.data.questions.map((eachQuestion) => {
            if (eachQuestion.data.code === modifiedQuestion.data.code) {
              return {
                ...modifiedQuestion,
              };
            } else {
              return eachQuestion;
            }
          }),
        },
      };

      applyUpdatesOnQuestionChange(appState, nextService);
      processedServices.push(nextService);
    } else {
      applyUpdatesOnQuestionChange(appState, eachService);
      processedServices.push(eachService);
    }
  }

  if (serviceDTO.data.code === Constants.ServiceCode.GeneralInformation) {
    processedServices.forEach((eachService) => {
      applyUpdatesOnQuestionChange(appState, eachService);
    });
    updateServicesOnGeneralQuestionChange(
      processedServices,
      modifiedQuestion,
      appState.generalVariables
    );
  }
  return processedServices;
}

export function updateServicesOnApplicationConfigurationChange(
  services: Array<DTOs.ServiceDTO>,
  applicationConfiguration: CoreInterfaces.ApplicationConfigurationReadPack,
  forceUpdate = false
): Array<DTOs.ServiceDTO> {
  const serviceTaskAllocationMap: {
    [serviceCode in Constants.TaskCode]?: CoreInterfaces.TaskAllocation;
  } = {};
  for (const taskAllocation of applicationConfiguration
    .TaskAllocationConfiguration?.TaskAllocations || []) {
    serviceTaskAllocationMap[taskAllocation.Code] = taskAllocation;
  }

  const serviceTaskCostsMap: {
    [taskCode in Constants.AdditionalCostConfigurationCode]?: CoreInterfaces.TaskCost;
  } = {};
  for (const taskCost of applicationConfiguration.TaskCostConfiguration
    ?.TaskCosts || []) {
    serviceTaskCostsMap[taskCost.TaskCode] = taskCost;
  }

  return services.map((eachService: DTOs.ServiceDTO) => {
    if (eachService.state.isSelected && !forceUpdate) {
      return eachService;
    }
    eachService.data.additionalCosts.forEach((eachAdditionalCost) => {
      const taskCostConfiguration =
        serviceTaskCostsMap[eachAdditionalCost.data.code];
      if (!!taskCostConfiguration) {
        eachAdditionalCost.data.cost = taskCostConfiguration.Cost;
        eachAdditionalCost.data.description = null;
      }
    });
    eachService.data.tasks.forEach((eachTask) => {
      const taskAllocation = serviceTaskAllocationMap[eachTask.data.code];
      if (!!taskAllocation) {
        eachTask.data.estimatedTimePerUnit =
          taskAllocation.EstimatedTimeInMinutes;
        eachTask.data.staffLevel = taskAllocation.StaffLevelCode;
      }
      eachTask.data.sumTimeInHrsAndMinutes = eachTask.data.estimatedTimePerUnit;
    });
    return eachService;
  });
}

export function createGeneralVariablesObject(
  genericService: DTOs.ServiceDTO,
  modifiedQuestionDTO?: DTOs.QuestionDTO
): CoreInterfaces.GeneralVariables {
  const generalVariables: CoreInterfaces.GeneralVariables =
    createGeneralVariables();
  if (genericService) {
    for (const eachQuestionDTO of genericService.data.questions) {
      const questionDTO =
        eachQuestionDTO.data.code === modifiedQuestionDTO?.data.code
          ? modifiedQuestionDTO
          : eachQuestionDTO;
      switch (questionDTO.data.code) {
        case Constants.GeneralInformationQuestion.Q0001: {
          generalVariables.organisationNo = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0002: {
          generalVariables.companyName = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0003: {
          generalVariables.contactPerson = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0004: {
          generalVariables.contactPersonAccounting = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0005: {
          generalVariables.contactPersonPayroll = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0006: {
          generalVariables.accountYearEndMonth = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0007: {
          generalVariables.isCompanyRegisteredVAT =
            questionDTO.data.userValue === Constants.YesNo.Yes;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0008: {
          generalVariables.declarationFrequencyVAT = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0009: {
          generalVariables.accountingMethod = questionDTO.data
            .userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0010: {
          generalVariables.customerBank = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0011: {
          generalVariables.costCenter = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0013: {
          generalVariables.isAuditorGrantThornton =
            questionDTO.data.userValue === Constants.YesNo.Yes ? true : false;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0014: {
          if (questionDTO.data.userValue === Constants.YesNo.Yes) {
            generalVariables.accountingSystem =
              Constants.HelpfulConstants.DefaultAccountingSystem;
            generalVariables.isDefaultAccountingSystem = true;
          } else {
            const question0015 =
              modifiedQuestionDTO?.data.code ===
              Constants.GeneralInformationQuestion.Q0015
                ? modifiedQuestionDTO
                : genericService.data.questions.find(
                    (eachQuestion) =>
                      eachQuestion.data.code ===
                      Constants.GeneralInformationQuestion.Q0015
                  );
            if (!!question0015) {
              generalVariables.accountingSystem = question0015.data
                .userValue as string;
              generalVariables.isDefaultAccountingSystem = false;
            }
          }
          break;
        }
        case Constants.GeneralInformationQuestion.Q0015: {
          const question0014 =
            modifiedQuestionDTO?.data.code ===
            Constants.GeneralInformationQuestion.Q0014
              ? modifiedQuestionDTO
              : genericService.data.questions.find(
                  (eachQuestion) =>
                    eachQuestion.data.code ===
                    Constants.GeneralInformationQuestion.Q0014
                );
          if (!!question0014) {
            if (question0014.data.userValue !== Constants.YesNo.Yes) {
              generalVariables.accountingSystem = questionDTO.data
                .userValue as string;
            } else {
              generalVariables.accountingSystem =
                Constants.HelpfulConstants.DefaultAccountingSystem;
            }
          }

          break;
        }
        case Constants.GeneralInformationQuestion.Q0019: {
          generalVariables.address = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0020: {
          generalVariables.postalNumber = questionDTO.data.userValue as string;
          break;
        }
        case Constants.GeneralInformationQuestion.Q0021: {
          generalVariables.area = questionDTO.data.userValue as string;
          break;
        }
      }
    }
  }
  return generalVariables;
}

// This function is meant to handle changes to general variables
export function updateServicesOnGeneralQuestionChange(
  services: Array<DTOs.ServiceDTO>,
  modifiedQuestion: DTOs.QuestionDTO,
  generalVariables: CoreInterfaces.GeneralVariables
) {
  services.forEach((eachService) => {
    // general service info

    // tasks
    eachService.data.tasks.forEach((eachTask) => {
      if (eachService.data.code === Constants.ServiceCode.AccountsReceivable) {
        if (eachTask.data.code === Constants.AccountReceivableTasks.T0105) {
          if (
            modifiedQuestion.data.code ===
            Constants.GeneralInformationQuestion.Q0011
          ) {
            eachTask.state.isVisible =
              modifiedQuestion.data.userValue === Constants.YesNo.Yes;
          }
        }
      }
      if (
        eachService.data.code ===
        Constants.ServiceCode.OtherAccountAndReconciliation
      ) {
        if (
          eachTask.data.code ===
          Constants.OtherAccountAndReconciliationTasks.T0416
        ) {
          if (
            modifiedQuestion.data.code ===
            Constants.GeneralInformationQuestion.Q0007
          ) {
            const frequency = (
              generalVariables.declarationFrequencyVAT ===
                Constants.VATDeclarationFrequency.MonthlyOver ||
              generalVariables.declarationFrequencyVAT ===
                Constants.VATDeclarationFrequency.MonthlyUnder
                ? Constants.Frequency.Monthly
                : generalVariables.declarationFrequencyVAT
            ) as Constants.Frequency;
            eachTask.data.frequency = frequency;
            eachTask.state.isVisible =
              modifiedQuestion.data.userValue === Constants.YesNo.Yes;
          }
        }
      }
      if (eachService.data.code === Constants.ServiceCode.PeriodReporting) {
        const frequency = (
          generalVariables.declarationFrequencyVAT ===
            Constants.VATDeclarationFrequency.MonthlyOver ||
          generalVariables.declarationFrequencyVAT ===
            Constants.VATDeclarationFrequency.MonthlyUnder
            ? Constants.Frequency.Monthly
            : generalVariables.declarationFrequencyVAT
        ) as Constants.Frequency;
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0509) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0511) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0512) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0513) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0514) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0515) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0516) {
          eachTask.data.frequency = frequency;
        }
        if (eachTask.data.code === Constants.PeriodReportingTasks.T0517) {
          eachTask.data.frequency = frequency;
        }
      }
    });
  });
}
