import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "../../../core/Constants";
import { getQuestionValue, mapQuestionCodesToQuestionDTOs } from "src/utils";

export const AccountsPayableTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const { Q0204, Q0205, Q0205_1, Q0206, Q0206_1, Q0210, Q0226 } =
      Constants.AccountPayableQuestion;
    const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
      Q0204,
      Q0205,
      Q0205_1,
      Q0206,
      Q0206_1,
      Q0210,
      Q0226,
    ]);

    const q0205ActiveQuestion = questionDTOMap.Q0205.state.isShown
      ? questionDTOMap.Q0205
      : questionDTOMap.Q0205_1;
    const q0206ActiveQuestion = questionDTOMap.Q0206.state.isShown
      ? questionDTOMap.Q0206
      : questionDTOMap.Q0206_1;

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0201) {
        taskDTO.state.isVisible =
          (q0205ActiveQuestion.state.isShown &&
            ["PreSystemFortnox", "PreSystemMediusGo"].includes(
              q0205ActiveQuestion.data.userValue as string
            )) ||
          (q0206ActiveQuestion.state.isShown &&
            ["PreSystemFortnox", "PreSystemMediusGo"].includes(
              q0206ActiveQuestion.data.userValue as string
            ));
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0202) {
        taskDTO.state.isVisible =
          (q0205ActiveQuestion.state.isShown &&
            ["PreSystemAnnat"].includes(
              q0205ActiveQuestion.data.userValue as string
            )) ||
          (q0206ActiveQuestion.state.isShown &&
            ["PreSystemAnnat"].includes(
              q0206ActiveQuestion.data.userValue as string
            ));
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0204) {
        taskDTO.state.isVisible =
          questionDTOMap.Q0210.state.isShown &&
          questionDTOMap.Q0210.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0206) {
        taskDTO.state.isVisible =
          appState.generalVariables.costCenter &&
          appState.generalVariables.costCenter === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0209) {
        const { Handelsbanken, SEB, Nordea } = Constants.CustomerBank;

        taskDTO.state.isVisible =
          appState.generalVariables.customerBank &&
          [Handelsbanken, SEB, Nordea].includes(
            appState.generalVariables.customerBank as Constants.CustomerBank
          );
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0210) {
        taskDTO.state.isVisible =
          appState.generalVariables.customerBank &&
          (appState.generalVariables.customerBank ===
            Constants.CustomerBank.Swedbank ||
            appState.generalVariables.customerBank ===
              Constants.CustomerBank.DanskeBank);
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0211) {
        taskDTO.state.isVisible =
          appState.generalVariables.customerBank &&
          appState.generalVariables.customerBank ===
            Constants.CustomerBank.Otherbanks;
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0225) {
        taskDTO.state.isVisible =
          questionDTOMap.Q0226.state.isShown &&
          questionDTOMap.Q0226.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0226) {
        taskDTO.state.isVisible =
          questionDTOMap.Q0226.state.isShown &&
          questionDTOMap.Q0226.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountPayableTasks.T0227) {
        taskDTO.state.isVisible =
          questionDTOMap.Q0226.state.isShown &&
          questionDTOMap.Q0226.data.userValue === Constants.YesNo.Yes;
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountPayableAdditionalCosts.T0214
        ) {
          eachAdditionalCost.state.isVisible =
            (appState.generalVariables.isDefaultAccountingSystem &&
              questionDTOMap.Q0206.state.isShown &&
              getQuestionValue(questionDTOMap.Q0206) ===
                Constants.AccountPayablePreSystem.MediusGo) ||
            (questionDTOMap.Q0205.state.isShown &&
              getQuestionValue(questionDTOMap.Q0205) ===
                Constants.AccountPayablePreSystem.MediusGo);
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountPayableAdditionalCosts.T0215
        ) {
          eachAdditionalCost.state.isVisible =
            (appState.generalVariables.isDefaultAccountingSystem &&
              questionDTOMap.Q0206.state.isShown &&
              getQuestionValue(questionDTOMap.Q0206) ===
                Constants.AccountPayablePreSystem
                  .FortnoxLeverantorsfakturaattest) ||
            (questionDTOMap.Q0205.state.isShown &&
              getQuestionValue(questionDTOMap.Q0205) ===
                Constants.AccountPayablePreSystem
                  .FortnoxLeverantorsfakturaattest);
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountPayableAdditionalCosts.T0216
        ) {
          eachAdditionalCost.state.isVisible =
            (appState.generalVariables.isDefaultAccountingSystem &&
              questionDTOMap.Q0206.state.isShown &&
              getQuestionValue(questionDTOMap.Q0206) ===
                Constants.AccountPayablePreSystem
                  .FortnoxLeverantorsfakturaattest) ||
            (questionDTOMap.Q0205.state.isShown &&
              getQuestionValue(questionDTOMap.Q0205) ===
                Constants.AccountPayablePreSystem
                  .FortnoxLeverantorsfakturaattest);
        }
      });
    }
  };
