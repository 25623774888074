import { TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CorePropsInterfaces } from "src/core/Models";
import { debounce } from "src/utils";

function GtMultilineInput(
  props: CorePropsInterfaces.GtMultilineInputProps
): JSX.Element {
  const { value, onChange, maxLength, disabled } = props;
  const [fieldValue, setFieldValue] = useState(value);
  const debouncedChange = useCallback(
    debounce((evt: any) => {
      onChange(evt.target.value);
    }, 500),
    [value]
  );

  function onInputValueChangeDelayed(evt: any): void {
    setFieldValue(evt.target.value);
    debouncedChange(evt);
  }

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <TextField
      className="gt-MultilineField"
      size="small"
      variant="outlined"
      onChange={onInputValueChangeDelayed}
      value={fieldValue}
      inputProps={{ maxLength: maxLength }}
      multiline
      maxRows={2}
      onKeyDown={(evt: any) => evt.stopPropagation()}
      disabled={disabled}
    />
  );
}

export default GtMultilineInput;
