import * as Constants from "./../../core/Constants";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ceil, findQuestionInService, showConfigurationCodes } from "src/utils";

import { DTOs } from "src/core/Models";
import GtInfoTooltip from "../Common/GtInfoTooltip";
import { t } from "i18next";

export default function AdditionalCostsPanel({
  serviceDTO,
}: {
  serviceDTO: DTOs.ServiceDTO;
}): JSX.Element {
  if (!serviceDTO || !serviceDTO.state.isSelected) {
    return null;
  }

  const columns = getColumnsDefinition();
  const rows: Array<any> = [];

  let total = 0;
  const visibleAdditionalCostDTOs = serviceDTO.data.additionalCosts.filter(
    (eachAdditionalCostDTO) =>
      eachAdditionalCostDTO.state.isUsedInSoftwareServiceCostsTable &&
      eachAdditionalCostDTO.state.isVisible
  );
  for (const additionalCostDTO of visibleAdditionalCostDTOs) {
    let usedValue = null;
    if (!!additionalCostDTO.data.numberOfUnits) {
      usedValue = additionalCostDTO.data.numberOfUnits;
    } else {
      if (!!additionalCostDTO.data.valueQuestionCode) {
        const usedQuestion = findQuestionInService(
          serviceDTO,
          additionalCostDTO.data.valueQuestionCode
        );
        if (!!usedQuestion && !!usedQuestion.data.userValue) {
          usedValue = usedQuestion.data.userValue;
        }
      }
    }
    const rowTotal = Number(usedValue) * additionalCostDTO.data.cost;
    total += rowTotal;

    const row = {
      title: t(additionalCostDTO.data.text),
      tooltip: t(additionalCostDTO.data.tooltip),
      pricePerUnit: `${additionalCostDTO.data.cost} ${Constants.USED_CURRENCY}`,
      numberOfUnits: usedValue,
      sum: `${ceil(rowTotal)} ${Constants.USED_CURRENCY}`,
      id: `additionalCostTask${additionalCostDTO.data.code}`,
      code: additionalCostDTO.data.code,
    };
    rows.push(row);
  }

  if (rows.length > 0) {
    // total service costs
    const row = {
      title: t("General.TotalSoftwareServicesCosts"),
      pricePerUnit: "",
      numberOfUnits: "",
      sum: `${ceil(total)} ${Constants.USED_CURRENCY}`,
      id: Constants.CustomDataGridColumnId.AdditionalCostsTotalCost,
    };
    rows.push(row);
  }

  return (
    rows.length > 0 && (
      <article className="gt-additionalCostsPanel">
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={44}
          columnHeaderHeight={48}
          hideFooterPagination={true}
        />
      </article>
    )
  );
}

function getColumnsDefinition(): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t("PanelHeader.SoftwareServices"),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      colSpan: ({ row }) => {
        if (
          row.id === Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
        ) {
          return 3;
        }
        return undefined;
      },
      renderCell(params) {
        return (
          <article className="gt-serviceTitleCell">
            <div>
              {showConfigurationCodes() && params.row.code && (
                <>[{params.row.code}]</>
              )}
              <span
                className="gt-serviceTitleCell__title"
                data-testid={params.row.id + "Title"}
              >
                {params.row.title}
              </span>
            </div>
            {params.row.tooltip && (
              <GtInfoTooltip
                testIdPart={params.row.code}
                tooltipTitle={params.row.tooltip}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "pricePerUnit",
      flex: 1,
      headerName: t("TableHeader.PricePerUnit"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "PricePerUnit"}>
            {params.row.pricePerUnit}
          </article>
        );
      },
    },
    {
      field: "numberOfUnits",
      flex: 1,
      headerName: t("TableHeader.NumberOfUnits"),
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "NumberOfUnits"}>
            {params.row.numberOfUnits}
          </article>
        );
      },
    },
    {
      field: "sum",
      headerName: t("TableHeader.Sum"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-additionalCostsPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "Sum"}>
            {params.row.sum}
          </article>
        );
      },
    },
  ];
  return columnDefinition;
}
