import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";

function LogoutPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <article className="gt-logoutPage">
      <DoneIcon className="gt-logoutPage__icon" />
      <section className="gt-logoutPage__message">
        {t("General.LogoutMessage")}
      </section>
    </article>
  );
}

export default LogoutPage;
