import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useContext, useEffect, useRef } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContext";
import Header from "src/components/Layout/Header/Header";
import LeftMenu from "src/components/Layout/LeftMenu/LeftMenu";
import { DTOs } from "./../core/Models";

import * as Constants from "src/core/Constants";
import {
  fetchApplicationConfigurationData,
  fetchBusinessOpportunityInfo,
  fetchCompanyDetails,
  fetchCustomerContactPersons,
  fetchEmployeesOffices,
  fetchEngagementConfigurationData,
  fetchGtContactPersons,
  fetchOfficeManagers,
  fetchTeams,
  fetchUserRoles,
  getAreAllServicesCompleted,
  getRelevantPageForNavigation,
  getUserInfo,
  isDigitallySigningChecked,
  isFinalorOutdatedVersion,
  updateStateOnEngagementConfigurationRead,
  wait,
} from "src/utils";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import { useQuery, useRootConfig } from "src/core/Hooks/index";
import { ReactComponent as GTSpinner } from "./../assets/spinner.svg";
import { useTranslation } from "react-i18next";
import GtAlertBanner from "src/components/Common/GtAlertBanner";
import GTNotificationBanner from "src/components/Common/GTNotificationBanner";
import { useBeforeunload } from "react-beforeunload";
import { saveCurrentConfiguration } from "src/utils/handle-configuration-utils";
import { areApplicationConfVersionsChanged } from "src/utils/engagement-utils";
import { useScrollRestorer } from "src/core/Hooks/use-scroll-restorer";
import { LoadingContext } from "src/contexts/LoadingContext";
import { useErrorBoundary } from "react-error-boundary";
import GtSystemAdministrationMessages from "src/components/Common/GTSystemAdministrationMessages";
import { useSnackBar } from "src/contexts/SnackbarContext";
import { LicenseInfo } from "@mui/x-license";
import { isEmailMissingForDigitalSigningUsers } from "src/utils/generate-documents";

export default function Root(): JSX.Element {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParamsMap = useQuery();
  const { dispatch, globalState, businessUnit } = useContext(AppContext);
  const { isLoading, loadingMessage, updateApplicationLoadingState } =
    useContext(LoadingContext);
  const { t } = useTranslation();
  const { ref: scrollPersisterRef } = useScrollRestorer();
  const { showBoundary } = useErrorBoundary();
  const rootConfig = useRootConfig();
  const { showSnackBar } = useSnackBar();

  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);
  const unit = queryParamsMap.get(Constants.QueryParams.unit);
  const engagementConfigurationId = queryParamsMap.get(
    Constants.QueryParams.engagementConfigurationId
  );
  const id = globalState.currentConfiguration.id;
  const areAllServicesCompleted = getAreAllServicesCompleted(
    globalState.services.filter(
      (service: DTOs.ServiceDTO) => service.state.isSelected
    ),
    businessUnit
  );
  const isGenerateDocumentsPage = location.pathname.endsWith(
    Constants.Routes.GenerateDocuments
  );

  const rateschangesMessage = {
    id: "RateschangesMsg",
    message: "General.RatesChangesMessage",
    title: "General.RatesChangesTitle",
    type: Constants.SystemAdministrationMessageTypes.Alert,
    state: {
      isShown: false,
    },
  };
  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);
  const globalStateRef = useRef(globalState);
  globalStateRef.current = globalState;

  const shouldDisplayRatesChangeMessage =
    globalState.state.isRatesChangeMessagesShow &&
    areApplicationConfVersionsChanged(globalState) &&
    !isFinalorOutdatedVersionStatus;
  const isPriceAndTimeCalculationPage = location.pathname.endsWith(
    Constants.Routes.PriceAndTimeCalculation
  );
  const hasSelectedServices = globalState.services.some(
    (service) =>
      service.state.canBeSelectedInServicePage && service.state.isSelected
  );

  function retrieveRemoteData(): Promise<void> {
    return Promise.all([
      fetchCustomerContactPersons(
        Constants.APIPath.ContactPersonsForGeneralInfo.replace(
          "{upsalescustomerid}",
          clientId
        )
      ),
      fetchGtContactPersons(),
      fetchCompanyDetails(
        Constants.APIPath.CompanyDetails.replace(
          "{upsalescustomerid}",
          clientId
        )
      ),
      fetchBusinessOpportunityInfo(businessOpportunityId),
      fetchEmployeesOffices(),
      fetchUserRoles(),
      fetchOfficeManagers(),
      fetchTeams(),
    ])
      .then(
        ([
          customerContactPersons,
          gtContactPersons,
          companyDetails,
          businessOpportunityInfo,
          employeesOffices,
          userRoles,
          officeManagers,
          teams,
        ]) => {
          dispatch({
            type: Constants.AppStateActions.UpdateRemoteData,
            payload: {
              remoteData: {
                customerContactPersons,
                gtContactPersons,
                companyDetails,
                businessOpportunityInfo,
                employeesOffices,
                userRoles,
                officeManagers,
                teams,
              },
            },
          });
        }
      )
      .catch((err) => {
        showBoundary(err);
      });
  }

  useBeforeunload(() => {
    if (
      globalState.currentConfiguration.id &&
      !isFinalorOutdatedVersionStatus &&
      globalState.areUnsavedChanges
    ) {
      saveCurrentConfiguration(
        globalState,
        dispatch,
        businessOpportunityId,
        unit,
        clientId
      );
      wait(500);
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        globalStateRef.current.currentConfiguration.id &&
        !isFinalorOutdatedVersion(globalStateRef.current) &&
        !globalStateRef.current.isConflictOnUpdateEngagement &&
        globalStateRef.current.areUnsavedChanges &&
        Date.now() - globalStateRef.current.unsavedChangesTimestamp >
          +process.env.REACT_APP_AUTOSAVE_INTERVAL
      ) {
        saveCurrentConfiguration(
          globalStateRef.current,
          dispatch,
          businessOpportunityId,
          unit,
          clientId
        ).then(() => {
          showSnackBar(t("General.AutoSaveMessage"), "success", 3000);
        });
        dispatch({
          type: Constants.AppStateActions.ChangeUnsavedFlag,
        });
      }
    }, +process.env.REACT_APP_AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [globalState.unsavedChangesTimestamp]);

  useEffect(() => {
    if (!globalState.authProviderUserDetails?.userId) {
      (async () => {
        const authProviderUserDetails = await getUserInfo();

        if (!authProviderUserDetails) {
          window.location.assign(
            `${
              Constants.AadApiPath.AuthLogin
            }?post_login_redirect_uri=${encodeURIComponent(
              location.pathname + location.search
            )}`
          );
          return;
        }

        dispatch({
          type: Constants.AppStateActions.UpdateAadUserInfo,
          payload: {
            authProviderUserDetails,
          },
        });
      })();
    }

    if (
      !!businessOpportunityId &&
      !!clientId &&
      globalState.authProviderUserDetails?.userId
    ) {
      const fetchDataFn = () => {
        updateApplicationLoadingState(true);
        fetchApplicationConfigurationData()
          .then((applicationConfigurationData) => {
            Promise.all([
              fetchEngagementConfigurationData(
                businessOpportunityId,
                engagementConfigurationId
              ),
              retrieveRemoteData(),
            ])
              .then(([engagementConfigurationData]) => {
                if (!!applicationConfigurationData) {
                  dispatch({
                    type: Constants.AppStateActions
                      .UpdateApplicationConfiguration,
                    payload: applicationConfigurationData,
                  });
                }

                dispatch({
                  type: Constants.AppStateActions
                    .ApplicationConfigurationVersionsChanged,
                  payload: {
                    rateCart:
                      applicationConfigurationData.RateCartConfiguration
                        .Version,
                    taskCost:
                      applicationConfigurationData.TaskCostConfiguration
                        .Version,
                    taskAllocation:
                      applicationConfigurationData.TaskAllocationConfiguration
                        .Version,
                  },
                });

                dispatch({
                  type: Constants.AppStateActions
                    .RatesChangeMessageVisibilityUpdated,
                  payload: true,
                });

                updateStateOnEngagementConfigurationRead(
                  engagementConfigurationData,
                  dispatch,
                  (service: DTOs.ServiceDTO) =>
                    applyUpdatesOnQuestionChange(globalState, service),
                  applicationConfigurationData
                );
                const nextRoutesMap = {
                  [Constants.ConfigurationStatus.Active]:
                    Constants.Routes.Question,
                  [Constants.ConfigurationStatus.FinalVersion]:
                    Constants.Routes.GenerateDocuments,
                  [Constants.ConfigurationStatus.OutdatedVersion]:
                    Constants.Routes.GenerateDocuments,
                };
                const relevantPageForNavigation = getRelevantPageForNavigation(
                  engagementConfigurationData?.Status,
                  nextRoutesMap,
                  businessOpportunityId,
                  clientId,
                  unit,
                  engagementConfigurationId ?? engagementConfigurationData?.Id
                );
                if (relevantPageForNavigation) {
                  navigate(relevantPageForNavigation, { replace: true });
                } else if (
                  engagementConfigurationData?.Id &&
                  engagementConfigurationData?.Id !== engagementConfigurationId
                ) {
                  const urlSearchParams = new URLSearchParams(
                    window.location.search
                  );
                  urlSearchParams.set(
                    Constants.QueryParams.engagementConfigurationId,
                    engagementConfigurationData.Id
                  );
                  navigate(`?${urlSearchParams}`, { replace: true });
                }
              })
              .catch((err: Error) => showBoundary(err))
              .finally(() => {
                updateApplicationLoadingState(false);
              });
          })
          .catch((err: Error) => showBoundary(err));
      };
      if (rootConfig.isEngagementApplicationPage) {
        fetchDataFn();
      }
    }
  }, [
    businessOpportunityId,
    clientId,
    globalState.authProviderUserDetails?.userId,
    rootConfig.isEngagementApplicationPage,
  ]);

  useEffect(() => {
    const fetchDataFn = () => {
      fetchApplicationConfigurationData()
        .then((applicationConfigurationData) => {
          Promise.all([
            fetchGtContactPersons(),
            fetchEmployeesOffices(),
            fetchUserRoles(),
            fetchOfficeManagers(),
            fetchTeams(),
          ])
            .then(
              ([
                gtContactPersons,
                employeesOffices,
                userRoles,
                officeManagers,
                teams,
              ]) => {
                dispatch({
                  type: Constants.AppStateActions.UpdateRemoteData,
                  payload: {
                    remoteData: {
                      gtContactPersons,
                      employeesOffices,
                      userRoles,
                      officeManagers,
                      teams,
                    },
                  },
                });

                if (!!applicationConfigurationData) {
                  dispatch({
                    type: Constants.AppStateActions
                      .UpdateApplicationConfiguration,
                    payload: applicationConfigurationData,
                  });
                }

                dispatch({
                  type: Constants.AppStateActions
                    .ApplicationConfigurationVersionsChanged,
                  payload: {
                    rateCart:
                      applicationConfigurationData.RateCartConfiguration
                        .Version,
                    taskCost:
                      applicationConfigurationData.TaskCostConfiguration
                        .Version,
                    taskAllocation:
                      applicationConfigurationData.TaskAllocationConfiguration
                        .Version,
                  },
                });

                dispatch({
                  type: Constants.AppStateActions
                    .RatesChangeMessageVisibilityUpdated,
                  payload: true,
                });
              }
            )
            .catch((err: Error) => showBoundary(err))
            .finally(() => {
              updateApplicationLoadingState(false);
            });
        })
        .catch((err: Error) => showBoundary(err));
    };
    if (
      !rootConfig.isEngagementApplicationPage &&
      !globalState.currentConfiguration.applicationConfiguration
    ) {
      fetchDataFn();
    }
  }, [
    globalState.authProviderUserDetails?.userId,
    rootConfig.isEngagementApplicationPage,
  ]);

  if (!globalState.authProviderUserDetails?.userId) {
    return null;
  }

  if (
    (!businessOpportunityId || !clientId) &&
    rootConfig.isEngagementApplicationPage
  ) {
    const queryParams = new URLSearchParams();
    queryParams.set(
      Constants.QueryParams.businessOpportunityId,
      Date.now().toString()
    );
    queryParams.set(Constants.QueryParams.clientId, "20");
    queryParams.set(Constants.QueryParams.unit, "es");
    const navigationURL = `?${queryParams}`;
    return (
      <article className="gt-invalidApplicationScreen">
        <h2>{t("General.InvalidApplicationURL")}</h2>
        {(process.env.REACT_APP_ENV === Constants.Environments.DEV ||
          process.env.REACT_APP_ENV === Constants.Environments.TEST) && (
          <NavLink to={navigationURL}>{t("General.LoadSampleApp")}</NavLink>
        )}
        {process.env.REACT_APP_ENV !== Constants.Environments.DEV &&
          process.env.REACT_APP_ENV !== Constants.Environments.TEST && (
            <div className="gt-invalidApplicationScreen__warningMessage">
              <WarningAmberIcon fontSize="medium" />
              <span>{t("General.StartJourneyMessage")}</span>
            </div>
          )}
      </article>
    );
  }

  let isEmailMissingDigitalSigningUsers = false;
  if (isGenerateDocumentsPage) {
    isEmailMissingDigitalSigningUsers =
      isEmailMissingForDigitalSigningUsers(globalState);
  }

  return (
    <article
      className={`gt-appRoot ${isLoading ? "gt-appRoot--loadingState" : ""} ${
        globalState.state.isPageBlurred ? "gt-appRoot--loadingBlurState" : ""
      } ${
        shouldDisplayRatesChangeMessage &&
        isPriceAndTimeCalculationPage &&
        hasSelectedServices
          ? "gt-appRoot--withPurpleBackground"
          : ""
      }`}
    >
      <section className="gt-appRoot__loadingContainer">
        <div className="gt-appRoot__loadingContainer-spinner">
          <GTSpinner />
        </div>
        <div className="gt-appRoot__loadingContainer-loadingText">
          {loadingMessage === null ? t("General.Loading") : loadingMessage}
        </div>
      </section>
      <Header
        areCtasButtonsHidden={rootConfig.areCtasButtonsHidden}
        isBusinessUnit={rootConfig.isBusinessUnit}
        isOfferStatusHidden={rootConfig.isOfferStatusHidden}
        isVersionNameHidden={rootConfig.isVersionNameHidden}
        isOwnerNameHidden={rootConfig.isOwnerNameHidden}
        areBreadcrumbsHidden={rootConfig.areBreadcrumbsHidden}
      />
      <main className="gt-appRoot__main">
        {!rootConfig.isLeftSideBarHidden && <LeftMenu />}
        <div ref={scrollPersisterRef} className="gt-appRoot__main-container">
          {!id && !rootConfig.isAlertBannerHidden && (
            <GtAlertBanner message={t("General.NotSavedMessage")} />
          )}
          {id && isGenerateDocumentsPage && !areAllServicesCompleted && (
            <GtAlertBanner
              message={t("General.GenerateDocumentsWarningMessage")}
            />
          )}
          {isEmailMissingDigitalSigningUsers &&
            isDigitallySigningChecked(globalState) && (
              <GtAlertBanner
                message={t("General.DigitallySigneeMissingEmail")}
                withCloseButton={false}
              />
            )}
          <GtSystemAdministrationMessages />
          {shouldDisplayRatesChangeMessage &&
            !rootConfig.isNotificationBannerHidden && (
              <GTNotificationBanner
                key={`banner${rateschangesMessage.id}`}
                systemAdministrationMessage={rateschangesMessage}
                shouldTranslateValue={true}
                onBannerClose={() => {
                  dispatch({
                    type: Constants.AppStateActions
                      .RatesChangeMessageVisibilityUpdated,
                    payload: false,
                  });
                }}
              />
            )}
          <Outlet />
        </div>
      </main>
    </article>
  );
}
