import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import { Button } from "@mui/material";

function GtOfficeManagerDeleteDialog({
  onDeleteOfficeManager,
  onClose,
}: CorePropsInterfaces.GtOfficeManagerDeleteDialogProps): JSX.Element {
  const officeManagerDeleteDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.DeleteOfficeManager"),
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="error"
          variant="outlined"
          key="confirmBtn"
          onClick={onDeleteOfficeManager}
        >
          {t("General.Delete")}
        </Button>,
      ],
    },
    api: {
      onConfirm: onDeleteOfficeManager,
      onClose: onClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  return (
    <GtDialog gtDialogDTO={officeManagerDeleteDialogDTO}>
      <article>{t("General.DeleteOfficeManagerText")}</article>
    </GtDialog>
  );
}

export default GtOfficeManagerDeleteDialog;
