import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";
import { getQuestionValue } from "src/utils";

export const CorporateCardManagementTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0303: findQuestionInService(
        serviceDTO,
        Constants.CorporateCardManagementQuestion.Q0303
      ),
      q0303_1: findQuestionInService(
        serviceDTO,
        Constants.CorporateCardManagementQuestion.Q0303_1
      ),
      q0304: findQuestionInService(
        serviceDTO,
        Constants.CorporateCardManagementQuestion.Q0304
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.CorporateCardManagementTasks.T0301) {
        taskDTO.state.isVisible =
          questionsMap.q0303.state.isShown ||
          questionsMap.q0303_1.state.isShown;
      }
      if (taskDTO.data.code === Constants.CorporateCardManagementTasks.T0302) {
        taskDTO.state.isVisible =
          questionsMap.q0304.state.isShown &&
          questionsMap.q0304.data.userValue !== Constants.CardProvider.Other &&
          questionsMap.q0304.data.userValue !==
            Constants.HelpfulConstants.PleaseSelect;
      }
      if (taskDTO.data.code === Constants.CorporateCardManagementTasks.T0307) {
        taskDTO.state.isVisible =
          questionsMap.q0304.state.isShown &&
          questionsMap.q0304.data.userValue === Constants.CardProvider.Other;
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.CorporateCardManagementAdditionalCosts.T0308
        ) {
          const activeQuestion = questionsMap.q0303.state.isShown
            ? questionsMap.q0303
            : questionsMap.q0303_1;
          eachAdditionalCost.state.isVisible =
            appState.generalVariables.isDefaultAccountingSystem &&
            getQuestionValue(activeQuestion) ===
              Constants.CorporateCardPreSystem.KvittoResa &&
            getQuestionValue(questionsMap.q0304) ===
              Constants.CardProvider.Other;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.CorporateCardManagementAdditionalCosts.T0309
        ) {
          const activeQuestion = questionsMap.q0303.state.isShown
            ? questionsMap.q0303
            : questionsMap.q0303_1;
          eachAdditionalCost.state.isVisible =
            appState.generalVariables.isDefaultAccountingSystem &&
            getQuestionValue(activeQuestion) ===
              Constants.CorporateCardPreSystem.KvittoResa &&
            getQuestionValue(questionsMap.q0304) !==
              Constants.CardProvider.Other &&
            getQuestionValue(questionsMap.q0304) !==
              Constants.HelpfulConstants.PleaseSelect;
        }
      });
    }
  };
