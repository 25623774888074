import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { CorePropsInterfaces } from "src/core/Models";

function GtDialog(props: CorePropsInterfaces.GtDialogProps): JSX.Element {
  const { children, gtDialogDTO } = props;
  const {
    data: { title, maxWidth, rightButtons, leftButtons },
    api: { onClose },
    state: { isOpen, isFullWidth },
  } = gtDialogDTO;
  return (
    <Dialog
      open={isOpen}
      fullWidth={isFullWidth}
      maxWidth={maxWidth}
      className="gt-dialog"
    >
      <DialogTitle>
        {title ? title : null}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <GridCloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {(leftButtons || rightButtons) && (
        <DialogActions className="gt-dialog__actionsContainer">
          <section className="gt-dialog__actionsContainer-left">
            {leftButtons}
          </section>
          <section className="gt-dialog__actionsContainer-right">
            {rightButtons}
          </section>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default GtDialog;
