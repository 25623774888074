import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Constants from "./../../core/Constants";
import GtNavigationButtons from "src/components/Common/GtNavigationButtons";
import { AppContext } from "../../contexts/AppContext";
import { CoreInterfaces, DTOs } from "src/core/Models";
import GtExpandCollapseAll from "src/components/Common/GtExpandCollapseAll";
import { Typography } from "@mui/material";
import EngagementDescriptionGeneralPanel from "src/components/Panels/EngagementDescriptionGeneralPanel";
import AccountsReceivableSummaryPanel from "src/components/Panels/AccountsReceivableSummaryPanel";
import AccountsPayableSummaryPanel from "src/components/Panels/AccountsPayableSummaryPanel";
import CorporateCardManagementSummaryPanel from "src/components/Panels/CorporateCardManagementSummaryPanel";
import OtherAccountAndReconciliationSummaryPanel from "src/components/Panels/OtherAccountAndReconciliationSummaryPanel";
import GtLegend from "src/components/Common/GtLegend";
import PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel from "src/components/Panels/PayrollAndExpenseAndTravelInvoiceManagement";
import EmptySummaryPanel from "src/components/Panels/EmptySummaryPanel";
import { mapServiceCodesToServicesDTOs } from "src/utils";
import ContactPersonSummaryPanel from "src/components/Panels/ContactPersonSummaryPanel";
import AnnualReportingSummaryPanel from "src/components/Panels/AnnualReportingSummaryPanel";
import EngagementServiceTasksPanelWrapper from "src/components/Panels/EngagementServiceTasksPanelWrapper";

function EngagementDescriptionPage(): JSX.Element {
  const { t } = useTranslation();
  const initialPageState: CoreInterfaces.PageState = {
    title: "Pages.EngagementDescription.Title",
  };
  const [pageState] = useState<CoreInterfaces.PageState>(initialPageState);
  const { globalState, dispatch } = useContext(AppContext);
  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.AccountsReceivable,
    Constants.ServiceCode.AccountsPayable,
    Constants.ServiceCode.CorporateCardManagement,
    Constants.ServiceCode.OtherAccountAndReconciliation,
    Constants.ServiceCode.PeriodReporting,
    Constants.ServiceCode.InterimListedCompanies,
    Constants.ServiceCode.AnnualReporting,
    Constants.ServiceCode.AnnualReportingListedCompanies,
    Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
    Constants.ServiceCode.AnnualPayrollRoutines,
    Constants.ServiceCode.StartUpAccounting,
    Constants.ServiceCode.StartUpPayroll,
  ]);

  function handleExpandedAccordionChange(code: Constants.ServiceCode) {
    const serviceDTO = globalState.services.find(
      (item) => item.data.code === code
    );
    const nextService: DTOs.ServiceDTO = {
      ...serviceDTO,
      state: {
        ...serviceDTO.state,
        isExpandedEngagementDescriptionAccordion:
          !serviceDTO.state.isExpandedEngagementDescriptionAccordion,
      },
    };
    dispatch({
      type: Constants.AppStateActions.ServiceExpandChange,
      payload: nextService,
    });
  }

  function toggleExpandedAll(flag: boolean): void {
    const nextServices = globalState?.services?.map(
      (serviceDTO: DTOs.ServiceDTO) => {
        const nextService: DTOs.ServiceDTO = {
          ...serviceDTO,
          state: {
            ...serviceDTO.state,
            isExpandedEngagementDescriptionAccordion: serviceDTO.state
              .isSelected
              ? flag
              : serviceDTO.state.isExpandedEngagementDescriptionAccordion,
          },
        };

        return nextService;
      }
    );

    dispatch({
      type: Constants.AppStateActions.ServicesOverride,
      payload: nextServices,
    });
  }

  return (
    <article className="gt-engagementDescriptionPage">
      <div className="gt-pageTopPanel">
        <Typography
          className="gt-pageTopPanel__title"
          variant="h5"
          gutterBottom={true}
        >
          {t(pageState.title)}
        </Typography>
        <div className="gt-pageTopPanel__actions">
          <GtLegend />
          <GtExpandCollapseAll
            toggleExpandedAll={(flag: boolean) => {
              toggleExpandedAll(flag);
            }}
            expandButtonText={t("General.ExpandDetails")}
            collapseButtonText={t("General.CollapseDetails")}
          ></GtExpandCollapseAll>
        </div>
      </div>

      <EngagementDescriptionGeneralPanel />

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.AccountsReceivable]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.AccountsReceivable
          );
        }}
      >
        <AccountsReceivableSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      {/* <EngagementServiceTasksPanel
        serviceDTO={servicesMap[Constants.ServiceCode.AccountsReceivable]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.AccountsReceivable
          );
        }}
      >
        <AccountsReceivableSummaryPanel />
      </EngagementServiceTasksPanel> */}

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.AccountsPayable]}
        onExpandChange={() => {
          handleExpandedAccordionChange(Constants.ServiceCode.AccountsPayable);
        }}
      >
        <AccountsPayableSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.CorporateCardManagement]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.CorporateCardManagement
          );
        }}
      >
        <CorporateCardManagementSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={
          servicesMap[Constants.ServiceCode.OtherAccountAndReconciliation]
        }
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.OtherAccountAndReconciliation
          );
        }}
      >
        <OtherAccountAndReconciliationSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.PeriodReporting]}
        onExpandChange={() => {
          handleExpandedAccordionChange(Constants.ServiceCode.PeriodReporting);
        }}
      >
        <ContactPersonSummaryPanel
          serviceDTO={servicesMap[Constants.ServiceCode.PeriodReporting]}
        />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.InterimListedCompanies]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.InterimListedCompanies
          );
        }}
      >
        <ContactPersonSummaryPanel
          serviceDTO={servicesMap[Constants.ServiceCode.PeriodReporting]}
        />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.AnnualReporting]}
        onExpandChange={() => {
          handleExpandedAccordionChange(Constants.ServiceCode.AnnualReporting);
        }}
      >
        <AnnualReportingSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={
          servicesMap[Constants.ServiceCode.AnnualReportingListedCompanies]
        }
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.AnnualReportingListedCompanies
          );
        }}
      >
        <ContactPersonSummaryPanel
          serviceDTO={servicesMap[Constants.ServiceCode.AnnualReporting]}
        />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={
          servicesMap[
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
          ]
        }
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
          );
        }}
      >
        <PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.AnnualPayrollRoutines]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.AnnualPayrollRoutines
          );
        }}
      >
        <PayrollAndExpenseAndTravelInvoiceManagementSummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <EngagementServiceTasksPanelWrapper
        serviceDTO={servicesMap[Constants.ServiceCode.StartUpAccounting]}
        onExpandChange={() => {
          handleExpandedAccordionChange(
            Constants.ServiceCode.StartUpAccounting
          );
        }}
      >
        <EmptySummaryPanel />
      </EngagementServiceTasksPanelWrapper>

      <GtNavigationButtons current={3} />
    </article>
  );
}

export default EngagementDescriptionPage;
