import { CorePropsInterfaces, DTOs } from "src/core/Models";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useTranslation } from "react-i18next";

function QuestionYesNoInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { t } = useTranslation();

  function onYesNoRadioChange(evt: any): void {
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: evt?.target?.value,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  return (
    <FormControl className="gt-YesNoField" disabled={isDisabled}>
      <RadioGroup
        data-testid={question.data.code}
        name="controlled-radio-buttons-group"
        value={question.data.userValue}
        onChange={onYesNoRadioChange}
      >
        <FormControlLabel
          value="Yes"
          data-testid={question.data.code + "Yes"}
          control={<Radio />}
          label={t("Options.Yes")}
        />
        <FormControlLabel
          data-testid={question.data.code + "No"}
          value="No"
          control={<Radio />}
          label={t("Options.No")}
        />
      </RadioGroup>
    </FormControl>
  );
}

export default QuestionYesNoInput;
