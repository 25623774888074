import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "../../core/Constants/Constants";
import {
  findQuestionInService,
  getFrequencyMultiplicator,
  resolveContactPerson,
} from "src/utils";

export default function AnnualReportingSummaryPanel(): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);

  const service = globalState.services.find(
    (item) => item.data.code === Constants.ServiceCode.AnnualReporting
  );

  const contactPersonQuestion = findQuestionInService(
    service,
    Constants.AnnualReportingQuestions.Q0608
  );
  const contactPerson = resolveContactPerson(
    contactPersonQuestion?.data?.options?.find(
      (item) => item.key === contactPersonQuestion.data.userValue
    )?.value
  );
  const nrTransactions = findQuestionInService(
    service,
    Constants.AnnualReportingQuestions.Q0602
  );

  const nrOfBalanceAccounts = getFrequencyMultiplicator(
    service.data.tasks.find(
      (taskDTO) => taskDTO.data.code === Constants.AnnualReportingTasks.T0612
    ),
    service
  );

  const transactionsText = nrTransactions.state.isShown
    ? `${t("PanelHeader.NrTransactions", {
        nrTransactions: nrTransactions.data.userValue,
      })}`
    : "";

  const balanceAccountsText = `${t("PanelHeader.NrOfBalanceAccount", {
    nrOfBalanceAccount: nrOfBalanceAccounts,
  })}`;

  return (
    <article className="gt-summaryPanel">
      <div className="gt-summaryPanel__contactPerson">
        {t("PanelHeader.ContactPersonClientText", { contactPerson })}
      </div>
      {<div className="gt-summaryPanel__data">{transactionsText}</div>}
      {<div className="gt-summaryPanel__data">{balanceAccountsText}</div>}
    </article>
  );
}
