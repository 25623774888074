import { TextField } from "@mui/material";
import { debounce } from "src/utils";
import * as Constants from "./../../core/Constants";
import { useEffect, useRef } from "react";

export default function GtTimeDueInput({
  value,
  code,
  onChange,
  isDisabled,
  updateTimestamp,
}: {
  value: string;
  code: string;
  onChange: Function;
  isDisabled: boolean;
  updateTimestamp: string;
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.value = value;
  }, [updateTimestamp]);

  const debouncedChange = debounce((value: string) => {
    onChange(value);
  }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS);

  return (
    <TextField
      inputRef={inputRef}
      data-testid={code + "TimeDue"}
      className="gt-MultilineField"
      size="small"
      variant="outlined"
      defaultValue={value}
      inputProps={{ maxLength: 50 }}
      multiline
      maxRows={2}
      onKeyDown={(evt: any) => evt.stopPropagation()}
      onKeyUp={(evt: any) => debouncedChange(evt.target.value)}
      disabled={isDisabled}
    />
  );
}
