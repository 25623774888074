import { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormGroup,
  TextField,
} from "@mui/material";
import { CorePropsInterfaces, CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "../../../core/Constants";
import { t } from "i18next";
import GtDialog from "src/components/Common/GtDialog";
import { AppContext } from "src/contexts/AppContext";
import {
  createEmptyContactPerson,
  formatStringToPersonalNumber,
  isFinalorOutdatedVersion,
} from "src/utils";

import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import GtDebouncedInput from "src/components/Common/GtDebouncedInput";

function QuestionContactPersonUserDetails({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { globalState, dispatch } = useContext(AppContext);
  const lastVersionChangedDate =
    globalState.currentConfiguration.lastModifiedDate;
  const isFinalOrOutdatedVersion = isFinalorOutdatedVersion(globalState);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contactPersonToDeleteUuid, setContactPersonToDeleteUuid] =
    useState<string>();

  const markedAnswerAsSigned = true;
  const userDetails =
    Array.isArray(question.data.userValue) && question.data.userValue.length > 0
      ? (
          question.data.userValue as CoreInterfaces.ContactPersonUserDetails[]
        ).map((userDetails: CoreInterfaces.ContactPersonUserDetails) => ({
          ContactPersonId: userDetails.ContactPersonId,
          PersonalNumber: userDetails.PersonalNumber,
          IsSigned: userDetails.IsSigned,
          Uuid: userDetails.Uuid,
        }))
      : [createEmptyContactPerson(markedAnswerAsSigned)];

  const options: CoreInterfaces.SelectableKeyValueItem[] =
    question.data.options.map((option) => {
      const isSelected = userDetails.some(
        (userValue) => userValue.ContactPersonId === option.key
      );
      return { ...option, isSelected };
    });

  const addContactPersons = () => {
    if (userDetails.length < 10 && !isFinalOrOutdatedVersion) {
      dispatch({
        type: Constants.AppStateActions.AddContactPerson,
      });
    }
  };

  const deleteContactPerson = () => {
    dispatch({
      type: Constants.AppStateActions.DeleteContactPerson,
      payload: {
        contactPersonToDeleteUuid,
      },
    });
  };

  const deleteDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.DeleteContactPerson"),
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => setIsModalVisible(!isModalVisible)}
          color="inherit"
          variant="contained"
          key="cancelAction"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          onClick={() => {
            deleteContactPerson();
            setIsModalVisible(!isModalVisible);
          }}
          color="tertiary"
          variant="contained"
          key="delecteLicense"
        >
          {t("General.DeleteContactPerson")}
        </Button>,
      ],
    },
    api: {
      onClose: () => setIsModalVisible(!isModalVisible),
    },
    state: {
      isOpen: isModalVisible,
      isFullWidth: true,
    },
  };

  const updateContactPersonProperty = ({
    value,
    contactPersonUuid,
    property,
  }: {
    value: string | boolean;
    contactPersonUuid: string;
    property: keyof CoreInterfaces.ContactPersonUserDetails;
  }): void => {
    dispatch({
      type: Constants.AppStateActions.UpdateContactPersonValue,
      payload: {
        contactPersonUuid,
        property,
        value,
      },
    });
  };

  return (
    <>
      {isModalVisible && (
        <GtDialog gtDialogDTO={deleteDialogDTO}>
          <article> {t("General.DeleteContactPerson")}</article>
        </GtDialog>
      )}
      <FormGroup className="gt-multipleEntriesInputContainer">
        {userDetails.map((contactPerson, index) => (
          <div
            key={contactPerson.Uuid}
            className="gt-multipleEntriesInputContainer__rows"
          >
            <Autocomplete
              data-testid={question.data.code}
              className="gt-Autocomplete"
              disablePortal
              disabled={isDisabled}
              options={options}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => option.isSelected === true}
              value={
                options.find(
                  (option) => option.key === contactPerson.ContactPersonId
                ) || null
              }
              onChange={(_, evt) => {
                const selectedKey = evt?.key;
                updateContactPersonProperty({
                  value: selectedKey,
                  contactPersonUuid: contactPerson.Uuid,
                  property: "ContactPersonId",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  className="gt-multipleEntriesInputContainer__rows"
                  component="li"
                  {...props}
                  data-testid={`option_question${question.data.code}_option${option.key}`}
                >
                  {option.value}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("Options.PleaseSelect")}
                />
              )}
            />
            <GtDebouncedInput
              value={contactPerson.PersonalNumber ?? ""}
              className="gt-multipleEntriesInputContainer__rows-row gt-TextField"
              onChange={(sanitizedValue: string) =>
                updateContactPersonProperty({
                  value: sanitizedValue,
                  contactPersonUuid: contactPerson.Uuid,
                  property: "PersonalNumber",
                })
              }
              placeholder={t("Options.PersonalNumber")}
              updateTimestamp={lastVersionChangedDate}
              sanitizationFn={formatStringToPersonalNumber}
              disabled={isFinalOrOutdatedVersion}
            />
            <div className="gt-multipleEntriesInputContainer__rows-row-checkbox">
              <div>
                <Checkbox
                  size="medium"
                  checked={contactPerson.IsSigned}
                  onChange={(e: any) => {
                    updateContactPersonProperty({
                      value: e.target.checked,
                      contactPersonUuid: contactPerson.Uuid,
                      property: "IsSigned",
                    });
                  }}
                  disabled={
                    (userDetails.length === 1 && index === 0) ||
                    isFinalOrOutdatedVersion
                  }
                />
                {index === 0 ? t("Options.Signed") : t("Options.SignedOwner")}
              </div>
              {(index > 0 || (index === 0 && userDetails.length > 1)) &&
                !isFinalOrOutdatedVersion && (
                  <Button
                    color="tertiary"
                    className="gt-multipleEntriesFormGroupButton gt-multipleEntriesFormGroupButton--delete"
                    onClick={() => {
                      setIsModalVisible(!isModalVisible);
                      setContactPersonToDeleteUuid(contactPerson.Uuid);
                    }}
                  >
                    <PersonRemoveIcon />
                  </Button>
                )}
            </div>
          </div>
        ))}
        <Button
          className={`gt-multipleEntriesInputContainerButton 
          ${
            userDetails.length <= 9 && !isFinalOrOutdatedVersion
              ? " gt-multipleEntriesInputContainerButton--add"
              : " gt-multipleEntriesInputContainerButton--disabled"
          }
        }`}
          onClick={addContactPersons}
        >
          {t("General.AddContactPerson")}
        </Button>
      </FormGroup>
    </>
  );
}

export default QuestionContactPersonUserDetails;
