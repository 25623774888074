import * as Constants from "../../core/Constants";

import { CorePropsInterfaces, DTOs } from "src/core/Models";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext } from "react";

import { AppContext } from "src/contexts/AppContext";
import GtDebouncedInput from "../Common/GtDebouncedInput";
import { t } from "i18next";
import { createServiceStaffingAllocationSummaryPanelTableRows } from "src/utils/service-utils";

export default function ServiceStaffingAllocationSummaryPanel({
  serviceDTO,
  taskGroups = [Constants.TaskGroup.DefaultGroup],
}: CorePropsInterfaces.ServiceStaffingAllocationSummaryPanelProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);

  const onServiceCostAdjustmentChange = (costValue: string) => {
    dispatch({
      type: Constants.AppStateActions.UpdateServiceStaffingAdjustmentCost,
      payload: {
        serviceCode: serviceDTO.data.code,
        adjustmentCost: costValue === "" ? null : Number(costValue),
      },
    });
  };

  const rows = createServiceStaffingAllocationSummaryPanelTableRows(
    globalState.currentConfiguration?.applicationConfiguration
      ?.rateCartConfiguration,
    serviceDTO,
    taskGroups
  );
  if (rows === null || !rows.length) {
    return null;
  }

  const columns = getColumnsDefinition(
    serviceDTO,
    onServiceCostAdjustmentChange,
    globalState.currentConfiguration.lastModifiedDate
  );

  return (
    <article
      className={`gt-serviceStaffingAllocationSummaryPanel ${
        columns.length < 5
          ? "gt-serviceStaffingAllocationSummaryPanel--widthSmaller"
          : ""
      }`}
      data-testid={
        serviceDTO.data.code + "ServiceStaffingAllocationSummaryPanel"
      }
    >
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        rowHeight={44}
        columnHeaderHeight={
          serviceDTO.data.allocationSummaryPanel.hasTableHeader ? 48 : 0
        }
        hideFooterPagination={true}
      />
    </article>
  );
}

function getColumnsDefinition(
  serviceDTO: DTOs.ServiceDTO,
  onServiceCostAdjustmentChange: Function,
  lastVersionChangedDate: string
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t(serviceDTO.data.calculationPage.staffingTableHeaderTitle),
      sortable: false,
      disableColumnMenu: true,
      flex: 6,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      colSpan: ({ row }) => {
        if (row.id === Constants.CustomDataGridColumnId.TotalWorkCost) {
          return 2;
        }
        if (row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost) {
          return 3;
        }
        if (row.id === Constants.CustomDataGridColumnId.TotalCost) {
          return 3;
        }
        return undefined;
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "Title"}>
            {params.row.title}
          </article>
        );
      },
    },
    {
      field: "pricePerHour",
      flex: 2,
      headerName: t("General.PricePerHour"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "PricePerHour"}>
            {params.row.pricePerHour}
          </article>
        );
      },
    },
  ];

  if (serviceDTO.data.allocationSummaryPanel.isDividedByTaskServiceGroup) {
    columnDefinition.push({
      field: "hoursAndMinutesAnnualAccounts",
      flex: 2,
      headerName: t(
        `TableHeader.ServiceStaffingAllocationPanel.${serviceDTO.data.code}.AnnualAcounts`
      ),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            data-testid={params.row.id + "HoursAndMinutesAnnualAccounts"}
          >
            {params.row.hoursAndMinutesAnnualAccounts}
          </article>
        );
      },
    });
    columnDefinition.push({
      field: "hoursAndMinutesStatutoryAnnualAccounts",
      flex: 2,
      headerName: t(
        `TableHeader.ServiceStaffingAllocationPanel.${serviceDTO.data.code}.StatutoryAnnualAcounts`
      ),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            data-testid={
              params.row.id + "HoursAndMinutesStatutoryAnnualAccounts"
            }
          >
            {params.row.hoursAndMinutesStatutoryAnnualAccounts}
          </article>
        );
      },
    });
    columnDefinition.push({
      field: "hoursAndMinutesCorporateIncomeTax",
      flex: 2,
      headerName: t(
        `TableHeader.ServiceStaffingAllocationPanel.${serviceDTO.data.code}.CorporateIncomeTax`
      ),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            data-testid={params.row.id + "HoursAndMinutesCorporateIncomeTax"}
          >
            {params.row.hoursAndMinutesCorporateIncomeTax}
          </article>
        );
      },
    });
  } else {
    columnDefinition.push({
      field: "hoursAndMinutes",
      flex: 2,
      headerName: t("TableHeader.NumberOfHours"),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "HoursAndMinutes"}>
            {params.row.hoursAndMinutes}
          </article>
        );
      },
    });
  }
  columnDefinition.push({
    field: "sum",
    headerName: t(serviceDTO.data.calculationPage.sumText),
    sortable: false,
    disableColumnMenu: true,
    align: "right",
    cellClassName: ({ row }) => {
      return row.id === Constants.CustomDataGridColumnId.TotalCost ||
        row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
        row.id === Constants.CustomDataGridColumnId.TotalWorkCost
        ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
        : "";
    },
    renderCell(params) {
      if (params.row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "Sum"}
          >
            <GtDebouncedInput
              value={params.row.sum ?? ""}
              onChange={onServiceCostAdjustmentChange}
              debounceTime={2 * Constants.INPUT_CHANGE_DEBOUNCE_MILLIS}
              updateTimestamp={lastVersionChangedDate}
              maxLength={8}
              size="small"
              className="gt-dataTableCellWrapper__textField gt-NumberField"
            />
            <section className="gt-dataTableCellWrapper__unit">
              {Constants.USED_CURRENCY}
            </section>
          </article>
        );
      }
      return (
        <article data-testid={params.row.id + "Sum"}>{params.row.sum}</article>
      );
    },
  });

  if (serviceDTO.data.allocationSummaryPanel.hasAveragePrice) {
    columnDefinition.push({
      field: "averagePricePerCustomerInvoice",
      flex: 3,
      headerName: t(
        `TableHeader.ServiceStaffingAllocationPanel.${serviceDTO.data.code}.AveragePrice`
      ),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (!row.averagePricePerCustomerInvoice) {
          return "gt-noBorderCell";
        }
        return row.id === Constants.CustomDataGridColumnId.TotalCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
    });
  }

  if (serviceDTO.data.allocationSummaryPanel.hasAverageTime) {
    columnDefinition.push({
      field: "averageTimePerCustomerInvoice",
      flex: 3,
      headerName: t(
        `TableHeader.ServiceStaffingAllocationPanel.${serviceDTO.data.code}.AverageTime`
      ),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        if (!row.averageTimePerCustomerInvoice) {
          return "gt-noBorderCell";
        }
        return row.id === Constants.CustomDataGridColumnId.TotalCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
    });
  }
  return columnDefinition;
}
