import { CorePropsInterfaces, DTOs } from "./../../../core/Models";
import GtDebouncedInput from "src/components/Common/GtDebouncedInput";
import * as Constants from "./../../../core/Constants";
import { isRangeNumberQuestionDataFilled } from "src/utils";
function QuestionRangeNumberInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  let [firstValue, secondValue] = !!question.data.userValue
    ? (question.data.userValue as string[])
    : ["", ""];

  function onSelectedValueChange(evt: any, index: number): void {
    if (index === 0) {
      firstValue = evt;
    } else {
      secondValue = evt;
    }
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: [firstValue, secondValue],
      },
    };

    newQuestionDTO.state.isFilled =
      question.state.isFilled &&
      isRangeNumberQuestionDataFilled(newQuestionDTO);

    onChange && onChange(newQuestionDTO);
  }

  return (
    <article className="gt-questionRow">
      <GtDebouncedInput
        data-testid={`${question.data.code}-firstInput`}
        className="gt-TextField"
        type="text"
        onChange={(evt: any) => onSelectedValueChange(evt, 0)}
        value={firstValue}
        debounceTime={Constants.INPUT_CHANGE_DEBOUNCE_MILLIS}
        variant="outlined"
        maxLength={8}
        disabled={isDisabled}
        updateTimestamp={firstValue}
      />

      <span className="gt-questionRow__separator">-</span>

      <GtDebouncedInput
        data-testid={`${question.data.code}-secondInput`}
        className="gt-TextField"
        type="text"
        onChange={(evt: any) => onSelectedValueChange(evt, 1)}
        value={secondValue}
        variant="outlined"
        debounceTime={Constants.INPUT_CHANGE_DEBOUNCE_MILLIS}
        disabled={isDisabled}
        maxLength={8}
        updateTimestamp={secondValue}
      />
    </article>
  );
}

export default QuestionRangeNumberInput;
