import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function GtBackArrow() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const backgroundImage = "url(/resources/gt-arrow-back.svg)";

  function showBackBtn(): boolean {
    return !!window.history.state.idx;
  }

  return (
    <>
      {showBackBtn() && (
        <article
          className={`gt-arrowBack`}
          onClick={() => {
            navigate(-1);
          }}
        >
          <div
            className={`gt-arrowBack__icon`}
            style={{ backgroundImage }}
          ></div>
          <div className={`gt-arrowBack__label`}>
            {t("General.BackToWorkflow")}
          </div>
        </article>
      )}
    </>
  );
}
