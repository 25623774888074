import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";

export const StartUpAccountingTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0805: findQuestionInService(
        serviceDTO,
        Constants.StartUpAccountingQuestions.Q0805
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0815) {
        taskDTO.state.isVisible =
          appState.generalVariables.isDefaultAccountingSystem;
      }
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0816) {
        taskDTO.state.isVisible =
          appState.generalVariables.isDefaultAccountingSystem;
      }
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0817) {
        taskDTO.state.isVisible =
          questionsMap.q0805.state.isShown &&
          questionsMap.q0805.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0818) {
        taskDTO.state.isVisible =
          !appState.generalVariables.isDefaultAccountingSystem;
      }
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0819) {
        taskDTO.state.isVisible =
          !appState.generalVariables.isDefaultAccountingSystem;
      }
      if (taskDTO.data.code === Constants.StartUpAccountingTasks.T0820) {
        taskDTO.state.isVisible =
          questionsMap.q0805.state.isShown &&
          questionsMap.q0805.data.userValue === Constants.YesNo.Yes;
      }
    });
  };
