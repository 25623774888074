import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "src/utils";
import * as Constants from "./../Constants";

const initialScrollRestorationData: Record<string, { scrollTop: number }> = {};
let abortController = new AbortController();

export function useScrollRestorer<U extends HTMLElement>() {
  const [scrollRestoration, setScrollRestoration] = useState(
    initialScrollRestorationData
  );
  const [element, setElement] = useState<U | null>(null);
  const ref = useCallback((element: U | null) => {
    if (element) {
      setElement(element);
    }
  }, []);
  const key = window.location.pathname;
  const currentScrollRestoration = scrollRestoration[key];

  useEffect(() => {
    if (!element) {
      return;
    }

    const config = { childList: true };
    const callback = (mutationList: any) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          handleScrollAfterContentDisplayed();
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(element, config);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    if (element) {
      abortController.abort();
    }
  }, [key]);

  const handleScroll = useMemo(
    () =>
      debounce(() => {
        const key = window.location.pathname;
        const scrollTop = element.scrollTop;

        if (scrollTop !== scrollRestoration[key]?.scrollTop) {
          setScrollRestoration((prevScrollRestoration) => {
            const nextScrollRestoration = {
              ...prevScrollRestoration,
              [key]: { scrollTop },
            };

            return nextScrollRestoration;
          });
        }
      }, Constants.SCROLL_RESTORER_DEBOUNCE_TIME),
    [element]
  );

  const handleScrollAfterContentDisplayed = debounce(() => {
    let scrollTo = null;
    const key = window.location.pathname;
    const sessionStorageValue = sessionStorage.getItem(
      `${Constants.SCROLL_RESTORER_SESSION_STORAGE_PREFIX}${key}`
    );
    if (!!sessionStorageValue) {
      const scrollToObj = JSON.parse(sessionStorageValue) as {
        scrollTop: number;
      };
      scrollTo = scrollToObj.scrollTop;
    } else {
      scrollTo = 0;
    }
    if (scrollTo !== null && scrollTo !== element.scrollTop) {
      element.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
    abortController = new AbortController();
    element.addEventListener("scroll", handleScroll, {
      signal: abortController.signal,
    });
  }, Constants.SCROLL_RESTORER_SCROLL_INTERACT_DEBOUNCE_TIME);

  useEffect(() => {
    if (!currentScrollRestoration) return;
    if (abortController.signal?.aborted) {
      return;
    }

    sessionStorage.setItem(
      `${Constants.SCROLL_RESTORER_SESSION_STORAGE_PREFIX}${key}`,
      JSON.stringify(currentScrollRestoration)
    );
  }, [currentScrollRestoration]);

  return { ref };
}
