import { createContext, useState } from "react";

export const LoadingContext = createContext<{
  isLoading: boolean;
  loadingMessage: string;
  updateApplicationLoadingState: (
    loadingState: boolean,
    loadingMessage?: string
  ) => void;
}>({
  isLoading: false,
  loadingMessage: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateApplicationLoadingState: () => {},
});

export function LoadingContextProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateApplicationLoadingState = (
    loadingState: boolean,
    loadingMessage?: string
  ) => {
    setLoadingMessage(loadingMessage ?? null);
    setIsLoading(loadingState);
  };

  const providerValue = {
    isLoading,
    loadingMessage,
    updateApplicationLoadingState,
  };

  return (
    <LoadingContext.Provider value={providerValue}>
      {children}
    </LoadingContext.Provider>
  );
}
