import { Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "src/core/Constants/Constants";
import {
  getEmail,
  getFirstLastNameInitials,
  getFullName,
  isAdministrator,
} from "src/utils";
import GtAboutDialog from "./GtAboutDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GtOfficeManagersDialog from "./GtOfficeManagersDialog";

export default function GtUserMenu(): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const claims = globalState.authProviderUserDetails?.claims;
  const firstLastNameInitials = claims ? getFirstLastNameInitials(claims) : "";
  const [isVisibleAboutDialog, setIsVisibleAboutDialog] = useState(false);
  const [isVisibleOfficeManagersDialog, setIsVisibleOfficeManagersDialog] =
    useState(false);
  const fullName = claims ? getFullName(claims) : "";
  const email = claims ? getEmail(claims) : "";

  return (
    <>
      <article
        id="user-button"
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        className="gt-userMenu"
      >
        {firstLastNameInitials}
      </article>
      <Menu
        id="user-menu"
        className="gt-userMenuBar"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          return setAnchorEl(null);
        }}
        disableAutoFocusItem={true}
      >
        <section className="gt-userMenuBar__info">
          <div className="gt-userMenuBar__info-initials">
            {firstLastNameInitials}
          </div>
          <div className="gt-userMenuBar__info-data">
            <div className="gt-userMenuBar__info-data-name">{fullName}</div>
            <div className="gt-userMenuBar__info-data-email">{email}</div>
          </div>
        </section>
        {isAdministrator(globalState) && (
          <MenuItem
            onClick={() => {
              setIsVisibleOfficeManagersDialog(true);
              setAnchorEl(null);
            }}
            className="gt-userMenuBar__menuItem"
          >
            <PersonOutlineOutlinedIcon /> {t("General.SetupOfficeManagers")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setIsVisibleAboutDialog(true);
            setAnchorEl(null);
          }}
          className="gt-userMenuBar__menuItem"
        >
          <HelpOutlineIcon /> {t("General.About")}
        </MenuItem>
        <section className="gt-userMenuBar__separator"></section>
        <MenuItem
          onClick={() => {
            window.location.assign(
              `${Constants.AadApiPath.AuthLogout}?post_logout_redirect_uri=/${Constants.Routes.Logout}`
            );
          }}
          className="gt-userMenuBar__menuItem"
        >
          <LogoutIcon /> {t("General.Logout")}
        </MenuItem>
      </Menu>
      {isVisibleAboutDialog && (
        <GtAboutDialog
          onAboutDialogClose={() => setIsVisibleAboutDialog(false)}
        />
      )}
      {isVisibleOfficeManagersDialog && (
        <GtOfficeManagersDialog
          onOfficeManagersDialogClose={() =>
            setIsVisibleOfficeManagersDialog(false)
          }
        />
      )}
    </>
  );
}
