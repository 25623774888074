import { Button } from "@mui/material";
import GtExpandMoreIcon from "./GtExpandMoreIcon";
import { useTranslation } from "react-i18next";
import { CorePropsInterfaces } from "src/core/Models";

export default function GtExpandCollapseAll({
  toggleExpandedAll,
  collapseButtonText,
  expandButtonText,
}: CorePropsInterfaces.GtExpandCollapseAllProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          toggleExpandedAll(false);
        }}
        endIcon={<GtExpandMoreIcon rotate={true} />}
      >
        {collapseButtonText}
      </Button>

      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          toggleExpandedAll(true);
        }}
        endIcon={<GtExpandMoreIcon />}
      >
        {expandButtonText}
      </Button>
    </>
  );
}
