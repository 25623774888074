import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";

export const AnnualReportingListedCompaniesTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0651: findQuestionInService(
        serviceDTO,
        Constants.AnnualReportingListedCompaniesQuestions.Q0651
      ),
      q0652: findQuestionInService(
        serviceDTO,
        Constants.AnnualReportingListedCompaniesQuestions.Q0652
      ),
      q0653: findQuestionInService(
        serviceDTO,
        Constants.AnnualReportingListedCompaniesQuestions.Q0653
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6607
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0651.data.userValue ===
          Constants.AccountingStandards.IFRS;
      }
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6608
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0651.data.userValue ===
          Constants.AccountingStandards.K3;
      }
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6612
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0652.data.userValue ===
          Constants.ResponsibleForInterimReport.GT;
      }
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6613
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0652.data.userValue ===
          Constants.ResponsibleForInterimReport.GTAndClient;
      }
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6614
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0652.data.userValue ===
          Constants.ResponsibleForInterimReport.Client;
      }
      if (
        taskDTO.data.code ===
        Constants.AnnualReportingListedCompaniesTasks.T6616
      ) {
        taskDTO.state.isVisible =
          questionsMap.q0653.data.userValue === Constants.YesNo.Yes;
      }
    });
  };
