import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CorePropsInterfaces } from "src/core/Models";

export default function Warning(
  props: CorePropsInterfaces.WarningProps
): JSX.Element {
  const { fontSize = "inherit" } = props;
  return (
    <article className="icon icon--warning icon--bordered">
      <WarningAmberIcon fontSize={fontSize} />
    </article>
  );
}
