import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "./../../../core/Constants";

export const CorporateCardManagementProcessors: CoreInterfaces.QuestionProcessors =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO) => {
    return {
      [Constants.CorporateCardManagementQuestion.Q0303]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          appState.generalVariables.isDefaultAccountingSystem;
      },
      [Constants.CorporateCardManagementQuestion.Q0303_1]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.state.isShown =
          !appState.generalVariables.isDefaultAccountingSystem;
      },
      [Constants.CorporateCardManagementQuestion.Q0304]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0303 = serviceDTO.data.questions.find(
          (q) => q.data.code === Constants.CorporateCardManagementQuestion.Q0303
        );
        const q0303_1 = serviceDTO.data.questions.find(
          (q) =>
            q.data.code === Constants.CorporateCardManagementQuestion.Q0303_1
        );
        questionDTO.state.isShown =
          (q0303?.state.isShown &&
            q0303?.data.userValue ===
              Constants.CorporateCardPreSystem.KvittoResa) ||
          (q0303_1.state.isShown &&
            q0303_1?.data.userValue ===
              Constants.CorporateCardPreSystem.KvittoResa);
      },
      [Constants.CorporateCardManagementQuestion.Q0307]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0304 = serviceDTO.data.questions.find(
          (q) => q.data.code === Constants.CorporateCardManagementQuestion.Q0304
        );
        questionDTO.state.isShown =
          q0304?.state.isShown &&
          q0304?.data.userValue !== Constants.CardProvider.Other;
      },
      [Constants.CorporateCardManagementQuestion.Q0310]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0304 = serviceDTO.data.questions.find(
          (q) => q.data.code === Constants.CorporateCardManagementQuestion.Q0304
        );
        questionDTO.state.isShown =
          q0304?.state.isShown &&
          q0304?.data.userValue === Constants.CardProvider.Other;
      },
      [Constants.CorporateCardManagementQuestion.Q0311]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        questionDTO.data.options = [
          {
            key: Constants.HelpfulConstants.PleaseSelect,
            value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
          },
          ...(appState.remoteData?.customerContactPersons || []),
        ];
      },
      [Constants.CorporateCardManagementQuestion.Q0313]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0303 = serviceDTO.data.questions.find(
          (q) => q.data.code === Constants.CorporateCardManagementQuestion.Q0303
        );
        const q0303_1 = serviceDTO.data.questions.find(
          (q) =>
            q.data.code === Constants.CorporateCardManagementQuestion.Q0303_1
        );
        questionDTO.state.isShown =
          (q0303?.state.isShown &&
            q0303?.data.userValue ===
              Constants.CorporateCardPreSystem.AnnatForsystem) ||
          (q0303_1.state.isShown &&
            q0303_1?.data.userValue ===
              Constants.CorporateCardPreSystem.AnnatForsystem);
      },
      [Constants.CorporateCardManagementQuestion.Q0314]: (
        questionDTO: DTOs.QuestionDTO
      ): void => {
        const q0303 = serviceDTO.data.questions.find(
          (q) => q.data.code === Constants.CorporateCardManagementQuestion.Q0303
        );
        const q0303_1 = serviceDTO.data.questions.find(
          (q) =>
            q.data.code === Constants.CorporateCardManagementQuestion.Q0303_1
        );
        questionDTO.state.isShown =
          (q0303?.state.isShown &&
            q0303?.data.userValue === Constants.CorporateCardPreSystem.Flex) ||
          (q0303_1.state.isShown &&
            q0303_1?.data.userValue === Constants.CorporateCardPreSystem.Flex);
      },
    };
  };
