import { useContext, useState } from "react";
import { Button, FormGroup } from "@mui/material";
import { CorePropsInterfaces, CoreInterfaces, DTOs } from "src/core/Models";
import {
  createEmptyLicense,
  isFinalorOutdatedVersion,
  sanitizedLicenseValue,
} from "src/utils";
import * as Constants from "../../../core/Constants";
import { t } from "i18next";
import GtDialog from "src/components/Common/GtDialog";
import { AppContext } from "src/contexts/AppContext";
import GtLicenseDebouncedInput from "src/components/Common/GtLicenseDebouncedInput";

function QuestionLicenses({
  question,
  isDisabled,
}: CorePropsInterfaces.QuestionInputProps) {
  const { globalState, dispatch } = useContext(AppContext);
  const lastVersionChangedDate =
    globalState.currentConfiguration.lastModifiedDate;
  const isFinalOrOutdatedVersion = isFinalorOutdatedVersion(globalState);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [licenseToDeleteUuid, setLicenseToDeleteUuid] = useState<string>();

  const licenses = !!question.data.userValue
    ? (question.data.userValue as CoreInterfaces.License[]).map(
        (license: any) => ({
          LicenseName: license.LicenseName,
          PricePerMonth: license.PricePerMonth,
          NumberOfUnits: license.NumberOfUnits,
          Uuid: license.Uuid,
        })
      )
    : [createEmptyLicense()];

  const addLicense = () => {
    if (licenses.length < 10 && !isFinalOrOutdatedVersion) {
      dispatch({
        type: Constants.AppStateActions.AddNewLicense,
      });
    }
  };

  const deleteSelectedLicense = () => {
    dispatch({
      type: Constants.AppStateActions.DeleteLicense,
      payload: {
        licenseToDeleteUuid,
      },
    });
  };

  const deleteDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.DeleteLicense"),
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => setIsModalVisible(!isModalVisible)}
          color="inherit"
          variant="contained"
          key="cancelAction"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          onClick={() => {
            deleteSelectedLicense();
            setIsModalVisible(!isModalVisible);
          }}
          color="tertiary"
          variant="contained"
          key="delecteLicense"
        >
          {t("General.DeleteLicense")}
        </Button>,
      ],
    },
    api: {
      onClose: () => setIsModalVisible(!isModalVisible),
    },
    state: {
      isOpen: isModalVisible,
      isFullWidth: true,
    },
  };

  const updateLicenseProperty = ({
    value,
    licenseUuid,
    property,
  }: {
    value: string;
    licenseUuid: string;
    property: keyof CoreInterfaces.License;
  }): void => {
    let parsedValue: string | number = value;

    if (
      (property === Constants.LicenseProperties.NumberOfUnits ||
        property === Constants.LicenseProperties.PricePerMonth) &&
      value !== "" &&
      value !== null
    ) {
      parsedValue = Number(value);
    }

    dispatch({
      type: Constants.AppStateActions.UpdateLicenseValue,
      payload: {
        question,
        licenseUuid,
        property,
        value: parsedValue,
      },
    });
  };
  return (
    <>
      {isModalVisible && (
        <GtDialog gtDialogDTO={deleteDialogDTO}>
          <article> {t("General.DeleteLicenseQuestion")}</article>
        </GtDialog>
      )}
      <FormGroup className="gt-multipleEntriesInputContainer">
        {licenses.map((license, index) => (
          <div
            key={`license${license.Uuid}`}
            className="gt-multipleEntriesInputContainer__rows"
          >
            <GtLicenseDebouncedInput
              value={license.LicenseName}
              clasName="gt-multipleEntriesInputContainer__rows-row"
              formControlLabel={t(
                `General.${Constants.LicenseProperties.LicenseName}`
              )}
              onChange={(sanitizedValue: string) =>
                updateLicenseProperty({
                  value: sanitizedValue,
                  licenseUuid: license.Uuid,
                  property: Constants.LicenseProperties.LicenseName,
                })
              }
              updateTimestamp={lastVersionChangedDate}
              maxLength={40}
              isDisabled={isDisabled}
            />
            <div className="gt-multipleEntriesInputContainer__rows-row">
              <div className="gt-multipleEntriesInputContainer__rows-row-currency">
                <GtLicenseDebouncedInput
                  value={license.PricePerMonth}
                  formControlLabel={t(
                    `General.${Constants.HelpfulConstants.PricePerMonth}`
                  )}
                  onChange={(sanitizedValue: string) =>
                    updateLicenseProperty({
                      value: sanitizedValue,
                      licenseUuid: license.Uuid,
                      property: Constants.LicenseProperties.PricePerMonth,
                    })
                  }
                  sanitizationFn={sanitizedLicenseValue}
                  updateTimestamp={lastVersionChangedDate}
                  maxLength={5}
                  isDisabled={isDisabled}
                />
                {Constants.Currency.kr}
              </div>
              <GtLicenseDebouncedInput
                value={license.NumberOfUnits}
                formControlLabel={t(
                  `General.${Constants.LicenseProperties.NumberOfUnits}`
                )}
                onChange={(sanitizedValue: string) =>
                  updateLicenseProperty({
                    value: sanitizedValue,
                    licenseUuid: license.Uuid,
                    property: Constants.LicenseProperties.NumberOfUnits,
                  })
                }
                sanitizationFn={sanitizedLicenseValue}
                updateTimestamp={lastVersionChangedDate}
                maxLength={5}
                isDisabled={isDisabled}
              ></GtLicenseDebouncedInput>
            </div>
            {(index > 0 || (index === 0 && licenses.length > 1)) &&
              !isFinalOrOutdatedVersion && (
                <Button
                  color="tertiary"
                  className="gt-multipleEntriesInputContainerButton gt-multipleEntriesInputContainerButton--delete"
                  onClick={() => {
                    setIsModalVisible(!isModalVisible);
                    setLicenseToDeleteUuid(license.Uuid);
                  }}
                >
                  {t("General.DeleteLicense")}
                </Button>
              )}
          </div>
        ))}

        <Button
          className={`gt-multipleEntriesInputContainerButton 
          ${
            licenses.length <= 9 && !isFinalOrOutdatedVersion
              ? " gt-multipleEntriesInputContainerButton--add"
              : " gt-multipleEntriesInputContainerButton--disabled"
          }
        }`}
          onClick={addLicense}
        >
          {t("General.AddLicenses")}
        </Button>
      </FormGroup>
    </>
  );
}

export default QuestionLicenses;
