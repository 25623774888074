import {
  debounce,
  getFrequencyMultiplicator,
  getServiceVariable,
  getTaskCalculationVariable,
} from "src/utils";
import { TextField } from "@mui/material";

import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import * as Constants from "./../../core/Constants";
import { CorePropsInterfaces } from "src/core/Models";

export default function GtEstimatedTimeInput({
  params,
  taskChangeFn,
  updateTimestamp,
}: CorePropsInterfaces.GtEstimatedTimeInputProps): JSX.Element {
  const inputRef = useRef(null);
  const isTypingActive = useRef(false);

  useEffect(() => {
    inputRef.current.value = params.row.estimatedTimePerUnit;
  }, [updateTimestamp]);

  const multiplicator = useMemo(() => {
    const multiplicator = getFrequencyMultiplicator(
      params.row.taskDTO,
      params.row.serviceDTO
    );
    if (
      params.row.taskDTO.data.calculationMethod ===
      Constants.TaskCalculationMethod.FrequencyXAmountXTime
    ) {
      const amountVariable = getTaskCalculationVariable(
        params.row.serviceDTO,
        params.row.taskDTO
      );
      return Math.round(multiplicator * Number(amountVariable));
    } else {
      return Math.round(multiplicator * 100) / 100;
    }
  }, [params.row.serviceDTO.data.calculationMethod]);

  const debouncedChange = debounce((value: string) => {
    if (params.row.taskDTO.data.adjustments.estimatedTimePerUnit !== value) {
      taskChangeFn(params.row.taskDTO, "estimatedTimePerUnit", value);
      isTypingActive.current = false;
    }
  }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS);

  function onInputValueChangeDelayed(evt: ChangeEvent<HTMLInputElement>): void {
    const decimalPointPosition = evt.target.value.indexOf(".");
    let sanitizedValue = evt.target.value
      .replace(/[^0-9]/g, "")
      .substring(0, 3);
    if (decimalPointPosition > 0 && decimalPointPosition <= 2) {
      const sanitizedValueChars = sanitizedValue.split("");
      let decimalSanitizedValue = "";
      for (let i = 0; i < sanitizedValueChars.length + 1; i++) {
        if (decimalPointPosition === i) {
          decimalSanitizedValue += `.${
            sanitizedValueChars[i] !== undefined ? sanitizedValueChars[i] : ""
          }`;
        } else if (sanitizedValueChars[i] !== undefined) {
          decimalSanitizedValue += sanitizedValueChars[i];
        }
      }
      sanitizedValue = decimalSanitizedValue;
    }
    inputRef.current.value = sanitizedValue;
    debouncedChange(sanitizedValue);
  }

  const { taskDTO } = params.row;
  const isRevertEnabled =
    !!taskDTO &&
    !!taskDTO.state.isActive &&
    taskDTO.data.estimatedTimePerUnit != null &&
    taskDTO.data.adjustments.estimatedTimePerUnit !== null;

  function revertEstimatedTaskTime(): void {
    if (isRevertEnabled) {
      taskChangeFn(params.row.taskDTO, "estimatedTimePerUnit", null);
      inputRef.current.value =
        params.row.taskDTO.data.estimatedTimePerUnit ?? "";
    }
  }

  const memoizedTimeInput = useMemo(() => {
    return (
      <>
        <span className="gt-dataTableCellWrapper__multiplicator">
          {multiplicator} x
        </span>
        <TextField
          inputRef={inputRef}
          defaultValue={params.row.estimatedTimePerUnit}
          onChange={onInputValueChangeDelayed}
          size="small"
          className="gt-dataTableCellWrapper__textField"
          autoComplete="false"
          disabled={!params.row.taskDTO.state.isActive}
        />
      </>
    );
  }, [params.row.taskDTO.state.isActive]);

  return (
    <>
      {memoizedTimeInput}
      <ReplayRoundedIcon
        onClick={revertEstimatedTaskTime}
        className={`gt-dataTableCellWrapper__revertIcon 
${isRevertEnabled ? "" : "gt-dataTableCellWrapper__revertIcon--disabled"}`}
      />
    </>
  );
}
