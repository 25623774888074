import { t } from "i18next";
import { CorePropsInterfaces } from "src/core/Models";
import CheckBoxOutlinedBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GtDocumentSigningStatusIcon from "./GtDocumentSigningStatusIcon";

function GtManageVersion({
  engagementVersion,
  onSelectedEngagementVersion,
}: CorePropsInterfaces.GtManageVersionProps): JSX.Element {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <article
      className={`gt-versionItem ${
        engagementVersion.state.isSelected
          ? "gt-versionItem--isSelectedState"
          : ""
      } ${
        engagementVersion.state.isDisabled
          ? "gt-versionItem--isDisabledState"
          : ""
      }`}
      onClick={() => onSelectedEngagementVersion(engagementVersion)}
    >
      <section className="gt-versionItem__mainInfo">
        <div className="gt-versionItem__mainInfo-leftSide">
          <div className="gt-versionItem__mainInfo-leftSide-title">
            {t("General.ConfigurationVersionNumber", {
              configurationNo: engagementVersion.data.version,
            })}
          </div>
          <div className="gt-versionItem__mainInfo-leftSide-createdBy">
            {t("General.CreatedBy", {
              name: engagementVersion.data.createdBy,
              datetime: new Date(
                engagementVersion.data.createdDate
              ).toLocaleDateString([], options),
            })}
          </div>
        </div>
        <div className="gt-versionItem__mainInfo-rightSide">
          {engagementVersion.data.status && (
            <>
              <span>
                {`${t("General.Status")}:  ${t(
                  `Options.ConfigurationStatus.${engagementVersion.data.status}`
                )}`}
              </span>
              {engagementVersion.data.signingStatus && (
                <GtDocumentSigningStatusIcon
                  status={engagementVersion.data.signingStatus}
                  signingDate={engagementVersion.data.signingDate}
                  isDarkIcon={true}
                />
              )}
            </>
          )}
        </div>
      </section>
      <section className="gt-versionItem__comment">
        {engagementVersion.data.description}
      </section>

      <section className="gt-versionItem__icon">
        {engagementVersion.state.isSelected && (
          <CheckBoxIcon className="gt-versionItem__icon-checked" />
        )}
        {!engagementVersion.state.isSelected && (
          <CheckBoxOutlinedBlankIcon className="gt-versionItem__icon-circle" />
        )}
      </section>
    </article>
  );
}

export default GtManageVersion;
