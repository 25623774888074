import * as Constants from "../../core/Constants";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { AppContext } from "src/contexts/AppContext";
import { t } from "i18next";
import { useContext } from "react";
import {
  formatMinutesToHoursAndMinutes,
  getPayrollServicePanelTableRows,
  getRecurringServicesStaffLevelUsageMap,
} from "src/utils";
import { CoreInterfaces } from "src/core/Models";

export default function PayrollMonthlyRecurringServicesPanel() {
  const { globalState } = useContext(AppContext);
  const serviceRecurringType = Constants.ServiceRecurringType.Monthly;
  const serviceGroup = Constants.ServiceGroup.Payroll;
  const columns = getColumnsDefinition(serviceRecurringType);
  let rows = getPayrollServicePanelTableRows(globalState);
  const staffLevelRows: Array<CoreInterfaces.PayrollServicesTableRow> = [];
  const staffLevelUsageMap = getRecurringServicesStaffLevelUsageMap(
    globalState,
    serviceGroup,
    null,
    [
      Constants.TaskGroup.MonthlyPayrollRun,
      Constants.TaskGroup.MonthlyPayrollReconciliation,
      Constants.TaskGroup.MonthlyReportingPayroll,
      Constants.TaskGroup.AdditionalPayrollServices,
      Constants.TaskGroup.PayrollServicesInAddition,
    ]
  );
  const staffingHeaderRow: CoreInterfaces.PayrollServicesTableRow = {
    title: t("General.StaffingLevel"),
    hoursPerPeriod: t("TableHeader.HoursMonthly"),
    minutesPerPeriodUnderlineValue: null,
    pricePerPeriod: null,
    pricePerPayslip: null,
    chargePerPayrollRun: null,
    totalPricePerPeriod: null,
    totalPricePerPeriodUnderlineValue: null,
    totalPricePerYear: null,
    totalPricePerYearUnderlineValue: null,
    totalHoursPerYear: null,
    id: `recurringSummaryServicesPanelServiceStaffLevelSectionHeader`,
  };

  staffLevelRows.push(staffingHeaderRow);

  let totalTimeInMinutes = 0;
  for (const staffLevel in staffLevelUsageMap) {
    const staffingRow: CoreInterfaces.PayrollServicesTableRow = {
      title: t(`Options.${staffLevel}`),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(
        staffLevelUsageMap[staffLevel]
      ),
      minutesPerPeriodUnderlineValue: null,
      pricePerPeriod: null,
      pricePerPayslip: null,
      chargePerPayrollRun: null,
      totalPricePerPeriod: null,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: null,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: null,
      id: `staffLevelUsageMapRow_${staffLevel}_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffLevelRows.push(staffingRow);
    totalTimeInMinutes += staffLevelUsageMap[staffLevel];
  }

  if (totalTimeInMinutes > 0) {
    const staffingTotalRow: CoreInterfaces.PayrollServicesTableRow = {
      title: t("General.TotalNumberOfHours"),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(totalTimeInMinutes),
      minutesPerPeriodUnderlineValue: null,
      pricePerPeriod: null,
      pricePerPayslip: null,
      chargePerPayrollRun: null,
      totalPricePerPeriod: null,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: null,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: null,
      id: `staffLevelUsageMapRow_Total_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffLevelRows.push(staffingTotalRow);
    rows = rows.concat(staffLevelRows);
  }

  return (
    !!rows.length && (
      <article
        className="gt-servicePanel"
        data-testid="PayrollMonthlyRecurringServicesPanel"
      >
        <Accordion className="gt-accordion" expanded={true}>
          <AccordionSummary className="gt-accordion__header">
            <div className="gt-accordion__header-container">
              <span className="gt-accordion__header-container-title">
                {t(
                  `PanelHeader.${serviceRecurringType}RecurringServices${serviceGroup}`
                )}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="gt-accordion__content">
            <div className="gt-tasksPanel">
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                rowHeight={44}
                columnHeaderHeight={48}
                hideFooterPagination={true}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </article>
    )
  );
}

function getColumnsDefinition(
  serviceRecurringType: Constants.ServiceRecurringType
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t("TableHeader.Payroll"),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total")
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "Title"}
          >
            {params.row.title}
          </article>
        );
      },
    },
    {
      field: "hoursPerPeriod",
      flex: 1,
      headerName: t(`TableHeader.Hours${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total")
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "HoursPerPeriod"}
          >
            {params.row.hoursPerPeriod}
          </article>
        );
      },
    },
    {
      field: "pricePerPeriod",
      flex: 1,
      headerName: t(`TableHeader.Price${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (
          row.id ===
            "recurringSummaryServicesPanelServiceStaffLevelSectionHeader" ||
          row.id.startsWith("staffLevelUsageMapRow_")
        ) {
          return "gt-noBorderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "PricePerPeriod"}
          >
            {params.row.pricePerPeriod}
          </article>
        );
      },
      colSpan: ({ row }) => {
        if (
          row.id ===
            "recurringSummaryServicesPanelServiceStaffLevelSectionHeader" ||
          row.id.startsWith("staffLevelUsageMapRow_")
        ) {
          return 6;
        }
        return undefined;
      },
    },
    {
      field: "pricePerPayslip",
      flex: 1,
      headerName: t(`TableHeader.MonthlyPricePerPayslip`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "PricePerPayslip"}
          >
            {params.row.pricePerPayslip}
          </article>
        );
      },
    },
    {
      field: "chargePerPayrollRun",
      flex: 1,
      headerName: t(`TableHeader.ChargePerPayrollRun`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "ChargePerPayrollRun"}
          >
            {params.row.chargePerPayrollRun}
          </article>
        );
      },
    },
    {
      field: "totalPricePerPeriod",
      flex: 1,
      headerName: t(`TableHeader.TotalPrice${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "TotalPricePerPeriod"}
          >
            {params.row.totalPricePerPeriod}
          </article>
        );
      },
    },
    {
      field: "totalPricePerYear",
      flex: 1,
      headerName: t("TableHeader.TotalPriceYearly"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "TotalPricePerYear"}
          >
            {params.row.totalPricePerYear}
          </article>
        );
      },
    },
    {
      field: "totalHoursPerYear",
      headerName: t("TableHeader.TotalHoursYearly"),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.id + "TotalHoursPerYear"}
          >
            {params.row.totalHoursPerYear}
          </article>
        );
      },
    },
  ];
  return columnDefinition;
}
