import QuestionTextInput from "../Inputs/QuestionTextInput";
import QuestionSelectInput from "../Inputs/QuestionSelectInput";
import QuestionNumberInput from "../Inputs/QuestionNumberInput";
import QuestionYesNoInput from "../Inputs/QuestionYesNoInput";
import QuestionReadonlyInput from "../Inputs/QuestionReadonlyInput";
import QuestionMultilineInput from "../Inputs/QuestionMultilineInput";
import { CorePropsInterfaces } from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import QuestionCurrencyInput from "../Inputs/QuestionCurrencyInput";
import QuestionDateFieldInput from "../Inputs/QuestionDateFieldInput";
import QuestionCheckBoxInput from "../Inputs/QuestionCheckboxInput";
import QuestionAutocompleteInput from "../Inputs/QuestionAutocompleteInput";
import AddionalCostQuestion from "../Inputs/QuestionLicenses";
import QuestionDateRangeInput from "../Inputs/QuestionDateRangeInput";
import QuestionMultipleSelectInput from "../Inputs/QuestionMultipleSelectInput";
import QuestionContactPersonUserDetails from "../Inputs/QuestionContactPersonUserDetails";
import QuestionRangeNumberInput from "../Inputs/QuestionRangeNumberInput";

function QuestionInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionRowProps) {
  let questionControl = null;

  switch (question.data.inputType) {
    case Constants.QuestionInputType.Text:
      questionControl = (
        <QuestionTextInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Select:
      questionControl = (
        <QuestionSelectInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Autocomplete:
      questionControl = (
        <QuestionAutocompleteInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Numeric:
      questionControl = (
        <QuestionNumberInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Currency:
      questionControl = (
        <QuestionCurrencyInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.YesNo:
      questionControl = (
        <QuestionYesNoInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.RangeNumber:
      questionControl = (
        <QuestionRangeNumberInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Readonly:
      questionControl = <QuestionReadonlyInput question={question} />;
      break;
    case Constants.QuestionInputType.Multiline:
      questionControl = (
        <QuestionMultilineInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Date:
      questionControl = (
        <QuestionDateFieldInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.FullDate:
      questionControl = (
        <QuestionDateRangeInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.Headline:
      questionControl = <div></div>;
      break;
    case Constants.QuestionInputType.Checkbox:
      questionControl = (
        <QuestionCheckBoxInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.License:
      questionControl = (
        <AddionalCostQuestion
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.ContactPersonUserDetails:
      questionControl = (
        <QuestionContactPersonUserDetails
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    case Constants.QuestionInputType.MultipleSelect:
      questionControl = (
        <QuestionMultipleSelectInput
          question={question}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      );
      break;
    default:
      questionControl = <div></div>;
      break;
  }

  return questionControl;
}

export default QuestionInput;
