import * as Constants from "../../core/Constants";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  formatMinutesToHoursAndMinutes,
  getRecurringServicePanelTableRows,
  getRecurringServicesStaffLevelUsageMap,
} from "src/utils";

import { AppContext } from "src/contexts/AppContext";
import { CoreInterfaces, CorePropsInterfaces } from "src/core/Models";
import { t } from "i18next";
import { useContext } from "react";

export default function AccountingRecurringServicesPanel({
  serviceRecurringType,
}: CorePropsInterfaces.RecurringServicesPanelProps) {
  const { globalState } = useContext(AppContext);
  const columns = getColumnsDefinition(serviceRecurringType);

  let rows: Array<CoreInterfaces.RecurringServicesTableRow> =
    getRecurringServicePanelTableRows(globalState, serviceRecurringType);

  const staffingHeaderRow: CoreInterfaces.RecurringServicesTableRow = {
    title: t("General.StaffingLevel"),
    hoursPerPeriod: t(`TableHeader.Hours${serviceRecurringType}`),
    workTimePricePerPeriod: null,
    minutesPerPeriodUnderlineValue: null,
    softwareServicesPerPeriod: null,
    totalPricePerPeriod: null,
    totalPricePerPeriodUnderlineValue: null,
    totalPricePerYear: null,
    totalPricePerYearUnderlineValue: null,
    totalHoursPerYear: null,
    id: `recurringSummaryServicesPanelServiceStaffLevelSectionHeader`,
  };

  const staffLevelUsageMap = getRecurringServicesStaffLevelUsageMap(
    globalState,
    Constants.ServiceGroup.Accounting,
    serviceRecurringType,
    [Constants.TaskGroup.DefaultGroup]
  );

  const staffingRows = [];
  let totalTimeInMinutes = 0;
  for (const staffLevel in staffLevelUsageMap) {
    const staffingRow: CoreInterfaces.RecurringServicesTableRow = {
      title: t(`Options.${staffLevel}`),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(
        staffLevelUsageMap[staffLevel]
      ),
      workTimePricePerPeriod: null,
      minutesPerPeriodUnderlineValue: null,
      softwareServicesPerPeriod: null,
      totalPricePerPeriod: null,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: null,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: null,
      id: `staffLevelUsageMapRow_${staffLevel}_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffingRows.push(staffingRow);
    totalTimeInMinutes += staffLevelUsageMap[staffLevel];
  }

  if (totalTimeInMinutes > 0) {
    const staffingTotalRow: CoreInterfaces.RecurringServicesTableRow = {
      title: t("General.TotalNumberOfHours"),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(totalTimeInMinutes),
      workTimePricePerPeriod: null,
      minutesPerPeriodUnderlineValue: null,
      softwareServicesPerPeriod: null,
      totalPricePerPeriod: null,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: null,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: null,
      id: `staffLevelUsageMapRow_Total_${Constants.TaskGroup.DefaultGroup}`,
    };
    staffingRows.push(staffingTotalRow);
  }

  if (staffingRows.length) {
    rows.push(staffingHeaderRow);
    rows = rows.concat(staffingRows);
  }

  return (
    !!rows.length && (
      <article
        className="gt-servicePanel"
        data-testid={`AccountingRecurringServicesPanel${serviceRecurringType}`}
      >
        <Accordion className="gt-accordion" expanded={true}>
          <AccordionSummary className="gt-accordion__header">
            <div className="gt-accordion__header-container">
              <span className="gt-accordion__header-container-title">
                {t(`PanelHeader.${serviceRecurringType}RecurringServices`)}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="gt-accordion__content">
            <div className="gt-tasksPanel">
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                rowHeight={44}
                columnHeaderHeight={48}
                hideFooterPagination={true}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </article>
    )
  );
}

function getColumnsDefinition(
  serviceRecurringType: Constants.ServiceRecurringType
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t("TableHeader.Accounting"),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        const isHighlightedCell =
          row.id === Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total");
        return isHighlightedCell ? "gt-tasksPanel__highlightedCell" : "";
      },
      renderCell(params) {
        return (
          <article
            data-testid={params.row.id + "Title"}
            className="gt-dataTableCellWrapper"
          >
            {params.row.title}
          </article>
        );
      },
    },
    {
      field: "hoursPerPeriod",
      flex: 1,
      headerName: t(`TableHeader.Hours${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        if (
          row.id ===
          "recurringSummaryServicesPanelServiceStaffLevelSectionHeader"
        ) {
          return "gt-tasksPanel__tableHeaderCell";
        }
        const isHighlightedCell =
          row.id === Constants.CustomDataGridColumnId.RecurringServicesTotal ||
          row.id.startsWith("staffLevelUsageMapRow_Total");
        return isHighlightedCell ? "gt-tasksPanel__highlightedCell" : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "HoursPerPeriod"}>
            {params.row.hoursPerPeriod}
          </article>
        );
      },
    },
    {
      field: "workTimePricePerPeriod",
      flex: 1,
      headerName: t(`TableHeader.WorkTimePrice${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (
          row.id ===
            "recurringSummaryServicesPanelServiceStaffLevelSectionHeader" ||
          row.id.startsWith("staffLevelUsageMapRow_")
        ) {
          return "gt-noBorderCell";
        }
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "WorkTimePricePerPeriod"}>
            {params.row.workTimePricePerPeriod}
          </article>
        );
      },
      colSpan: ({ row }) => {
        if (
          row.id ===
            "recurringSummaryServicesPanelServiceStaffLevelSectionHeader" ||
          row.id.startsWith("staffLevelUsageMapRow_")
        ) {
          return 5;
        }
        return undefined;
      },
    },
    {
      field: "softwareServicesPerPeriod",
      flex: 1,
      headerName: t(`TableHeader.SoftwareServices${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "SoftwareServicesPerPeriod"}>
            {params.row.softwareServicesPerPeriod}
          </article>
        );
      },
    },
    {
      field: "totalPricePerPeriod",
      flex: 1,
      headerName: t(`TableHeader.TotalPrice${serviceRecurringType}`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "TotalPricePerPeriod"}>
            {params.row.totalPricePerPeriod}
          </article>
        );
      },
    },
    {
      field: "totalPricePerYear",
      flex: 1,
      headerName: t("TableHeader.TotalPriceYearly"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "TotalPricePerYear"}>
            {params.row.totalPricePerYear}
          </article>
        );
      },
    },
    {
      field: "totalHoursPerYear",
      headerName: t("TableHeader.HoursYearly"),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.RecurringServicesTotal
          ? "gt-tasksPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={params.row.id + "TotalHoursPerYear"}>
            {params.row.totalHoursPerYear}
          </article>
        );
      },
    },
  ];
  return columnDefinition;
}
