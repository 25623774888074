import { CorePropsInterfaces } from "src/core/Models";
import AdditionalCostsPanel from "./AdditionalCostsPanel";
import GtScrollElement from "../Common/GtScrollElement";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import GtExpandMoreIcon from "../Common/GtExpandMoreIcon";

export default function ServiceCostSummaryPanel({
  serviceDTO,
  onExpandChange,
  children,
}: CorePropsInterfaces.ServiceTasksPanelProps): JSX.Element {
  const { t } = useTranslation();

  if (!serviceDTO || !serviceDTO.state.isSelected) {
    return null;
  }

  return (
    <article className="gt-servicePanel">
      <GtScrollElement service={serviceDTO} />
      <Accordion
        className="gt-accordion"
        onChange={() => {
          onExpandChange();
        }}
        expanded={serviceDTO.state.isExpandedPriceAndTimeCalculationAccordion}
      >
        <AccordionSummary
          className="gt-accordion__header"
          expandIcon={<GtExpandMoreIcon isDarkColor={false} />}
        >
          <div className="gt-accordion__header-container">
            <span className="gt-accordion__header-container-title">
              {t(serviceDTO.data.calculationPage.calculationPanelTitle)}
            </span>
            <span className="gt-accordion__header-container-expandedTextWithoutIcon">
              {serviceDTO.state.isExpandedPriceAndTimeCalculationAccordion
                ? t("General.Collapse")
                : t("General.Expand")}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="gt-accordion__content">
          <AdditionalCostsPanel serviceDTO={serviceDTO} />
          {children && children}
        </AccordionDetails>
      </Accordion>
    </article>
  );
}
