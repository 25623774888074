import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@mui/material";
import { CoreInterfaces } from "src/core/Models";
import EngagementLettersSummaryViewPanel from "src/components/Panels/EngagementLettersSummaryViewPanel";
import GtBackArrow from "src/components/Common/GtBackArrow";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function EngagementLettersSummaryViewPage(): JSX.Element {
  const { t } = useTranslation();
  const initialPageState: CoreInterfaces.PageState = {
    title: "Pages.EngagementLettersSummaryViewPage.Title",
  };
  const [pageState] = useState<CoreInterfaces.PageState>(initialPageState);

  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  return (
    <article className="gt-engagementLettersSummaryViewPage">
      <GtBackArrow></GtBackArrow>
      <div className="gt-pageTopPanel">
        <Typography
          className="gt-pageTopPanel__title"
          variant="h5"
          gutterBottom={true}
        >
          <span>{t(pageState.title)}</span>
          <Tooltip title={t("Pages.EngagementLettersSummaryViewPage.Tooltip")}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Typography>
      </div>
      <EngagementLettersSummaryViewPanel></EngagementLettersSummaryViewPanel>
    </article>
  );
}
export default EngagementLettersSummaryViewPage;
