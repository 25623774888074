import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button } from "@mui/material";

function GtImportWarningDialog({
  onImportWarningDialogClose,
  onImportWarningDialogConfirm,
}: CorePropsInterfaces.GtImportWarningDialogProps): JSX.Element {
  const importWarningDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.Warning"),
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => onImportWarningDialogClose()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={() => onImportWarningDialogConfirm()}
        >
          {t("General.Continue")}
        </Button>,
      ],
    },
    api: {
      onClose: onImportWarningDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  return (
    <GtDialog gtDialogDTO={importWarningDialogDTO}>
      <article className="gt-importWarningDialog">
        <section className="gt-importWarningDialog__label">
          <WarningAmberIcon
            fontSize="medium"
            className="gt-importWarningDialog__label-icon"
          />
          {t("General.ImportWarningTitle")}
        </section>
        <section className="gt-importWarningDialog__value">
          {t("General.ImportWarningMessage")}
        </section>
      </article>
    </GtDialog>
  );
}

export default GtImportWarningDialog;
