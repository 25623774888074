import { CoreInterfaces, DTOs } from "./../Models";
import * as StateProcessors from "./state-processors";
import * as Constants from "../Constants/Constants";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import {
  arrayMove,
  buildNextState,
  createEmptyContactPerson,
  createEmptyLicense,
  findQuestionInService,
  handleAnnualPayrollRoutinesState,
  handleAnnualReportingListedCompaniesState,
  handleAnnualReportingState,
  handleEngagementCounselingState,
  handleEngagementMetadataResetWhenConfigurationChange,
  handleInterimListedCompaniesState,
  handleValidFromDateChange,
  handleYearlyServiceState,
  isContactPersonDataFilled,
  isLicenseDataFilled,
  setEngagementConfigurationOwner,
  applyChangesTimestampOnNextState,
  checkContactPersonUserDetailsFilled,
  handleAuditServices,
} from "src/utils";

export function appReducer(
  state: CoreInterfaces.AppState,
  action: { type: Constants.AppStateActions; payload?: any }
): CoreInterfaces.AppState {
  switch (action.type) {
    case Constants.AppStateActions.UpdateAadUserInfo: {
      const authProviderUserDetails = action.payload
        .authProviderUserDetails as CoreInterfaces.AuthProviderUserDetails;
      const nextState = {
        ...state,
        authProviderUserDetails,
      };
      return nextState;
    }
    case Constants.AppStateActions.UpdateStateWithSavedConfiguration: {
      const actionPayload = action.payload as Partial<CoreInterfaces.AppState>;
      const nextState = {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          ...actionPayload.currentConfiguration,
        },
        remoteData: {
          ...state.remoteData,
          ...actionPayload.remoteData,
        },
      };
      if (!!actionPayload.services) {
        const services = actionPayload.services.map((service) => {
          applyUpdatesOnQuestionChange(state, service);
          return service;
        });
        const generalInformation = services.find(
          (service) =>
            service.data.code === Constants.ServiceCode.GeneralInformation
        );
        const generalVariables =
          StateProcessors.createGeneralVariablesObject(generalInformation);

        generalInformation.data.questions.forEach((eachGeneralQuestion) => {
          StateProcessors.updateServicesOnGeneralQuestionChange(
            services,
            eachGeneralQuestion,
            generalVariables
          );
        });

        handleAnnualReportingListedCompaniesState(services);
        handleInterimListedCompaniesState(services);
        handleAnnualReportingState(services);
        handleEngagementCounselingState(services);
        handleAuditServices(services);

        nextState.generalVariables = generalVariables;
        nextState.services = services.map((service) => {
          applyUpdatesOnQuestionChange(
            {
              ...nextState,
              services,
            },
            service
          );

          return service;
        });
      }

      return nextState;
    }
    case Constants.AppStateActions.UpdateStateWithImportedConfiguration: {
      const actionPayload = action.payload as Partial<CoreInterfaces.AppState>;

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: actionPayload.services,
        currentConfiguration: {
          ...state.currentConfiguration,
          ...actionPayload.currentConfiguration,
        },
        remoteData: {
          ...state.remoteData,
          ...actionPayload.remoteData,
        },
        unsavedChangesTimestamp: null,
      };

      if (!!actionPayload.services) {
        const generalInformation = actionPayload.services.find(
          (service) =>
            service.data.code === Constants.ServiceCode.GeneralInformation
        );
        const generalVariables =
          StateProcessors.createGeneralVariablesObject(generalInformation);
        nextState.generalVariables = generalVariables;

        const services = actionPayload.services.map((service) => {
          applyUpdatesOnQuestionChange(nextState, service);
          return service;
        });

        generalInformation.data.questions.forEach((eachGeneralQuestion) => {
          StateProcessors.updateServicesOnGeneralQuestionChange(
            services,
            eachGeneralQuestion,
            generalVariables
          );
        });

        handleAnnualReportingListedCompaniesState(services);
        handleInterimListedCompaniesState(services);
        handleAnnualReportingState(services);
        handleEngagementCounselingState(services);
        handleAuditServices(services);

        nextState.generalVariables = generalVariables;
        nextState.services = services.map((service) => {
          applyUpdatesOnQuestionChange(
            {
              ...nextState,
              services,
            },
            service
          );

          return service;
        });
      }

      return nextState;
    }
    case Constants.AppStateActions.UpdateRateCartsConfiguration: {
      const actionPayload =
        action.payload as CoreInterfaces.RateCartConfiguration;
      const nextState: CoreInterfaces.AppState = {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          applicationConfiguration: {
            ...state.currentConfiguration.applicationConfiguration,
            rateCartConfiguration: actionPayload,
          },
        },
      };
      return nextState;
    }
    case Constants.AppStateActions.UpdateRemoteData: {
      const actionPayload = action.payload as Partial<CoreInterfaces.AppState>;
      const services = state.services.map((service) => {
        applyUpdatesOnQuestionChange(
          {
            ...state,
            remoteData: {
              ...state.remoteData,
              ...actionPayload.remoteData,
            },
          },
          service
        );

        return service;
      });
      const generalInformation = services.find(
        (service) =>
          (service.data.code = Constants.ServiceCode.GeneralInformation)
      );
      const generalVariables =
        StateProcessors.createGeneralVariablesObject(generalInformation);
      const nextState = {
        ...state,
        generalVariables,
        services,
        remoteData: {
          ...state.remoteData,
          ...actionPayload.remoteData,
        },
      };
      return nextState;
    }
    case Constants.AppStateActions.HideSystemAdministrationMessage: {
      const systemAdministrationMessageId = action.payload as string;

      const systemAdministrationMessages =
        state.remoteData.systemAdministrationMessages.map((item) => {
          if (item.id === systemAdministrationMessageId) {
            item.state.isShown = false;
          }

          return item;
        });

      const nextState = {
        ...state,
        remoteData: {
          ...state.remoteData,
          systemAdministrationMessages,
        },
      };
      return nextState;
    }
    case Constants.AppStateActions.UpdateApplicationConfiguration: {
      const applicationConfigurationReadPack =
        action.payload as CoreInterfaces.ApplicationConfigurationReadPack;
      const nextServices =
        StateProcessors.updateServicesOnApplicationConfigurationChange(
          state.services,
          applicationConfigurationReadPack
        );
      const applicationConfigurationPart: CoreInterfaces.ApplicationConfiguration =
        {
          rateCartConfiguration:
            applicationConfigurationReadPack.RateCartConfiguration,
          taskAllocationConfigurationVersion:
            applicationConfigurationReadPack.TaskAllocationConfiguration
              .Version,
          taskAllocationConfigurationDescription:
            applicationConfigurationReadPack.TaskAllocationConfiguration
              .Description,
          taskCostConfigurationVersion:
            applicationConfigurationReadPack.TaskCostConfiguration.Version,
          taskCostConfigurationDescription:
            applicationConfigurationReadPack.TaskCostConfiguration.Description,
        };

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
        currentConfiguration: {
          ...state.currentConfiguration,
          applicationConfiguration: applicationConfigurationPart,
        },
      };
      if (
        !!applicationConfigurationReadPack.TaskAllocationConfiguration
          .TaskAllocations
      ) {
        nextState.taskAllocations =
          applicationConfigurationReadPack.TaskAllocationConfiguration.TaskAllocations;
      }
      if (!!applicationConfigurationReadPack.TaskCostConfiguration.TaskCosts) {
        nextState.taskCosts =
          applicationConfigurationReadPack.TaskCostConfiguration.TaskCosts;
      }
      return nextState;
    }
    case Constants.AppStateActions.ServicesOverride: {
      const nextServices = action.payload as Array<DTOs.ServiceDTO>;
      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
      };

      applyChangesTimestampOnNextState(nextState);

      return nextState;
    }
    case Constants.AppStateActions.UpdateServiceStaffingAdjustmentCost: {
      const { serviceCode, adjustmentCost } = action.payload as {
        serviceCode: Constants.ServiceCode;
        adjustmentCost: number;
      };
      const nextServices = state.services.map((eachService) => {
        if (eachService.data.code === serviceCode) {
          const updatedService = {
            ...eachService,
            data: {
              ...eachService.data,
              staffingAdjustments: {
                ...eachService.data.staffingAdjustments,
                cost: adjustmentCost,
              },
            },
          };
          return updatedService;
        } else {
          return eachService;
        }
      });

      handleYearlyServiceState(nextServices);
      handleAnnualPayrollRoutinesState(nextServices);
      handleAnnualReportingListedCompaniesState(nextServices);
      handleInterimListedCompaniesState(nextServices);
      handleAnnualReportingState(nextServices);
      handleEngagementCounselingState(nextServices);

      handleAuditServices(nextServices);

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
      };

      applyChangesTimestampOnNextState(nextState);

      // TODO to improve that
      [
        Constants.ServiceCode.GeneralInformation,
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.AnnualReporting,
        Constants.ServiceCode.OtherAccountAndReconciliation,
      ].forEach((serviceCode) => {
        applyUpdatesOnQuestionChange(
          nextState,
          state.services.find(
            (eachService) => eachService.data.code === serviceCode
          )
        );
      });

      return nextState;
    }
    case Constants.AppStateActions
      .UpdateServiceStaffingAdjustmentExtendedCost: {
      const { serviceCode, extendedPriceListPanelCost } = action.payload as {
        serviceCode: Constants.ServiceCode;
        extendedPriceListPanelCost: number;
      };
      const nextServices = state.services.map((eachService) => {
        if (eachService.data.code === serviceCode) {
          const updatedService = {
            ...eachService,
            data: {
              ...eachService.data,
              staffingAdjustments: {
                ...eachService.data.staffingAdjustments,
                extendedPriceListPanelCost: extendedPriceListPanelCost,
              },
            },
          };
          return updatedService;
        } else {
          return eachService;
        }
      });

      handleYearlyServiceState(nextServices);
      handleAnnualPayrollRoutinesState(nextServices);
      handleAnnualReportingListedCompaniesState(nextServices);
      handleInterimListedCompaniesState(nextServices);
      handleAnnualReportingState(nextServices);
      handleEngagementCounselingState(nextServices);

      handleAuditServices(nextServices);

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
      };

      applyChangesTimestampOnNextState(nextState);

      // TODO to improve that
      [
        Constants.ServiceCode.GeneralInformation,
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.AnnualReporting,
        Constants.ServiceCode.OtherAccountAndReconciliation,
      ].forEach((serviceCode) => {
        applyUpdatesOnQuestionChange(
          nextState,
          state.services.find(
            (eachService) => eachService.data.code === serviceCode
          )
        );
      });

      return nextState;
    }
    case Constants.AppStateActions.ServiceUpdated: {
      const nextService = action.payload as DTOs.ServiceDTO;
      const nextServices = state.services.map((eachService) => {
        if (eachService.data.code === nextService.data.code) {
          return nextService;
        } else {
          return eachService;
        }
      });
      const previousState = structuredClone(state);

      handleYearlyServiceState(nextServices);
      handleAnnualPayrollRoutinesState(nextServices);
      handleAnnualReportingListedCompaniesState(nextServices);
      handleInterimListedCompaniesState(nextServices);
      handleAnnualReportingState(nextServices);
      handleEngagementCounselingState(nextServices);

      handleAuditServices(nextServices);

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
      };

      applyChangesTimestampOnNextState(nextState);

      // TODO to improve that
      [
        Constants.ServiceCode.GeneralInformation,
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.AnnualReporting,
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
      ].forEach((serviceCode) => {
        applyUpdatesOnQuestionChange(
          nextState,
          state.services.find(
            (eachService) => eachService.data.code === serviceCode
          )
        );
      });
      const intermediateState = handleValidFromDateChange(nextState);
      handleEngagementMetadataResetWhenConfigurationChange(
        previousState,
        intermediateState
      );
      return intermediateState;
    }
    case Constants.AppStateActions.ServiceExpandChange: {
      const nextService = action.payload as DTOs.ServiceDTO;

      const nextServices = state.services.map((eachService) => {
        if (eachService.data.code === nextService.data.code) {
          return nextService;
        } else {
          return eachService;
        }
      });

      const nextState: CoreInterfaces.AppState = {
        ...state,
        services: nextServices,
      };

      return nextState;
    }
    case Constants.AppStateActions.ServiceAdditionalCostAdjustementChange: {
      const { serviceCode, additionalCostCode, costValue } =
        action.payload as CoreInterfaces.ServiceAdditionalCostAdjustement;
      const serviceToBeUpdated = state.services.find(
        (serviceDTO) => serviceDTO.data.code === serviceCode
      );
      if (serviceToBeUpdated) {
        const updatedService = {
          ...serviceToBeUpdated,
          data: {
            ...serviceToBeUpdated.data,
            additionalCosts: serviceToBeUpdated.data.additionalCosts.map(
              (additionalCostDTO) => {
                if (additionalCostDTO.data.code === additionalCostCode) {
                  const updatedTask = {
                    ...additionalCostDTO,
                    data: {
                      ...additionalCostDTO.data,
                      adjustments: {
                        ...additionalCostDTO.data.adjustments,
                        Cost: Number(costValue),
                      },
                    },
                  };
                  return updatedTask;
                } else {
                  return additionalCostDTO;
                }
              }
            ),
          },
        };

        const nextstate = {
          ...state,
          services: state.services.map((eachService) => {
            if (eachService.data.code === updatedService.data.code) {
              return updatedService;
            }
            return eachService;
          }),
        };
        return nextstate;
      }
      return state;
    }
    case Constants.AppStateActions.QuestionUpdated: {
      const serviceDTO = action.payload.serviceDTO as DTOs.ServiceDTO;
      const modifiedQuestion = action.payload.questionDTO as DTOs.QuestionDTO;
      const previousState = structuredClone(state);

      let generalVariables = state.generalVariables;
      if (serviceDTO.data.code === Constants.ServiceCode.GeneralInformation) {
        generalVariables = StateProcessors.createGeneralVariablesObject(
          serviceDTO,
          modifiedQuestion
        );
      }
      const intermediateState = {
        ...state,
        generalVariables,
      };

      const processedServices =
        StateProcessors.updateServicesBasedOnQuestionUpdate(
          intermediateState,
          serviceDTO,
          modifiedQuestion
        );

      handleAnnualReportingState(processedServices);

      let nextState: CoreInterfaces.AppState = {
        ...intermediateState,
        services: processedServices,
      };

      applyChangesTimestampOnNextState(nextState);

      if (
        modifiedQuestion.data.code ===
          Constants.GeneralInformationQuestion.Q0003 ||
        modifiedQuestion.data.code ===
          Constants.GeneralInformationQuestion.QA0006
      ) {
        setEngagementConfigurationOwner(nextState, modifiedQuestion);
      }

      nextState = handleValidFromDateChange(nextState);
      handleEngagementMetadataResetWhenConfigurationChange(
        previousState,
        nextState
      );

      return nextState;
    }
    case Constants.AppStateActions.ChangeUnsavedFlag: {
      const nextState: CoreInterfaces.AppState = {
        ...state,
        areUnsavedChanges: false,
      };
      return nextState;
    }
    case Constants.AppStateActions.UpdateHiddenFromImportingState: {
      const { status } = action.payload;
      const nextState = {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          isHiddenFromImporting: status,
        },
      };
      return nextState;
    }
    case Constants.AppStateActions.TaskUpdated: {
      const serviceDTO = action.payload.serviceDTO as DTOs.ServiceDTO;
      const taskDTO = action.payload.taskDTO as DTOs.TaskDTO;
      const nextState = {
        ...state,
        services: state.services.map((eachService) => {
          if (eachService.data.code === serviceDTO.data.code) {
            const nextService = {
              ...eachService,
              data: {
                ...eachService.data,
                tasks: eachService.data.tasks.map((eachTask) => {
                  if (eachTask.data.code === taskDTO.data.code) {
                    return taskDTO;
                  } else {
                    return eachTask;
                  }
                }),
              },
            };
            return nextService;
          } else {
            return eachService;
          }
        }),
      };
      applyChangesTimestampOnNextState(nextState);
      return nextState;
    }
    case Constants.AppStateActions.TaskAdd: {
      const serviceDTO = action.payload.serviceDTO as DTOs.ServiceDTO;
      const taskDTO = action.payload.taskDTO as DTOs.TaskDTO;
      const nextState = {
        ...state,
        services: state.services.map((eachService) => {
          if (eachService.data.code === serviceDTO.data.code) {
            const nextService = {
              ...eachService,
              data: {
                ...eachService.data,
                tasks: [...eachService.data.tasks, taskDTO],
              },
            };
            return nextService;
          } else {
            return eachService;
          }
        }),
      };

      applyChangesTimestampOnNextState(nextState);

      return nextState;
    }
    case Constants.AppStateActions.TaskOrderChange: {
      const serviceDTO = action.payload.serviceDTO as DTOs.ServiceDTO;
      const taskDTO = action.payload.taskDTO as DTOs.TaskDTO;
      const previousTargetTaskCode = action.payload.previousTargetTaskCode;
      const nextState = {
        ...state,
        services: state.services.map((eachService) => {
          if (eachService.data.code === serviceDTO.data.code) {
            const oldIndex = eachService.data.tasks.findIndex(
              (task) => task.data.code === taskDTO.data.code
            );
            let newIndex = previousTargetTaskCode
              ? eachService.data.tasks.findIndex(
                  (task) => task.data.code === previousTargetTaskCode
                )
              : 0;
            if (previousTargetTaskCode && newIndex < oldIndex) {
              newIndex++;
            }

            const nextService = {
              ...eachService,
              data: {
                ...eachService.data,
                tasks: arrayMove(eachService.data.tasks, oldIndex, newIndex),
              },
            };
            return nextService;
          } else {
            return eachService;
          }
        }),
      };
      applyChangesTimestampOnNextState(nextState);

      return nextState;
    }
    case Constants.AppStateActions.TaskDelete: {
      const serviceDTO = action.payload.serviceDTO as DTOs.ServiceDTO;
      const taskDTO = action.payload.taskDTO as DTOs.TaskDTO;
      const nextState = {
        ...state,
        services: state.services.map((eachService) => {
          if (eachService.data.code === serviceDTO.data.code) {
            const nextService = {
              ...eachService,
              data: {
                ...eachService.data,
                tasks: [
                  ...eachService.data.tasks.filter(
                    (item) => item.data.code !== taskDTO.data.code
                  ),
                ],
              },
            };
            return nextService;
          } else {
            return eachService;
          }
        }),
      };
      applyChangesTimestampOnNextState(nextState);
      return nextState;
    }
    case Constants.AppStateActions.UpdateApplicationLanguage: {
      const language = action.payload.language as Constants.Languages;
      const nextState = {
        ...state,
        language,
      };

      applyUpdatesOnQuestionChange(
        nextState,
        state.services.find(
          (eachService) =>
            eachService.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
        )
      );

      return nextState;
    }
    case Constants.AppStateActions.LoadingBlurredStateUpdate: {
      const payload = action.payload;
      const nextState = {
        ...state,
        state: {
          ...state.state,
          ...payload,
        },
      };

      return nextState;
    }
    case Constants.AppStateActions.UpdateConsent: {
      const actionPayload = action.payload;
      const nextState = {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          consent: actionPayload,
        },
      };
      applyChangesTimestampOnNextState(nextState);
      return nextState;
    }
    case Constants.AppStateActions.RatesChangeMessageVisibilityUpdated: {
      const payload = action.payload as boolean;
      const nextState = {
        ...state,
        state: {
          ...state.state,
          isRatesChangeMessagesShow: payload,
        },
      };

      return nextState;
    }
    case Constants.AppStateActions.ApplicationConfigurationVersionsChanged: {
      const payload =
        action.payload as CoreInterfaces.ApplicationConfigurationVersios;
      const nextState = {
        ...state,
        applicationConfCurrentVersions: payload,
      };

      return nextState;
    }
    case Constants.AppStateActions.UpdateEngagementConflict: {
      const actionPayload = action.payload;
      const nextState = {
        ...state,
        showConflictUpdateDialog: actionPayload.showConflictUpdateDialog,
        isConflictOnUpdateEngagement:
          actionPayload.isConflictOnUpdateEngagement,
        lastModifiedBy: actionPayload.lastModifiedBy,
      };

      return nextState;
    }
    case Constants.AppStateActions.UpdateLicenseValue: {
      const { question, licenseUuid, property, value } = action.payload;

      const stateService = state.services.find(
        (s) =>
          s.data.code === Constants.ServiceCode.OtherAccountAndReconciliation
      );
      const stateQuestion = stateService.data.questions.find(
        (q) => q.data.code === question.data.code
      );
      const emptyLicense = createEmptyLicense();
      emptyLicense.Uuid = licenseUuid;
      const licenses = !!stateQuestion.data.userValue
        ? (stateQuestion.data.userValue as unknown as CoreInterfaces.License[])
        : [emptyLicense];
      if (Array.isArray(licenses)) {
        const stateLicense = licenses.find((l) => l.Uuid === licenseUuid);
        if (!!stateLicense) {
          const nextStateLicense = { ...stateLicense, [property]: value };
          const nextStateQuestion = {
            ...stateQuestion,
            data: {
              ...stateQuestion.data,
              userValue: licenses.map((l) => {
                if (l.Uuid === licenseUuid) {
                  return nextStateLicense;
                }
                return l;
              }),
            },
            state: {
              ...stateQuestion.state,
              isFilled:
                stateQuestion.state.isFilled &&
                isLicenseDataFilled(stateQuestion),
            },
          };

          return buildNextState(
            state,
            nextStateQuestion,
            Constants.ServiceCode.OtherAccountAndReconciliation
          );
        } else {
          return state;
        }
      }
      return state;
    }
    case Constants.AppStateActions.AddNewLicense: {
      const stateService = state.services.find(
        (s) =>
          s.data.code === Constants.ServiceCode.OtherAccountAndReconciliation
      );
      const stateQuestion = stateService.data.questions.find(
        (q) =>
          q.data.code === Constants.OtherAccountAndReconciliationQuestions.Q0411
      );
      const nextStateQuestion = {
        ...stateQuestion,
        data: {
          ...stateQuestion.data,
          userValue: [
            ...(Array.isArray(stateQuestion.data.userValue)
              ? stateQuestion.data.userValue
              : []),
            createEmptyLicense(),
          ] as CoreInterfaces.License[],
        },
        state: {
          ...stateQuestion.state,
          isFilled: false,
        },
      };

      return buildNextState(
        state,
        nextStateQuestion,
        Constants.ServiceCode.OtherAccountAndReconciliation
      );
    }
    case Constants.AppStateActions.DeleteLicense: {
      const { licenseToDeleteUuid } = action.payload;
      const stateService = state.services.find(
        (s) =>
          s.data.code === Constants.ServiceCode.OtherAccountAndReconciliation
      );
      const stateQuestion = findQuestionInService(
        stateService,
        Constants.OtherAccountAndReconciliationQuestions.Q0411
      );
      const nextLicenses = (
        (stateQuestion.data.userValue as CoreInterfaces.License[]) ?? []
      ).filter((l) => l.Uuid !== licenseToDeleteUuid);
      const nextStateQuestion = {
        ...stateQuestion,
        data: {
          ...stateQuestion.data,
          userValue: nextLicenses,
        },
      };
      return buildNextState(
        state,
        nextStateQuestion,
        Constants.ServiceCode.OtherAccountAndReconciliation
      );
    }

    case Constants.AppStateActions.UpdateContactPersonValue:
    case Constants.AppStateActions.AddContactPerson:
    case Constants.AppStateActions.DeleteContactPerson: {
      const serviceToUpdate = Constants.ServiceCode.GeneralInformation;
      const stateService = state.services.find(
        (s) => s.data.code === serviceToUpdate
      );
      const stateQuestion = findQuestionInService(
        stateService,
        Constants.GeneralInformationQuestion.Q0034
      );
      let contactPersons: CoreInterfaces.ContactPersonUserDetails[] = [
        createEmptyContactPerson(true),
      ];
      if (Array.isArray(stateQuestion.data.userValue)) {
        contactPersons = stateQuestion.data
          .userValue as CoreInterfaces.ContactPersonUserDetails[];
      }
      let updatedContactPersons;
      if (action.type === Constants.AppStateActions.UpdateContactPersonValue) {
        const { contactPersonUuid, property, value } = action.payload;
        if (contactPersons.length === 0) {
          contactPersons.push(createEmptyContactPerson(true));
        }
        updatedContactPersons = contactPersons.map((contactPerson) => {
          if (
            contactPersons.length === 1 ||
            contactPerson.Uuid === contactPersonUuid
          ) {
            return {
              ...contactPerson,
              [property]: value,
            };
          }
          return contactPerson;
        });
      } else if (action.type === Constants.AppStateActions.AddContactPerson) {
        updatedContactPersons = [
          ...contactPersons,
          createEmptyContactPerson(false),
        ];
      } else if (
        action.type === Constants.AppStateActions.DeleteContactPerson
      ) {
        const { contactPersonToDeleteUuid } = action.payload;
        updatedContactPersons = contactPersons.filter(
          (contactPerson) => contactPerson.Uuid !== contactPersonToDeleteUuid
        );
        if (updatedContactPersons.length === 1) {
          updatedContactPersons[0].IsSigned = true;
        }
      }

      const nextStateQuestion = {
        ...stateQuestion,
        data: {
          ...stateQuestion.data,
          userValue: updatedContactPersons as unknown as string,
        },
        state: {
          ...stateQuestion.state,
          isFilled:
            action.type !== Constants.AppStateActions.DeleteContactPerson &&
            stateQuestion.state.isFilled &&
            isContactPersonDataFilled(stateQuestion) &&
            checkContactPersonUserDetailsFilled(stateQuestion),
        },
      };

      return buildNextState(state, nextStateQuestion, serviceToUpdate);
    }
    case Constants.AppStateActions.UpdateEngagementDocumentsMetadata: {
      const {
        currentUser,
        generationDate,
        version,
      }: {
        currentUser: CoreInterfaces.Employee;
        generationDate: Date;
        version: number;
      } = action.payload;
      const nextState: CoreInterfaces.AppState = {
        ...state,
      };
      nextState.currentConfiguration.engagementLetterMetadata = {
        version,
        createdBy: currentUser,
        createdDate: generationDate,
      };

      return nextState;
    }
    case Constants.AppStateActions.UpdateTotalCostManualOverride: {
      const payload = action.payload as number;
      const nextState: CoreInterfaces.AppState = {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          totalCostManualOverride: payload,
        },
      };

      return nextState;
    }
    default:
      throw new Error("Invalid action");
  }
}
