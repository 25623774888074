import { CorePropsInterfaces } from "src/core/Models";

export default function GtExpandMoreIcon({
  rotate = false,
  isDarkColor = true,
}: CorePropsInterfaces.GtExpandMoreIconProps) {
  const backgroundImage = isDarkColor
    ? "url(/resources/gt-expanded-icon.svg)"
    : "url(/resources/gt-expanded-white-icon.svg)";
  return (
    <article
      className={`gt-expandMoreIcon ${
        rotate ? "gt-expandMoreIcon--rotateState" : ""
      }`}
      style={{ backgroundImage }}
    ></article>
  );
}
