import { t } from "i18next";
import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "../../../core/Constants";
import {
  calculateTaskGroupSum,
  ceil,
  findQuestionInService,
  formatMinutesToHoursAndMinutes,
  getServiceStaffLevelUsageMap,
  getServiceVariable,
  sanitizeWorkMinutes,
} from "src/utils";

function calculationSummaryServiceProcessor(
  rateCartConfiguration: CoreInterfaces.RateCartConfiguration,
  serviceDTO: DTOs.ServiceDTO,
  taskGroups?: Array<Constants.TaskGroup>
): Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> {
  const rows: Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> = [];
  if (!!rateCartConfiguration && rateCartConfiguration.RateCarts) {
    const staffLevelUsageMap = getServiceStaffLevelUsageMap(
      serviceDTO,
      taskGroups
    );

    const amountVariableValue = getServiceVariable(
      serviceDTO,
      Constants.CalculationVariable.Amount
    );
    const amountVariable = Number(amountVariableValue);

    const serviceTotalWorkCostVariable = getServiceVariable(
      serviceDTO,
      Constants.CalculationVariable.TotalWorkCost
    );
    const totalWorkCostVariable = Number(serviceTotalWorkCostVariable);

    let totalWorkCost = 0;
    let totalWorkMinutes = 0;
    let workCost = 0;
    const adjustments = serviceDTO.data.staffingAdjustments.cost;

    const hasMultipleTaskGroups = taskGroups.length > 1;
    for (const k in staffLevelUsageMap) {
      const staffLevelCode = k as Constants.StaffLevel;
      const rateCart = rateCartConfiguration.RateCarts.find(
        (eachRateCart) => eachRateCart.StaffLevelCode === staffLevelCode
      );
      if (!!rateCart) {
        const workMinutes = staffLevelUsageMap[staffLevelCode];
        if (hasMultipleTaskGroups) {
          workCost = 0;
        }
        for (const taskGroupWorkMinutes in workMinutes) {
          const workHours = ceil(workMinutes[taskGroupWorkMinutes]) / 60;
          if (hasMultipleTaskGroups) {
            workCost += workHours * rateCart.RateOnHour;
          } else {
            workCost = workHours * rateCart.RateOnHour;
          }
          totalWorkMinutes += workMinutes[taskGroupWorkMinutes];
        }
        totalWorkCost += ceil(workCost);
        if (
          !serviceDTO.data.allocationSummaryPanel.isDividedByTaskServiceGroup
        ) {
          const totalWorkMinutesForAllTaskGroups = Object.values(
            workMinutes
          ).reduce((sum, minutes) => sum + minutes, 0);

          const tableRow: CoreInterfaces.AllocationServiceCalculationSummaryRow =
            {
              id: `service${serviceDTO.data.code}calculationSummaryStaffLevel${rateCart.StaffLevelCode}`,
              title: t(`Options.${rateCart.StaffLevelCode}`),
              tooltip: null,
              pricePerHour: `${rateCart.RateOnHour} ${Constants.USED_CURRENCY}`,
              hoursAndMinutes: formatMinutesToHoursAndMinutes(
                totalWorkMinutesForAllTaskGroups
              ),
              sum: `${ceil(workCost)} ${Constants.USED_CURRENCY}`,
              sumUnderlineValue: ceil(workCost),
              averagePricePerCustomerInvoice: null,
              averageTimePerCustomerInvoice: null,
              type: Constants.SummaryRowType.Allocation,
            };
          rows.push(tableRow);
        } else {
          const workMinutesAnnualReport =
            sanitizeWorkMinutes(workMinutes[Constants.TaskGroup.AnnualReport]) +
            sanitizeWorkMinutes(
              workMinutes[Constants.TaskGroup.AnnualReportV2]
            ) +
            sanitizeWorkMinutes(
              workMinutes[Constants.TaskGroup.AnnualReportV3]
            );

          const tableRow: CoreInterfaces.AllocationServiceCalculationSummaryRow =
            {
              id: `service${serviceDTO.data.code}calculationSummaryStaffLevel${rateCart.StaffLevelCode}`,
              title: t(`Options.${rateCart.StaffLevelCode}`),
              pricePerHour: `${rateCart.RateOnHour} ${Constants.USED_CURRENCY}`,
              hoursAndMinutesAnnualAccounts: formatMinutesToHoursAndMinutes(
                workMinutesAnnualReport
              ),
              hoursAndMinutesAnnualAccountsUnderlineValue:
                workMinutesAnnualReport,
              hoursAndMinutesStatutoryAnnualAccounts:
                formatMinutesToHoursAndMinutes(
                  sanitizeWorkMinutes(
                    workMinutes[Constants.TaskGroup.StatutoryAnnualReport]
                  )
                ),
              hoursAndMinutesStatutoryAnnualAccountsUnderlineValue:
                sanitizeWorkMinutes(
                  workMinutes[Constants.TaskGroup.StatutoryAnnualReport]
                ),
              hoursAndMinutesCorporateIncomeTax: formatMinutesToHoursAndMinutes(
                sanitizeWorkMinutes(
                  workMinutes[Constants.TaskGroup.CorporateIncomeTax]
                )
              ),
              hoursAndMinutesCorporateIncomeTaxUnderlineValue:
                sanitizeWorkMinutes(
                  workMinutes[Constants.TaskGroup.CorporateIncomeTax]
                ),
              sum: formatMinutesToHoursAndMinutes(
                Object.values(workMinutes).reduce(
                  (sum, minutes) => sum + minutes,
                  0
                )
              ),
            };
          rows.push(tableRow);
        }
      }
    }
    // total work cost
    if (!serviceDTO.data.calculationVariables.totalWorkCost) {
      if (!serviceDTO.data.allocationSummaryPanel.isDividedByTaskServiceGroup) {
        rows.push({
          id: Constants.CustomDataGridColumnId.TotalWorkCost,
          title: t("General.TotalWorkCost"),
          hoursAndMinutes: formatMinutesToHoursAndMinutes(totalWorkMinutes),
          sum: `${ceil(totalWorkCost)} ${Constants.USED_CURRENCY}`,
          type: Constants.SummaryRowType.TotalCost,
        });
      } else {
        rows.push({
          id: Constants.CustomDataGridColumnId.TotalWorkCost,
          title: t("General.TotalWorkCost"),
          hoursAndMinutesAnnualAccounts: formatMinutesToHoursAndMinutes(
            calculateTaskGroupSum(staffLevelUsageMap, [
              Constants.TaskGroup.AnnualReport,
              Constants.TaskGroup.AnnualReportV2,
              Constants.TaskGroup.AnnualReportV3,
            ])
          ),
          hoursAndMinutesStatutoryAnnualAccounts:
            formatMinutesToHoursAndMinutes(
              calculateTaskGroupSum(staffLevelUsageMap, [
                Constants.TaskGroup.StatutoryAnnualReport,
              ])
            ),
          hoursAndMinutesCorporateIncomeTax: formatMinutesToHoursAndMinutes(
            calculateTaskGroupSum(staffLevelUsageMap, [
              Constants.TaskGroup.CorporateIncomeTax,
            ])
          ),
          hoursAndMinutes: null,
          hoursAndMinutesAnnualAccountsUnderlineValue: calculateTaskGroupSum(
            staffLevelUsageMap,
            [
              Constants.TaskGroup.AnnualReport,
              Constants.TaskGroup.AnnualReportV2,
              Constants.TaskGroup.AnnualReportV3,
            ]
          ),
          hoursAndMinutesCorporateIncomeTaxUnderlineValue:
            calculateTaskGroupSum(staffLevelUsageMap, [
              Constants.TaskGroup.CorporateIncomeTax,
            ]),
          hoursAndMinutesStatutoryAnnualAccountsUnderlineValue:
            calculateTaskGroupSum(staffLevelUsageMap, [
              Constants.TaskGroup.StatutoryAnnualReport,
            ]),
          pricePerHour: null,
          sum: formatMinutesToHoursAndMinutes(totalWorkMinutes),
        });
      }
    }

    // adjustment of cost
    if (serviceDTO.data.allocationSummaryPanel.hasAdjustmentOfCost) {
      rows.push({
        id: Constants.CustomDataGridColumnId.AdjustmentOfCost,
        title: t("General.AdjustmentOfCost"),
        sum: adjustments,
        type: null,
      });
    }

    // additional costs totals
    let additionalCostsTotal = 0;
    if (serviceDTO.data.allocationSummaryPanel.shouldIncludeAdditionalCosts) {
      const visibleAdditionalCostDTOs = serviceDTO.data.additionalCosts.filter(
        (eachAdditionalCostDTO) => eachAdditionalCostDTO.state.isVisible
      );
      for (const additionalCostDTO of visibleAdditionalCostDTOs) {
        let usedValue = null;
        if (!!additionalCostDTO.data.numberOfUnits) {
          usedValue = additionalCostDTO.data.numberOfUnits;
        } else {
          if (!!additionalCostDTO.data.valueQuestionCode) {
            const usedQuestion = findQuestionInService(
              serviceDTO,
              additionalCostDTO.data.valueQuestionCode
            );
            if (!!usedQuestion && !!usedQuestion.data.userValue) {
              usedValue = usedQuestion.data.userValue;
            }
          }
        }
        const rowTotal = Number(usedValue) * additionalCostDTO.data.cost;
        additionalCostsTotal += rowTotal;
      }
    }

    const totalCost =
      totalWorkCost +
      additionalCostsTotal +
      totalWorkCostVariable +
      (adjustments || 0);
    // totals
    if (serviceDTO.data.allocationSummaryPanel.hasTotalCost) {
      rows.push({
        id: Constants.CustomDataGridColumnId.TotalCost,
        title: t("General.TotalCostForService", {
          serviceName: t(serviceDTO.data.calculationPage.totalCostText),
        }),
        sum: `${ceil(totalCost)} ${Constants.USED_CURRENCY}`,
        sumUnderlineValue: ceil(totalCost),
        averagePricePerCustomerInvoice: `${ceil(
          totalCost / (amountVariable || 1)
        )} ${Constants.USED_CURRENCY}`,
        averageTimePerCustomerInvoice: formatMinutesToHoursAndMinutes(
          totalWorkMinutes / (amountVariable || 1)
        ),
        totalWorkMinutes,
        type: null,
      });
    }
  }
  return rows;
}

const CalculationSummaryServiceProcessors: CoreInterfaces.ServiceProcessorMap<CoreInterfaces.AllocationServiceCalculationSummaryRow> =
  {
    AccountsReceivable: calculationSummaryServiceProcessor,
    AccountsPayable: calculationSummaryServiceProcessor,
    CorporateCardManagement: calculationSummaryServiceProcessor,
    OtherAccountAndReconciliation: calculationSummaryServiceProcessor,
    PeriodReporting: calculationSummaryServiceProcessor,
    InterimListedCompanies: calculationSummaryServiceProcessor,
    AnnualReporting: calculationSummaryServiceProcessor,
    AnnualReportingListedCompanies: calculationSummaryServiceProcessor,
    PayrollAndExpenseAndTravelInvoiceManagement:
      calculationSummaryServiceProcessor,
    AnnualPayrollRoutines: calculationSummaryServiceProcessor,
    StartUpAccounting: calculationSummaryServiceProcessor,
    StartUpPayroll: calculationSummaryServiceProcessor,
    YearlyInternalDocumentationAndFormalities:
      calculationSummaryServiceProcessor,
  };

export default CalculationSummaryServiceProcessors;
