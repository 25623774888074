import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../core/Constants";
import ServiceProcessors from "./processors/Services";
import { getDefaultTableRow } from "./processors/Services/Engagement";
import { getVisibleTaskRowsForService } from "./service-utils";

export function createEngagementTableRows(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): Array<CoreInterfaces.EngagementServiceTableRow> {
  const rowsArr: Array<CoreInterfaces.EngagementServiceTableRow> = [];
  if (!serviceDTO.state.isSelected) {
    return rowsArr;
  }
  const serviceProcessor = ServiceProcessors.Engagement[serviceDTO.data.code];
  if (!serviceProcessor) {
    return rowsArr;
  }

  return getVisibleTaskRowsForService<CoreInterfaces.EngagementServiceTableRow>(
    serviceDTO,
    serviceProcessor(serviceDTO, extraData),
    Constants.TaskVisibilityArea.Engagement,
    getDefaultTableRow
  );
}

export function areApplicationConfVersionsChanged(
  appState: CoreInterfaces.AppState
): boolean {
  return (
    appState.currentConfiguration.applicationConfiguration &&
    (appState.currentConfiguration.applicationConfiguration
      ?.rateCartConfiguration.Version !==
      appState.applicationConfCurrentVersions.rateCart ||
      appState.currentConfiguration.applicationConfiguration
        .taskAllocationConfigurationVersion !==
        appState.applicationConfCurrentVersions.taskAllocation ||
      appState.currentConfiguration.applicationConfiguration
        .taskCostConfigurationVersion !==
        appState.applicationConfCurrentVersions.taskCost)
  );
}
