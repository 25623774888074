import { CoreInterfaces, DTOs } from "../../../core/Models";
import * as Constants from "./../../../core/Constants";

export const PeriodReportingProcessor: CoreInterfaces.QuestionProcessors = (
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) => {
  return {
    [Constants.PeriodReportingQuestion.Q0501]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.PeriodReportingTasks.T0501,
        Constants.PeriodReportingTasks.T0502,
        Constants.PeriodReportingTasks.T0503,
        Constants.PeriodReportingTasks.T0504,
        Constants.PeriodReportingTasks.T0505,
        Constants.PeriodReportingTasks.T0506,
        Constants.PeriodReportingTasks.T0507,
        Constants.PeriodReportingTasks.T0508,
        Constants.PeriodReportingTasks.T0510,
        Constants.PeriodReportingTasks.T0523,
      ];

      const frequencyToUse = (
        questionDTO.data.userValue &&
        questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
          ? questionDTO.data.userValue
          : questionDTO.data.defaultValue
      ) as Constants.Frequency;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.frequency = frequencyToUse;
        }
      });
    },
    [Constants.PeriodReportingQuestion.Q0504]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const taskToBeUpdated: Array<Constants.TaskCode> = [
        Constants.PeriodReportingTasks.T0512,
        Constants.PeriodReportingTasks.T0513,
        Constants.PeriodReportingTasks.T0514,
        Constants.PeriodReportingTasks.T0515,
      ];

      const allocationOfResponsabilityToUse =
        questionDTO.data.userValue === Constants.YesNo.Yes
          ? Constants.TaskAllocationResponsibility.GT
          : Constants.TaskAllocationResponsibility.Client;

      serviceDTO.data.tasks.forEach((eachTask) => {
        if (taskToBeUpdated.includes(eachTask.data.code)) {
          eachTask.data.allocationOfResponsibility =
            allocationOfResponsabilityToUse;
        }
      });

      questionDTO.state.isShown =
        appState.generalVariables.isCompanyRegisteredVAT;
    },
    [Constants.PeriodReportingQuestion.Q0506]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.isCompanyRegisteredVAT;
    },
    [Constants.PeriodReportingQuestion.Q0507]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.isCompanyRegisteredVAT;
    },
    [Constants.PeriodReportingQuestion.Q0509]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        appState.generalVariables.isDefaultAccountingSystem;
    },
    [Constants.PeriodReportingQuestion.Q0509_1]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown =
        !appState.generalVariables.isDefaultAccountingSystem;
    },
    [Constants.PeriodReportingQuestion.Q0510]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0509 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.PeriodReportingQuestion.Q0509
      );
      const q0509_1 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.PeriodReportingQuestion.Q0509_1
      );
      questionDTO.state.isShown =
        (q0509_1?.state.isShown &&
          q0509_1?.data.userValue === Constants.PeriodReportMethod.Other) ||
        (q0509?.state.isShown &&
          q0509?.data.userValue === Constants.PeriodReportMethod.Other);
    },

    [Constants.PeriodReportingQuestion.Q0513]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        {
          key: Constants.HelpfulConstants.PleaseSelect,
          value: `Options.${Constants.HelpfulConstants.PleaseSelect}`,
        },
        ...(appState.remoteData?.customerContactPersons || []),
      ];
    },
    [Constants.PeriodReportingQuestion.Q0514]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const payrollService = appState.services.find(
        (serviceDTO) =>
          serviceDTO.data.code ===
          Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
      );
      questionDTO.state.isShown =
        !!payrollService && !payrollService.state.isSelected;
    },
  };
};
