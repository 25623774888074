import * as Constants from "../../core/Constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import GtInfoTooltip from "../Common/GtInfoTooltip";
import { t } from "i18next";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import GtDebouncedInput from "../Common/GtDebouncedInput";
import { resolveService6FixedPrice, showConfigurationCodes } from "src/utils";
import { CorePropsInterfaces } from "src/core/Models";

export default function AdditionalCostsFixedPricePanel({
  serviceDTO,
}: CorePropsInterfaces.SummaryPanelProps): JSX.Element {
  if (!serviceDTO || !serviceDTO.state.isSelected) {
    return null;
  }
  const { globalState, dispatch } = useContext(AppContext);

  const onServiceCostAdjustmentChange = function onServiceCostAdjustmentChange(
    costValue: string,
    additionalCostCode: Constants.AdditionalCostConfigurationCode
  ) {
    dispatch({
      type: Constants.AppStateActions.ServiceAdditionalCostAdjustementChange,
      payload: {
        serviceCode: serviceDTO.data.code,
        additionalCostCode,
        costValue,
      },
    });
  };

  const columns = getColumnsDefinition(
    onServiceCostAdjustmentChange,
    globalState.currentConfiguration.lastModifiedDate
  );

  const rows = resolveService6FixedPrice(globalState, serviceDTO);

  return (
    rows &&
    rows.length > 0 && (
      <article className="gt-additionalCostsPanel">
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={44}
          columnHeaderHeight={48}
          hideFooterPagination={true}
        />
      </article>
    )
  );
}

function getColumnsDefinition(
  onServiceCostAdjustmentChange: Function,
  lastVersionChangedDate: string
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t("PanelHeader.FixedPriceServices"),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article className="gt-serviceTitleCell">
            <div>
              {showConfigurationCodes() && params.row.additionalCostCode && (
                <>[{params.row.additionalCostCode}]</>
              )}
              <span className="gt-serviceTitleCell__title">
                {params.row.title}
              </span>
            </div>
            {params.row.tooltip && (
              <GtInfoTooltip
                testIdPart={params.row.code}
                tooltipTitle={params.row.tooltip}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "numberOfHours",
      flex: 1,
      headerName: t("TableHeader.NumberOfHours"),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-additionalCostsPanel__highlightedCell"
          : "";
      },
    },
    {
      field: "referenceCostWork",
      flex: 1,
      headerName: t("TableHeader.ReferenceCostWork"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-additionalCostsPanel__highlightedCell"
          : "";
      },
    },
    {
      field: "basePrice",
      flex: 1,
      headerName: t("TableHeader.BasePrice"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-additionalCostsPanel__highlightedCell"
          : "";
      },
    },
    {
      field: "basePriceAdjusted",
      headerName: t("TableHeader.BasePriceAdjusted"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id ===
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
          ? "gt-additionalCostsPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        if (
          params.row.id !==
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost
        ) {
          return (
            <article className="gt-dataTableCellWrapper">
              <GtDebouncedInput
                value={params.row.basePriceAdjustment ?? ""}
                onChange={(newValue: any) =>
                  onServiceCostAdjustmentChange(
                    newValue,
                    params.row.additionalCostCode,
                    params.row.serviceDTO
                  )
                }
                debounceTime={2 * Constants.INPUT_CHANGE_DEBOUNCE_MILLIS}
                updateTimestamp={lastVersionChangedDate}
                maxLength={8}
                size="small"
                className="gt-dataTableCellWrapper__textField gt-NumberField"
              />
              <section className="gt-dataTableCellWrapper__unit">
                {Constants.USED_CURRENCY}
              </section>
            </article>
          );
        } else {
          return params.row.basePriceAdjustment;
        }
      },
    },
  ];
  return columnDefinition;
}
