import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import * as Constants from "src/core/Constants";

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (
  !!process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV != Constants.Environments.DEV
) {
  appInsights.loadAppInsights();
}
