import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";

export const PeriodReportingTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0504: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0504
      ),
      q0506: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0506
      ),
      q0507: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0507
      ),
      q0508: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0508
      ),
      q0509: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0509
      ),
      q0509_1: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0509_1
      ),
      q0511: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0511
      ),
      q0514: findQuestionInService(
        serviceDTO,
        Constants.PeriodReportingQuestion.Q0514
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0504) {
        taskDTO.state.isVisible =
          (questionsMap.q0509_1.state.isShown &&
            questionsMap.q0509_1.data.userValue ===
              Constants.PeriodReportMethod.BudgetRapport) ||
          (questionsMap.q0509.state.isShown &&
            questionsMap.q0509.data.userValue ===
              Constants.PeriodReportMethod.BudgetRapport);
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0505) {
        taskDTO.state.isVisible =
          (questionsMap.q0509_1.state.isShown &&
            questionsMap.q0509_1.data.userValue ===
              Constants.PeriodReportMethod.Other) ||
          (questionsMap.q0509.state.isShown &&
            questionsMap.q0509.data.userValue ===
              Constants.PeriodReportMethod.Other);
      }

      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0509) {
        taskDTO.state.isVisible =
          appState.generalVariables.isCompanyRegisteredVAT;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0511) {
        taskDTO.state.isVisible =
          questionsMap.q0504.state.isShown &&
          questionsMap.q0504.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0512) {
        taskDTO.state.isVisible =
          questionsMap.q0504.state.isShown &&
          questionsMap.q0504.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0513) {
        taskDTO.state.isVisible =
          questionsMap.q0504.state.isShown &&
          questionsMap.q0504.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0514) {
        taskDTO.state.isVisible =
          questionsMap.q0504.state.isShown &&
          questionsMap.q0504.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0515) {
        taskDTO.state.isVisible =
          questionsMap.q0504.state.isShown &&
          questionsMap.q0504.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0516) {
        taskDTO.state.isVisible =
          appState.generalVariables.isCompanyRegisteredVAT;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0517) {
        taskDTO.state.isVisible =
          questionsMap.q0506.state.isShown &&
          questionsMap.q0506.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0518) {
        taskDTO.state.isVisible =
          questionsMap.q0506.state.isShown &&
          questionsMap.q0506.data.userValue === Constants.YesNo.No;
      }

      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0519) {
        taskDTO.state.isVisible =
          questionsMap.q0507.state.isShown &&
          questionsMap.q0507.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0520) {
        taskDTO.state.isVisible =
          questionsMap.q0507.state.isShown &&
          questionsMap.q0507.data.userValue === Constants.YesNo.No;
      }

      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0521) {
        taskDTO.state.isVisible =
          questionsMap.q0508.state.isShown &&
          questionsMap.q0508.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0522) {
        taskDTO.state.isVisible =
          questionsMap.q0508.state.isShown &&
          questionsMap.q0508.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0523) {
        taskDTO.state.isVisible =
          questionsMap.q0511.state.isShown &&
          questionsMap.q0511.data.comment !==
            Constants.HelpfulConstants.EmptyString;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0532) {
        taskDTO.state.isVisible =
          questionsMap.q0514.state.isShown &&
          questionsMap.q0514.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.PeriodReportingTasks.T0533) {
        taskDTO.state.isVisible =
          questionsMap.q0514.state.isShown &&
          questionsMap.q0514.data.userValue === Constants.YesNo.No;
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.PeriodReportingAdditionalCosts.T0524
        ) {
          eachAdditionalCost.state.isVisible =
            (questionsMap.q0509.state.isShown &&
              questionsMap.q0509.data.userValue ===
                Constants.PeriodReportMethod.BudgetRapport) ||
            (questionsMap.q0509_1.state.isShown &&
              questionsMap.q0509_1.data.userValue ===
                Constants.PeriodReportMethod.BudgetRapport);
        }
      });
    }
  };
