import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import { isOptionChecked, resolveUserValue } from "src/utils";

function QuestionCheckBoxInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { t } = useTranslation();
  const questionSelectionMap: Record<string, boolean> =
    question.data.options.reduce(
      (acc, eachOption) => ({
        ...acc,
        [eachOption.key]: isOptionChecked(question, eachOption.key),
      }),
      {}
    );

  const handleOptionChange = (optionKey: string, optionValue: string) => {
    const newSelectionMap = {
      ...questionSelectionMap,
      [optionKey]: !questionSelectionMap[optionKey],
    };
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: resolveUserValue(
          question,
          optionValue,
          newSelectionMap[optionKey]
        ),
      },
    };
    onChange && onChange(newQuestionDTO);
  };

  return (
    <FormGroup>
      {question.data.options &&
        question.data.options.map((eachOption) => (
          <FormControlLabel
            key={eachOption.key}
            control={
              <Checkbox
                checked={questionSelectionMap[eachOption.key]}
                onChange={() =>
                  handleOptionChange(eachOption.key, eachOption.value)
                }
                disabled={isDisabled}
              />
            }
            label={t(eachOption.value)}
          />
        ))}
    </FormGroup>
  );
}

export default QuestionCheckBoxInput;
