import { useTranslation } from "react-i18next";
import { CorePropsInterfaces } from "src/core/Models";
import * as Constants from "../../core/Constants/Constants";
import { getServiceVariable, resolveContactPerson } from "src/utils";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";

export default function ContactPersonSummaryPanel({
  serviceDTO,
}: CorePropsInterfaces.SummaryPanelProps): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);

  const contactPersonUserValueKey = getServiceVariable(
    serviceDTO,
    Constants.CalculationVariable.ContactPerson
  );
  const contactPerson = resolveContactPerson(
    globalState.remoteData.customerContactPersons.find(
      (item) => item.key === contactPersonUserValueKey
    )?.value
  );

  return (
    <article className="gt-summaryPanel">
      <div className="gt-summaryPanel__contactPerson">
        {t("PanelHeader.ContactPersonClientText", { contactPerson })}
      </div>
    </article>
  );
}
