import { CorePropsInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../core/Constants";
import CalculationServiceTasksPanel from "./CalculationServiceTasksPanel";

import { useMemo, useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import { isFinalorOutdatedVersion } from "src/utils";

export default function CalculationServiceTaskPanelWrapper({
  serviceDTO,
  children,
}: CorePropsInterfaces.CalculationServiceTasksPanelWrapperProps) {
  const { globalState, dispatch } = useContext(AppContext);
  const generalVariables = globalState.generalVariables;
  const currentRateCartConfiguration =
    globalState.currentConfiguration?.applicationConfiguration
      ?.rateCartConfiguration;

  const serviceChangeToken = (service: DTOs.ServiceDTO): string => {
    const taskTokens = service.data.tasks.map((task: DTOs.TaskDTO) => {
      return `${task.data.code}_${task.state.isVisible ? `1` : `0`}_${
        task.state.isActive ? `1` : `0`
      }_${task.data.adjustments.estimatedTimePerUnit}_${
        task.data.adjustments.staffLevel
      }`;
    });

    const serviceToken: string = taskTokens.join("|");

    return serviceToken;
  };

  let serviceToken = serviceChangeToken(serviceDTO);
  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);
  const lastConfigurationModifiedDate =
    globalState.currentConfiguration.lastModifiedDate;
  const extraProcessingServices: Array<DTOs.ServiceDTO> = [];
  if (serviceDTO.data.code === Constants.ServiceCode.AnnualPayrollRoutines) {
    const payrollService = globalState.services.find(
      (eachService) =>
        eachService.data.code ===
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
    );
    extraProcessingServices.push(payrollService);
    serviceToken += serviceChangeToken(payrollService);
  }
  const memoizedComponent = useMemo(
    () => (
      <CalculationServiceTasksPanel
        serviceDTO={serviceDTO}
        generalVariables={generalVariables}
        dispatch={dispatch}
        isFinalorOutdatedVersionStatus={isFinalorOutdatedVersionStatus}
        lastConfigurationModifiedDate={lastConfigurationModifiedDate}
        extraProcessingServices={extraProcessingServices}
        rateCartConfiguration={currentRateCartConfiguration}
      >
        {children && children}
      </CalculationServiceTasksPanel>
    ),
    [
      serviceToken,
      isFinalorOutdatedVersionStatus,
      lastConfigurationModifiedDate,
    ]
  );

  return memoizedComponent;
}
