import * as Constants from "./../core/Constants";
import { CoreInterfaces } from "src/core/Models";
import { fetchData } from "./api";

export async function getUserInfo(): Promise<
  CoreInterfaces.AuthProviderUserDetails | undefined
> {
  try {
    const response = (await fetchData(Constants.AadApiPath.AuthMe)) as any;
    const { clientPrincipal } = response;

    return clientPrincipal;
  } catch (error) {
    return undefined;
  }
}

export function getFirstLastNameInitials(
  claims: Array<CoreInterfaces.Claim>
): string {
  const firstName =
    claims.find((item) => item.typ === Constants.Claims.Firstname)?.val ?? "";
  const lastName =
    claims.find((item) => item.typ === Constants.Claims.Lastname)?.val ?? "";

  return `${firstName[0]}${lastName[0]}`;
}

export function getFullName(claims: Array<CoreInterfaces.Claim>): string {
  const firstName =
    claims?.find((item) => item.typ === Constants.Claims.Firstname)?.val ?? "";
  const lastName =
    claims?.find((item) => item.typ === Constants.Claims.Lastname)?.val ?? "";

  return `${firstName} ${lastName}`;
}

export function getEmail(claims: Array<CoreInterfaces.Claim>): string {
  return claims?.find((item) => item.typ === Constants.Claims.Email)?.val ?? "";
}
