import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";

function QuestionAutocompleteInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { t } = useTranslation();

  function onSelectedValueChange(
    selectedOption: CoreInterfaces.KeyValueItem
  ): void {
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: selectedOption?.key ?? "",
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  return (
    <Autocomplete
      data-testid={question.data.code}
      className="gt-Autocomplete"
      disablePortal
      disabled={isDisabled}
      options={question.data.options}
      value={
        question.data.options.find((x) => x.key === question.data.userValue) ??
        null
      }
      getOptionLabel={(option) => option.value}
      onChange={(syntheticBaseEvent, evt) => {
        onSelectedValueChange(evt);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          data-testid={`option_question${question.data.code}_option${option.key}`}
        >
          {option.value}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder={t("Options.PleaseSelect")} />
      )}
    />
  );
}

export default QuestionAutocompleteInput;
