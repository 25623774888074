import { CoreInterfaces } from "../Models";
import * as Constants from "../Constants";

export const AdditionalCosts: {
  [serviceCode in Constants.ServiceCode]: Array<CoreInterfaces.AdditionalCostConfiguration>;
} = {
  GeneralInformation: [],
  AccountsReceivable: [
    {
      Code: Constants.AccountReceivableAdditionalCosts.T0122,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.AccountReceivableQuestion.Q0101,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.AccountReceivableAdditionalCosts.T0123,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.AccountReceivableAdditionalCosts.T0124,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.AccountReceivableQuestion.Q0102,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  AccountsPayable: [
    {
      Code: Constants.AccountPayableAdditionalCosts.T0214,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.AccountPayableQuestion.Q0201,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.AccountPayableAdditionalCosts.T0215,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.AccountPayableQuestion.Q0201,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.AccountPayableAdditionalCosts.T0216,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.AccountPayableQuestion.Q0202,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  CorporateCardManagement: [
    {
      Code: Constants.CorporateCardManagementAdditionalCosts.T0308,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.CorporateCardManagementQuestion.Q0301,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.CorporateCardManagementAdditionalCosts.T0309,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.CorporateCardManagementQuestion.Q0301,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  OtherAccountAndReconciliation: [
    {
      Code: Constants.OtherAccountAndReconciliationAdditionalCosts.T0429,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: Constants.OtherAccountAndReconciliationQuestions.Q0409,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  PeriodReporting: [
    {
      Code: Constants.PeriodReportingAdditionalCosts.T0524,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: null,
      NumberOfUnits: 1,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  InterimListedCompanies: [],
  AnnualReporting: [
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0661,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0662,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0663,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0664,
      Cost: 0,
      Adjustments: {
        Cost: 0,

        ExtendedPriceListPanelCost: 0,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0665,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0666,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0685,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0686,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0687,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0667,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0668,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0669,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0670,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0671,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0672,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0673,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0674,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0675,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0676,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0677,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0678,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0679,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0680,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0681,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0682,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0683,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
    {
      Code: Constants.AnnualReportingAdditionalCosts.T0684,
      Cost: 0,
      Adjustments: {
        Cost: 0,
        ExtendedPriceListPanelCost: null,
      },
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
    },
  ],
  AnnualReportingListedCompanies: [],
  PayrollAndExpenseAndTravelInvoiceManagement: [
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0751,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode:
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0752,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode:
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0753,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: null,
      NumberOfUnits: 1,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0754,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode:
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0755,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode:
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0757,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode:
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: true,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
        .T0774,
      Cost: 0,
      Adjustments: null,
      ValueQuestionCode: null,
      NumberOfUnits: null,
      Description: null,
      UsedInSoftwareServiceCostsTable: false,
      UsedInSoftwareServiceFixedPriceTable: false,
      ServiceTaskGroup: null,
    },
  ],
  AnnualPayrollRoutines: [],
  StartUpAccounting: [],
  StartUpPayroll: [],
  YearlyInternalDocumentationAndFormalities: [],
  EngagementCounseling: [],
  LimitedCompany: [],
  NonProfitOrg: [],
  EconomicAssociation: [],
};
