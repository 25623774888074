import { Snackbar, AlertColor, Alert } from "@mui/material";
import React, { createContext, useContext, useRef } from "react";
import * as Constants from "./../core/Constants";

type SnackBarContextActions = {
  showSnackBar: (
    text: string,
    typeColor: AlertColor,
    autocloseTime?: number
  ) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [typeColor, setTypeColor] = React.useState<AlertColor>("info");
  const timeoutRef = useRef(null);

  const showSnackBar = (
    text: string,
    color: AlertColor,
    autocloseTime?: number
  ) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setMessage(null);
      setOpen(false);
    }, autocloseTime ?? Constants.SNACKBAR_AUTOCLOSE_TIME_MILLIS);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor("info");
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={typeColor}>
          {message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider");
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
