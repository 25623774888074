import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "../../../core/Constants";
import { isServiceTaskGroupSelected } from "src/utils/task-utils";
import { mapQuestionCodesToQuestionDTOs } from "src/utils/question-utils";

export const AnnualReportingTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const service6b = appState.services.find(
      (service) =>
        service.data.code ===
        Constants.ServiceCode.AnnualReportingListedCompanies
    );
    let isSelectedService6b = true;
    if (!!service6b) {
      isSelectedService6b = service6b.state.isSelected;
    }

    const {
      Q0601,
      Q0603,
      Q0607,
      Q0610,
      Q0615,
      Q0616,
      Q0617,
      Q0618,
      Q0619,
      Q0620,
      Q0621,
      Q0622,
      Q0623,
      Q0624,
      Q0625,
      Q0626,
      Q0627,
      Q0628,
      Q0629,
      Q0630,
      Q0631,
      Q0632,
      Q0633,
      Q0634,
      Q0635,
      Q0636,
      Q0637,
      Q0638,
      Q0639,
      Q0640,
      Q0641,
      Q0642,
    } = Constants.AnnualReportingQuestions;
    const questionDTOMap = mapQuestionCodesToQuestionDTOs(serviceDTO, [
      Q0601,
      Q0603,
      Q0607,
      Q0610,
      Q0615,
      Q0616,
      Q0617,
      Q0618,
      Q0619,
      Q0620,
      Q0621,
      Q0622,
      Q0623,
      Q0624,
      Q0625,
      Q0626,
      Q0627,
      Q0628,
      Q0629,
      Q0630,
      Q0631,
      Q0632,
      Q0633,
      Q0634,
      Q0635,
      Q0636,
      Q0637,
      Q0638,
      Q0639,
      Q0640,
      Q0641,
      Q0642,
    ]);

    const isCheckboxSelectedAnnualAccounts = isServiceTaskGroupSelected(
      questionDTOMap.Q0610,
      Constants.PartOfAnnualAccounts.AnnualAccounts
    );
    const isCheckboxSelectedAnnualReport = isServiceTaskGroupSelected(
      questionDTOMap.Q0610,
      Constants.PartOfAnnualAccounts.AnnualReport
    );
    const isCheckboxSelectedCompanyDeclaration = isServiceTaskGroupSelected(
      questionDTOMap.Q0610,
      Constants.PartOfAnnualAccounts.CompanyDeclaration
    );

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (
        !isCheckboxSelectedAnnualAccounts &&
        (taskDTO.data.serviceTaskGroup === Constants.TaskGroup.AnnualReport ||
          taskDTO.data.serviceTaskGroup ===
            Constants.TaskGroup.AnnualReportV2 ||
          taskDTO.data.serviceTaskGroup === Constants.TaskGroup.AnnualReportV3)
      ) {
        taskDTO.state.isVisible = false;
      } else {
        if (
          !isCheckboxSelectedAnnualReport &&
          taskDTO.data.serviceTaskGroup ===
            Constants.TaskGroup.StatutoryAnnualReport
        ) {
          taskDTO.state.isVisible = false;
        } else {
          if (
            !isCheckboxSelectedCompanyDeclaration &&
            taskDTO.data.serviceTaskGroup ===
              Constants.TaskGroup.CorporateIncomeTax
          ) {
            taskDTO.state.isVisible = false;
          } else {
            taskDTO.state.isVisible = true;
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0603) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                appState.generalVariables.isAuditorGrantThornton;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0604) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                appState.generalVariables.isAuditorGrantThornton;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0605) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                !appState.generalVariables.isAuditorGrantThornton;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0606) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                questionDTOMap.Q0603.state.isShown &&
                questionDTOMap.Q0603.data.userValue === Constants.YesNo.Yes;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0607) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                questionDTOMap.Q0603.state.isShown &&
                questionDTOMap.Q0603.data.userValue === Constants.YesNo.Yes;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0608) {
              taskDTO.data.allocationOfResponsibility =
                questionDTOMap.Q0603.data.userValue === Constants.YesNo.Yes
                  ? Constants.TaskAllocationResponsibility.GT
                  : Constants.TaskAllocationResponsibility.Client;

              taskDTO.state.isVisible =
                questionDTOMap.Q0601.state.isShown &&
                questionDTOMap.Q0601.data.userValue === Constants.YesNo.Yes &&
                questionDTOMap.Q0603.state.isShown &&
                questionDTOMap.Q0603.data.userValue === Constants.YesNo.Yes;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0623) {
              taskDTO.state.isVisible = questionDTOMap.Q0621.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0624) {
              taskDTO.state.isVisible = questionDTOMap.Q0622.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0625) {
              taskDTO.state.isVisible = questionDTOMap.Q0623.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0626) {
              taskDTO.state.isVisible = questionDTOMap.Q0624.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0627) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0625.state.isShown ||
                questionDTOMap.Q0642.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0628) {
              taskDTO.state.isVisible = questionDTOMap.Q0626.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0629) {
              taskDTO.state.isVisible = questionDTOMap.Q0627.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0630) {
              taskDTO.state.isVisible = questionDTOMap.Q0628.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0631) {
              taskDTO.state.isVisible = questionDTOMap.Q0629.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0632) {
              taskDTO.state.isVisible = questionDTOMap.Q0630.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0633) {
              taskDTO.state.isVisible = questionDTOMap.Q0631.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0634) {
              taskDTO.state.isVisible = questionDTOMap.Q0621.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0635) {
              taskDTO.state.isVisible = questionDTOMap.Q0622.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0636) {
              taskDTO.state.isVisible = questionDTOMap.Q0623.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0637) {
              taskDTO.state.isVisible = questionDTOMap.Q0624.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0638) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0625.state.isShown ||
                questionDTOMap.Q0642.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0639) {
              taskDTO.state.isVisible = questionDTOMap.Q0626.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0640) {
              taskDTO.state.isVisible = questionDTOMap.Q0627.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0641) {
              taskDTO.state.isVisible = questionDTOMap.Q0628.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0642) {
              taskDTO.state.isVisible = questionDTOMap.Q0629.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0643) {
              taskDTO.state.isVisible = questionDTOMap.Q0630.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0644) {
              taskDTO.state.isVisible = questionDTOMap.Q0631.state.isShown;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0645) {
              taskDTO.state.isVisible = !isSelectedService6b;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0646) {
              taskDTO.state.isVisible = !isSelectedService6b;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0647) {
              taskDTO.state.isVisible = !isSelectedService6b;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0649) {
              taskDTO.state.isVisible =
                !appState.generalVariables.isAuditorGrantThornton;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0650) {
              taskDTO.state.isVisible =
                appState.generalVariables.isAuditorGrantThornton;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0655) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0607.state.isShown &&
                questionDTOMap.Q0607.data.userValue === Constants.YesNo.No;
            }
            if (taskDTO.data.code === Constants.AnnualReportingTasks.T0656) {
              taskDTO.state.isVisible =
                questionDTOMap.Q0607.state.isShown &&
                questionDTOMap.Q0607.data.userValue === Constants.YesNo.Yes;
            }
          }
        }
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0661
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0615.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0662
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0616.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0663
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0617.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0664
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0618.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0665
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0619.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0666
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0620.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0667
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0621.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0668
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0622.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0669
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0623.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0670
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0624.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0671
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0625.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0672
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0626.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0673
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0627.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0674
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0628.state.isShown ||
            questionDTOMap.Q0642.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0675
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0629.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0676
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0630.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0677
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0631.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0678
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0632.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0679
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0633.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0680
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0634.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0681
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0635.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0682
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0636.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0683
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0637.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0684
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0638.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0685
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0639.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0686
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0640.state.isShown;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AnnualReportingAdditionalCosts.T0687
        ) {
          eachAdditionalCost.state.isVisible =
            questionDTOMap.Q0641.state.isShown;
        }
        if (
          appState.currentConfiguration.version > 0 &&
          !eachAdditionalCost.state.isVisible
        ) {
          eachAdditionalCost.data.adjustments.Cost = 0;
        }
      });
    }
  };
