import { fetchData, sendFile } from "./api";
import * as Constants from "./../core/Constants";
import { CoreInterfaces } from "src/core/Models";
import { t } from "i18next";

export function fetchEngagementDocuments(
  engagementConfigurationId: string
): Promise<Array<CoreInterfaces.EngagementDocument>> {
  const url = Constants.APIPath.EngagementDocumentGetAll.replace(
    "{engagementConfigurationId}",
    engagementConfigurationId
  );
  return fetchData<any>(url);
}

export function getFileContent(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const fileContent = (evt.target.result as string).split(",")[1];
      resolve(fileContent);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}

export const handleFileUpload = (
  businessOpportunityId: string,
  engagementConfigurationId: string,
  file: any,
  documentType: Constants.EngagementDocumentType,
  maxSize: number = null,
  allowedExtensions: Array<string> = [],
  customFilename: string = null
): Promise<void> => {
  if (Array.isArray(allowedExtensions) && allowedExtensions.length) {
    const fileExtension = file.name?.split(".").pop();
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      return new Promise((_, reject) => {
        reject(t(`General.InvalidFileExtension`));
      });
    }
  }

  if (maxSize && file.size > maxSize) {
    return new Promise((_, reject) => {
      reject(
        t("General.EngagementUploaderMaximulAllowedFileSize", {
          size: (maxSize / 1024 / 1024).toFixed() + " MB",
        })
      );
    });
  }

  const formData = new FormData();

  if (!!customFilename) {
    formData.append("file", file, customFilename);
  } else {
    formData.append("file", file);
  }
  formData.append("DocumentType", documentType);

  return sendFile(
    Constants.APIPath.EngagementDocumentsUpload.replace(
      "{businessOpportunityId}",
      businessOpportunityId
    ).replace("{engagementConfigurationId}", engagementConfigurationId),
    Constants.APIMethod.POST,
    formData
  )
    .then(
      // eslint-disable-next-line
      (_) => {}
    )
    .catch((Ex) =>
      console.log("Exception encountered during document upload", Ex)
    );
};

export function deleteDocument(
  businessOpportunityId: string,
  engagementConfigurationId: string,
  document: CoreInterfaces.EngagementDocument
) {
  const url = Constants.APIPath.EngagementDocumentDelete.replace(
    "{businessOpportunityId}",
    businessOpportunityId
  )
    .replace("{engagementConfigurationId}", engagementConfigurationId)
    .replace("{documentId}", document.Id);
  return fetchData<any>(url, Constants.APIMethod.DELETE);
}
