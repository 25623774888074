import * as Constants from "./../core/Constants";

export function fetchData<T>(
  url: string,
  method: Constants.APIMethod = Constants.APIMethod.GET,
  payload?: any,
  queryParams?: { [key: string]: string },
  externUrl = false
): Promise<T> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  const options: RequestInit = {
    method,
    headers,
    body: payload ? JSON.stringify(payload) : undefined,
  };

  let fullUrl = externUrl ? url : `${Constants.API_BASE_URL}${url}`;
  if (queryParams) {
    fullUrl = `${fullUrl}?${new URLSearchParams(queryParams)}`;
  }

  return fetch(fullUrl, options).then((response) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && !contentType.includes("/json")) {
        // handle empty string response
        throw new Error(`Invalid response from ${url}`);
      }
      return contentType
        ? (response.json().catch(() => null) as Promise<T>)
        : null;
    } else if (response.status === 401) {
      window.location.assign(
        `${
          Constants.AadApiPath.AuthLogin
        }?post_login_redirect_uri=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    } else {
      throw response;
    }
  });
}

export function fetchPdfFile(
  url: string,
  method: Constants.APIMethod,
  formData: any
): Promise<Blob> {
  const headers = new Headers();
  headers.append("Content-Type", "multipart/form-data");
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  const options: RequestInit = {
    method,
    headers,
    body: formData,
  };

  const fullUrl = `${Constants.API_BASE_URL}${url}`;

  return fetch(fullUrl, options).then((response) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && !contentType.includes("/pdf")) {
        throw new Error(`Invalid response from ${url}`);
      }
      return contentType
        ? (response.blob().catch(() => null) as Promise<Blob>)
        : null;
    } else {
      throw response.statusText;
    }
  });
}

export function sendFile<T>(
  url: string,
  method: Constants.APIMethod,
  formData: any
): Promise<T> {
  const headers = new Headers();
  headers.append("Content-Type", "multipart/form-data");
  headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
  headers.append("Pragma", "no-cache");
  headers.append("Expires", "0");

  const options: RequestInit = {
    method,
    headers,
    body: formData,
  };

  const fullUrl = `${Constants.API_BASE_URL}${url}`;

  return fetch(fullUrl, options).then((response) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && !contentType.includes("/json")) {
        // handle empty string response
        throw new Error(`Invalid response from ${url}`);
      }
      return contentType
        ? (response.json().catch(() => null) as Promise<T>)
        : null;
    } else if (response.status === 401) {
      window.location.assign(
        `${
          Constants.AadApiPath.AuthLogin
        }?post_login_redirect_uri=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    } else {
      throw response;
    }
  });
}
