import { t } from "i18next";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import { Button } from "@mui/material";
import * as Constants from "../../core/Constants";
import { useEffect, useState } from "react";
import GtOfficeManagersRow from "./GtOfficeManagersRow";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import GtOfficeManagerDeleteDialog from "./GtOfficeManagerDeleteDialog";
import { fetchData } from "src/utils";
import { useSnackBar } from "src/contexts/SnackbarContext";
import { v4 as uuid } from "uuid";

function GtOfficeManagersDialog({
  onOfficeManagersDialogClose,
}: CorePropsInterfaces.GtOfficeManagersDialogProps): JSX.Element {
  const { showSnackBar } = useSnackBar();
  const [
    isVisibleOfficeManagerDeleteDialog,
    setIsVisibleOfficeManagerDeleteDialog,
  ] = useState(false);
  const [officeManagerToDelete, setOfficeManagerToDelete] = useState(null);
  const [officeManagersList, setOfficeManagersList] = useState([]);
  const officeManagersDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.SetupOfficeManagers"),
      maxWidth: "lg",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onOfficeManagersDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={() => {
            if (
              officeManagersList.some(
                (officeManager) =>
                  officeManager.UserId ===
                    Constants.HelpfulConstants.PleaseSelect ||
                  officeManager.Office ===
                    Constants.HelpfulConstants.PleaseSelect ||
                  officeManager.BusinessUnits.length === 0
              )
            ) {
              showSnackBar(t("General.EmptyOfficeManagersWarning"), "warning");
              return;
            }
            fetchData<Array<CoreInterfaces.OfficeManager>>(
              Constants.APIPath.OfficeManagers,
              Constants.APIMethod.PUT,
              officeManagersList
            )
              .then(() => {
                onOfficeManagersDialogClose();
              })
              .catch(() => {
                showSnackBar(t("General.AnErrorHasOccured"), "error");
              });
          }}
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: onOfficeManagersDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  useEffect(() => {
    fetchData<Array<CoreInterfaces.OfficeManager>>(
      Constants.APIPath.OfficeManagers
    )
      .then((data: Array<CoreInterfaces.OfficeManager>) => {
        setOfficeManagersList(
          data.map((item) => ({
            ...item,
            uniqueId: uuid(),
          }))
        );
      })
      .catch(() => []);
  }, []);

  return (
    <>
      <GtDialog gtDialogDTO={officeManagersDialogDTO}>
        <article className="gt-officeManagersDialog">
          <Button
            color="tertiary"
            variant="outlined"
            className="gt-officeManagersDialog__addButton"
            onClick={() => {
              setOfficeManagersList([
                ...officeManagersList,
                {
                  UserId: Constants.HelpfulConstants.PleaseSelect,
                  Offices: Constants.HelpfulConstants.PleaseSelect,
                  BusinessUnits: [],
                  uniqueId: uuid(),
                },
              ]);
            }}
          >
            {t("General.AddNewManager")}
          </Button>
          {officeManagersList.map((officeManagerData) => {
            return (
              <div
                key={`officeManagersDialogRowWrapper${officeManagerData.uniqueId}`}
                className="gt-officeManagersDialog__row"
              >
                <GtOfficeManagersRow
                  uniqueId={officeManagerData.uniqueId}
                  officeManager={officeManagerData}
                  onChange={(data) => {
                    setOfficeManagersList(
                      officeManagersList.map((officeManager) => {
                        if (data.uniqueId === officeManager.uniqueId) {
                          return {
                            ...officeManager,
                            Office: data.office,
                            UserId: data.userId,
                            BusinessUnits: data.businessUnits,
                          };
                        }
                        return officeManager;
                      })
                    );
                  }}
                />
                <DeleteOutlinedIcon
                  onClick={() => {
                    setOfficeManagerToDelete(officeManagerData.uniqueId);
                    setIsVisibleOfficeManagerDeleteDialog(true);
                  }}
                />
              </div>
            );
          })}
        </article>
      </GtDialog>
      {isVisibleOfficeManagerDeleteDialog && (
        <GtOfficeManagerDeleteDialog
          onDeleteOfficeManager={() => {
            setOfficeManagersList(
              officeManagersList.filter(
                (item) => item.uniqueId !== officeManagerToDelete
              )
            );
            setOfficeManagerToDelete(null);
            setIsVisibleOfficeManagerDeleteDialog(false);
          }}
          onClose={() => {
            setIsVisibleOfficeManagerDeleteDialog(false);
          }}
        />
      )}
    </>
  );
}

export default GtOfficeManagersDialog;
