import EngagementServiceProcessors from "./Engagement";
import CalculationServiceProcessors from "./Calculation";
import CalculationSummaryServiceProcessors from "./CalculationSummary";

const ServiceProcessors = {
  Engagement: EngagementServiceProcessors,
  Calculation: CalculationServiceProcessors,
  CalculationSummary: CalculationSummaryServiceProcessors,
};
export default ServiceProcessors;
