import "./App.scss";
import "./utils/app-insights";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useEffect } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as Constants from "src/core/Constants/Constants";
import { AppContextProvider } from "./contexts/AppContext";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import router from "./router.config";
import { theme } from "./theming";
import { LoadingContextProvider } from "./contexts/LoadingContext";
import { SnackBarProvider } from "./contexts/SnackbarContext";
import { MaintenanceProvider } from "./contexts/MaintenanceContext";

const params = new URLSearchParams(window.location.search);
const businessUnit =
  (params.get("unit") as string as Constants.BusinessUnit) ||
  Constants.BusinessUnit.Outsourcing;

const Routes = (): JSX.Element => {
  useEffect(() => {
    document.title = "GT Engagement Module";
  }, []);

  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <MaintenanceProvider>
          <ThemeProvider theme={theme}>
            <AppContextProvider businessUnit={businessUnit}>
              <LoadingContextProvider>
                <SnackBarProvider>
                  <RouterProvider router={router} />
                </SnackBarProvider>
              </LoadingContextProvider>
            </AppContextProvider>
          </ThemeProvider>
        </MaintenanceProvider>
      </StyledEngineProvider>
    </>
  );
};

const App = ({ instance }: { instance: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={instance}>
      <Routes />
    </MsalProvider>
  );
};

export default App;
