import { useEffect, useState } from "react";

import { CorePropsInterfaces } from "src/core/Models";
import { TextField } from "@mui/material";

function QuestionReadonlyInput({
  question,
}: CorePropsInterfaces.QuestionInputProps) {
  const [fieldValue, setFieldValue] = useState(question.data.userValue);

  useEffect(() => {
    setFieldValue(question.data.userValue);
  }, [question.data.userValue]);

  return (
    <TextField
      data-testid={question.data.code}
      className="gt-TextField"
      disabled={true}
      value={fieldValue || ""}
      variant="outlined"
    />
  );
}

export default QuestionReadonlyInput;
