import { Button, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import React from "react";

export default function GtLegend(): JSX.Element {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        endIcon={<HelpOutlineIcon />}
        id="help-button"
        aria-controls={open ? "help-popover" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {t("General.Help")}
      </Button>

      <Popover
        id="help-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <article className="gt-legend">
          <section className="gt-legend__item">
            <CheckBoxIcon className="gt-legend__item-filledIndicator" />
            <span>{t("General.Filled")}</span>
          </section>
          <section className="gt-legend__item">
            <CircleRoundedIcon className="gt-legend__item-notStandardIndicator" />
            <span>{t("General.NotStandard")}</span>
          </section>
          <section className="gt-legend__item">
            <CircleRoundedIcon className="gt-legend__item-defaultIndicator" />
            <span>{t("General.Default")}</span>
          </section>
          <section className="gt-legend__item">
            <ReplayRoundedIcon className="gt-legend__item-revertIndicator" />
            <span>{t("General.Revert")}</span>
          </section>
        </article>
      </Popover>
    </>
  );
}
