import { t } from "i18next";
import { CoreInterfaces, CorePropsInterfaces } from "src/core/Models";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as Constants from "../../core/Constants";
import { useContext, useState } from "react";
import { AppContext } from "src/contexts/AppContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function GtOfficeManagersRow({
  uniqueId,
  officeManager,
  onChange,
}: CorePropsInterfaces.GtOfficeManagersRowProps): JSX.Element {
  const { globalState } = useContext(AppContext);
  const [userId, setUserId] = useState(officeManager.UserId);
  const [office, setOffice] = useState(officeManager.Office);
  const [businessUnits, setBusinessUnits] = useState(
    officeManager.BusinessUnits
  );
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function onValuesChange(
    userId: string,
    office: string,
    businessUnits: Array<string>
  ): void {
    onChange({
      uniqueId,
      userId,
      office,
      businessUnits,
    });
  }

  return (
    <article className="gt-officeManagersRow">
      <section className="gt-officeManagersRow__ownerSection">
        <Select
          value={userId ?? Constants.HelpfulConstants.PleaseSelect}
          onChange={(e) => {
            setUserId(e.target.value);
            onValuesChange(e.target.value, office, businessUnits);
          }}
        >
          <MenuItem
            key={Constants.HelpfulConstants.PleaseSelect}
            value={Constants.HelpfulConstants.PleaseSelect}
          >
            {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
          </MenuItem>
          {globalState.remoteData.gtContactPersons.map(
            (gtContactPerson: CoreInterfaces.ContactPersonItem) => (
              <MenuItem
                key={`${gtContactPerson.key}`}
                value={gtContactPerson.key}
              >
                {gtContactPerson.firstName} {gtContactPerson.lastName}
              </MenuItem>
            )
          )}
        </Select>
      </section>
      <section className="gt-officeManagersRow__areaSection">
        <Select
          value={office ?? Constants.HelpfulConstants.PleaseSelect}
          onChange={(e) => {
            setOffice(e.target.value);
            onValuesChange(userId, e.target.value, businessUnits);
          }}
        >
          <MenuItem
            key={Constants.HelpfulConstants.PleaseSelect}
            value={Constants.HelpfulConstants.PleaseSelect}
          >
            {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
          </MenuItem>
          {globalState.remoteData.employeesOffices
            .map((office) => office.Name)
            .map((office: string) => (
              <MenuItem key={`${office}`} value={office}>
                {office}
              </MenuItem>
            ))}
        </Select>
      </section>
      <section className="gt-officeManagersRow__businessUnitSection">
        <Autocomplete
          multiple
          value={businessUnits || []}
          options={Object.values(Constants.BusinessUnit)}
          disableCloseOnSelect
          getOptionLabel={(option) => {
            const translatedOption = t(`Options.BusinessArea.${option}`);
            if (translatedOption.length > 20) {
              return `${translatedOption.substring(0, 20)}...`;
            }
            return translatedOption;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {t(`Options.BusinessArea.${option}`)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                businessUnits?.length === 0
                  ? t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)
                  : null
              }
            />
          )}
          limitTags={businessUnits?.slice(0, 2).join("").length > 35 ? 1 : 2}
          onChange={(e, value) => {
            setBusinessUnits(value);
            onValuesChange(userId, office, value);
          }}
        />
      </section>
    </article>
  );
}

export default GtOfficeManagersRow;
