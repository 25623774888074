import { CoreInterfaces } from "src/core/Models";
import * as Constants from "./../core/Constants";

export function isAdministrator(globalState: CoreInterfaces.AppState): boolean {
  return globalState.remoteData.userRoles.includes(
    Constants.UserRoles.Administrator
  );
}

export function isOfficeManager(globalState: CoreInterfaces.AppState): boolean {
  return globalState.remoteData.userRoles.includes(
    Constants.UserRoles.OfficeManager
  );
}
