import { t } from "i18next";
import { useContext, useState } from "react";
import {
  ComponentStates,
  CoreInterfaces,
  CorePropsInterfaces,
  DTOs,
} from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useQuery } from "src/core/Hooks";
import { fetchApplicationConfigurationData } from "src/utils";
import { AppContext } from "src/contexts/AppContext";
import GtDialog from "./GtDialog";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { handleConfigurationPersistence } from "src/utils/handle-configuration-utils";
import * as StateProcessors from "src/core/Context/state-processors";
import { LoadingContext } from "src/contexts/LoadingContext";

function GtUpdateRatesDialog({
  onDialogClose,
  afterUpdateRates,
}: CorePropsInterfaces.GtUpdateRatesDialogProps): JSX.Element {
  const { globalState, dispatch, businessUnit } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);

  const [dialogState, setDialogState] =
    useState<ComponentStates.UpdateRatesDialogState>({
      updateRateCartConfiguration: true,
      updateTaskAllocationConfiguration: true,
      updateTaskCostConfiguration: true,
      updateOfficeCostConfiguration: true,
    });

  const updateRatesDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.UpdateRates"),
      maxWidth: "md",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => onDialogClose()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={
            !(
              dialogState.updateRateCartConfiguration ||
              dialogState.updateTaskAllocationConfiguration ||
              dialogState.updateTaskCostConfiguration
            )
          }
          onClick={() => updateRates()}
        >
          {t("General.Update")}
        </Button>,
      ],
    },
    api: {
      onClose: onDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function getAlterAppState(
    applicationConfigurationData: CoreInterfaces.ApplicationConfigurationReadPack
  ): CoreInterfaces.AppState {
    const applicationConfiguration = {
      RateCartConfiguration: dialogState.updateRateCartConfiguration
        ? applicationConfigurationData.RateCartConfiguration
        : null,
      TaskAllocationConfiguration: dialogState.updateTaskAllocationConfiguration
        ? applicationConfigurationData.TaskAllocationConfiguration
        : null,
      TaskCostConfiguration: dialogState.updateTaskCostConfiguration
        ? applicationConfigurationData.TaskCostConfiguration
        : null,
    };

    const services =
      StateProcessors.updateServicesOnApplicationConfigurationChange(
        globalState.services,
        applicationConfiguration,
        true
      );
    const currentApplicationConfiguration =
      globalState.currentConfiguration.applicationConfiguration;

    const appState: CoreInterfaces.AppState = {
      ...globalState,
      services,
      currentConfiguration: {
        ...globalState.currentConfiguration,
        applicationConfiguration: {
          ...globalState.currentConfiguration.applicationConfiguration,
          rateCartConfiguration:
            applicationConfiguration.RateCartConfiguration ??
            currentApplicationConfiguration.rateCartConfiguration,
          taskCostConfigurationVersion:
            !!applicationConfiguration.TaskCostConfiguration
              ? applicationConfiguration.TaskCostConfiguration.Version
              : currentApplicationConfiguration.taskCostConfigurationVersion,
          taskAllocationConfigurationVersion:
            !!applicationConfiguration.TaskAllocationConfiguration
              ? applicationConfiguration.TaskAllocationConfiguration.Version
              : currentApplicationConfiguration.taskAllocationConfigurationVersion,
        },
      },
    };

    return appState;
  }

  function updateRates(): void {
    updateApplicationLoadingState(true);
    fetchApplicationConfigurationData()
      .then((applicationConfigurationData) => {
        const appState = getAlterAppState(applicationConfigurationData);
        handleConfigurationPersistence(
          Constants.APIPath.EngagementConfiguration,
          Constants.APIMethod.PUT,
          appState,
          dispatch,
          businessOpportunityId,
          businessUnit,
          clientId,
          globalState.currentConfiguration.description,
          globalState.currentConfiguration.version,
          globalState.currentConfiguration.engagementLetterMetadata
        )
          .then(() => {
            dispatch({
              type: Constants.AppStateActions
                .ApplicationConfigurationVersionsChanged,
              payload: {
                rateCart:
                  applicationConfigurationData.RateCartConfiguration.Version,
                taskCost:
                  applicationConfigurationData.TaskCostConfiguration.Version,
                taskAllocation:
                  applicationConfigurationData.TaskAllocationConfiguration
                    .Version,
              },
            });

            const payload = {
              RateCartConfiguration: dialogState.updateRateCartConfiguration
                ? applicationConfigurationData.RateCartConfiguration
                : appState.currentConfiguration.applicationConfiguration
                    .rateCartConfiguration,
              TaskAllocationConfiguration:
                dialogState.updateTaskAllocationConfiguration
                  ? applicationConfigurationData.TaskAllocationConfiguration
                  : {
                      Version:
                        appState.currentConfiguration.applicationConfiguration
                          .taskAllocationConfigurationVersion,
                      Description:
                        appState.currentConfiguration.applicationConfiguration
                          .taskAllocationConfigurationDescription,
                    },
              TaskCostConfiguration: dialogState.updateTaskCostConfiguration
                ? applicationConfigurationData.TaskCostConfiguration
                : {
                    Version:
                      appState.currentConfiguration.applicationConfiguration
                        .taskCostConfigurationVersion,
                    Description:
                      appState.currentConfiguration.applicationConfiguration
                        .taskCostConfigurationDescription,
                  },
            };

            dispatch({
              type: Constants.AppStateActions.UpdateApplicationConfiguration,
              payload,
            });
          })
          .finally(() => {
            updateApplicationLoadingState(false);
          });
        afterUpdateRates({
          severity: Constants.AlertSeverity.Success,
          isDisplayed: true,
          message: "General.UpdateRatesSuccess",
        });
      })
      .catch(() => {
        afterUpdateRates({
          severity: Constants.AlertSeverity.Error,
          isDisplayed: true,
          message: "General.UpdateRatesFail",
        });
        updateApplicationLoadingState(false);
      });
  }

  return (
    <GtDialog gtDialogDTO={updateRatesDialogDTO}>
      <article className="gt-form">
        <section className="gt-form__info">
          {t("General.PleaseSelectConfigurationToBeUpdated")}
        </section>
        <section className="gt-form__fieldSection">
          <FormControlLabel
            label={t("General.RateCartConfiguration")}
            control={
              <Checkbox
                size="small"
                checked={dialogState.updateRateCartConfiguration}
                onChange={(e) => {
                  setDialogState((currentState) => {
                    return {
                      ...currentState,
                      updateRateCartConfiguration: e.target.checked,
                    };
                  });
                }}
              />
            }
          />
          <FormHelperText>
            {
              globalState.currentConfiguration.applicationConfiguration
                .rateCartConfiguration?.Description
            }
          </FormHelperText>
        </section>
        <section className="gt-form__fieldSection">
          <FormControlLabel
            label={t("General.TaskAllocationConfiguration")}
            control={
              <Checkbox
                size="small"
                checked={dialogState.updateTaskAllocationConfiguration}
                onChange={(e) => {
                  setDialogState((currentState) => {
                    return {
                      ...currentState,
                      updateTaskAllocationConfiguration: e.target.checked,
                    };
                  });
                }}
              />
            }
          />
          <FormHelperText>
            {
              globalState.currentConfiguration.applicationConfiguration
                .taskAllocationConfigurationDescription
            }
          </FormHelperText>
        </section>
        <section className="gt-form__fieldSection">
          <FormControlLabel
            label={t("General.TaskCostConfiguration")}
            control={
              <Checkbox
                size="small"
                checked={dialogState.updateTaskCostConfiguration}
                onChange={(e) => {
                  setDialogState((currentState) => {
                    return {
                      ...currentState,
                      updateTaskCostConfiguration: e.target.checked,
                    };
                  });
                }}
              />
            }
          />
          <FormHelperText>
            {
              globalState.currentConfiguration.applicationConfiguration
                .taskCostConfigurationDescription
            }
          </FormHelperText>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtUpdateRatesDialog;
