import { CorePropsInterfaces } from "src/core/Models";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import * as Constants from "src/core/Constants/Constants";

import { t } from "i18next";
import { useQuery } from "src/core/Hooks";

function GtEngagementLetterDocumentsManagerPanelLinks(
  props: CorePropsInterfaces.GtEngagementLetterDocumentsManagerPanelLinksProp
) {
  const {
    engagementConfigurationId,
    links,
    hasDownloadLink,
    isReadonlyMode,
    deleteHandler,
    clickHandler,
  } = props;
  const queryParamsMap = useQuery();
  const businessUnit = queryParamsMap.get(Constants.QueryParams.unit);
  return (
    <ul className="gt-documentsList">
      {links.map((link) => (
        <li
          className="gt-documentsList__li"
          key={`engagementDocument${link.Id}`}
        >
          <span className="gt-documentsList__li-link">
            {isReadonlyMode ? (
              <>{link.OriginalFileName}</>
            ) : !!clickHandler ? (
              <a onClick={() => clickHandler(link)} href="#">
                {link.OriginalFileName}
              </a>
            ) : (
              <a
                target="_blank"
                rel="noreferrer"
                href={`/api/engagementConfigurationDocument/${engagementConfigurationId}/documents/${link.Id}`}
              >
                {link.OriginalFileName}
              </a>
            )}
          </span>

          {hasDownloadLink && (
            <span className="gt-documentsList__li-cta">
              <a
                className="gt-documentsList__li-cta-a"
                style={{ color: "black" }}
                href={`/api/engagementConfigurationDocument/${engagementConfigurationId}/documents/${link.Id}`}
              >
                <DownloadIcon />
              </a>
            </span>
          )}
          {!isReadonlyMode && !!deleteHandler && (
            <span className="gt-documentsList__li-cta">
              <a
                onClick={() => deleteHandler(link)}
                style={{ color: "black" }}
                href="#"
              >
                <DeleteOutlineIcon />
              </a>
            </span>
          )}
        </li>
      ))}
    </ul>
  );
}

export default GtEngagementLetterDocumentsManagerPanelLinks;
