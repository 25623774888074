import { t } from "i18next";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import * as Constants from "../../core/Constants";
import { useContext, useState } from "react";
import { fetchData } from "src/utils";
import { useSnackBar } from "src/contexts/SnackbarContext";
import { AppContext } from "src/contexts/AppContext";
import { LoadingContext } from "src/contexts/LoadingContext";
import { Telegram } from "@mui/icons-material";

function GtChangeTeamDialog({
  engagementLettersSummaryItem,
  onTeamChanged,
  onChangeTeamDialogClose,
}: CorePropsInterfaces.GtChangeTeamDialogProps): JSX.Element {
  const { showSnackBar } = useSnackBar();
  const [team, setTeam] = useState(engagementLettersSummaryItem.Team);
  const { globalState } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const changeTeamDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.ChangeTeamFor", {
        companyName: engagementLettersSummaryItem.CompanyName,
      }),
      maxWidth: "md",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onChangeTeamDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={!Telegram}
          onClick={() => {
            saveTeam(engagementLettersSummaryItem, team);
          }}
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: onChangeTeamDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function saveTeam(
    engagementLettersSummaryItem: CoreInterfaces.EngagementLettersSummaryItem,
    team: CoreInterfaces.Team
  ): void {
    updateApplicationLoadingState(true);
    const payload: CoreInterfaces.EngagementConfigurationTeamUpdate = {
      EngagementConfigurationId: engagementLettersSummaryItem.Id,
      BusinessOpportunityId: engagementLettersSummaryItem.BusinessOpportunityId,
      NewTeam: team,
    };
    fetchData(Constants.APIPath.SetTeam, Constants.APIMethod.PUT, payload)
      .then(() => {
        onTeamChanged();
      })
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar(t("General.AnErrorHasOccured"), "error");
      });
  }

  return (
    <GtDialog gtDialogDTO={changeTeamDialogDTO}>
      <article className="gt-changeAssignmentDialog">
        <section className="gt-changeAssignmentDialog__currentAssignmentSection">
          <span>{t("General.CurrentTeam")}</span>
          <TextField
            variant="outlined"
            value={`${engagementLettersSummaryItem.Team?.DisplayName || ""}`}
            disabled={true}
          />
        </section>
        <section className="gt-changeAssignmentDialog__newAssignmentSection">
          <span>{t("General.NewTeam")}</span>
          <Select
            value={team?.Id ?? Constants.HelpfulConstants.PleaseSelect}
            onChange={(e) => {
              const newTeam = globalState.remoteData.teams.find(
                (item) => item.Id === e.target.value
              );
              setTeam(newTeam);
            }}
          >
            <MenuItem
              key={Constants.HelpfulConstants.PleaseSelect}
              value={Constants.HelpfulConstants.PleaseSelect}
            >
              {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
            </MenuItem>
            {globalState.remoteData.teams.map((team: CoreInterfaces.Team) => (
              <MenuItem key={`${team.Id}`} value={team.Id}>
                {team.DisplayName}
              </MenuItem>
            ))}
          </Select>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtChangeTeamDialog;
