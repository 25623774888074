import { CorePropsInterfaces } from "src/core/Models";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import * as Constants from "src/core/Constants/Constants";
import { useTranslation } from "react-i18next";

export default function GTNotificationBanner({
  systemAdministrationMessage,
  shouldTranslateValue,
  onBannerClose,
}: CorePropsInterfaces.GTNotificationBannerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <article
      className={`gt-banner 
          ${
            systemAdministrationMessage.type ===
            Constants.SystemAdministrationMessageTypes.Information
              ? "gt-banner--infoState"
              : ""
          } 
          ${
            systemAdministrationMessage.type ===
            Constants.SystemAdministrationMessageTypes.Alert
              ? "gt-banner--alertState"
              : ""
          } 
      `}
    >
      <section className="gt-banner__icon">
        {systemAdministrationMessage.type ===
          Constants.SystemAdministrationMessageTypes.Alert && (
          <ErrorOutlineIcon />
        )}
        {systemAdministrationMessage.type ===
          Constants.SystemAdministrationMessageTypes.Information && (
          <CheckCircleOutlineIcon />
        )}
      </section>
      <section className="gt-banner__main">
        <div className="gt-banner__main-title">
          {shouldTranslateValue
            ? t(systemAdministrationMessage.title)
            : systemAdministrationMessage.title}
        </div>
        <div className="gt-banner__main-message">
          {shouldTranslateValue
            ? t(systemAdministrationMessage.message)
            : systemAdministrationMessage.message}
        </div>
        {systemAdministrationMessage.link && (
          <div className="gt-banner__main-link">
            <a
              href={systemAdministrationMessage.link}
              target="_blank"
              rel="noreferrer"
            >
              {shouldTranslateValue
                ? t(systemAdministrationMessage.linkText)
                : systemAdministrationMessage.linkText}
            </a>
          </div>
        )}
      </section>
      <section className="gt-banner__closeBtn">
        <CloseIcon onClick={onBannerClose} />
      </section>
    </article>
  );
}
