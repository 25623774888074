import { createBrowserRouter } from "react-router-dom";
import Root from "./routes/root";
import * as Pages from "./pages";
import * as Constants from "./core/Constants/Constants";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={Pages.ErrorPage}>
    <Root />
  </ErrorBoundary>
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <ErrorBoundaryLayout />,
    errorElement: <Pages.ErrorPage />,
    children: [
      {
        path: Constants.Routes.Home,
        element: <Pages.ServicesPage />,
      },
      {
        path: Constants.Routes.Question,
        element: <Pages.QuestionsPage />,
      },
      {
        path: Constants.Routes.PriceAndTimeCalculation,
        element: <Pages.PriceAndTimeCalculationPage />,
      },
      {
        path: Constants.Routes.EngagementDescription,
        element: <Pages.EngagementDescriptionPage />,
      },
      {
        path: Constants.Routes.GenerateDocuments,
        element: <Pages.GenerateDocumentsPage />,
      },
      {
        path: Constants.Routes.EngagementLettersSummaryView,
        element: <Pages.EngagementLettersSummaryViewPage />,
      },
    ],
  },
  {
    path: Constants.Routes.Logout,
    element: <Pages.LogoutPage />,
  },
]);

export default router;
