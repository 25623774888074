import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "../../core/Constants/Constants";
import { findQuestionInService, resolveContactPerson } from "src/utils";

export default function CorporateCardManagementSummaryPanel(): JSX.Element {
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);

  const service = globalState.services.find(
    (item) => item.data.code === Constants.ServiceCode.CorporateCardManagement
  );

  const contactPersonQuestion = findQuestionInService(
    service,
    Constants.CorporateCardManagementQuestion.Q0311
  );
  const contactPerson = resolveContactPerson(
    contactPersonQuestion?.data?.options?.find(
      (item) => item.key === contactPersonQuestion.data.userValue
    )?.value
  );

  const nrCorporateCards = findQuestionInService(
    service,
    Constants.CorporateCardManagementQuestion.Q0301
  )?.data?.userValue;
  const nrReceipts = findQuestionInService(
    service,
    Constants.CorporateCardManagementQuestion.Q0302
  )?.data?.userValue;

  const corporateCardsText = nrCorporateCards
    ? `${t("PanelHeader.NrOfCoporateCards")} ${t("PanelHeader.CorporateCards", {
        nrCorporateCards,
      })}`
    : t("PanelHeader.NrOfCoporateCards");
  const receiptsText = nrReceipts
    ? `${t("PanelHeader.NrOfReceipts")} ${t("PanelHeader.Receipts", {
        nrReceipts,
      })}`
    : t("PanelHeader.NrOfReceipts");

  return (
    <article className="gt-summaryPanel">
      <div className="gt-summaryPanel__contactPerson">
        {t("PanelHeader.ContactPersonClientText", { contactPerson })}
      </div>
      <div className="gt-summaryPanel__data">{corporateCardsText}</div>
      <div className="gt-summaryPanel__data">{receiptsText}</div>
    </article>
  );
}
