import { CoreInterfaces, DTOs } from "src/core/Models";
import { AccountsReceivableProcessors } from "./AccountsReceivable";
import { AccountsPayableProcessors } from "./AccountsPayable";
import { GeneralSectionProcessors } from "./GeneralSection";
import { CorporateCardManagementProcessors } from "./CorporateCardManagement";
import { OtherAccountAndReconciliationProcessor } from "./OtherAccountAndReconciliation";
import { PeriodReportingProcessor } from "./PeriodReporting";
import { InterimListedCompaniesProcessor } from "./InterimListedCompanies";
import { AccountsReceivableTaskProcessor } from "../Tasks/AccountsReceivable";
import { CorporateCardManagementTaskProcessor } from "../Tasks/CorporateCardManagement";
import { PayrollAndExpenseAndTravelInvoiceManagementProcessor } from "./PayrollAndExpenseAndTravelInvoiceManagement";
import { OtherAccountAndReconciliationTaskProcessor } from "../Tasks/OtherAccountAndReconciliation";
import { PeriodReportingTaskProcessor } from "../Tasks/PeriodReporting";
import { AccountsPayableTaskProcessor } from "../Tasks/AccountsPayable";
import { PayrollAndExpenseAndTravelInvoiceManagementTaskProcessor } from "../Tasks/PayrollAndExpenseAndTravelInvoiceManagement";
import { InterimListedCompaniesTaskProcessor } from "../Tasks/InterimListedCompanies";
import { StartUpPayrollProcessor } from "./StartUpPayroll";
import { AnnualReportingListedCompaniesProcessor } from "./AnnualReportingListedCompanies";
import { AnnualReportingListedCompaniesTaskProcessor } from "../Tasks/AnnualReportingListedCompanies";
import { StartUpAccountingProcessor } from "./StartUpAccounting";
import { StartUpAccountingTaskProcessor } from "../Tasks/StartUpAccounting";
import { YearlyInternalDocumentationAndFormalitiesTaskProcessor } from "../Tasks/YearlyInternalDocumentationAndFormalities";
import { AnnualPayrollRoutinesTaskProcessor } from "../Tasks/AnnualPayrollRoutines";
import { AnnualReportingProcessor } from "./AnnualReporting";
import { AnnualReportingTaskProcessor } from "../Tasks/AnnualReporting";

const QuestionsServiceProcessors: CoreInterfaces.QuestionsServiceProcessorMap<CoreInterfaces.QuestionProcessors> =
  {
    AccountsReceivable: AccountsReceivableProcessors,
    AccountsPayable: AccountsPayableProcessors,
    GeneralInformation: GeneralSectionProcessors,
    CorporateCardManagement: CorporateCardManagementProcessors,
    OtherAccountAndReconciliation: OtherAccountAndReconciliationProcessor,
    PeriodReporting: PeriodReportingProcessor,
    AnnualReporting: AnnualReportingProcessor,
    AnnualReportingListedCompanies: AnnualReportingListedCompaniesProcessor,
    InterimListedCompanies: InterimListedCompaniesProcessor,
    PayrollAndExpenseAndTravelInvoiceManagement:
      PayrollAndExpenseAndTravelInvoiceManagementProcessor,
    StartUpPayroll: StartUpPayrollProcessor,
    StartUpAccounting: StartUpAccountingProcessor,
  };

const TaskServiceProcessors: CoreInterfaces.ServiceTaskProcessorMap<CoreInterfaces.ServiceTaskProcessor> =
  {
    AccountsReceivable: AccountsReceivableTaskProcessor,
    AccountsPayable: AccountsPayableTaskProcessor,
    CorporateCardManagement: CorporateCardManagementTaskProcessor,
    OtherAccountAndReconciliation: OtherAccountAndReconciliationTaskProcessor,
    PeriodReporting: PeriodReportingTaskProcessor,
    InterimListedCompanies: InterimListedCompaniesTaskProcessor,
    AnnualReporting: AnnualReportingTaskProcessor,
    AnnualReportingListedCompanies: AnnualReportingListedCompaniesTaskProcessor,
    PayrollAndExpenseAndTravelInvoiceManagement:
      PayrollAndExpenseAndTravelInvoiceManagementTaskProcessor,
    AnnualPayrollRoutines: AnnualPayrollRoutinesTaskProcessor,
    StartUpAccounting: StartUpAccountingTaskProcessor,
    YearlyInternalDocumentationAndFormalities:
      YearlyInternalDocumentationAndFormalitiesTaskProcessor,
  };

export function applyUpdatesOnQuestionChange(
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
): void {
  if (serviceDTO) {
    const questionsProcessorsFn =
      QuestionsServiceProcessors[serviceDTO.data.code];
    if (!!questionsProcessorsFn) {
      const questionProcessors = questionsProcessorsFn(appState, serviceDTO);
      serviceDTO.data.questions.forEach((question: DTOs.QuestionDTO) => {
        const processor = questionProcessors[question.data.code];
        if (processor) {
          processor(question);
        }
      });
    }

    // update service tasks after the updates on questions have been applied
    const serviceTaskProcessor = TaskServiceProcessors[serviceDTO.data.code];
    if (!!serviceTaskProcessor) {
      serviceTaskProcessor(appState, serviceDTO);
    }
  }
}
