import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import { Button } from "@mui/material";

function GtEngagementLetterDocumentsManagerDeleteConfirm({
  onConfirm,
  onDialogClose,
}: CorePropsInterfaces.GtEngagementLetterDocumentsManagerDeleteDialogProps): JSX.Element {
  const finalDocumentDialogDTO: DTOs.GtDialogDTO = {
    data: {
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={onConfirm}
        >
          {t("General.Ok")}
        </Button>,
      ],
      title: (
        <span className="gt-deleteDocumentDialogIcon">
          {t("General.ConfirmDocumentDelete")}
        </span>
      ),
    },
    api: {
      onClose: onDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  return (
    <GtDialog gtDialogDTO={finalDocumentDialogDTO}>
      <article className="gt-deleteDocumentDialog">
        <section className="gt-deleteDocumentDialog__content">
          <span className="gt-deleteDocumentDialog__content-explanation">
            {t("General.ConfirmDocumentDeleteMessage")}
          </span>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtEngagementLetterDocumentsManagerDeleteConfirm;
