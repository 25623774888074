import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../../../core/Constants";
import { findQuestionInService } from "src/utils/shared";
import { getQuestionValue } from "src/utils";

export const AccountsReceivableTaskProcessor: CoreInterfaces.ServiceTaskProcessor =
  (appState: CoreInterfaces.AppState, serviceDTO: DTOs.ServiceDTO): void => {
    const questionsMap = {
      q0103: findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0103
      ),
      q0105: findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0105
      ),
      q0107: findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0107
      ),
      q0109: findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0109
      ),
      q0110: findQuestionInService(
        serviceDTO,
        Constants.AccountReceivableQuestion.Q0110
      ),
    };

    serviceDTO.data.tasks.forEach((taskDTO) => {
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0101) {
        taskDTO.state.isVisible =
          questionsMap.q0103.state.isShown &&
          questionsMap.q0103.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0102) {
        taskDTO.state.isVisible =
          questionsMap.q0103.state.isShown &&
          questionsMap.q0103.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0103) {
        taskDTO.state.isVisible =
          questionsMap.q0103.state.isShown &&
          questionsMap.q0103.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0104) {
        taskDTO.state.isVisible =
          questionsMap.q0103.state.isShown &&
          questionsMap.q0103.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0105) {
        taskDTO.state.isVisible =
          appState.generalVariables.costCenter === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0106) {
        taskDTO.state.isVisible =
          questionsMap.q0109.state.isShown &&
          questionsMap.q0109.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0107) {
        taskDTO.state.isVisible =
          questionsMap.q0110.state.isShown &&
          questionsMap.q0110.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0108) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0110) {
        const { Handelsbanken, SEB, Swedbank, Nordea } = Constants.CustomerBank;

        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.No &&
          appState.generalVariables.customerBank &&
          [Handelsbanken, SEB, Swedbank, Nordea].includes(
            appState.generalVariables.customerBank as Constants.CustomerBank
          );
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0111) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.No &&
          appState.generalVariables.customerBank ===
            Constants.CustomerBank.DanskeBank;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0112) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.No &&
          appState.generalVariables.customerBank ===
            Constants.CustomerBank.Otherbanks;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0113) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0114) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.Yes;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0115) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0116) {
        taskDTO.state.isVisible =
          questionsMap.q0105.state.isShown &&
          questionsMap.q0105.data.userValue === Constants.YesNo.No;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0118) {
        taskDTO.state.isVisible =
          questionsMap.q0107.state.isShown &&
          questionsMap.q0107.data.userValue ===
            Constants.InvoicingTime.InAdvance;
      }
      if (taskDTO.data.code === Constants.AccountReceivableTasks.T0119) {
        taskDTO.state.isVisible =
          questionsMap.q0107.state.isShown &&
          questionsMap.q0107.data.userValue !==
            Constants.InvoicingTime.InAdvance &&
          questionsMap.q0107.data.userValue !==
            Constants.HelpfulConstants.PleaseSelect;
      }
    });

    if (serviceDTO.data.additionalCosts) {
      serviceDTO.data.additionalCosts.forEach((eachAdditionalCost) => {
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountReceivableAdditionalCosts.T0122
        ) {
          eachAdditionalCost.state.isVisible =
            questionsMap.q0105.state.isShown &&
            getQuestionValue(questionsMap.q0105) === Constants.YesNo.Yes;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountReceivableAdditionalCosts.T0123
        ) {
          eachAdditionalCost.state.isVisible =
            !appState.generalVariables.isDefaultAccountingSystem;
        }
        if (
          eachAdditionalCost.data.code ===
          Constants.AccountReceivableAdditionalCosts.T0124
        ) {
          eachAdditionalCost.state.isVisible =
            appState.generalVariables.isDefaultAccountingSystem;
        }
      });
    }
  };
