import { CoreInterfaces } from "src/core/Models";
import * as Constants from "../core/Constants";
import { formServiceConfigurationWritePack } from "./dto";
import { fetchData } from "./api";
import { updateStateOnEngagementConfigurationRead } from "./shared";

export function handleConfigurationPersistence(
  url: string,
  fetchMethod: Constants.APIMethod,
  appState: CoreInterfaces.AppState,
  dispatchFn: Function,
  businessOpportunityId: string,
  businessUnit: string,
  clientId: string,
  description: string,
  version: number,
  engagementLetterMetadata: CoreInterfaces.EngagementLetterMetadata,
  digitalSigningDetails: CoreInterfaces.DigitalSigningDetails = appState
    .currentConfiguration.digitalSigningDetails,
  totalCost: number = appState.currentConfiguration.totalCost,
  fetchResolveCallbackFn: (serverReturn: any) => void = null,
  engagementDocumentsLanguage: Constants.Languages = null
): Promise<CoreInterfaces.EngagementConfigurationReadPack> {
  const configurationWritePack: CoreInterfaces.EngagementConfigurationWritePack =
    formServiceConfigurationWritePack(
      appState,
      businessOpportunityId,
      businessUnit,
      clientId,
      description,
      version,
      engagementLetterMetadata,
      digitalSigningDetails,
      totalCost,
      engagementDocumentsLanguage
    );

  return fetchData<CoreInterfaces.EngagementConfigurationReadPack>(
    `${url}`,
    fetchMethod,
    configurationWritePack
  )
    .then((serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
      if (!fetchResolveCallbackFn) {
        updateStateOnEngagementConfigurationRead(serverReturn, dispatchFn);
      } else {
        fetchResolveCallbackFn(serverReturn);
        return null;
      }
      return serverReturn;
    })
    .catch((error) => {
      if (error.status === 409) {
        error
          .json()
          .then(
            (saveConflictErrorResponse: CoreInterfaces.SaveConflictError) => {
              dispatchFn({
                type: Constants.AppStateActions.UpdateEngagementConflict,
                payload: {
                  showConflictUpdateDialog: true,
                  isConflictOnUpdateEngagement: true,
                  lastModifiedBy: saveConflictErrorResponse.LastModifiedBy,
                },
              });
            }
          );
      }
      throw error;
    });
}

export function saveCurrentConfiguration(
  appState: CoreInterfaces.AppState,
  dispatchFn: Function,
  businessOpportunityId: string,
  businessUnit: string,
  clientId: string
): Promise<CoreInterfaces.EngagementConfigurationReadPack> {
  return handleConfigurationPersistence(
    Constants.APIPath.EngagementConfiguration,
    Constants.APIMethod.PUT,
    appState,
    dispatchFn,
    businessOpportunityId,
    businessUnit,
    clientId,
    appState.currentConfiguration.description,
    appState.currentConfiguration.version,
    appState.currentConfiguration.engagementLetterMetadata
  );
}
