import React, { createContext, useEffect } from "react";
import * as Constants from "./../core/Constants";
import { checkMaintenanceMode, fetchData } from "src/utils";
import { CoreInterfaces } from "src/core/Models";

export const MaintenanceContext = createContext(null);

interface MaintenanceProviderProps {
  children: React.ReactNode;
}

export const MaintenanceProvider: React.FC<MaintenanceProviderProps> = ({
  children,
}: {
  children: any;
}) => {
  const handleMaintenanceMode = () => {
    checkMaintenanceMode()
      .then((serverResponse: CoreInterfaces.MaintenanceReadPack) => {
        if (serverResponse.ShouldRedirectUser && serverResponse.RedirectUrl) {
          window.location.href = serverResponse.RedirectUrl;
        }
      })
      .catch((Ex: any) => {
        console.error(Ex);
      });
  };

  useEffect(() => {
    handleMaintenanceMode();

    const interval = setInterval(() => {
      handleMaintenanceMode();
    }, +process.env.REACT_APP_CHECK_MAINTENANCE_MODE_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <MaintenanceContext.Provider value={null}>
      {children}
    </MaintenanceContext.Provider>
  );
};
