import * as Constants from "./../../../core/Constants";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlinedBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { DTOs, CorePropsInterfaces } from "src/core/Models";
import QuestionInput from "./QuestionInput";
import {
  debounce,
  questionHasDefaultAnswer,
  showConfigurationCodes,
  isLicenseDataFilled,
  isContactPersonDataFilled,
  isSelectableQuestionDataFilled,
  areAllFieldsCompleted,
  isRangeNumberQuestionDataFilled,
  calculateYearsDifference,
  checkContactPersonUserDetailsFilled,
  checkSelectableQuestionFilled,
} from "src/utils";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { TOptions } from "i18next";

function QuestionRow({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionRowProps): JSX.Element {
  const [t] = useTranslation();
  const [comment, setComment] = useState("");

  //check if is License Question Type
  const isLicenseQuestion =
    question.data.inputType === Constants.QuestionInputType.License;
  let isLicenseQuestionFilled = false;
  if (isLicenseQuestion) {
    isLicenseQuestionFilled = isLicenseDataFilled(question);
  }

  const isContactPersonUserDetails =
    question.data.inputType ===
    Constants.QuestionInputType.ContactPersonUserDetails;
  let isContactPersonUserDetailsQuestionFilled = false;
  if (isContactPersonUserDetails) {
    isContactPersonUserDetailsQuestionFilled =
      isContactPersonDataFilled(question) &&
      checkContactPersonUserDetailsFilled(question);
  }
  const isMultipleSelectQuestion =
    question.data.inputType === Constants.QuestionInputType.MultipleSelect;
  let isMultipleSelectDataFilled = false;
  if (isMultipleSelectQuestion) {
    isMultipleSelectDataFilled =
      isSelectableQuestionDataFilled(question) &&
      checkSelectableQuestionFilled(question);
  }

  const isHeadlineQuestion =
    question.data.inputType === Constants.QuestionInputType.Headline;

  const isRangeDateQuestion =
    question.data.inputType === Constants.QuestionInputType.FullDate;
  let isRangeDateQuestionFilled = false;
  if (isRangeDateQuestion) {
    const userValue = question.data.userValue as Date[];
    if (Array.isArray(userValue)) {
      const isOrderedCronologically = userValue[0] < userValue[1];
      isRangeDateQuestionFilled =
        isOrderedCronologically &&
        userValue.every((value) => value !== null) &&
        calculateYearsDifference(userValue[0], userValue[1]) < 5;
    }
  }

  const isRangeNumberQuestion =
    question.data.inputType === Constants.QuestionInputType.RangeNumber;
  let isRangeNumberQuestionFilled = false;
  if (isRangeNumberQuestion) {
    isRangeNumberQuestionFilled = isRangeNumberQuestionDataFilled(question);
  }
  const isAllDataFilled = areAllFieldsCompleted(question);

  const isDataIncomplete =
    (isLicenseQuestion && !isLicenseQuestionFilled) ||
    (isContactPersonUserDetails && !isContactPersonUserDetailsQuestionFilled) ||
    (isMultipleSelectQuestion && !isMultipleSelectDataFilled) ||
    (isRangeDateQuestion && !isRangeDateQuestionFilled) ||
    (isRangeNumberQuestion && !isRangeNumberQuestionFilled);

  function onQuestionChange(question: DTOs.QuestionDTO): void {
    const isFilled =
      (question.data.userValue &&
        question.data.userValue !== Constants.HelpfulConstants.PleaseSelect) ||
      (isLicenseQuestion && isLicenseQuestionFilled) ||
      (isMultipleSelectQuestion && isMultipleSelectDataFilled) ||
      (isContactPersonUserDetails &&
        isContactPersonUserDetailsQuestionFilled) ||
      (isRangeNumberQuestion && isRangeNumberQuestionFilled);

    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      state: {
        ...question.state,
        isFilled: isFilled ? question.state.isFilled : false,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  function onCommentChange(evt: any) {
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        comment: evt?.target?.value,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  const debouncedChange = useCallback(
    debounce(async (evt: any) => {
      onCommentChange(evt);
    }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS),
    [onChange]
  );

  function onInputValueChangeDelayed(evt: any): void {
    setComment(evt.target.value);
    debouncedChange(evt);
  }

  function onFilledStateChange(isFilled: boolean) {
    if (isDisabled) {
      return;
    }
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      state: {
        ...question.state,
        isFilled:
          (question.data.userValue &&
            question.data.userValue ===
              Constants.HelpfulConstants.PleaseSelect &&
            !isRangeNumberQuestion) ||
          isDataIncomplete ||
          (question.data.inputType ===
            Constants.QuestionInputType.Autocomplete &&
            question.data.userValue === "")
            ? false
            : isFilled,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  useEffect(() => {
    setComment(question.data.comment);
  }, [question.data.comment]);

  return (
    <article
      className={`gt-questionRow 

    ${
      !!question.data.defaultValue &&
      question.data.userValue === question.data.defaultValue &&
      question.data.userValue !== Constants.HelpfulConstants.PleaseSelect
        ? "gt-questionRow--defaultAnswerState"
        : ""
    } 
    ${
      !questionHasDefaultAnswer(question)
        ? "gt-questionRow--notStandardState"
        : ""
    }
    ${
      !question.data.userValue ||
      question.data.userValue === Constants.HelpfulConstants.PleaseSelect ||
      isDataIncomplete
        ? "gt-questionRow--isNotAnsweredState"
        : ""
    } 
          
    ${isHeadlineQuestion ? "gt-questionRow--isHeadline" : ""}
    ${
      question.state.isFilled && isAllDataFilled
        ? "gt-questionRow--filledAnswerState"
        : ""
    }
    ${
      !question.state.isFilled && isAllDataFilled
        ? "gt-questionRow--unfilledAnswerState"
        : ""
    }
    ${
      isLicenseQuestion ||
      isContactPersonUserDetails ||
      isMultipleSelectQuestion
        ? "gt-questionRow--isLicense"
        : ""
    }
    ${
      question.state.isInfoTextVariat ? "gt-questionRow--isInfoTextVariat" : ""
    } `}
    >
      <section className="gt-questionRow__title">
        <div>
          {showConfigurationCodes() && <>[{question.data.code}]</>}
          <span
            data-testid={question.data.code + "Label"}
            className="gt-questionRow__title-text"
            dangerouslySetInnerHTML={{
              __html: `${t(
                question.data.text,
                question.data.translationParams as TOptions
              )} `,
            }}
          ></span>
        </div>

        {t(question.data.tooltipText) && (
          <Tooltip
            className="gt-questionRow__title-tooltip"
            data-testid={question.data.code + "Tooltip"}
            title={
              <span
                className="gt-tootipHint"
                data-testid={question.data.code + "TooltipHint"}
                dangerouslySetInnerHTML={{
                  __html: t(question.data.tooltipText),
                }}
              ></span>
            }
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </section>

      <section
        className="gt-questionRow__control"
        data-testid={question.data.code + "Input"}
      >
        <QuestionInput
          question={question}
          isDisabled={isDisabled}
          onChange={(question: DTOs.QuestionDTO) => onQuestionChange(question)}
        />
      </section>

      {question.data.inputType && !isHeadlineQuestion ? (
        <section className="gt-questionRow__indicators">
          <span className="gt-questionRow__indicators-icon">
            <CircleRoundedIcon fontSize="small" />
          </span>
          <span className="gt-questionRow__indicators-iconFill">
            {question.state.isFilled && isAllDataFilled ? (
              <CheckBoxIcon
                fontSize="small"
                onClick={() => onFilledStateChange(!question.state.isFilled)}
              />
            ) : (
              <CheckBoxOutlinedBlankIcon
                fontSize="small"
                onClick={() => onFilledStateChange(!question.state.isFilled)}
              />
            )}
          </span>
        </section>
      ) : (
        <section className="gt-questionRow__indicators"></section>
      )}
      {!isHeadlineQuestion && (
        <section className="gt-questionRow__comment">
          <TextField
            className="gt-questionRow__comment-input"
            value={comment}
            onChange={onInputValueChangeDelayed}
            multiline
            maxRows={2}
            inputProps={{ maxLength: 300 }}
            disabled={isDisabled}
          />
        </section>
      )}
    </article>
  );
}

export default QuestionRow;
