/**
 * Import here any 3rd party libs that needs to be used by the app
 */
import lodashRound from "lodash/round";
import lodashDebounce from "lodash/debounce";
import lodashCeil from "lodash/ceil";
import {
  format as dateFnsFormat,
  parseISO,
  isValid,
  parse,
  formatISO,
  isAfter,
  isBefore,
} from "date-fns";
// import { format as dateFnsTzFormat } from "date-fns-tz";
import dayjs from "dayjs";

export function round(n: number, precision?: number): number {
  return lodashRound(n, precision);
}

export function debounce(fn: (...args: any) => any, wait: number) {
  return lodashDebounce(fn, wait);
}

export function ceil(n: number, precision?: number): number {
  return lodashCeil(n, precision);
}

export function day(date?: dayjs.ConfigType): dayjs.Dayjs {
  return dayjs(date);
}

export function formatDate(
  date: number | Date,
  formatStr: string,
  options?: object
): string {
  return dateFnsFormat(date, formatStr, options);
}

export function dateToISO(date: Date): string {
  // const utcDate = utcToZonedTime(date, "UTC");
  const isoDate = formatISO(date);
  return isoDate;
}

export function stringToISODate(dateString: string): Date {
  return parseISO(dateString);
}

export function showConfigurationCodes(): boolean {
  return (
    process.env.REACT_APP_SHOW_CONFIGURATION_CODES?.toLowerCase() === "true"
  );
}

export function stringToArray(element: string) {
  return element ? element.split(",").map((item) => item.trim()) : [];
}

export function parseDate(dateString: string, format: string): Date {
  return parse(dateString, format, new Date());
}

export function isValidDate(date: Date): boolean {
  return isValid(date);
}

export function formatDateToISO(date: Date) {
  return formatISO(date);
}

export function isDateAfter(
  date: Date | number,
  dateToCompare: Date | number
): boolean {
  return isAfter(date, dateToCompare);
}

export function isDateBefore(
  date: Date | number,
  dateToCompare: Date | number
): boolean {
  return isBefore(date, dateToCompare);
}

export function calculateYearsDifference(
  startDate: Date,
  endDate: Date
): number {
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  let diffInYears = endDate.getFullYear() - startDate.getFullYear();

  if (
    endMonth < startMonth ||
    (endMonth === startMonth && endDate.getDate() <= startDate.getDate())
  ) {
    diffInYears--;
  }

  return diffInYears;
}
