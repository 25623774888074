import * as Constants from "../../core/Constants";

import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext } from "react";

import { AppContext } from "src/contexts/AppContext";
import GtDebouncedInput from "../Common/GtDebouncedInput";
import ServiceProcessors from "src/utils/processors/Services";
import { t } from "i18next";
import { getServiceStaffingAllocationExtendedSummaryPanelRows } from "src/utils";
import GtInfoTooltip from "../Common/GtInfoTooltip";

export default function ServiceStaffingAllocationExtendedSummaryPanel({
  serviceDTO,
  taskGroups = [Constants.TaskGroup.DefaultGroup],
}: CorePropsInterfaces.ServiceStaffingAllocationSummaryPanelProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);

  const onServiceCostAdjustmentChange = (costValue: string) => {
    dispatch({
      type: Constants.AppStateActions
        .UpdateServiceStaffingAdjustmentExtendedCost,
      payload: {
        serviceCode: serviceDTO.data.code,
        extendedPriceListPanelCost: costValue === "" ? null : Number(costValue),
      },
    });
  };

  const rows = getServiceStaffingAllocationExtendedSummaryPanelRows(
    globalState,
    serviceDTO,
    taskGroups
  );

  if (!rows.length) {
    return null;
  }

  const columns = getColumnsDefinition(
    onServiceCostAdjustmentChange,
    globalState.currentConfiguration.lastModifiedDate
  );

  return (
    <article
      className="gt-serviceStaffingAllocationSummaryPanel gt-serviceStaffingAllocationSummaryPanel--extendedVariant"
      data-testid={
        serviceDTO.data.code + "ServiceStaffingAllocationExtendedSummaryPanel"
      }
    >
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        rowHeight={44}
        columnHeaderHeight={
          serviceDTO.data.allocationSummaryPanel.hasTableHeader ? 48 : 0
        }
        hideFooterPagination={true}
      />
    </article>
  );
}

function getColumnsDefinition(
  onServiceCostAdjustmentChange: Function,
  lastVersionChangedDate: string
): Array<GridColDef> {
  const columnDefinition: Array<GridColDef> = [
    {
      field: "title",
      headerName: t(`General.StaffLevelPayrollWithPriceList`),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      cellClassName: ({ row }) => {
        return [
          Constants.CustomDataGridColumnId.TotalCost,
          Constants.CustomDataGridColumnId.AdjustmentOfCost,
          Constants.CustomDataGridColumnId.TotalWorkCost,
          Constants.CustomDataGridColumnId.AdditionalCostsTotalCost,
          Constants.CustomDataGridColumnId.PriceListCostRow,
          Constants.CustomDataGridColumnId.T0774Row,
          Constants.CustomDataGridColumnId.SumRow,
        ].includes(row.id)
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      colSpan: ({ row }) => {
        if (row.id === Constants.CustomDataGridColumnId.TotalWorkCost) {
          return 2;
        }
        if (
          [
            Constants.CustomDataGridColumnId.AdditionalCostsTotalCost,
            Constants.CustomDataGridColumnId.TotalCost,
            Constants.CustomDataGridColumnId.SumRow,
          ].includes(row.id)
        ) {
          return 3;
        }
        if (
          [
            Constants.CustomDataGridColumnId.AdjustmentOfCost,
            Constants.CustomDataGridColumnId.T0774Row,
            Constants.CustomDataGridColumnId.PriceListCostRow,
            Constants.CustomDataGridColumnId.AdjustmentOfCost,
          ].includes(row.id)
        ) {
          return 4;
        }
        return undefined;
      },
      renderCell(params) {
        return (
          <article className="gt-serviceTitleCell">
            <div
              className="gt-serviceTitleCell__title"
              data-testid={`${params.row.id}Title`}
            >
              {params.row.title}
            </div>
            {params.row.tooltip && (
              <GtInfoTooltip
                testIdPart={params.row.code}
                tooltipTitle={params.row.tooltip}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "pricePerHour",
      flex: 1,
      headerName: t("General.PricePerHour"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={`${params.row.id}PricePerHour`}>
            {params.row.pricePerHour}
          </article>
        );
      },
    },
    {
      field: "hoursAndMinutes",
      flex: 1,
      headerName: t("TableHeader.NumberOfHours"),
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={`${params.row.id}HoursAndMinutes`}>
            {params.row.hoursAndMinutes}
          </article>
        );
      },
    },
    {
      field: "sum",
      flex: 1,
      headerName: t("TableHeader.ReferenceCost"),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost ||
          row.id === Constants.CustomDataGridColumnId.TotalWorkCost ||
          row.id ===
            Constants.CustomDataGridColumnId.AdditionalCostsTotalCost ||
          row.id === Constants.CustomDataGridColumnId.SumRow
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        return (
          <article data-testid={`${params.row.id}Sum`}>
            {params.row.sum}
          </article>
        );
      },
    },
    {
      field: "fixedPriceFromPriceList",
      flex: 1,
      headerName: t(`TableHeader.FixedPriceFromPriceList`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (!row.fixedPriceFromPriceList) {
          if (row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost) {
            return "gt-noBorderCell gt-serviceStaffingAllocationSummaryPanel__highlightedCell";
          } else {
            return "gt-noBorderCell";
          }
        }
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.T0774Row ||
          row.id === Constants.CustomDataGridColumnId.PriceListCostRow ||
          row.id === Constants.CustomDataGridColumnId.SumRow ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
      renderCell(params) {
        if (
          params.row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost
        ) {
          return (
            <article className="gt-dataTableCellWrapper">
              <GtDebouncedInput
                value={params.row.fixedPriceFromPriceList ?? ""}
                onChange={onServiceCostAdjustmentChange}
                debounceTime={2 * Constants.INPUT_CHANGE_DEBOUNCE_MILLIS}
                updateTimestamp={lastVersionChangedDate}
                maxLength={8}
                size="small"
                className="gt-dataTableCellWrapper__textField gt-NumberField"
              />
              <section className="gt-dataTableCellWrapper__unit">
                {Constants.USED_CURRENCY}
              </section>
            </article>
          );
        }
        return params.row.fixedPriceFromPriceList;
      },
    },
    {
      field: "pricePerPayslip",
      flex: 1,
      headerName: t(`TableHeader.PricePerPayslip`),
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      cellClassName: ({ row }) => {
        if (!row.pricePerPayslip) {
          return "gt-noBorderCell";
        }
        return row.id === Constants.CustomDataGridColumnId.TotalCost ||
          row.id === Constants.CustomDataGridColumnId.T0774Row ||
          row.id === Constants.CustomDataGridColumnId.PriceListCostRow ||
          row.id === Constants.CustomDataGridColumnId.SumRow ||
          row.id === Constants.CustomDataGridColumnId.AdjustmentOfCost
          ? "gt-serviceStaffingAllocationSummaryPanel__highlightedCell"
          : "";
      },
    },
  ];
  return columnDefinition;
}
